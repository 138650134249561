import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as ApexCharts from 'apexcharts';

import { BsModalService } from 'ngx-bootstrap/modal';
import {
  ChartOptions,
  ColumnOptions,
  ColumnStackedOptions,
  PERIODENUM,
  PieChartOptions,
} from 'src/app/components/labels/others';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { DashBoardService } from './dashBoard.service';
import { Router } from '@angular/router';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  cardDetails: any;
  // PERIODENUM.map(value => value.replace(new RegExp('_', 'g'), ' ').toLowerCase())
  graphEnumList = PERIODENUM;
  agoFilterForm: FormControl = new FormControl(this.graphEnumList[getRandomInt(0, 9)]);
  pmsFilterForm: FormControl = new FormControl(this.graphEnumList[getRandomInt(0, 9)]);
  filterForm: FormControl = new FormControl(this.graphEnumList[getRandomInt(0, 9)]);
  filterForm2: FormControl = new FormControl(this.graphEnumList[getRandomInt(0, 9)]);

  columnOptions: any = ColumnOptions;
  columnChart: any;

  agoOptions: any = ColumnOptions;
  agoChart: any;

  jetOptions: any = ColumnOptions;
  jetChart: any;

  pmsOptions: any = ColumnOptions;
  pmsChart: any;

  lineChart: any;
  chartOptions = ChartOptions;

  pieChart: any;
  pieOptions: Partial<any> = PieChartOptions;

  topups!: number;
  sales!: number;

  stockCardList: Array<any> = [];
  cardsloading = false;

  constructor(
    public modalService: BsModalService,
    public apiConnect: ApiConnectionService,
    public dashBoardService: DashBoardService,
    private session: SessionStorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getDashBoardCards();
    this.getStockCard();
    this.getSalesTrend();
    this.getMarkedVols();
    this.getMarkedAgoVols();
    this.getMarkedJetVols();
    this.getMarkedPmsVols();
    this.loadCharts();

    this.filters();
    this.routerMove();
  }

  filters(): void {
    this.agoFilterForm.valueChanges.subscribe(() => {
      this.getMarkedAgoVols();
    });
    this.pmsFilterForm.valueChanges.subscribe(() => {
      this.getMarkedPmsVols();
    });
    this.filterForm.valueChanges.subscribe(() => {
      this.getMarkedVols();
    });
    this.filterForm2.valueChanges.subscribe(() => {
      this.getSalesTrend();
    });
  }

  getDashBoardCards(): void {
    this.cardsloading = true;
    this.dashBoardService.getCards((res, status) => {
      this.cardsloading = false;
      if (status) {
        this.cardDetails = res.data.reverse();
        // this.cardDetails.splice(2, 1);
      }
    });
  }

  getStockCard(): void {
    this.cardsloading = true;
    this.dashBoardService.getStockCard((res, status) => {
      this.cardsloading = false;
      if (status) {
        this.stockCardList = res.data;
      }
    });
  }

  getMarkedVols(): void {
    this.dashBoardService.getMarkedVols(
      'DashBoardMarkedFuelVol',
      (res, status) => {
        if (status) {
          this.columnChart.updateOptions({
            series: res.data.series,
            labels: res.data.labels,
          });
        }
      },
      undefined,
      this.filterForm.value
    );
  }

  getMarkedAgoVols(): void {
    this.dashBoardService.getMarkedVols(
      'DashBoardMarkedFuelAgoVol',
      (res, status) => {
        console.log(res);
        if (status) {
          this.agoChart.updateOptions({
            series: res.data.series,
            labels: res.data.labels,
          });
        }
      },
      undefined,
      this.agoFilterForm.value
    );
  }

  getMarkedJetVols(): void {
    this.dashBoardService.getMarkedVols(
      'DashBoardMarkedFuelJetVol',
      (res, status) => {
        if (status) {
          this.columnChart.updateOptions({
            series: res.data.series,
            labels: res.data.labels,
          });
        }
      },
      undefined,
      this.filterForm.value
    );
  }

  getMarkedPmsVols(): void {
    this.dashBoardService.getMarkedVols(
      'DashBoardMarkedFuelPmsVol',
      (res, status) => {
        if (status) {
          this.pmsChart.updateOptions({
            series: res.data.series,
            labels: res.data.labels,
          });
        }
      },
      undefined,
      this.pmsFilterForm.value
    );
  }

  getSalesTrend(): void {
    this.dashBoardService.getSalesTrend(
      (res, status) => {
        if (status) {
          this.lineChart.updateOptions({
            series: res.data.series,
            labels: res.data.labels,
          });
        }
      },
      undefined,
      this.filterForm2.value
    );
  }

  customControl(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl;
  }

  routerMove(): void {
    switch (this.session.getActiveUserRole()) {
      case 'DATA_ENTRY':
        this.router.navigate(['/app/job'], {
          queryParams: { type: 'DataEntryJobs' },
        });
        break;
      case 'LAB':
        this.router.navigate(['/app/job'], {
          queryParams: { type: 'LabJobs' },
        });
        break;
      case 'CASHIER':
        this.router.navigate(['/app/job'], {
          queryParams: { type: 'CashierJobs' },
        });
        break;
      case 'MANAGER':
        this.router.navigate(['/app/dashboard']);
        break;
      default:
        break;
    }
  }

  loadCharts(): void {
    this.pieChart = new ApexCharts(
      document.querySelector('#pie-chart'),
      this.pieOptions
    );
    this.pieChart.render();

    this.lineChart = new ApexCharts(
      document.querySelector('#line-chart'),
      this.chartOptions
    );
    this.lineChart.render();

    this.columnChart = new ApexCharts(
      document.querySelector('#column-chart'),
      this.columnOptions
    );
    this.agoChart = new ApexCharts(
      document.querySelector('#ago-chart'),
      this.agoOptions
    );
    this.jetChart = new ApexCharts(
      document.querySelector('#jet-chart'),
      this.jetOptions
    );
    this.pmsChart = new ApexCharts(
      document.querySelector('#pms-chart'),
      this.pmsOptions
    );
    this.columnChart.render();
    this.agoChart.render();
    this.jetChart.render();
    this.pmsChart.render();
  }
}
