import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { ARCHIVE, CASHIER, DATAETRY, LAB } from 'src/app/constants/form-labels/others';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';
import { JobService } from '../job.service';

class BaseClass { }
interface BaseClass extends ModalComponent, PaginatorComponent, FilterComponent { }
applyMixins(BaseClass, [ModalComponent, PaginatorComponent, FilterComponent]);

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent extends BaseClass implements OnInit {
  status: any[] = [];
  role: any;
  currentPage = 0;
  elementsPerPage = 10;
  showEntries: FormControl = new FormControl(10);
  filterEntry: FormControl = new FormControl(null);
  chunkedRecords: Array<any> = [];
  searchFilter = '';
  jobsList: any[] = [];

  loading = false;
  lab = false;

  constructor(
    public modalService: BsModalService,
    public apiConnect: ApiConnectionService,
    public router: Router,
    public route: ActivatedRoute,
    private jobService: JobService,
    private session: SessionStorageService
  ) {
    super();
    this.filterList = [
      'jobNumber',
      'shipmentNumber',
      'companyName',
      'vehicleNumber',
      'destination',
      'jobStatus',
    ];

    route.queryParams.subscribe((p) => console.log(p));
  }

  ngOnInit(): void {
    this.filterEntry.valueChanges.subscribe((rex) => {
      this.searchFilter = rex;
    });
    this.route.queryParams.subscribe((vax: any) => {
      if (vax.type) {
        if (vax.type !== 'Credit') {
          this.getJobs(vax.type);
          this.role = vax.type;
          this.searchFilter = '';
          this._filterList();
        } else if (vax.type === 'Credit') {
          this.getCreditJobs();
          this.role = vax.type;
        }
      }
    });
  }

  getJobs(type: string): void {
    this.loading = true;
    this.jobService.getJobs(type, (res, status) => {
      this.loading = false;
      if (status) {
        this.jobsList = res.data;
      }
    });
  }

  getCreditJobs(): void {
    this.loading = true;
    this.jobService.getJobs('CreditJob', (res, status) => {
      this.loading = false;
      if (status) {
        this.jobsList = res.data;
      }
    });
  }

  getLabJobs(): void {
    this.loading = true;
    this.jobService.getJobs('LabJobs', (res, status) => {
      this.loading = false;
      if (status) {
        this.jobsList = res.data;
      }
    });
  }

  displayActivePage(chunkedData: any): void {
    setTimeout(() => {
      this.chunkedRecords = chunkedData.chunks;
      if (chunkedData.controlClicked) {
        this.currentPage = chunkedData.currentPage;
      }
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ['name'];
    }
  }

  customControl(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl;
  }

  _filterList(): void {
    this.searchFilter = '';
    switch (this.role) {
      case 'DataEntryJobs':
        this.status = DATAETRY;
        break;
      case 'CashierJobs':
        this.status = CASHIER;
        break;
      case 'LabJobs':
        this.status = LAB;
        break;
      case 'ArchiveJobs':
        this.status = ARCHIVE;
        break;
      default:
        break;
    }
  }
}
