import { FormGroup, FormControl } from '@angular/forms';
import { PermissionInterface } from './permissions.interface';

export class PermissionForm {

  permissionsForm!: FormGroup;

  constructor() {
    this.initializeFormValue();
  }

  initializeFormValue(permissonValues?: PermissionInterface): void {
    this.permissionsForm = new FormGroup({
      dashboard: new FormGroup({
        access: new FormControl(permissonValues ? permissonValues.dashboard.access : null),
        description: new FormControl(permissonValues ? permissonValues.dashboard.description : null),
        name: new FormControl(permissonValues ? permissonValues.dashboard.name : null),
      }),
      job_settings: new FormGroup({
        access: new FormControl(permissonValues ? permissonValues.job_settings.access : null),
        description: new FormControl(permissonValues ? permissonValues.job_settings.description : null),
        name: new FormControl(permissonValues ? permissonValues.job_settings.name : null),
        create_compartment: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.create_compartment.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.create_compartment.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.create_compartment.name : null),
        }),
        create_customer: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.create_customer.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.create_customer.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.create_customer.name : null),
        }),
        create_dipping: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.create_dipping.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.create_dipping.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.create_dipping.name : null),
        }),
        create_driver: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.create_driver.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.create_driver.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.create_driver.name : null),
        }),
        create_job: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.create_job.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.create_job.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.create_job.name : null),
        }),
        delete_bill_of_landing: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.delete_bill_of_landing.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.delete_bill_of_landing.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.delete_bill_of_landing.name : null),
        }),
        delete_compartment: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.delete_compartment.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.delete_compartment.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.delete_compartment.name : null),
        }),
        delete_customer: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.delete_customer.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.delete_customer.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.delete_customer.name : null),
        }),
        delete_dipping: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.delete_dipping.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.delete_dipping.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.delete_dipping.name : null),
        }),
        delete_driver: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.delete_driver.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.delete_driver.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.delete_driver.name : null),
        }),
        delete_job: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.delete_job.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.delete_job.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.delete_job.name : null),
        }),
        update_bill_of_landing: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.update_bill_of_landing.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.update_bill_of_landing.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.update_bill_of_landing.name : null),
        }),
        update_compartment: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.update_compartment.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.update_compartment.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.update_compartment.name : null),
        }),
        update_customer: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.update_customer.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.update_customer.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.update_customer.name : null),
        }),
        update_dipping: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.update_dipping.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.update_dipping.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.update_dipping.name : null),
        }),
        update_driver: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.update_driver.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.update_driver.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.update_driver.name : null),
        }),
        update_job: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.job_settings.update_job.access : null),
          description: new FormControl(permissonValues ? permissonValues.job_settings.update_job.description : null),
          name: new FormControl(permissonValues ? permissonValues.job_settings.update_job.name : null),
        }),
      }),
      inventory: new FormGroup({
        access: new FormControl(permissonValues ? permissonValues.inventory.access : null),
        description: new FormControl(permissonValues ? permissonValues.inventory.description : null),
        name: new FormControl(permissonValues ? permissonValues.inventory.name : null),
        approve_adjustment: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.inventory.approve_adjustment.access : null),
          description: new FormControl(permissonValues ? permissonValues.inventory.approve_adjustment.description : null),
          name: new FormControl(permissonValues ? permissonValues.inventory.approve_adjustment.name : null),
        }),
        create_item: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.inventory.create_item.access : null),
          description: new FormControl(permissonValues ? permissonValues.inventory.create_item.description : null),
          name: new FormControl(permissonValues ? permissonValues.inventory.create_item.name : null),
        }),
        delete_adjustment: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.inventory.delete_adjustment.access : null),
          description: new FormControl(permissonValues ? permissonValues.inventory.delete_adjustment.description : null),
          name: new FormControl(permissonValues ? permissonValues.inventory.delete_adjustment.name : null)
        }),
        delete_item: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.inventory.delete_item.access : null),
          description: new FormControl(permissonValues ? permissonValues.inventory.delete_item.description : null),
          name: new FormControl(permissonValues ? permissonValues.inventory.delete_item.name : null)
        }),
        request_adjustment: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.inventory.request_adjustment.access : null),
          description: new FormControl(permissonValues ? permissonValues.inventory.request_adjustment.description : null),
          name: new FormControl(permissonValues ? permissonValues.inventory.request_adjustment.name : null)
        }),
        reverse_adjustment: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.inventory.reverse_adjustment.access : null),
          description: new FormControl(permissonValues ? permissonValues.inventory.reverse_adjustment.description : null),
          name: new FormControl(permissonValues ? permissonValues.inventory.reverse_adjustment.name : null)
        }),
        update_adjustment: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.inventory.update_adjustment.access : null),
          description: new FormControl(permissonValues ? permissonValues.inventory.update_adjustment.description : null),
          name: new FormControl(permissonValues ? permissonValues.inventory.update_adjustment.name : null)
        }),
        update_item: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.inventory.update_item.access : null),
          description: new FormControl(permissonValues ? permissonValues.inventory.update_item.description : null),
          name: new FormControl(permissonValues ? permissonValues.inventory.update_item.name : null)
        })
      }),
      purchase: new FormGroup({
        access: new FormControl(permissonValues ? permissonValues.purchase.access : null),
        description: new FormControl(permissonValues ? permissonValues.purchase.description : null),
        name: new FormControl(permissonValues ? permissonValues.purchase.name : null),
        approve_purchase_order: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.purchase.approve_purchase_order.access : null),
          description: new FormControl(permissonValues ? permissonValues.purchase.approve_purchase_order.description : null),
          name: new FormControl(permissonValues ? permissonValues.purchase.approve_purchase_order.name : null),
        }),
        approve_purchase_order_receive: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.purchase.approve_purchase_order_receive.access : null),
          description: new FormControl(permissonValues ? permissonValues.purchase.approve_purchase_order_receive.description : null),
          name: new FormControl(permissonValues ? permissonValues.purchase.approve_purchase_order_receive.name : null),
        }),
        cancel_purchase_order: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.purchase.cancel_purchase_order.access : null),
          description: new FormControl(permissonValues ? permissonValues.purchase.cancel_purchase_order.description : null),
          name: new FormControl(permissonValues ? permissonValues.purchase.cancel_purchase_order.name : null),
        }),
        cancel_purchase_order_receive: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.purchase.cancel_purchase_order_receive.access : null),
          description: new FormControl(permissonValues ? permissonValues.purchase.cancel_purchase_order_receive.description : null),
          name: new FormControl(permissonValues ? permissonValues.purchase.cancel_purchase_order_receive.name : null),
        }),
        delete_direct_purchase: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.purchase.delete_direct_purchase.access : null),
          description: new FormControl(permissonValues ? permissonValues.purchase.delete_direct_purchase.description : null),
          name: new FormControl(permissonValues ? permissonValues.purchase.delete_direct_purchase.name : null),
        }),
        delete_purchase_order: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.purchase.delete_purchase_order.access : null),
          description: new FormControl(permissonValues ? permissonValues.purchase.delete_purchase_order.description : null),
          name: new FormControl(permissonValues ? permissonValues.purchase.delete_purchase_order.name : null),
        }),
        delete_purchase_order_receive: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.purchase.delete_purchase_order_receive.access : null),
          description: new FormControl(permissonValues ? permissonValues.purchase.delete_purchase_order_receive.description : null),
          name: new FormControl(permissonValues ? permissonValues.purchase.delete_purchase_order_receive.name : null),
        }),
        make_direct_purchase: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.purchase.make_direct_purchase.access : null),
          description: new FormControl(permissonValues ? permissonValues.purchase.make_direct_purchase.description : null),
          name: new FormControl(permissonValues ? permissonValues.purchase.make_direct_purchase.name : null),
        }),
        make_purchase_order: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.purchase.make_purchase_order.access : null),
          description: new FormControl(permissonValues ? permissonValues.purchase.make_purchase_order.description : null),
          name: new FormControl(permissonValues ? permissonValues.purchase.make_purchase_order.name : null),
        }),
        make_purchase_order_receive: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.purchase.make_purchase_order_receive.access : null),
          description: new FormControl(permissonValues ? permissonValues.purchase.make_purchase_order_receive.description : null),
          name: new FormControl(permissonValues ? permissonValues.purchase.make_purchase_order_receive.name : null),
        }),
        update_direct_purchase: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.purchase.update_direct_purchase.access : null),
          description: new FormControl(permissonValues ? permissonValues.purchase.update_direct_purchase.description : null),
          name: new FormControl(permissonValues ? permissonValues.purchase.update_direct_purchase.name : null),
        }),
        update_purchase_order: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.purchase.update_purchase_order.access : null),
          description: new FormControl(permissonValues ? permissonValues.purchase.update_purchase_order.description : null),
          name: new FormControl(permissonValues ? permissonValues.purchase.update_purchase_order.name : null),
        }),
        update_purchase_order_receive: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.purchase.update_purchase_order_receive.access : null),
          description: new FormControl(permissonValues ? permissonValues.purchase.update_purchase_order_receive.description : null),
          name: new FormControl(permissonValues ? permissonValues.purchase.update_purchase_order_receive.name : null),
         }),
         
      }),
      site_settings: new FormGroup({
        access: new FormControl(permissonValues ? permissonValues.site_settings.access : null),
        description: new FormControl(permissonValues ? permissonValues.site_settings.description : null),
        name: new FormControl(permissonValues ? permissonValues.site_settings.name : null),
        create_site: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.site_settings.create_site.access : null),
          description: new FormControl(permissonValues ? permissonValues.site_settings.create_site.description : null),
          name: new FormControl(permissonValues ? permissonValues.site_settings.create_site.name : null),
        }),
        delete_site: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.site_settings.delete_site.access : null),
          description: new FormControl(permissonValues ? permissonValues.site_settings.delete_site.description : null),
          name: new FormControl(permissonValues ? permissonValues.site_settings.delete_site.name : null),
        }),
        update_site: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.site_settings.update_site.access : null),
          description: new FormControl(permissonValues ? permissonValues.site_settings.update_site.description : null),
          name: new FormControl(permissonValues ? permissonValues.site_settings.update_site.name : null),
        })
      }),
      user_settings: new FormGroup({
        access: new FormControl(permissonValues ? permissonValues.user_settings.access : null),
        description: new FormControl(permissonValues ? permissonValues.user_settings.description : null),
        name: new FormControl(permissonValues ? permissonValues.user_settings.name : null),
        create_role: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.user_settings.create_role.access : null),
          description: new FormControl(permissonValues ? permissonValues.user_settings.create_role.description : null),
          name: new FormControl(permissonValues ? permissonValues.user_settings.create_role.name : null),
        }),
        create_user: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.user_settings.create_user.access : null),
          description: new FormControl(permissonValues ? permissonValues.user_settings.create_user.description : null),
          name: new FormControl(permissonValues ? permissonValues.user_settings.create_user.name : null),
        }),
        delete_role: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.user_settings.delete_role.access : null),
          description: new FormControl(permissonValues ? permissonValues.user_settings.delete_role.description : null),
          name: new FormControl(permissonValues ? permissonValues.user_settings.delete_role.name : null),
        }),
        delete_user: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.user_settings.delete_user.access : null),
          description: new FormControl(permissonValues ? permissonValues.user_settings.delete_user.description : null),
          name: new FormControl(permissonValues ? permissonValues.user_settings.delete_user.name : null),
        }),
        update_role: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.user_settings.update_role.access : null),
          description: new FormControl(permissonValues ? permissonValues.user_settings.update_role.description : null),
          name: new FormControl(permissonValues ? permissonValues.user_settings.update_role.name : null),
        }),
        update_user: new FormGroup({
          access: new FormControl(permissonValues ? permissonValues.user_settings.update_user.access : null),
          description: new FormControl(permissonValues ? permissonValues.user_settings.update_user.description : null),
          name: new FormControl(permissonValues ? permissonValues.user_settings.update_user.name : null),
        }),
      }),
      reports: new FormGroup({
        access: new FormControl(permissonValues ? permissonValues.reports.access : null),
        description: new FormControl(permissonValues ? permissonValues.reports.description : null),
        name: new FormControl(permissonValues ? permissonValues.reports.name : null),
      })
    });
  }

  get getPermissionForm(): FormGroup {
    return this.permissionsForm;
  }

}

