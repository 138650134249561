<div class="stepper">
  <div [ngStyle]="{'display': 'contents'}" *ngFor="let step of steps; let i = index">
    <div (click)="changeStep({index: i, step: step})" class="stepper-item"
      [ngClass]="{'stepper-item-pending': step !== activeStep.step, 'active': step === activeStep.step,'menu-disabled':nextBtn.status}">
      <span class=" stepper-item-content">{{ i + 1 }}</span>
    </div>
    <div *ngIf="i != steps.length - 1" class="stepper-divider"></div>
  </div>
</div>
<!-- stepper body -->
<div class="stepper-body mt-4">
  <ng-content></ng-content>
</div>

<div class="stepper-controls mt-2">
  <div class="row">
    <div class="col-lg-6">
      <button *ngIf="activeStep.index > 0" (click)="onClickBtn(activeStep.index - 1)"
        class="btn btn-link btn-sm float-left">
        <em class="fas fa-angle-left text-primary"></em>
        prev
      </button>
    </div>

    <div class="col-lg-6 mr-0">
      <button [disabled]="nextBtn.status" *ngIf="activeStep.index !== (steps.length-1)"
        (click)="onClickBtn(activeStep.index + 1)" class="btn btn-primary px-5 float-right">
        Next
        <em class="fas fa-angle-right"></em>
      </button>
      <div class="clearfix"></div>
      <button *ngIf="actionBtn && (activeStep.index === (steps.length-1))" (click)="actionBtnEvent()"
        class="btn btn-primary float-right" [disabled]="actionBtn.status">
        <span *ngIf="loadingSaveBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{loadingSaveBtn ? 'Loading...' : actionBtn.name}}
      </button>
    </div>
    <div class="clearfix"></div>
  </div>
</div>