<div class="container">
    <div *ngIf="!cardsloading" class="row g-6 mb-6">
        <div class="col-xl-4 col-sm-6 col-12" *ngFor="let item of cardDetails">
            <div class="card shadow h-100 border-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <span class="h6 font-semibold text-muted text-sm d-block mb-2">{{ item.name }}</span><span
                                class="h3 font-bold mb-0 hide-text" style="max-width: 160px;">{{ item.value }}
              </span>
                        </div>
                        <div class="col-auto">
                            <div *ngIf="item.name ==='Collection Expected'"
                                 class="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                <em class="bi bi-cash-coin"></em>
                            </div>
                            <div *ngIf="item.name ==='Jobs in queue'"
                                 class="icon icon-shape bg-info text-white text-lg rounded-circle">
                                <em class="bi bi-hourglass-split"></em>
                            </div>
                            <div *ngIf="item.name ==='Jobs Completed'"
                                 class="icon icon-shape bg-warning text-white text-lg rounded-circle">
                                <em class="bi bi-truck"></em>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 mb-0 text-sm">
              <span class="badge badge-pill bg-soft-success text-success me-2">
                <em class="bi bi-arrow-up me-1"></em>
                  {{ item.change }}
              </span>
                        <small class="text-nowrap text-xs text-muted">This month</small></div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="cardsloading" class="row g-6 mb-6 mx-1 justify-content-center"
         style="border: dotted 1px grey; border-radius: 1%;">
        <app-loading-placeholder class="my-5"></app-loading-placeholder>
    </div>
</div>

<div class="container mt-4">
    <div class="row g-6 mb-6">

        <div class="col-xl-6 col-sm-6 col-12 px-2 ">
            <div class="card shadow border-0">
                <div class="card-body">
                    <div class="row">
                        <div class="d-flex align-items-center justify-content-between col-lg-12">
                            <h6 class="mb-0">DIESEL(AGO)</h6>
                            <div class="form-group ml-auto mb-0">
                                <select [formControl]="agoFilterForm" class="form-control custom-select-sm">
                                    <option *ngFor="let value of graphEnumList" [ngValue]="value">{{ value }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div id="ago-chart"></div>
                </div>
            </div>
        </div>

        <div class="col-xl-6 col-sm-6 col-12 px-2">
            <div class="card shadow border-0">
                <div class="card-body">
                    <div class="row">
                        <div class="d-flex align-items-center justify-content-between col-lg-12">
                            <h6 class="mb-0">PETROL (PMS)</h6>
                            <div class="form-group ml-auto mb-0">
                                <select [formControl]="pmsFilterForm" class="form-control custom-select-sm">
                                    <option *ngFor="let value of graphEnumList" [ngValue]="value">{{ value }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div id="pms-chart"></div>
                </div>
            </div>
        </div>

        <div class="col-xl-12 col-sm-6 col-12 mt-3 px-2">
            <div class="card shadow border-0">
                <div class="card-body">
                    <div class="row">
                        <div class="d-flex align-items-center justify-content-between col-lg-12">
                            <h6 class="mb-0">DIESEL(AGO) VS PETROL(PMS) VS JETA1</h6>
                            <div class="form-group ml-auto mb-0">
                                <select [formControl]="filterForm" class="form-control custom-select-sm">
                                    <option *ngFor="let value of graphEnumList" [ngValue]="value">{{ value }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div id="column-chart"></div>
                </div>
            </div>
        </div>

        <div class="col-xl-12 col-sm-12 col-12 mt-3 px-2">
            <div class="card shadow border-0">
                <div class="card-body">
                    <div class="row">
                        <div class="d-flex align-items-center justify-content-between col-lg-12">
                            <h6 class="mb-0">Sales Trend</h6>
                            <div class="form-group ml-auto mb-0">
                                <select [formControl]="filterForm2" class="form-control custom-select-sm">
                                    <option *ngFor="let value of graphEnumList" [ngValue]="value">{{ value }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div id="line-chart"></div>
                </div>
            </div>
        </div>

    </div>
</div>