<!-- <div class="col-6">
  <label class="" for="">Phone Number <span class="text-danger">*</span></label>
  <div class="input-group input-group-sm mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text ">+</span>
      <input [formControl]="customCotrol('code', driverForm)" size="4" class="form-control form-control-sm" />
    </div>
    <input type="number" class="form-control form-control-sm" maxlength="9"
      [formControl]="customCotrol('phone',driverForm)">
  </div>
</div> -->

<div class="form-group" [ngClass]="{'position-relative': isFilterVar, 'mb-0': mb0 !== undefined}">
  <label *ngIf="fieldId" [for]="fieldId" class="label text-muted font-weight-bolder"
    [ngClass]="{'position-absolute': isFilterVar, 'filter-label': isFilterVar}">
    {{label}}
    <span *ngIf="required" class="text-danger">*</span>
  </label>
  <div class="input-group input-group-sm mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text ">+</span>
      <input [formControl]="code" size="4" maxlength="3" class="form-control form-control-sm"/>
    </div>
    <input [readOnly]="readOnly" [attr.max]="maxNo" [attr.min]="minNo" [attr.placeholder]="placeHolder" type="number"
      [formControl]="number" autocomplete="off" class="form-control" [ngClass]="{'form-control-sm': formControlSm}" />
  </div>
  <app-form-validation [validationErrors]="validationErrors"></app-form-validation>
</div>