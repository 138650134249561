<div class="d-flex align-items-center justify-content-center h-100vh" style="background: #237d4b; height: 100%;">
    <div class="form-wrapper m-auto">
        <div class="form-container my-auto">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-6">
                    <div class="">
                        <!-- <img src="assets/images/logo.png" class="img-fluid d-block mx-auto" alt=""
                            style="width: 200px" /> -->
                    </div>
                </div>
                <div class="col-lg-5 col-md-6">
                    <div class="card login-page bg-white shadow rounded border-0">
                        <div class="card-body">
                            <h4 class="card-title text-center">UnLock</h4>
                            <form class="login-form mt-4" [formGroup]="unlockForm" (submit)="unlock()">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label class="form-label">Password <span
                                                    class="text-danger">*</span></label>
                                            <div class="input-group with-focus">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text bg-transparent border-right-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            class="feather feather-key fea icon-sm icons">
                                                            <path
                                                                d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4">
                                                            </path>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <input [type]="fieldTextType ? 'text' : 'password'"
                                                    [formControl]="customControl('password',unlockForm)"
                                                    class="form-control border-left-0" placeholder="Password"
                                                    required="" />
                                                <div class="input-group-append">
                                                    <span class="input-group-text bg-transparent border-left-0">
                                                        <em class="bi eye-slash-position"
                                                            [ngClass]="{ 'bi-eye-slash': !fieldTextType,'bi-eye': fieldTextType}"
                                                            (click)="toggleFieldTextType()"></em>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 mb-0">
                                        <div class="d-grid">
                                            <button type="submit"
                                                class="btn btn-primary btn-block d-flex justify-content-center align-items-center">
                                                <span *ngIf="loading" class="spinner-border spinner-border-sm"
                                                    role="status" aria-hidden="true"></span>
                                                <span class="ml-2">{{
                                                    loading ? "Loading..." : "unlock"
                                                    }}</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div *ngIf="!!unlockError" class="col-12 text-center mt-2">
                                        <p class="text-center text-danger">{{ unlockError }}</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>