import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { GeneralService } from '../../settings/general-settings/general.service';

class BaseClass { }
interface BaseClass extends ModalComponent, PaginatorComponent, FilterComponent { }
applyMixins(BaseClass, [ModalComponent, PaginatorComponent, FilterComponent]);


@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss']
})
export class VendorsComponent extends BaseClass implements OnInit {

  currentPage = 0;
  elementsPerPage = 10;
  showEntries: FormControl = new FormControl(10);
  chunkedRecords: Array<any> = [];
  searchFilter: string = '';

  vendorsList: Array<any> = [];

  vendorForm!: FormGroup;
  newBtn!: object;

  editVendorForm!: FormGroup;
  editBtn!: object;

  isSaving = false;
  loading = false;
  isEditting = false;

  constructor(
    public modalService: BsModalService,
    public apiConnect: ApiConnectionService,
    public router: Router,
    public generalService: GeneralService
  ) {
    super();
    this.vendorForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      displayName: new FormControl(null, Validators.required),
      email: new FormControl(null),
      phone: new FormControl(null),
      address: new FormControl(null),
    });
    this.newBtn = {
      method: () => { return this.saveVendor(this.vendorForm.value); },
      text: 'Save'
    };
    this.editBtn = {
      method: () => { return this.updateVendor(this.editVendorForm.value); },
      text: 'Update'
    };
  }

  ngOnInit(): void {
    this.getVendors();

  }

  getVendors(): void {
    this.loading = true;
    this.generalService.saveVendor('GET', undefined, (res, status) => {
      this.loading = false
      if (status) {
        this.vendorsList = res.data;
        console.log(res.data);
      }
    });
  }

  saveVendor(form: any): void {
    this.isSaving = true;
    this.generalService.saveVendor('POST', form, (res, status) => {
      this.isSaving = false;
      this.closeModal();
      if (status) {
        this.getVendors();
        this.vendorForm.reset()
      }
    });
  }

  _editVendor(template: TemplateRef<any>, item: any): void {
    this.isEditting = true;
    this.editVendorForm = new FormGroup({
      id: new FormControl(item.id),
      name: new FormControl(item.name, Validators.required),
      displayName: new FormControl(item.displayName, Validators.required),
      email: new FormControl(item.email),
      phone: new FormControl(item.phone),
      address: new FormControl(item.address),
    });
    this.modalService.show(template);
  }

  updateVendor(form: any): void {
    this.isSaving = true;
    this.generalService.saveVendor('PUT', form, (res, status) => {
      this.isSaving = false;
      this.closeModal();
      if (status) {
        this.vendorForm.reset()
        this.getVendors();
      }
    }, undefined, form.id);
  }

  deleteVendor(id: number): void {
    this.generalService.saveVendor('DELETE', undefined, (res, status) => {
      if (status) {
        this.getVendors();
      }
    }, undefined, id);
  }

  customCotrol(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl
  }

  displayActivePage(chunckedData: any): void {
    setTimeout(() => {
      this.chunkedRecords = chunckedData.chunks;
      if (chunckedData.controlClicked) {
        this.currentPage = chunckedData.currentPage;
      }
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ['name'];
    }
  }

}
