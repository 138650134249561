export const Category = {
    settings: 'SETTINGS',
    accounting: 'ACCOUNTING',
    inventory: 'INVENTORY',
    job: 'JOB',
    dashboard: 'DASHBOARD',
    reports: 'REPORTS'
}

export const Categories = [
    { cat: 'DASHBOARD', role: ['MANAGER'] },
    { cat: 'SETTINGS', role: ['MANAGER'] },
    { cat: 'JOB', role: ['MANAGER', 'CASHIER', 'DATA_ENTRY', 'LAB'] },
    { cat: 'INVENTORY', role: ['MANAGER'] },
    { cat: 'SETTINGS', role: ['MANAGER'] },
    { cat: 'REPORTS', role: ['MANAGER'] }]

export const CategoriesItems = [
    {
        cat: 'JOB',
        items: [
            { item: 'DATA_ENTRY', role: ['MANAGER', 'DATA_ENTRY'] },
            { item: 'LAB_PROCESSING', role: ['MANAGER', 'LAB'] },
            { item: 'CASHIER', role: ['MANAGER', 'CASHIER'] },
            { item: 'ARCHIVE', role: ['MANAGER'] }]
    },
    {
        cat: 'JOB',
        items: [
            { item: 'DATA_ENTRY', role: ['MANAGER', 'DATA_ENTRY'] },
            { item: 'LAB_PROCESSING', role: ['MANAGER', 'LAB'] },
            { item: 'CASHIER', role: ['MANAGER', 'CASHIER'] },
            { item: 'ARCHIVE', role: ['MANAGER'] }]
    },
]