import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Role } from 'src/app/services/authorization/models/Role.model';
import { PermissionForm } from './permissions.form';
import { PermissionInterface } from './permissions.interface';
import { RoleService } from './roles.service';

class BaseClass { }
interface BaseClass extends ModalComponent, PaginatorComponent, FilterComponent { }
applyMixins(BaseClass, [ModalComponent, PaginatorComponent, FilterComponent]);

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent extends BaseClass implements OnInit {

  rolesList: Array<any> = [];
  newRoleForm!: FormGroup;
  saveRoleBtn: any;

  permissionForm: PermissionForm = new PermissionForm();
  activeRolePermissionForm: FormGroup = this.permissionForm.getPermissionForm;
  activePermissions!: PermissionInterface;
  activeRoleId: any = undefined;
  activeRoleIndex = 0;
  activeRole!: Role;
  permissionTouched = false;

  editRoleForm!: FormGroup;
  editRoleBtn: any;

  saving = false;
  loadingRoles = false;
  loadingPerminsions = false;
  userType: any;

  constructor(
    public modalService: BsModalService,
    public apiConnect: ApiConnectionService,
    public authService: AuthenticationService,
    private roleService: RoleService
  ) {

    super();

    this.newRoleForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl()
    });

    this.saveRoleBtn = {
      method: () => {
        this.saveRole(this.newRoleForm.value);
      },
      text: 'save'
    };
    this.editRoleBtn = {
      method: () => {
        this.updateRole(this.editRoleForm.value);
      },
      text: 'update'
    };
  }

  updateAccess(id:number){
    this.loadingPerminsions = true;
    this.roleService.updateCategoryPermission(null, (res, status) => {
      this.loadingPerminsions = false;
      if (status) {
        this.getRoleDetails(this.activeRoleId);
     }
    }, undefined, id);
  }

  updateAccessCategory(id:number){
    this.loadingPerminsions = true;
    this.roleService.updateCategoryItemPermission(null, (res, status) => {
      this.loadingPerminsions = false;
      if (status) {
        this.getRoleDetails(this.activeRoleId);
     }
    }, undefined, id);
  }

  updateAccessCategoryItem(id:number){
    this.loadingPerminsions = true;
    this.roleService.updateCategoryPrivilegePermission(null, (res, status) => {
      this.loadingPerminsions = false;
      if (status) {
        this.getRoleDetails(this.activeRoleId);
     }
    }, undefined, id);
  }

  ngOnInit(): void {
    this.getRoles();
    this.checkActiveUser();
  }

  checkActiveUser(): void {
    const rem = this.authService.sessionStorageService.getActiveUser();
    if (rem) {
      this.userType = rem.type;
    }
  }

  getRoles() {
    this.loadingRoles = true;
    this.roleService.saveRole('GET', undefined, (res, status) => {
      this.loadingRoles = false;
      if (status) {
        this.rolesList = res;
        if (this.activeRoleId === undefined) {
          this.activeRoleId = this.rolesList[this.activeRoleIndex].id;
          this.activeRole = this.rolesList[this.activeRoleIndex];
        }
        this.getRoleDetails(this.activeRoleId);
      }
    });
  }

  getRoleDetails(id: number): void {
    this.loadingPerminsions = true;
    this.roleService.saveRole('GET', undefined, (res, status) => {
      this.loadingPerminsions = false;
      if (status) {
        this.permissionForm.initializeFormValue(res.roledefinition);
        this.activePermissions = res.roledefinition;
        this.activeRole = res;
        this.activeRoleId = res.id;
        this.activeRolePermissionForm = this.permissionForm.getPermissionForm;
        this.activeRolePermissionForm.valueChanges.subscribe(val => {
          this.permissionTouched = true;
        });
      }
    }, undefined, id);
  }

  saveRole(form: any): void {
    this.saving = true;
    this.roleService.saveRole('POST', form, (res, status) => {
      this.saving = false;
      if (status) {
        this.getRoles();
        this.closeModal();
        this.newRoleForm.reset();
      }
    });
  }

  updatePermissions(): void {
    this.loadingPerminsions = true;
    this.roleService.updatePermissions(this.activeRolePermissionForm.value, (res, status) => {
      this.loadingPerminsions = false;
      if (status) {
        this.permissionTouched = false;
        this.getRoleDetails(this.activeRoleId);
     }
    }, undefined, this.activeRoleId);
  }

  _editRole(template: TemplateRef<any>, item: any): void {
    this.editRoleForm = new FormGroup({
      id: new FormControl(item.id),
      name: new FormControl(item.name, Validators.required),
      description: new FormControl(item.description)
    });
    this.modalRef = this.modalService.show(template);
  }

  updateRole(form: any): void {
    this.saving = true;
    this.roleService.saveRole('PUT', form, (res, status) => {
      this.saving = false;
      this.getRoles();
      this.closeModal();
      this.editRoleForm.reset();
    }, undefined, form.id);
  }

  deleteRole(id: number): void {
    this.roleService.saveRole('DELETE', undefined, (res, status) => {
      this.getRoles();
    });
  }

  // ..FormCOntrols
  get rolename(): FormControl {
    return this.newRoleForm.get('name') as FormControl;
  }
  get roledescription(): FormControl {
    return this.newRoleForm.get('description') as FormControl;
  }

  get editrolename(): FormControl {
    return this.editRoleForm.get('name') as FormControl;
  }
  get editroledescription(): FormControl {
    return this.editRoleForm.get('description') as FormControl;
  }

  // dashboard permissions
  roleGroup(group: string): FormGroup {
    return this.activeRolePermissionForm.get(group) as FormGroup;
  }

  roleNextGroup(nextGroup: string, group: FormGroup): FormGroup {
    return group.get(nextGroup) as FormGroup;
  }

  roleControl(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl;
  }

}
