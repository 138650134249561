export const ROLES = {
  Compartment: {
    name: 'Compartment',
    children: {
      FuelType: 'Fuel Type',
      DeclaredVol: 'Declared Volume',
      ActualQtyL20: 'Actual Qty.(L20)',
      Density20KGV: 'Density @20°C KGV',
      TemperatureC: 'Temperature (°C)',
      NewSeal: 'New Seal',
      OldSeal: 'Old Seal',
      ObsVol20C: 'Observed Vol @20°C',
      ObsDensity20C: 'Observed Density @20°C',
      FlashPoint: 'Flash Point (°C)',
      FinalBP: 'Final Boiling Point FBP (°C)',
    },
  },
  Job: {
    name: 'Job',
    children: {
      View: 'View',
      EditDip: 'Edit Dipping',
      EditComp: 'Edit Compartment',
      GenInvoice: 'Generate Invoice',
      ProJob: 'Process Job',
      ToLab: 'Proceed To Lab',
      CompJob: 'Complete Job',
      Print: 'Print Certificate',
      RecvPayment: 'Receive Payment',
      PayClientBal: 'Pay With Client Balance'
    },
  },
};
