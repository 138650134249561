import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { JobService } from '../job/job.service';

class BaseClass { }
interface BaseClass extends ModalComponent, PaginatorComponent, FilterComponent { }
applyMixins(BaseClass, [ModalComponent, PaginatorComponent, FilterComponent]);


@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent extends BaseClass implements OnInit {
  status: any[] = [];
  role: any;
  currentPage = 0;
  elementsPerPage = 10;
  showEntries: FormControl = new FormControl(10);
  filterEntry: FormControl = new FormControl(null);
  chunkedRecords: Array<any> = [];
  searchFilter: string = '';
  sessionList: any[] = [];
  newSessionForm: FormGroup = new FormGroup({
    openingDate: new FormControl(),
    exchangeRage: new FormControl()
  });
  editSessionForm: FormGroup = new FormGroup({
    id: new FormControl(null),
    openingDate: new FormControl(),
    exchangeRage: new FormControl()
  });
  saveBtn: any;
  editBtn: any;

  loading = false;
  isSaving = false;
  lab = false;


  constructor(
    public modalService: BsModalService,
    private jobService: JobService
  ) {
    super();
    this.saveBtn = {
      method: () => { return this.saveSession(); },
      text: 'Save'
    };
    this.editBtn = {
      method: () => { return this.updateSession(); },
      text: 'Updatea'
    };
  }

  ngOnInit() {
    this.getSessions();
  }


  getSessions(): void {
    this.loading = true;
    this.jobService.sessions('GET', 'Session', undefined, (res, status) => {
      this.loading = false
      if (status) {
        this.sessionList = res.data;
        console.log(res.data)
      }
    });
  }

  saveSession(): void {
    this.isSaving = true;
    this.jobService.sessions('POST', 'Session', this.newSessionForm.value, (res, status) => {
      this.isSaving = false
      if (status) {
        this.getSessions();
        this.closeModal();
        this.newSessionForm.reset();
      }
    });
  }

  _editSession(template: TemplateRef<any>, session: any): void {
    this.editSessionForm.patchValue({
      id: session.id,
      openingDate: session.openingDate.replace(/-/g, "/") ,
      exchangeRage: session.exchangeRage
    });
    console.log(session.openingDate.replace(/-/g, "/"))

    this.modalService.show(template);
  }

  updateSession(): void {
    this.isSaving = true;
    this.jobService.sessions('PUT', 'UpdateSession', this.editSessionForm.valid, (res, status) => {
      this.isSaving = false
      if (status) {
        this.getSessions();
        this.closeModal();
        this.editSessionForm.reset();
      }
    }, undefined, this.editSessionForm.value.id);
  }

  approveSession(id: number): void {
    this.isSaving = true;
    this.jobService.sessions('PUT', 'ApproveSession', undefined, (res, status) => {
      this.isSaving = false
      if (status) {
        this.getSessions();
      }
    }, undefined, id);
  }

  closeSession(id: number): void {
    this.isSaving = true;
    this.jobService.sessions('PUT', 'CloseSession', undefined, (res, status) => {
      this.isSaving = false
      if (status) {
        this.getSessions();
      }
    }, undefined, id);
  }

  displayActivePage(chunckedData: any): void {
    setTimeout(() => {
      this.chunkedRecords = chunckedData.chunks;
      if (chunckedData.controlClicked) {
        this.currentPage = chunckedData.currentPage;
      }
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ['name'];
    }
  }

  customCotrol(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl
  }

}
