import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SidePanelService } from 'src/app/components/slidepanel/sidepanel.service';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  // tslint:disable-next-line: semicolon
  userId!: number
  public storageSubscription = new BehaviorSubject<string>('');

  constructor(
    public session: SessionStorageService,
    public apiConnect: ApiConnectionService,
    public sidePanelService: SidePanelService
  ) { }

  updateUserData(userId: number): void {
    this.apiConnect.secureConnect('GET', 'User', undefined, undefined, userId, this.onUpdateUserData.bind(this));
  }

  onUpdateUserData(res: any, status: string, callback: any): void {
    if (status === 'success') {
      this.session.setActiveUser(res);
      this.storageSubscription.next('changed');
    } else {
      callback('danger', 'Error', res.message);
    }
  }

  resendCredentials(id: number): void {
    this.apiConnect.secureConnect('PUT', 'UserResend', undefined, undefined, id, this.onResendCredentials.bind(this));
  }

  onResendCredentials(res: any, status: string, callback: any): void {
    if (status === 'success') {
      callback('success', 'Success', 'Credentials successfully resent');
    } else {
      callback('danger', 'Error', res.message);
    }
  }
  
  getAuditTrails(id: number): void {
    this.sidePanelService.open(id);
  }

  watchStorage(): Observable<any> {
    return this.storageSubscription.asObservable();
  }
}
