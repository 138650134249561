import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { tap } from 'rxjs/operators';
import { AlertPopupService } from './alert-popup.service'

@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss']
})
export class AlertPopupComponent implements OnInit {

  type!: string;
  message!: string;
  actionTitle!: string;
  actionBtn!: (close?: () => void) => void;

  alertBorder: any = {
    success: 'border-success',
    danger: 'border-danger',
    info: 'border-info',
    warning: 'border-warning',
    primary: 'border-primary',
    announce: 'border-warning'
  };

  alertIcons: any = {
    success: 'bi bi-check2',
    danger: 'bi bi-x-lg',
    info: 'bi bi-info-lg',
    primary: 'bi bi-info-lg',
    warning: 'bi bi-exclamation-lg',
    announce: 'bi bi-megaphone'
  };

  alertText: any = {
    success: 'text-success',
    danger: 'text-danger',
    info: 'text-info',
    warning: 'text-warning',
    primary: 'text-primary',
    announce: 'text-warning'
  };

  constructor(
    public bsModalRef: BsModalRef,
    public alertPopupService: AlertPopupService
  ) { }

  ngOnInit(): void {
    /*
    * close modal only when loading is set to false <-
    * keep open if true or udefined <-
    */
    this.alertPopupService.getLoading()
      .pipe(tap(val => { val === false ? this.close() : null }))
      .subscribe()
  }

  close(): void {
    this.alertPopupService.setLoading(undefined);
    this.bsModalRef.hide();
  }
}
