import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule} from '@angular/router';
import { routes } from './routes';
import { ComponentsModule } from '../components/components.module';

import { AuthDirectiveModule } from '../services/directives/auth.directive.module';
import { jwtInterceptorProviders } from '../services/interceptor/Jwt.interceptor';
import { LayoutModule } from 'src/app/layout/layout.module';
import { DashboardComponent } from './../pages/dashboard/dashboard.component';
import { LoginComponent } from '../pages/auth/login/login.component';
import { ForgotComponent } from './../pages/auth/forgot/forgot.component';
import { UserAccountComponent } from './../pages/user-account/user-account.component';
import { Access403Component } from './../pages/error/access403/access403.component';
import { Error404Component } from './../pages/error/error404/error404.component';
import { EmailConfrimComponent } from './../pages/open-links/confrim/email-confrim.component';
import { JobsComponent } from './../pages/job/jobs/jobs.component';
import { JobDetailsComponent } from '../pages/job/job-details/job-details.component';
import { WorkspaceComponent } from '../pages/auth/workspace/workspace.component';
import { CustomersComponent } from '../pages/others/customers/customers.component';
import { DriversComponent } from '../pages/others/drivers/drivers.component';
import { NewJobComponent } from '../pages/job/new-job/new-job.component';
import { CargoComponent } from '../pages/job/job-details/cargo/cargo.component';
import { JobActionsComponent } from '../pages/job/job-details/job-actions/job-actions.component';
import { RightsComponent } from '../pages/auth/rights/rights.component';
import { StockActivityComponent } from '../pages/reports/stock-activity/stock-activity.component';
import { StockItemReportComponent } from '../pages/reports/stock-item-report/stock-item-report.component';
import { MarkerItemsComponent } from '../pages/settings/general-settings/marker-items/marker-items.component';
import { VendorsComponent } from '../pages/inventory/vendors/vendors.component';
import { JobReportsComponent } from '../pages/reports/job-reports/job-reports.component';
import { CompletedComponent } from '../pages/reports/job-reports/completed/completed.component';
import { IncompleteComponent } from '../pages/reports/job-reports/incomplete/incomplete.component';
import { PaymentsComponent } from '../pages/reports/job-reports/payments/payments.component';
import { InjectComptsComponent } from '../pages/job/new-job/inject-compts/inject-compts.component';
import { ReportUtilityComponent } from '../pages/reports/report-utility/report-utility.component';
import { LockComponent } from '../pages/auth/lock/lock.component';
import { SessionsComponent } from '../pages/sessions/sessions.component';
import { DetailsComponent } from '../pages/others/customers/details/details.component';

@NgModule({
  declarations: [
    DashboardComponent,
    // RolesComponent,
    LoginComponent,
    LockComponent,
    ForgotComponent,
    Error404Component,
    Access403Component,
    EmailConfrimComponent,
    UserAccountComponent,
    JobsComponent,
    JobDetailsComponent,
    WorkspaceComponent,
    CustomersComponent,
    DriversComponent,
    NewJobComponent,
    CargoComponent,
    JobActionsComponent,
    RightsComponent,
    StockActivityComponent,
    StockItemReportComponent,
    MarkerItemsComponent,
    VendorsComponent,
    JobReportsComponent,
    SessionsComponent,
    CompletedComponent,
    PaymentsComponent,
    IncompleteComponent,
    ReportUtilityComponent,
    InjectComptsComponent,
    DetailsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking',
    }),
    LayoutModule,
    ComponentsModule,
    AuthDirectiveModule,
  ],
  exports: [
    RouterModule,
    LayoutModule,
    AuthDirectiveModule
  ],
  providers: [jwtInterceptorProviders]
})
export class RoutesModule { }
