import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

    @Input() bgStyle!: string;
    @Input() label!: string;

    @Input()
    set statusType(value: string) {
        this.label = value;

        switch (value) {
            case 'DRAFT':
                this.bgStyle = 'badge-warning';
                break;
            case 'ACTIVE':
                this.bgStyle = 'badge-success';
                break;
            case 'APPROVED':
                this.bgStyle = 'badge-primary';
                break;
            case 'REVERSED':
                this.bgStyle = 'badge-danger';
                break;
            case 'DECLINED':
                this.bgStyle = 'badge-danger';
                break;
            case 'PENDING':
                this.bgStyle = 'badge-info';
                break;
            case 'CANCELLED':
                this.bgStyle = 'badge-secondary';
                break;
            case 'CONFIRMED':
                this.bgStyle = 'badge-success';
                break;
            case 'RUNNING':
                this.bgStyle = 'badge-primary';
                break;
            case 'CLOSED':
                this.bgStyle = 'badge-info';
                break;
            case 'PENDING_PAYMENT':
                this.bgStyle = 'badge-warning';
                break;
            case 'DELETED':
                this.bgStyle = 'badge-danger';
                break;
            case 'PAID':
                this.bgStyle = 'badge-primary';
                break;
            case 'LAB_PROCESSING':
                this.bgStyle = 'badge-info';
                break;
            case 'Sent':
                this.bgStyle = 'badge-info';
                break;
            case 'NOT_DIPPED':
                this.bgStyle = 'badge-warning';
                break;
            case 'DIPPED':
                this.bgStyle = 'badge-info';
                break;
            case 'DRIVER_APPROVED':
                this.bgStyle = 'badge-info';
                break;
            case 'INVOICED':
                this.bgStyle = 'badge-info';
                break;
            case 'LABARATORY-PROCESSING':
                this.bgStyle = 'badge-info';
                break;
            case 'LAB_PENDING':
                this.bgStyle = 'badge-warning';
                break;
            case 'COMPLETED':
                this.bgStyle = 'badge-primary';
                break;
            case 'NEW':
                this.bgStyle = 'badge-warning';
                break;
            case 'ACKNOWLEDGED':
                this.bgStyle = 'badge-primary';
                break;
            case 'PAYMENT_VERIFIED':
                this.bgStyle = 'badge-primary';
                break;
            case 'DEACTIVATED':
                this.bgStyle = 'badge-warning';
                break;
            case 'REQUESTED':
                this.bgStyle = 'badge-warning';
                break;
            case 'ALLOWED':
                this.bgStyle = 'badge-success';
                break;
        }
    }

    constructor() {
    }

    ngOnInit(): void {
    }

}
