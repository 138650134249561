import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-page-state',
    templateUrl: './page-state.component.html',
    styleUrls: ['./page-state.component.scss']
})
export class PageStateComponent implements OnInit {

    filterState: any = {};
    searchString: string = '';
    activePageSubject: BehaviorSubject<number>;
    filterBtnSubject: BehaviorSubject<any>;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public location: Location
    ) {
        this.activePageSubject = new BehaviorSubject(1);
        this.filterBtnSubject = new BehaviorSubject(false);
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(val => {
            if (!this.isEmpty(val)) {
                this.filterState = val;
                this.searchString = val?.search;
                this.activePageSubject.next(isNaN(+val?.activePage) ? 1 : +val?.activePage);
            } else {
                this.filterState = {
                    activePage: 1,
                    elementsPerPage: 10
                };
            }
        });
    }

    // update page filter
    updateActivePage(page: number): void {
        this.activePageSubject.next(page);
    }

    // listen to all filter changes
    onGetFilterData($event: any): void {
        this.filterState = { ...this.filterState, ...$event };
        this.updatePageUrl({ ...this.filterState });
    }

    // listen to all filter activepage
    onGetFilterActivePage($event: any): void {
        this.filterState = { ...this.filterState, ...$event };
        this.updatePageUrl({ ...this.filterState });
    }

    // listen to all filter activepage
    onGetFilterElementsPerPage($event: any): void {
        this.filterState = { ...this.filterState, ...$event };
        this.updatePageUrl({ ...this.filterState });
    }

    // listen to search changes
    onGetSearchString($event: string): void {
        this.searchString = $event
        this.updatePageUrl({ ...this.filterState, search: $event });
    }

    // update url with queryparams
    updatePageUrl(data: any): void {
        const url: string = this.router
            .createUrlTree([], { relativeTo: this.route, queryParams: data })
            .toString();
        this.location.go(url);
    }

    // is empty query
    isEmpty(data: any): boolean {
        return data && Object.keys(data).length === 0 && Object.getPrototypeOf(data) === Object.prototype
    }
}
