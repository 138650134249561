
import { Injectable } from '@angular/core';
import { AlertService } from '../../../../app/services/alert/alert.service'
import { ApiConnectionService } from '../../../../app/services/api/apiconnection.service'

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private apiConnect: ApiConnectionService,
    private alertService: AlertService
  ) {}

  saveUser(
    link: string,
    form: any,
    onSave: (res?: any, status?: boolean) => void,
    queryParam?: any,
    id?: any
  ): void {
    this.apiConnect.callApi(link, "User", form, queryParam, id).subscribe(
      (res: any) => {
        onSave(res.data, true);
        if (link !== "GET") {
          this.alertService.setAlert({
            type: "success",
            title: "Success",
            message: res.message,
          });
        }
      },
      (err: any) => {
        onSave(err, false);
        console.log(err);
        this.alertService.setAlert({
          type: "danger",
          title: "Error",
          message: err.message.error.error,
        });
      }
    );
  }

  getBusinesses(onSave: (res?: any, status?: boolean) => void): void {
    this.apiConnect.callApi("GET", "Businesses").subscribe(
      (res: any) => {
        onSave(res.data, true);
      },
      (err: any) => {
        onSave(err, false);
        this.alertService.setAlert({
          type: "danger",
          title: "Error",
          message: err.message.error.error,
        });
      }
    );
  }

  toggleUserAccess(id: any,onSave: (res?: any, status?: boolean) => void): void {
    this.apiConnect
      .callApi("PUT", "UserAccess", undefined, undefined, id)
      .subscribe(
        (res: any) => {
          onSave(res.data, true);
          this.alertService.setAlert({
            type: "success",
            title: "Success",
            message: res.message,
          });
        },
        (err: any) => {
          onSave(err, false);
          this.alertService.setAlert({
            type: "danger",
            title: "Error",
            message: err.message.error.error,
          });
        }
      );
  }
}