import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @Input() activeStep: any;
  constructor() { }

  @Input() steps: Array<string> = [];
  @Input() actionBtn: any;
  @Input() nextBtn: any;
  @Input() loadingSaveBtn = false;
  @Output() onActiveStep: EventEmitter<string> = new EventEmitter();
  @Output() actionBtnClicked: EventEmitter<boolean> = new EventEmitter();

  ngOnInit(): void {
    this.activeStep = { index: 0, step: this.steps[0] };
  }

  /**
   * emitts event step change
   * @param active {index: number, step: string}
   */
  changeStep(active: any) {
    this.activeStep = active;
    this.onActiveStep.emit(this.activeStep.step);
  }

  /**
   * handles click event on previous and next btn
   * @param stepIndex {index: number, step: string}
   * @returns
   */
  onClickBtn(stepIndex: any) {
    if (stepIndex < 0) return;
    if (stepIndex >= this.steps.length) return;
    this.activeStep = { index: stepIndex, step: this.steps[stepIndex] };
    this.onActiveStep.emit(this.activeStep.step);
  }

  /**
   * Actions btn event
   */
  actionBtnEvent() {
    this.actionBtnClicked.emit(true);
  }
}
