export const ValidationMessages: any = {
  email: 'Wrong email format',
  required: 'Required',
  passwordMatch: 'Password does not match',
  imageFormat: 'Incorrect file type | accepts png, jpg, webp',
  fileFormat: 'Incorrect file type | accepts png, jpg, webp, pdf',
  itemBarcodeExists: 'Barcode already exists',
  skuExists: 'SKU already exists',
  passwordLength: ' Minimum 8 characters long',
  passwordLowerCase:'Atleast one lowercase character',
  passwordUpperCase: 'Atleast one uppercase character',
  passwordSymbol: 'Atleast one symbol character'
}
