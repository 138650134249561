<div class="container">
    <div class="row mx-0 mb-2 mt-2">
        <div class="col-12 d-flex px-0">
            <div>
                <h5 class="font-weight-bold text-muted">Jobs</h5>
            </div>
            <div class="ml-auto">
                <button hideSubModule [module]="'Data-Entry'" [query]="'Create'" class="btn-sm btn btn-primary"
                        [routerLink]="['/app/job/new-job']">
                    New Job
                </button>
            </div>
            <!-- <div *ngIf="role === 'CashierJobs' || role === 'GeneralManager'" class="ml-auto">
                <button class="btn-sm btn btn-primary" [routerLink]="['/app/job/new-job']"
                    [queryParams]="{exempt:true}">
                    New Exempted Job Without Dipping
                </button>
            </div> -->
        </div>
    </div>
    <div class="row mx-0 mb-2 mt-2">
        <div class="col-12 d-flex px-0">
            <div class="entries">
                <select [formControl]="filterEntry" class="custom-select custom-select-sm form-control form-control-sm">
                    <option *ngFor="let item of status" [ngValue]="item">{{ item }}</option>
                </select>
            </div>
            <div class="ml-auto mr-1">
                <input type="text" placeholder="search" [(ngModel)]="searchFilter" class="form-control form-control-sm">
            </div>
            <div class="entries">
                <select [formControl]="showEntries" class="custom-select custom-select-sm form-control form-control-sm">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                    <option [ngValue]="jobsList.length">Show All</option>
                </select>
            </div>
        </div>
    </div>
    <!-- list -->
    <div class="col-lg-12 p-0" style="overflow-x: auto;">
        <div *ngIf="!loading">
            <div class="table-card shadow-sm" *ngIf="jobsList.length != 0">
                <div class="table-card-body">
                    <div class="table-responsive">
                        <table class="table themed-table table-bordered themed-table-plain">
                            <thead>
                            <th class="cell">
                                <div class="cell-border">
                                    <span>Job No.</span>
                                </div>
                            </th>
                            <th class="cell">
                                <div class="cell-border">
                                    <span>Shipment No.</span>
                                </div>
                            </th>
                            <th class="cell">
                                <div class="cell-border">
                                    <span>Consigner</span>
                                </div>
                            </th>
                            <th class="cell">
                                <div class="cell-border">
                                    <span>VehicleNo.</span>
                                </div>
                            </th>
                            <th class="cell" *ngIf="role !== 'Credit'">
                                <div class="cell-border">
                                    <span>Destination</span>
                                </div>
                            </th>
                            <th class="cell">
                                <div class="cell-border">
                                    <span class="text-center">Status</span>
                                </div>
                            </th>
                            <th class="cell" *ngIf="role == 'Credit'">
                                <div class="cell-border">
                                    <span class="text-center">Credit</span>
                                </div>
                            </th>
                            <th class="cell text-center" style="min-width: 10px;">
                                <div class="cell-border">Action</div>
                            </th>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of chunkedRecords[currentPage]">
                                <td style="cursor:pointer;">
                                        <span *ngIf="role !== 'DataEntryJobs'"
                                              [routerLink]="['/app/job/job-details',item.id]"
                                              class="font-weight-bolder text-black">{{ item.jobNumber }}</span>
                                    <span *ngIf="role === 'DataEntryJobs'"
                                          class="font-weight-bolder text-black">{{ item.jobNumber }}</span>
                                </td>
                                <td> {{ item.shipmentNumber }}</td>
                                <td>{{ item.companyName }}</td>
                                <td>{{ item.vehicleNumber }}</td>
                                <td *ngIf="role !== 'Credit'"> {{ item.destination }}</td>
                                <td>
                                    <app-status [statusType]="item.jobStatus"></app-status>
                                    <app-status *ngIf="item.jobStatus === 'INVOICED'"
                                                [statusType]="item.paymentStatus"></app-status>
                                </td>
                                <td *ngIf="role == 'Credit'">
                                    <app-status *ngIf="item.isCreditAllowed" [statusType]="'ALLOWED'"></app-status>
                                    <app-status *ngIf="item.isCreditRequested"
                                                [statusType]="'REQUESTED'"></app-status>
                                </td>
                                <td class="text-center">
                                    <button hideSubModule [module]="'Job'" [query]="'View'"
                                            class="btn btn-primary btn-sm"
                                            [routerLink]="['/app/job/job-details',item.id]">
                                        <em class="bi bi-eye"></em>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card h-100" *ngIf="jobsList.length === 0" [hidden]="loading">
                <app-empty-placeholder></app-empty-placeholder>
                <div class="text-center mb-4">
                    <button class="btn btn-primary btn-sm" (click)="getJobs(role)"><em
                            class="bi bi-arrow-clockwise"></em> Reload
                    </button>
                </div>
            </div>

        </div>

        <div *ngIf="loading" class="text-center">
            <app-loading-placeholder></app-loading-placeholder>
        </div>

        <!-- pagination -->
        <div class="d-flex justify-content-center align-items-center mt-3" *ngIf="jobsList.length != 0">
            <app-paginator
                    [chuckRecords]="jobsList | filter :filterList :searchFilter :false | paginator :elementsPerPage"
                    (onPageChange)="displayActivePage($event)">
            </app-paginator>
        </div>
    </div>

</div>