import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { Currency } from 'src/app/constants/form-labels/others';
import { ROLES } from 'src/app/constants/roles';
import { JobService } from 'src/app/pages/job/job.service';
import { GeneralService } from 'src/app/pages/settings/general-settings/general.service';
import { PdfPrintService } from 'src/app/services/settings/pdf-print.service';
import { SettingsService } from 'src/app/services/settings/settings.service';

class BaseClass { }
interface BaseClass extends ModalComponent, PaginatorComponent, FilterComponent { }
applyMixins(BaseClass, [ModalComponent, PaginatorComponent, FilterComponent]);

@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"],
})
export class DetailsComponent extends BaseClass implements OnInit {
  JobRoles = ROLES.Job;
  clientId!: number;
  jobSessions: any[] = [];
  currentPage = 0;
  elementsPerPage = 20;
  chunkedRecords: Array<any> = [];
  chunkedRecords2: Array<any> = [];
  searchFilter: string = "";

  customerDetails: any;
  payments: any[] = [];
  invoices: any[] = [];
  Currency: string[] = Currency;

  receivePayForm: FormGroup;
  btn: any;

  loading = false;
  isSaving = false;

  constructor(
    public location: Location,
    public generalService: GeneralService,
    public route: ActivatedRoute,
    public modalService: BsModalService,
    public settingService: SettingsService,
    public jobService: JobService,
    public pdfPrintService: PdfPrintService
  ) {
    super();
    this.receivePayForm = new FormGroup({
      bankReference: new FormControl(null, Validators.required),
      notes: new FormControl(null),
      clientId: new FormControl(null),
      currency: new FormControl(null, Validators.required),
      invoiceId: new FormControl(null),
      sessionId: new FormControl(null),
      amount: new FormControl(null, Validators.required),
      date: new FormControl(
        this.settingService.formatDateToSlashes(new Date()),
        Validators.required
      ),
    });

    this.btn = {
      method: () => {
        this.receivePayment(this.receivePayForm.value);
      },
      text: "Save",
    };
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.clientId = params.id;
        this.getCustomerDetails(params.id);
      }
    });
    this.getActiveSession();
  }

  getCustomerDetails(id: number): void {
    this.loading = true;
    this.generalService.getClientDetails(
      (res, status) => {
        this.loading = false;
        if (status) {
          this.customerDetails = res.data;
          this.payments = res.data.payments;
          this.invoices = res.data.invoices;
        }
      },
      undefined,
      id
    );
  }

  receivePayment(form: any): void {
    this.isSaving = true;
    this.jobService.receiveClientPayment(
      { ...form, clientId: this.clientId, sessionId: this.jobSessions[0]?.id },
      (res, status) => {
        this.isSaving = false;
        if (status) {
          this.customCotrol("sessionId", this.receivePayForm).setValue(
            this.jobSessions[0]?.id
          );
          this.closeModal();
          this.receivePayForm.reset();
          this.getCustomerDetails(this.clientId);
          this.jobService.printReceipt(res.data.id);
        }
      }
    );
  }

  print(id: number): void {
    this.jobService.printInvoice(id, "InvoicePrint");
  }

  getActiveSession(): void {
    this.isSaving = true;
    this.jobService.sessions(
      "GET",
      "ActiveSession",
      undefined,
      (res, status) => {
        this.isSaving = false;
        if (status) {
          this.jobSessions = res.data;
          if (this.jobSessions.length == 0)
            this.customCotrol("sessionId", this.receivePayForm).setValue(
              this.jobSessions[0].id
            );
        }
      }
    );
  }

  displayActivePage(chunckedData: any): void {
    setTimeout(() => {
      this.chunkedRecords = chunckedData.chunks;
      if (chunckedData.controlClicked) {
        this.currentPage = chunckedData.currentPage;
      }
    });
  }

  displayActivePage2(chunckedData: any): void {
    setTimeout(() => {
      this.chunkedRecords2 = chunckedData.chunks;
      if (chunckedData.controlClicked) {
        this.currentPage = chunckedData.currentPage;
      }
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ["name"];
    }
  }

  customCotrol(name: string, form: FormGroup): FormControl {
    return form.get(name) as FormControl;
  }
}
