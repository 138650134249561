import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ExportXlsxComponent } from 'src/app/components/export-xlsx/export-xlsx.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ASSETSROOT } from 'src/app/services/api/api-endpoints';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { JobReportsService } from '../job-reports.service';

class BaseClass { }
interface BaseClass extends ModalComponent, ExportXlsxComponent { }
applyMixins(BaseClass, [ModalComponent, ExportXlsxComponent]);


@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent extends BaseClass implements OnInit {

  currentFilter: any = {};
  reportForm = new FormGroup({
    dateRange: new FormControl([this.settingService.getCurrentMonthDates().first,
    this.settingService.getCurrentMonthDates().last])
  });

  exporting = false;
  showFilter = false;
  assetsRoot = ASSETSROOT;
  loading = false;
  pdfReport: any;

  constructor(
    private jobReportsService: JobReportsService,
    public modalService: BsModalService,
    public apiConnect: ApiConnectionService,
    public authService: AuthenticationService,
    private settingService: SettingsService
  ) {
    super();
  }

  ngOnInit(): void {

    this.reportForm.valueChanges.subscribe(val => {
      const currentFilter: any = {};
      currentFilter.startDate = this.filterdateRange.value[0];
      currentFilter.endDate = this.filterdateRange.value[1];

      this.currentFilter = currentFilter;
    });
    // this.getReports();
  }

  getReports(): void {
    this.loading = true;
    this.jobReportsService.getJobReports('JobPayments', undefined, (res, status) => {
      this.loading = false;
      if (status) {
        this.pdfReport = res;
        console.log(res)
      }
    }, this.currentFilter);
  }

  exportExcelReport(): void {
    this.exporting = true;
    if (this.pdfReport) {
      const blobFile = this.convertBase64xlsxToBlob(this.pdfReport.xls.data);
      this.exportXlsx(blobFile, 'JobPayments.xlsx');
    }
    this.exporting = false;
  }

  // Form Controls
  get filterdateRange(): FormControl {
    return this.reportForm.get('dateRange') as FormControl;
  }
}
