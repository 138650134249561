<div [hidden]="isHidden" class="form-group" [ngClass]="[mb, (isFilterVar && !inTable) ? 'position-relative' : '']">
  <label for="select-hide-search" [ngClass]="{
      'position-absolute': isFilterVar,
      'filter-label': isFilterVar
    }">
    {{ setFormLabel() }}
    <span *ngIf="requiredValidator() && setFormLabel()" class="text-danger">*</span>
  </label>
  <span class="select2 select2-container select2-container--default select2-container--below" dir="ltr"
    data-select2-id="25" [ngClass]="[selectKeyClass, !inTable ? 'postion-relative' : '']"
    (click)="toggleSelectDropdown($event)">
    <!-- selection -->
    <span class="selection">
      <!-- single selection -->
      <span *ngIf="!multipleSelect" class="form-control select2-selection select2-selection--single position-relative"
        role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false"
        aria-labelledby="select2-select2-hide-search-container">
        <span class="select2-selection__rendered" id="select2-select2-hide-search-container" role="textbox"
          aria-readonly="true" [attr.title]="getSelection()">
          {{ getSelection() }}
        </span>
        <span class="select2-selection__arrow" role="presentation">
          <b role="presentation"></b>
        </span>
      </span>
      <!-- single selection -->

      <!-- multiple selection -->
      <span *ngIf="multipleSelect" class="select2-selection select2-selection--multiple" role="combobox"
        aria-haspopup="true" aria-expanded="true" tabindex="-1" aria-disabled="false"
        aria-owns="select2-default-select-multi-results"
        aria-activedescendant="select2-default-select-multi-result-vedw-polygon">
        <ul class="select2-selection__rendered">
          <li *ngFor="let item of selected" class="select2-selection__choice" title="Rombo" data-select2-id="1198">
            <span (click)="removeSelection(item)" class="select2-selection__choice__remove" role="presentation">×
            </span>
            {{ getSelection(item) === "" ? null : getSelection(item) }}
          </li>
          <li class="select2-search select2-search--inline">
            <input class="select2-search__field" type="search" tabindex="0" autocomplete="off" autocorrect="off"
              autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" placeholder=""
              style="width: 0.75em" aria-controls="select2-default-select-multi-results"
              aria-activedescendant="select2-default-select-multi-result-vedw-polygon" />
          </li>
        </ul>
      </span>
      <!-- multiple selection -->
    </span>
    <!-- selection -->

    <span class="dropdown-wrapper" aria-hidden="true"></span>

    <!-- select dropdown -->
    <span class="select2-container select2-container--default" style="position: absolute"><span
        class="select2-dropdown select2-dropdown--below" dir="ltr">
        <!-- search -->
        <span *ngIf="selectSearch" class="select2-search select2-search--dropdown select2-search--hide">
          <input class="select2-search__field form-control form-control-sm" [(ngModel)]="searchText" type="search"
            tabindex="0" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox"
            placeholder="search" aria-autocomplete="list" aria-controls="select2-select2-hide-search-results"
            aria-activedescendant="select2-select2-hide-search-result-khb7-trapeze" />
        </span>
        <!-- search -->

        <span class="select2-results">
          <!-- grouped data -->
          <ul *ngIf="groupedOptions" class="select2-results__options" role="listbox"
            id="select2-select2-hide-search-results" aria-expanded="true" aria-hidden="false">
            <li class="select2-results__option" role="group" aria-label="Figures" data-select2-id="682">
              <strong class="select2-results__group">Figures</strong>
              <ul class="select2-results__options select2-results__options--nested">
                <li class="select2-results__option" id="select2-select2-hide-search-result-7pnu-romboid" role="option"
                  aria-selected="false" data-select2-id="select2-select2-hide-search-result-7pnu-romboid">
                  Romboid
                </li>
                <li class="select2-results__option" id="select2-select2-hide-search-result-khb7-trapeze" role="option"
                  aria-selected="false" data-select2-id="select2-select2-hide-search-result-khb7-trapeze">
                  Trapeze
                </li>
                <li class="select2-results__option" id="select2-select2-hide-search-result-hhuj-triangle" role="option"
                  aria-selected="true" data-select2-id="select2-select2-hide-search-result-hhuj-triangle">
                  Triangle
                </li>
                <li class="select2-results__option" id="select2-select2-hide-search-result-rhdh-polygon" role="option"
                  aria-selected="false" data-select2-id="select2-select2-hide-search-result-rhdh-polygon">
                  Polygon
                </li>
              </ul>
            </li>
          </ul>
          <!-- grouped data -->

          <!-- single data -->
          <ul *ngIf="!groupedOptions" class="select2-results__options" role="listbox"
            id="select2-select2-hide-search-results" aria-expanded="true" aria-hidden="false">
            <li *ngFor="
                let opt of iteratableOptions
                  | filter: filterList:searchText:false
              " class="select2-results__option single__data" role="group" aria-label="Figures" data-select2-id="682"
              (click)="selectItem(opt?.ngValue)">
              {{ opt?.display }}
            </li>
          </ul>
          <!-- single data -->
        </span>
      </span>
    </span>
    <!-- select dropdown -->
  </span>

  <!-- validation error -->
  <!-- <div *ngIf="control.invalid && (control.dirty || control.touched) && setFormLabel()" class="mt-1">
    <small class="text-danger">
      <i class="bi bi-exclamation-circle me-1"></i>
      {{ validationError(control.errors) }}
    </small>
  </div> -->
  <!-- validation error -->
</div>