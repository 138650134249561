import {Injectable} from '@angular/core';
import {ApiLinkService} from 'src/app/services/api/apilink.service';
import {AlertService} from 'src/app/services/alert/alert.service';
import {SessionStorageService} from 'src/app/services/session/session-storage.service';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApiConnectionService {
    constructor(
        public apiLink: ApiLinkService,
        public alertService: AlertService,
        public sessionStorageService: SessionStorageService
    ) {
    }

    secureConnect(
        httpMethod: string,
        link: string,
        data?: any,
        params?: any,
        id?: number,
        callback?: any,
        unsubscribe?: Observable<void>,
        dataTransform?: any
    ): void {
        this.apiLink.dataUplink(httpMethod, link, data, params, id, dataTransform)
            .pipe(takeUntil(unsubscribe ? unsubscribe : new Observable()))
            .subscribe(
                (res: any) => {
                    callback(res, 'success', this.alertMessage.bind(this));
                },
                (err: any) => {
                    callback(err, 'error', this.alertMessage.bind(this));
                });
    }

    alertMessage(type: string, title: string, message: string): void {
        this.alertService.setAlert({type, title, message});
    }

    callApi(
        httpMethod: string,
        link: string,
        data?: any,
        params?: any,
        id?: any
    ): Observable<any> {
        return this.apiLink.dataUplink(httpMethod, link, data, params, id);
    }
}
