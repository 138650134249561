<div class="form-group" [ngClass]="{'position-relative': isFilterVar, 'mb-0': mb0 !== undefined}">
  <label *ngIf="fieldId" [for]="fieldId" class="label text-muted font-weight-bolder"
    [ngClass]="{'position-absolute': isFilterVar, 'filter-label': isFilterVar}">
    {{label}}
    <span *ngIf="required" class="text-danger">*</span>
  </label>

  <!-- capitalise input -->
  <input *ngIf="capitalType" [readOnly]="readOnly" [attr.maxlength]="maxNo" [attr.min]="minNo"
    [attr.placeholder]="placeHolder" [attr.type]="fieldType" [formControl]="control" autocomplete="off"
    class="form-control" [ngClass]="{'form-control-sm': formControlSm}"
    oninput="javascript: if(true)this.value = this.value.toUpperCase()" />
  <!-- capitalise input -->

  <!-- normal input -->
  <input *ngIf="!capitalType" [readOnly]="readOnly" [attr.maxlength]="maxNo" [attr.min]="minNo"
  [step]="step"  [attr.placeholder]="placeHolder" [attr.type]="fieldType" [formControl]="control" autocomplete="off"
    class="form-control" [ngClass]="{'form-control-sm': formControlSm}" />
  <!-- normal input -->


  <app-form-validation [validationErrors]="validationErrors"></app-form-validation>
</div>

<!-- (input)="control.setValue(control.value.toUpperCase())" -->