import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {SessionStorageService} from '../session/session-storage.service';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[hideSubModule]',
})
export class HideIfNotGrantedDirective implements OnInit {
    constructor(
        public session: SessionStorageService,
        private el: ElementRef
    ) {
    }

    @Input() query!: string;
    @Input() module!: string;

    roles: any[] = [];

    ngOnInit(): void {
        this.roles = this.session.getUserExcess();
        this.check();
    }

    check(): void {
        if (this.query && this.module) {
            this.roles.forEach(role => {
                if (role.section === this.module) {
                    role.children.forEach((child: any) => {
                        if (child.section === this.query) {
                            if (!child.canAccess) {
                                this.el.nativeElement.style.display = 'none';
                            }
                        }
                    });
                }
            });
        }
    }
}

