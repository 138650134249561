import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { sidebarMenu } from 'src/app/constants/side-menu';
import { ASSETSROOT } from 'src/app/services/api/api-endpoints';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ProfileService } from 'src/app/services/proflie/profile.service';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';
import {WorkSpaceService} from '../../../pages/auth/workspace/workspace.service';
import {CategoryAuthService} from '../../../services/authorization/guards/category-auth.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {

  activeUser: any;
  Userphoto: any;
  forgetMe = false;
  assetsRoot = ASSETSROOT;
  siteList: any[] = [];
  activeBusiness: any;
  activeSite: any;
  hideSideBar = false;
  menu = sidebarMenu;

  constructor(
    public authService: AuthenticationService,
    public profile: ProfileService,
    public router: Router,
    public session: SessionStorageService,
    private workSpaceService: WorkSpaceService,
    public authCategory: CategoryAuthService
  ) {
  }

  ngOnInit(): void {
    this.activeBusiness = this.session.getActiveBusiness();
    this.activeSite = this.session.getActiveSite();
    this.workSpaceService.getUserSites((res, status) => {
      if (status) {
        this.siteList = res;
      }
    });

    this.checkActiveUser();
    this.profile.watchStorage().subscribe(val => {
      this.checkActiveUser();
    });

  }

  switchSite(id: number): void {
    this.session.setActiveSite(id);
    window.location.reload();
  }

  checkActiveUser(): void {
    const cred = this.authService.sessionStorageService.getUserCredentials();
    const rem = this.authService.sessionStorageService.getActiveUser();
    if (rem) {
      this.activeUser = {
        name: rem.firstname + ' ' + rem.lastname,
        email: rem.email,
        type: rem.type,
        role: rem.role.name
      };
      if (rem.photo) {
        this.Userphoto = rem.photo.url;
      }
    }
    if (cred) {
      if (cred.isValid) {
        this.forgetMe = true;
      }
    }
  }

  _backToSignUp(): void {
    this.authService.logout();
  }

  _forgetMe(): void {
    this.authService.forgetMe();
  }
}
