import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {JobService} from '../job.service';
import {GeneralService} from '../../settings/general-settings/general.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {applyMixins} from 'src/app/components/mixin/mixin';
import {ModalComponent} from 'src/app/components/modal/modal.component';
import {SALETYPE, TRANSMODE} from 'src/app/components/labels/others';
import {ActivatedRoute, Router} from '@angular/router';
import {SettingsService} from 'src/app/services/settings/settings.service';
import {SessionStorageService} from 'src/app/services/session/session-storage.service';
import {WebSocketService} from 'src/app/services/settings/web-socket.service';
import {ROLES} from 'src/app/constants/roles';

class BaseClass {
}

interface BaseClass extends ModalComponent {
}

applyMixins(BaseClass, [ModalComponent]);

@Component({
    selector: 'app-new-job',
    templateUrl: './new-job.component.html',
    styleUrls: ['./new-job.component.scss'],
})
export class NewJobComponent extends BaseClass implements OnInit {
    Comp = ROLES.Compartment;
    activeStep: any;
    title!: string;
    identity: any[] = ['PASSPORT', 'NATIONAL ID', 'DRIVER\'S LICENCE'];
    nationality: any[] = [];
    currentStep = 'step1';
    steps = ['step1', 'step2'];
    saleTypes: any[] = SALETYPE;
    transModes: any[] = TRANSMODE;
    source: any[] = [
        'ELDORET',
        'NAKURU',
        'KISUMU',
        'MOMBASA',
        'KONZA CITY',
        'TANGA TZ',
    ];
    fuelTypesList: any[] = [];
    fuelTypesSub: BehaviorSubject<[]> = new BehaviorSubject([]);
    fuelTypesPredicate!: object;
    driversList: any[] = [];
    driversSub: BehaviorSubject<[]> = new BehaviorSubject([]);
    driversPredicate!: object;
    customersList: any[] = [];
    customersSub: BehaviorSubject<[]> = new BehaviorSubject([]);
    customersPredicate!: object;
    activeSite: any;
    consigner!: FormGroup;
    consignee!: FormGroup;
    shipper!: FormGroup;
    cargoEntries!: FormArray;
    densityAt20: any;
    jobSessions: any[] = [];
    customerForm!: FormGroup;
    newBtn!: object;

    driverForm!: FormGroup;
    driverBtn!: object;

    moreInfoform!: FormGroup;
    remarksForm!: FormGroup;
    comments: any[] = [];

    jobId!: number;
    jobDetails: any;

    loadingSaveBtn = false;
    addBtn = false;
    isSaving = false;
    isEditing = false;
    noDipping = false;
    loading = false;
    comptsInvalid = true;

    constructor(
        public modalService: BsModalService,
        private router: ActivatedRoute,
        private route: Router,
        private location: Location,
        private jobService: JobService,
        private generalService: GeneralService,
        public settingService: SettingsService,
        private session: SessionStorageService,
        private websocket: WebSocketService
    ) {
        super();
        this.consigner = new FormGroup({
            source: new FormControl(null, Validators.required),
            deliveryDate: new FormControl(
                this.settingService.formatDateToSlashes(new Date()),
                Validators.required
            ),
            customerId: new FormControl(null, Validators.required),
        });
        this.consignee = new FormGroup({
            destination: new FormControl(null, Validators.required),
            typeOfSale: new FormControl('Import', Validators.required),
            customerId: new FormControl(null, Validators.required),
        });
        this.shipper = new FormGroup({
            shipmentNumber: new FormControl(null, Validators.required),
            modeOfTransport: new FormControl('Road', Validators.required),
            vehicleNumber: new FormControl(null, Validators.required),
            vehicleOwner: new FormControl(null, Validators.required),
            driverId: new FormControl(null, Validators.required),
            trailerNumber: new FormControl(null),
        });
        this.moreInfoform = new FormGroup({
            numberOfCompartments: new FormControl(null, Validators.required),
            portOfEntry: new FormControl(null, Validators.required),
            overallQuantity: new FormControl(null, Validators.required),
            sessionId: new FormControl(null, Validators.required),
        });
        this.remarksForm = new FormGroup({
            dippingDate: new FormControl(null, Validators.required),
            dipperRemarks: new FormControl(null, Validators.required),
            variationComment: new FormControl(null, Validators.required),
        });

        this.cargoEntries = new FormArray([
            new FormGroup({
                compartmentNumber: new FormControl(null, Validators.required),
                actualQuantityL: new FormControl(null),
                actualQuantityL20: new FormControl(null),
                densityAt20: new FormControl(null),
                temperature: new FormControl(null),
                fuelTypeId: new FormControl(null, Validators.required),
                oldSeal: new FormControl(null, Validators.required),
                newSeal: new FormControl(null),
                observedVolumeAt20C: new FormControl(null),
                observedDensityAt20C: new FormControl(null),
                finalBoilingPoint: new FormControl(null),
                flashPoint: new FormControl(null),
                expand: new FormControl(true),
            }),
        ]);

        this.customerForm = new FormGroup({
            companyName: new FormControl(null, Validators.required),
            companyTIN: new FormControl(null),
            contact: new FormControl(null),
            code: new FormControl(null),
            canClearWithNoPayment: new FormControl(false, Validators.required),
        });
        this.newBtn = {
            method: () => {
                return this.saveCustomer(this.customerForm.value);
            },
            text: 'Save',
        };
        this.driverForm = new FormGroup({
            firstname: new FormControl(null, Validators.required),
            lastname: new FormControl(null, Validators.required),
            othername: new FormControl(null),
            nationality: new FormControl(null, Validators.required),
            typeOfIdentification: new FormControl(null, Validators.required),
            identificationNumber: new FormControl(null, Validators.required),
            code: new FormControl(null, Validators.required),
            phoneNumber: new FormControl(null, Validators.required),
        });
        this.driverBtn = {
            method: () => {
                return this.saveDriver(this.driverForm.value);
            },
            text: 'Save',
        };

        this.fuelTypesPredicate = {
            method1: (obj: any) => obj.id,
            method2: (obj: any) => obj.name,
        };
        this.customersPredicate = {
            method1: (obj: any) => obj.id,
            method2: (obj: any) => obj.companyName,
        };
        this.driversPredicate = {
            method1: (obj: any) => obj.id,
            method2: (obj: any) => obj.firstname,
        };
    }

    ngOnInit(): void {
        this.consigner.valueChanges.subscribe(val => {
            console.log(val);
        });
        this.consignee.valueChanges.subscribe(val => {
            console.log(val);
        });
        this.shipper.valueChanges.subscribe(val => {
            console.log(val);
        });
        this.moreInfoform.valueChanges.subscribe(val => {
            console.log(val);
        });
        this.remarksForm.valueChanges.subscribe(val => {
            console.log(val);
        });
        this.driverForm.valueChanges.subscribe(val => {
            console.log(val);
        });
        this.cargoEntries.valueChanges.subscribe(val => {
            console.log(val);
        });
        this.getActiveSession();
        this.getCustomers();
        this.getDrivers();
        this.getFuelTypes();
        this.getNationality();
        this.getVariationComments();
        this.router.queryParams.subscribe((vax) => {
            if (vax.id) {
                this.steps = ['step1'];
                this.isEditing = true;
                this.jobId = vax.id;
                this.getJobDetails(vax.id);
            }
            if (vax.exempt) {
                this.noDipping = true;
                this.steps = ['step1'];
            }
        });
        this.activeSite = this.session.getActiveSite();
        this._more();
    }

    _more(): void {
        console.log(this.activeSite);
        this.customCotrol('portOfEntry', this.moreInfoform).patchValue(
            this.activeSite.name
        );
        this.customCotrol(
            'numberOfCompartments',
            this.moreInfoform
        ).valueChanges.subscribe((val) => {
            if (val) {
                this.cargoEntries.clear();
                for (let i = 0; i < val; i++) {
                    this.newEntry();
                }
            }
        });
        this.customCotrol('nationality', this.driverForm).valueChanges.subscribe(
            (val) => {
                this._findCode(val, this.driverForm);
            }
        );
    }

    _insertComps($event: any): void {
        this.cargoEntries.clear();
        if ($event) {
            this.comptsInvalid = false;
            this.closeModal();
            $event.forEach((item: any) => {
                this.newEntry(item);
            });
        }
    }

    _findCode(val: any, form: FormGroup) {
        this.nationality.forEach((vax) => {
            if (vax.nationality === val) {
                this.customCotrol('code', form).patchValue(vax.code);
            }
        });
    }

    onChangeStep($event: any): void {
        this.currentStep = $event;
    }

    expandCargo(cargo: any, index: number): void {
        cargo = this.cargoEntries.value.find(
            (a: any) => a.compartmentNumber === cargo.compartmentNumber
        );
        this.customEntryCell('expand', index).setValue(!cargo.expand);
    }

    onSubmitStepper($event: any): void {
        if ($event) {
            if (
                this.moreInfoform.valid &&
                this.consignee.valid &&
                this.consigner.valid &&
                this.shipper.valid
            ) {
                if (this.isEditing) {
                    this.updateJob();
                } else if (this.noDipping) {
                    this.saveExemptedJob();
                } else {
                    this.saveJob();
                }
            }
        }
    }

    getFuelTypes(): void {
        this.generalService.saveFuelType('GET', undefined, (res, status) => {
            if (status) {
                this.fuelTypesList = res.data;
                this.fuelTypesSub.next(res.data);
            }
        });
    }

    getCustomers(): void {
        this.generalService.saveCustomer('GET', undefined, (res, status) => {
            if (status) {
                this.customersList = res.data;
                this.customersSub.next(res.data);
            }
        });
    }

    getDrivers(): void {
        this.generalService.saveDriver('GET', undefined, (res, status) => {
            if (status) {
                this.driversList = res.data;
                this.driversSub.next(res.data);
            }
        });
    }

    saveJob(): void {
        this.loadingSaveBtn = true;
        this.jobService.saveJob(
            'POST',
            {
                ...this.remarksForm.value,
                ...this.moreInfoform.value,
                consigner: this.consigner.value,
                shipper: this.shipper.value,
                consignee: this.consignee.value,
                compartments: this.cargoEntries.value,
            },
            (res, status) => {
                this.loadingSaveBtn = false;
                if (status) {
                    // this.currentStep = 'step1';
                    this.route.navigate(['/app/job'], {
                        queryParams: {type: 'DataEntryJobs'},
                    });
                }
            }
        );
    }

    saveExemptedJob(): void {
        this.loadingSaveBtn = true;
        this.jobService.saveExemptedJob(
            'POST',
            {
                ...this.remarksForm.value,
                ...this.moreInfoform.value,
                consigner: this.consigner.value,
                shipper: this.shipper.value,
                consignee: this.consignee.value,
                compartments: this.cargoEntries.value,
            },
            (res, status) => {
                this.loadingSaveBtn = false;
                if (status) {
                    // this.currentStep = 'step1';
                    this.route.navigate(['/app/job'], {
                        queryParams: {type: 'DataEntryJobs'},
                    });
                }
            }
        );
    }

    saveCustomer(form: any): void {
        this.isSaving = true;
        this.generalService.saveCustomer('POST', form, (res, status) => {
            this.isSaving = false;
            this.closeModal();
            if (status) {
                this.getCustomers();
                this.customerForm.reset();
                this.customerForm.get('canClearWithNoPayment')?.setValue(false);
            }
        });
    }

    saveDriver(form: any): void {
        this.isSaving = true;
        this.generalService.saveDriver('POST', form, (res, status) => {
            this.isSaving = false;
            this.closeModal();
            if (status) {
                this.getDrivers();
                this.driverForm.reset();
            }
        });
    }

    getJobDetails(id: any): void {
        this.loading = true;
        this.jobService.saveJob(
            'GET',
            undefined,
            (res: any, status: any) => {
                this.loading = false;
                if (status) {
                    console.log(res.data);
                    this.jobDetails = res.data;
                    this._prefillData(res.data);
                }
            },
            undefined,
            id
        );
    }

    _prefillData(data: any): void {
        this._rearrandeDate(data.consigner?.deliveryDate.replace(/-/g, '/'));
        this.customCotrol('shipmentNumber', this.shipper).setValue(
            data.shipper?.shipmentNumber
        );
        this.customCotrol('modeOfTransport', this.shipper).setValue(
            data.shipper?.modeOfTransport
        );
        this.customCotrol('typeOfSale', this.consignee).setValue(
            data.consignee?.typeOfSale
        );
        this.customCotrol('vehicleNumber', this.shipper).setValue(
            data.shipper?.vehicleNumber
        );
        this.customCotrol('trailerNumber', this.shipper).setValue(
            data?.trailerNumber
        );
        this.customCotrol('destination', this.consignee).setValue(
            data.consignee?.destination
        );
        this.customCotrol('source', this.consigner).setValue(
            data.consigner?.source
        );
        this.customCotrol('customerId', this.consignee).setValue(
            data.consignee?.client?.id
        );
        this.customCotrol('customerId', this.consigner).setValue(
            data.consignee?.client?.id
        );
        this.customCotrol('driverId', this.shipper).setValue(
            data.shipper?.driver?.id
        );
        this.customCotrol('vehicleOwner', this.shipper).setValue(
            data.shipper?.driver?.id
        );
        this.customCotrol('overallQuantity', this.moreInfoform).setValue(
            data.overallQuantity
        );
        this.customCotrol('numberOfCompartments', this.moreInfoform).setValue(
            data.numberOfCompartments
        );
        this.customCotrol('portOfEntry', this.moreInfoform).setValue(
            data.portOfEntry
        );
    }

    _rearrandeDate(dateStr: any) {
        const dateObj = new Date(dateStr);
        this.customCotrol('deliveryDate', this.consigner).patchValue(
            this.settingService.formatDateToSlashes(dateObj)
        );
    }

    updateJob(): void {
        console.log(this.consigner.value);
        this.loadingSaveBtn = true;
        this.jobService.updateJob(
            'PUT',
            {
                consigner: this.consigner.value,
                shipper: this.shipper.value,
                consignee: this.consignee.value,
            },
            (res, status) => {
                this.loadingSaveBtn = false;
                if (status) {
                    this.route.navigate(['/app/job'], {
                        queryParams: {type: 'DataEntryJobs'},
                    });
                }
            },
            undefined,
            this.jobDetails.id
        );
    }

    deleteRow(index: number): void {
        this.cargoEntries.removeAt(index);
    }

    customCotrol(name: string, group: FormGroup): FormControl {
        return group.get(name) as FormControl;
    }

    customEntryCell(name: string, index: number): FormControl {
        return (this.cargoEntries.controls[index] as FormGroup)?.get(
            name
        ) as FormControl;
    }

    newEntry(item?: any): void {
        this.cargoEntries.push(
            new FormGroup({
                compartmentNumber: new FormControl(
                    item?.compartmentNumber,
                    Validators.required
                ),
                // lonNumber: new FormControl(null, Validators.required),
                // orderQuantity: new FormControl(null, Validators.required),
                actualQuantityL: new FormControl(
                    item?.actualQuantityL
                ),
                actualQuantityL20: new FormControl(null, Validators.required),
                densityAt20: new FormControl(null),
                temperature: new FormControl(null),
                fuelTypeId: new FormControl(item?.fuelTypeId, Validators.required),
                oldSeal: new FormControl(null),
                newSeal: new FormControl(null),
                observedVolumeAt20C: new FormControl(null),
                observedDensityAt20C: new FormControl(null),
                finalBoilingPoint: new FormControl(null),
                flashPoint: new FormControl(null),
                expand: new FormControl(true),
            })
        );
    }

    _findProduct(fuelId: any): string {
        let name = '';
        const result = this.fuelTypesList.find(({id}) => id == +fuelId);
        name = result?.name;
        return name;
    }

    getNationality(): void {
        this.generalService.getNationality((res, status) => {
            if (status) {
                this.nationality = res.data;
            }
        });
    }

    getVariationComments(): void {
        this.jobService.variationComments((res, status) => {
            if (status) {
                this.comments = res.data;
            }
        });
    }

    getActiveSession(): void {
        this.isSaving = true;
        this.jobService.sessions(
            'GET',
            'ActiveSession',
            undefined,
            (res, status) => {
                this.isSaving = false;
                if (status) {
                    this.jobSessions = res.data;
                    if (this.jobSessions.length > 0) {
                        this.customCotrol('sessionId', this.moreInfoform).setValue(
                            this.jobSessions[0].id
                        );
                    }
                }
            }
        );
    }

    _back() {
        console.log('clicked');
        this.websocket.emitWebNoti('New Job Has Been Entered...');
        if (this.currentStep === 'step2') {
            this.activeStep = {index: 0, step: 'step1'};
            this.currentStep = 'step1';
        } else {
            this.location.back();
        }
    }
}
