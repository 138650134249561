<!-- table data -->
<div class="row d-flex justify-content-between mt-4" style="padding-right: 15px;padding-left: 15px;">
    <h6 class="text-muted">Compartment List</h6>
    <span *ngIf="jobDetails?.jobStatus === 'NEW'" class="text-primary" style="cursor: pointer;"
        (click)="openModal(addCargo)">
        <em class="bi bi-plus-circle"></em> Add</span>
</div>
<div class="row mx-0">
    <div class="col-12 px-0" *ngIf="!loading">
        <table class="table table-bordered table-centered table-nowrap mb-0">
            <thead class="">
                <tr class="text-muted">
                    <th rowspan="2"></th>
                    <th rowspan="2" class="text-center">Comp.</th>
                    <th rowspan="2">Product</th>
                    <th rowspan="2">Actual Qty.(L)</th>
                    <th rowspan="2">Temp.</th>
                    <th rowspan="2">Flash Point (°C)</th>
                    <th rowspan="2">Boiling Point (°C)</th>
                    <th colspan="3" class="col-bg1">Qty.(L20)</th>
                    <th colspan="3" class="col-bg2">Density @20C KGV</th>
                </tr>
                <tr class="text-muted">
                    <th class="col-bg1">Actual</th>
                    <th class="col-bg1">Obs</th>
                    <th class="col-bg1">Var</th>
                    <th class="col-bg2">Actual</th>
                    <th class="col-bg2">Obs</th>
                    <th class="col-bg2">Var</th>
                </tr>

            </thead>
            <tbody>
                <ng-container *ngFor="let item of cargoList; let i as index">
                    <tr>
                        <td class="text-center">
                            <span (click)="expandCargo(item)" class="action-btn" type="button">
                                <em class="bi"
                                    [ngClass]="{'bi-chevron-right': !item.expand,'bi-chevron-down': item.expand}"></em>
                            </span>
                        </td>
                        <td class="text-center">
                            {{item.compartmentNumber}}
                        </td>
                        <td>
                            <span class="font-weight-bolder text-black">{{item.fuelType.name}}</span>
                        </td>
                        <td>
                            {{item.actualQuantityL | number:'1.0-0'}}
                        </td>
                        <td class="text-right">
                            {{item.temperature}}
                        </td>
                        <td class="text-right">
                            {{item.dipping?.flashPoint}}
                        </td>
                        <td class="text-right">
                            {{item.dipping?.finalBoilingPoint}}
                        </td>
                        <td class="col-bg1 text-right">
                            {{item.actualQuantityL20 | number:'1.0-0'}}
                        </td>
                        <td class="col-bg1 text-right">
                            {{item?.dipping?.observedVolumeAt20C | number:'1.0-0'}}
                        </td>
                        <td class="col-bg1 text-right" style="color: #89fb00e8;">
                            {{item?.dipping?.variationInVolumeAt20C | number:'1.0-0'}}
                        </td>
                        <td class="text-right col-bg2">
                            {{item.densityAt20 | number:'1.0-0'}}
                        </td>
                        <td class="col-bg2 text-right">
                            {{item?.dipping?.observedDensityAt20C | number:'1.0-0'}}
                        </td>
                        <td class="col-bg2 text-right" style="color: #89fb00e8;">
                            {{item?.dipping?.variationInDensityAt20C | number:'1.0-0'}}</td>
                        <td class="text-center">
                            <button disable [module]="Job.name" [query]="Job.children.EditComp"
                                class="btn btn-info btn-sm" (click)="_editCargo(addCargo,item)">
                                <em class="bi bi-pencil-square"></em>
                            </button>
                        </td>
                    </tr>
                    <tr [ngClass]="{'d-none': !item.expand}" style="background-color: #ebf0f1;">
                        <td colspan="14" *ngIf="item.dipping !== undefined">
                            <div *ngIf="!lab" class="d-flex justify-content-between">
                                <span class="font-weight-bold text-primary">Dipping Info</span>
                            </div>
                            <div class="card">
                                <div class="card-body p-2 position-relative group-action">
                                    <div *ngIf="!lab" class="row mx-0">
                                        <div class="col card-divider">
                                            <div class="profile-view py-3">
                                                <div class="profile-basic position-relative">
                                                    <ul class="personal-info">
                                                        <li>
                                                            <div class="title">Marker</div>
                                                            <div class="text"> :
                                                                <a href="javascript:void(0);"
                                                                    class="ml-1 text-uppercase">{{item?.dipping?.marker.name}}</a>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="title">Marker Qty</div>
                                                            <div class="text"> :
                                                                <a href="javascript:void(0);"
                                                                    class="ml-1 text-uppercase">{{item?.dipping?.calculatedMarkerQuantity}}
                                                                    (L)</a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col card-divider">
                                            <div class="profile-view py-3">
                                                <div class="profile-basic position-relative">
                                                    <ul class="personal-info">
                                                        <li>
                                                            <div class="title">Seal(Old)</div>
                                                            <div class="text"> :
                                                                <a href="javascript:void(0);"
                                                                    class="ml-1">{{item?.dipping?.oldSeal
                                                                    |uppercase}}</a>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="title">Seal(New)</div>
                                                            <div class="text"> :
                                                                <a href="javascript:void(0);"
                                                                    class="ml-1">{{item?.dipping?.newSeal|uppercase}}</a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col card-divider">
                                            <div class="profile-view py-3">
                                                <div class="profile-basic position-relative">
                                                    <ul class="personal-info">
                                                        <li>
                                                            <div class="title">Volume @20°C</div>
                                                            <div class="text"> :
                                                                <a href="javascript:void(0);"
                                                                    class="ml-1 text-uppercase">{{item?.dipping?.observedVolumeAt20C}}</a>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="title">Density @20°C</div>
                                                            <div class="text"> :
                                                                <a href="javascript:void(0);"
                                                                    class="ml-1 text-uppercase">{{item?.dipping?.observedDensityAt20C}}</a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="row" *ngIf="lab">
                                        <div class="col">
                                            <div class="profile-view py-3">
                                                <div class="profile-basic position-relative">
                                                    <ul class="personal-info">
                                                        <li>
                                                            <div class="title">Marker</div>
                                                            <div class="text"> :
                                                                <a href="javascript:void(0);"
                                                                    class="ml-1 text-uppercase">{{item?.dipping?.marker.name}}</a>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="title">Marker Qty</div>
                                                            <div class="text"> :
                                                                <a href="javascript:void(0);"
                                                                    class="ml-1 text-uppercase">{{item?.dipping?.calculatedMarkerQuantity}}
                                                                    (L)</a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col"><small>Chemical</small></th>
                                                        <th scope="col"><small>Concetration/(Ltr) in Marker</small></th>
                                                        <th scope="col"><small>Total</small></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let chem of item?.dipping?.marker.items">
                                                        <th scope="row">1</th>
                                                        <td>{{chem.inventoryItem.name}}</td>
                                                        <td>{{chem.concPerLitreInMarker}}</td>
                                                        <td>{{chem.concPerLitreInMarker *
                                                            item?.dipping?.calculatedMarkerQuantity}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td colspan="14" *ngIf="item.dipping === undefined" class="text-center">
                            <div class="card">
                                <div class="card-body">
                                    <img src="assets/images/empty-illustration.svg" width="120px" alt="">
                                    <p><span class="text-warning font-weight-bold">No Dipping Infomation yet</span></p>
                                    <p class="m-0"><span class="btn btn-sm btn-outline-info"
                                            (click)="_dippComp(newDipping,item)">
                                            <em class="bi bi-plus"></em> Add</span></p>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                <tr class="mt-3 border-none"></tr>
                <ng-container>
                    <tr>
                        <td colspan="3">
                            <span class="text-info font-weight-bold">Totals</span>
                        </td>
                        <td class="text-right">{{_findTotals('aL')|number}}</td>
                        <td class="text-right">{{_findTotals('temp')}}</td>
                        <td></td>
                        <td></td>
                        <td class="col-bg1 text-right">{{_findTotals('aL20')|number}}</td>
                        <td class="col-bg1 text-right">{{_findTotals('aL20Obs')|number}}</td>
                        <td class="col-bg1 text-right">{{_findTotals('aL20Var')|number}}</td>
                        <td class="col-bg2 text-right">{{_findTotals('d20')|number}}</td>
                        <td class="col-bg2 text-right">{{_findTotals('d20Obs')|number}}</td>
                        <td class="col-bg2 text-right">{{_findTotals('d20Var')|number}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div class="col-12 text-center" *ngIf="loading">
        <app-loading-placeholder></app-loading-placeholder>
    </div>
</div>

<!-- new dipping form -->
<ng-template #newDipping>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="newBtn"
        [modalTitle]="'Comp. '+cargo.compartmentNumber+' Dipping Info'" [condition]="dippingForm.invalid">
        <div class="row mx-0" [formGroup]="dippingForm">
            <div class="col-6">
                <label>Old Seal<span class="text-danger">*</span></label>
                <div class="form-group mb-3">
                    <input type="string" class="form-control form-control-sm"
                        onkeyup="this.value = this.value.toUpperCase();"
                        [formControl]="customCotrol('oldSeal',dippingForm)">
                </div>
            </div>
            <div class="col-6">
                <label>New Seal<span class="text-danger">*</span></label>
                <div class="form-group mb-3">
                    <input type="string" class="form-control form-control-sm"
                        onkeyup="this.value = this.value.toUpperCase();"
                        [formControl]="customCotrol('newSeal',dippingForm)">
                </div>
            </div>
            <div class="col-6">
                <app-input [required]="true" [fieldId]="'observedVolumeAt20C'" [fieldType]="'number'"
                    [control]="customCotrol('observedVolumeAt20C', dippingForm)">
                </app-input>
            </div>
            <div class="col-6">
                <app-input [required]="true" [fieldId]="'observedDensityAt20C'" [fieldType]="'number'"
                    [control]="customCotrol('observedDensityAt20C', dippingForm)">
                </app-input>
            </div>
        </div>
    </app-modal>
</ng-template>

<!-- edit dipping form -->
<ng-template #editDipping>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="editBtn" [modalTitle]="'Edit Dipping Info'"
        [condition]="editDippingForm.invalid">
        <div class="row mx-0" [formGroup]="editDippingForm">
            <div class="col-6" disable [module]="Comp.name" [query]="Comp.children.OldSeal">
                <label>Old Seal<span class="text-danger">*</span></label>
                <div class="form-group mb-3">
                    <input type="string" class="form-control form-control-sm"
                        onkeyup="this.value = this.value.toUpperCase();"
                        [formControl]="customCotrol('oldSeal',editDippingForm)">
                </div>
            </div>
            <div class="col-6" disable [module]="Comp.name" [query]="Comp.children.NewSeal">
                <label>New Seal<span class="text-danger">*</span></label>
                <div class="form-group mb-3">
                    <input type="string" class="form-control form-control-sm"
                        onkeyup="this.value = this.value.toUpperCase();"
                        [formControl]="customCotrol('newSeal',editDippingForm)">
                </div>
            </div>
            <div class="col-6" disable [module]="Comp.name" [query]="Comp.children.ObsVol20C">
                <app-input [required]="true" [fieldId]="'observedVolumeAt20C'" [fieldType]="'number'"
                    [control]="customCotrol('observedVolumeAt20C', editDippingForm)">
                </app-input>
            </div>
            <div class="col-6" disable [module]="Comp.name" [query]="Comp.children.ObsDensity20C">
                <app-input [required]="true" [fieldId]="'observedDensityAt20C'" [fieldType]="'number'"
                    [control]="customCotrol('observedDensityAt20C', editDippingForm)">
                </app-input>
            </div>
        </div>
    </app-modal>
</ng-template>

<!-- new CARGO form -->
<ng-template #addCargo>
    <app-modal [size]="'long'" [loading]="isSaving" [modalActionButton]="cargobtn"
        [modalTitle]="'Edit Compartment '+customCotrol('compartmentNumber', cargoForm).value"
        [condition]="cargoForm.invalid">
        <div class="row px-2">
            <div class="col-lg-12 user-form-group mb-4">
                <label for="" class="group-label text-primary">Compartment Info</label>
                <div class="row">
                    <div class="col-4" disable [module]="Comp.name" [query]="Comp.children.FuelType">
                        <app-input-select-new [fieldId]="'fuelTypeId'" [control]="customCotrol('fuelTypeId', cargoForm)"
                            [predicate]="fuelTypesPredicate" [options]="fuelTypesList" [optionsList]="fuelTypesSub">
                        </app-input-select-new>
                    </div>
                    <div class="col-4" disable [module]="Comp.name" [query]="Comp.children.DeclaredVol">
                        <label class="label text-muted font-weight-bolder">
                            Actual Qty.(L)
                            <span class="text-danger">*</span>
                        </label>
                        <input class="form-control form-control-sm" type=number AllowDecimalNumbers
                            [formControl]="customCotrol('actualQuantityL', cargoForm)" />
                        <!-- <app-input [required]="true" [fieldId]="'actualQuantityL'" [fieldType]="'number'"
                            [control]="customCotrol('actualQuantityL', cargoForm)">
                        </app-input> -->
                    </div>
                    <div class="col-4" disable [module]="Comp.name" [query]="Comp.children.ActualQtyL20">
                        <label class="label text-muted font-weight-bolder">
                            Actual Qty.(L20)
                            <span class="text-danger">*</span>
                        </label>
                        <input class="form-control form-control-sm" type=number AllowDecimalNumbers
                            [formControl]="customCotrol('actualQuantityL20', cargoForm)" />
                        <!-- <app-input [required]="true" [fieldId]="'actualQuantityL20'" [fieldType]="'number'"
                            [control]="customCotrol('actualQuantityL20', cargoForm)">
                        </app-input> -->
                    </div>
                    <div class="col-4" disable [module]="Comp.name" [query]="Comp.children.Density20KGV">
                        <label class="label text-muted font-weight-bolder">
                            Density @20°C KGV
                            <span class="text-danger">*</span>
                        </label>
                        <input class="form-control form-control-sm" type=number AllowDecimalNumbers
                            [formControl]="customCotrol('densityAt20', cargoForm)" />
                        <!-- <app-input [required]="true" [fieldId]="'densityAt20'" [fieldType]="'number'"
                            [control]="customCotrol('densityAt20', cargoForm)">
                        </app-input> -->
                    </div>
                    <div class="col-4" disable [module]="Comp.name" [query]="Comp.children.TemperatureC">
                        <label class="label text-muted font-weight-bolder">
                            Temperature (°C)
                            <span class="text-danger">*</span>
                        </label>
                        <input class="form-control form-control-sm" type=number AllowDecimalNumbers
                            [formControl]="customCotrol('temperature', cargoForm)" />
                        <!-- <app-input [required]="true" [fieldId]="'temperature'" [fieldType]="'number'"
                            [control]="customCotrol('temperature', cargoForm)">
                        </app-input> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-12 user-form-group">
                <label for="" class="group-label text-primary">Dipping Info</label>
                <div class="row">
                    <div class="col-lg-6" disable [module]="Comp.name" [query]="Comp.children.OldSeal">
                        <app-input [required]="true" [fieldId]="'oldSeal'"
                            [control]="customCotrol('oldSeal', cargoForm)">
                        </app-input>
                    </div>
                    <div class="col-lg-6" disable [module]="Comp.name" [query]="Comp.children.NewSeal">
                        <app-input [required]="true" [fieldId]="'newSeal'"
                            [control]="customCotrol('newSeal', cargoForm)">
                        </app-input>
                    </div>
                    <!--  [maxNo]="5" -->
                    <div class="col-lg-6" disable [module]="Comp.name" [query]="Comp.children.ObsVol20C">
                        <label class="label text-muted font-weight-bolder">
                            Observed Vol @20°C
                            <span class="text-danger">*</span>
                        </label>
                        <input class="form-control form-control-sm" type=number AllowDecimalNumbers
                            [formControl]="customCotrol('observedVolumeAt20C', cargoForm)" />
                        <!-- <app-input [required]="true" [fieldId]="'observedVolumeAt20C'" [fieldType]="'number'"
                            [control]="customCotrol('observedVolumeAt20C', cargoForm)">
                        </app-input> -->
                    </div>
                    <!--    [maxNo]="3" -->
                    <div class="col-lg-6 mb-3" disable [module]="Comp.name" [query]="Comp.children.ObsDensity20C">
                        <label class="label text-muted font-weight-bolder">
                            Observed Density @20°C
                            <span class="text-danger">*</span>
                        </label>
                        <input class="form-control form-control-sm" type=number AllowDecimalNumbers
                            [formControl]="customCotrol('observedDensityAt20C', cargoForm)" />
                        <!-- <app-input [required]="true" [fieldId]="'observedDensityAt20C'" [fieldType]="'number'"
                          [control]="customCotrol('observedDensityAt20C', cargoForm)">
                        </app-input> -->
                    </div>
                    <!-- [maxNo]="5" -->
                    <div class="col-lg-6" disable [module]="Comp.name" [query]="Comp.children.FlashPoint">
                        <label class="label text-muted font-weight-bolder">
                            Flash Point
                            <span class="text-danger">*</span>
                        </label>
                        <input class="form-control form-control-sm" type=number AllowDecimalNumbers
                            [formControl]="customCotrol('flashPoint', cargoForm)" />
                        <!-- <app-input [required]="true" [fieldId]="'flashPoint'" [fieldType]="'number'" 
                            [control]="customCotrol('flashPoint', cargoForm)">
                        </app-input> -->
                    </div>
                    <!--  [maxNo]="3" -->
                    <div class="col-lg-6" disable [module]="Comp.name" [query]="Comp.children.FinalBP">
                        <label class="label text-muted font-weight-bolder">
                            Final Boiling Point
                            <span class="text-danger">*</span>
                        </label>
                        <input class="form-control form-control-sm" type=number AllowDecimalNumbers
                            [formControl]="customCotrol('finalBoilingPoint', cargoForm)" />
                        <!-- <app-input [required]="true" [fieldId]="'finalBoilingPoint'" [fieldType]="'number'"
                            [control]="customCotrol('finalBoilingPoint', cargoForm)">
                        </app-input> -->
                    </div>
                </div>
            </div>
        </div>
    </app-modal>
</ng-template>