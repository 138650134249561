<div class="container-xl">
    <div class="row mx-0">
        <h1 class="app-page-title text-muted">Incomplete Jobs Report</h1>
        <div class="ml-auto">
            <!-- <button (click)="showFilter =! showFilter" class="btn btn-secondary btn-sm mr-2">
                <em [ngClass]="{'d-none': !showFilter}" class="fa fa-filter"></em>
                <em [ngClass]="{'d-none': showFilter}" class="fa fa-times-circle"></em>
            </button> -->
        </div>
    </div>
    <div class="row">
        <!-- filter search -->
        <div class="col-12 mb-2" [ngClass]="{'d-none': showFilter}">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-8 col-sm-8">
                            <form action="" [formGroup]="reportForm">
                                <div class="form-row align-items-center">
                                    <div class="col-6">
                                        <app-input-date [mb0]="'mb-0'" [isFilterVar]="true" [singleDate]="false"
                                            [fieldId]="'dateRage'" [control]="filterdateRange">
                                        </app-input-date>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-4 col-sm-4 ml-auto text-right">
                            <button [disabled]="reportForm.invalid" class="btn btn-info btn-sm mb-2"
                                (click)="getReports()">Load</button>
                            <!-- <p *ngIf="pdfReport" style="cursor: pointer;">
                                <span (click)="exportExcelReport()">
                                    <em class="fa fa-file-excel mr-2"></em>Export Excel
                                </span>
                                <span *ngIf="exporting"><em
                                        class="fa fa-spinner fa-spin text-success fs-29"></em></span>
                            </p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- filter search -->

        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div *ngIf="loading" class=" d-flex justify-content-center ">
                        <div>
                            <span class="spinner-border ml-3" role="status">
                            </span>
                            <p class="text-center">Loading...</p>
                        </div>
                    </div>
                    <div *ngIf="!loading">
                        <div class="pdf-file" *ngIf="pdfReport !== undefined">
                            <ngx-extended-pdf-viewer [base64Src]="pdfReport.data" height="80vh">
                            </ngx-extended-pdf-viewer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>