export const SESSION_TOKEN = 'session_token';
export const TOKEN = 'token';
export const ACTIVE_BUSINESS = 'active_business';
export const ACTIVE_SITE = 'active_site';
export const PROJECT_VAT = 'project_vat';
export const USER_ROLES = 'user_roles';
export const USER_ACCESS = "user_access";
export const ACTIVE_ROLE = 'active_role';
export const JWT_TOKEN = 'jwt_token';
export const ACTIVE_USER = 'active_user';
export const USER_BUSINESSES = "user_businesses";
export const USER_CREDENTIALS = 'user_cred';
export const LOCK_URL = 'lock_url';
export const LOCK_EMAIL = 'lock_email';
export const LOCK_STATUS = 'lock_status';



