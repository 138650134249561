import { BsModalService } from 'ngx-bootstrap/modal';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RoutesModule } from './routes/routes.module';
import { AppComponent } from './app.component';
import { ApiConnectionService } from './services/api/apiconnection.service';
import { ApiLinkService } from './services/api/apilink.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { FilterService } from './services/filter/filter.service';
import { RedirectionManagerService } from './services/permissions/redirection-manager/redirection-manager.service';
import { ProfileService } from './services/proflie/profile.service';
import { SessionStorageService } from './services/session/session-storage.service';
import { SettingsService } from './services/settings/settings.service';
import { NgxPrintModule } from 'ngx-print';
import { AuthDirectiveModule } from './services/directives/auth.directive.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RoutesModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxPrintModule,
    AuthDirectiveModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    HttpClient,
    SettingsService,
    ApiConnectionService,
    ApiLinkService,
    SessionStorageService,
    AuthenticationService,
    RedirectionManagerService,
    ProfileService,
    FilterService,
    // AlertService,
    BsModalService,
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
