import { Injectable } from '@angular/core';
import { DashBoards } from '../constants/dashboards';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectionManagerService {
  activeUserRole: any;

  constructor(public session: SessionStorageService) {
    this.activeUserRole = this.session.getActiveUserRole();
  }

  /**
   * Gets redirection url
   * @returns redirection url
   */
  getRedirectionUrl(): Array<any> | any {
    const activeRole = this.session.getActiveUserRole();
    this.activeUserRole = activeRole;
    const LandingPages = this.getListOfLandingPages(this.activeUserRole);
    return LandingPages;
  }

  /**
   * Checks role type
   * @returns role type
   */
  checkRoleType(): string {
    return this.activeUserRole.role.type;
  }

  /**
   * Gets list of landing pages
   */
  getListOfLandingPages(activeRole: any): any {
    const landingPages = [];
    // tslint:disable-next-line: prefer-for-of
    for (let d = 0; d < DashBoards.length; d++) {
      // tslint:disable-next-line: prefer-for-of
      for (let m = 0; m < activeRole.categories.length; m++) {
        // tslint:disable-next-line: prefer-for-of
        for (let a = 0; a < activeRole.categories[m].items.length; a++) {
          if (
            activeRole.categories[m].items[a].name === DashBoards[d].name
            && activeRole.categories[m].items[a].isCanView
          ) {
            landingPages.push(DashBoards[d]);
          }
        }
      }
    }
    return landingPages;
  }
}
