<div class="top-pattern"></div>

<div class="d-flex align-items-center justify-content-center h-100vh" style="background: #96b7db; height: 100%;">

  <div *ngIf="isDisplay" class="form-wrapper m-auto">
    <div class="form-container my-auto">
      <div class="row d-flex bg-white m-auto" style="border-radius: 10px;">
        <div class="col-lg-5 col-md-5 p-0 flex-fill">
          <div class="panel d-flex align-items-baseline flex-column h-100 bg"
            style="border: none; box-shadow: none; border-top-right-radius: 0px; border-bottom-right-radius: 0px;">
            <div class="cust">
              <div class="dix row d-flex ">
                <div class="col">
                  <a href="index.html">
                    <img src="assets/images/kibali.jpeg" alt="logo" width="120px" height="50px">
                  </a>
                </div>
                <div class="col">
                  <a href="index.html">
                    <img class="" src="assets/images/gcs.png" alt="logo" width="130px" height="70px">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-7 p-0 flex-fill">
          <div class="panel h-100" style="border: none; box-shadow: none;">
            <div class="text-center mb-1"><a class="app-logo" href="index.html"><img class="logo-icon mr-2"
                  src="assets/images/arkapos.png" alt="logo"></a></div>
            <h4 class="auth-heading text-center mb-2">Password Reset!</h4>
            <span class="text-center">Your Old password has been rest, please enter a new one and submit</span>
            <div class="auth-form-container text-left mt-3">
              <div class="email mb-3">
                <app-input [control]="newPassword" [placeHolder]="'New Password ...'"></app-input>
                <small class="text-warning" *ngIf="newPassword.errors?.minlength">
                  Password must be at least 4 characters long.
                </small>
              </div>
              <div class="text-center">
                <button [disabled]="newPassword.invalid" type="submit" (click)="_submit()"
                  class="btn app-btn-primary btn-block theme-btn mx-auto mb-3">
                  <span *ngIf="loadingStatus" class="spinner-border text-light" role="status"></span>
                  <span *ngIf="!loadingStatus">Submit</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isSuccess" class="wrapper text-center">
    <h5 class="text-success mt-4">{{title}}</h5>
    <p>
      <img src="assets/images/tick.jpg" alt="">
    </p>
    <a class="text-black" href="index.html">
      <span class="mt-4"> <i class="fa fa-arrow-left"></i> Back</span>
    </a>
  </div>

  <div *ngIf="isError" class="wrapper text-center p-4">
    <h5 class="text-danger mt-4">{{title}}</h5>
    <p>
      <img src="assets/images/error.jpg" alt="" height="150px" width="200px">
    </p>
    <a class="text-black" href="index.html">
      <span class="mt-4"> <i class="fa fa-arrow-left"></i> Back</span>
    </a>
  </div>
</div>
