<div class="container">
    <div class="row mx-0 mb-2 mt-2">
        <div class="col-12 d-flex px-0">
            <div>
                <h5 class="font-weight-bold text-muted">{{ isEditing ? 'Update' : 'New' }} Job</h5>
            </div>
            <div class="entries mr-2 ml-auto">
                <button class="btn-sm btn btn-primary" (click)="_back()">
                    <em class="bi bi-arrow-left mr-1"></em> back
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="jobSessions.length > 0">
        <div *ngIf="!loading" class=" mt-4">
            <div class="">
                <app-stepper (actionBtnClicked)="onSubmitStepper($event)"
                             [actionBtn]="noDipping?{name:'Submit Exempted Job Without Dipping',status: (consigner.invalid || consignee.invalid || shipper.invalid )}:{ name: isEditing?'Update':'Submit', status: (consigner.invalid || consignee.invalid || shipper.invalid || isEditing?false:(cargoEntries.invalid || remarksForm.invalid)) }"
                             (onActiveStep)="onChangeStep($event)"
                             [nextBtn]="{ status:(consigner.invalid || consignee.invalid || shipper.invalid || moreInfoform.invalid || comptsInvalid) }"
                             [activeStep]="activeStep" [steps]="steps" [loadingSaveBtn]="loadingSaveBtn">

                    <div data-tab="1" [ngClass]="{'display-step': currentStep !== 'step1'}" class="col-lg-12 col-md-12">
                        <span class="text-primary font-weight-bold">Job / Info</span>

                        <div class="row mt-2">
                            <div class="col-4">
                                <div class="card h-100vh">
                                    <div class="card-header">
                                        Consigner Info
                                    </div>
                                    <div class="card-body">
                                        <div class="flex-row">
                                            <app-input-select-new [selectSearch]="true" [predicate]="customersPredicate"
                                                                  [options]="customersList" [optionsList]="customersSub"
                                                                  [mb]="'mb-0'"
                                                                  [fieldId]="'consigner'"
                                                                  [control]="customCotrol('customerId', consigner)">
                                            </app-input-select-new>
                                            <small style="cursor: pointer;" class="text-primary"
                                                   (click)="openModal(newCustomer); title = 'New Consigner'">Add
                                                Consigner</small>
                                        </div>
                                        <div class="form-group">
                                            <label class="" for="">Source <span class="text-danger">*</span></label>
                                            <select class="form-control custom-select-sm"
                                                    [formControl]="customCotrol('source', consigner)">
                                                <option [ngValue]="name" *ngFor="let name of source">
                                                    {{ name }}
                                                </option>
                                            </select>
                                        </div>
                                        <app-input-date [required]="true" [fieldId]="'deliveryDate'"
                                                        [control]="customCotrol('deliveryDate', consigner)">
                                        </app-input-date>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 px-0">
                                <div class="card h-100vh">
                                    <div class="card-header">
                                        Shipper Info
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-6">
                                                <app-input [required]="true" [fieldId]="'shipmentNumber'"
                                                           [control]="customCotrol('shipmentNumber', shipper)">
                                                </app-input>
                                            </div>
                                            <div class="col-6">
                                                <app-input-select-new [selectSearch]="true" [fieldId]="'driverId'"
                                                                      [control]="customCotrol('driverId', shipper)"
                                                                      [mb]="'mb-0'"
                                                                      [predicate]="driversPredicate"
                                                                      [options]="driversList"
                                                                      [optionsList]="driversSub">
                                                </app-input-select-new>
                                                <small style="cursor: pointer;" class="text-primary"
                                                       (click)="openModal(newDriver)">Add
                                                    Driver</small>
                                            </div>
                                        </div>
                                        <app-input [required]="true" [fieldId]="'vehicleOwner'"
                                                   [control]="customCotrol('vehicleOwner', shipper)">
                                        </app-input>
                                        <div class="row">
                                            <div class="col-6">
                                                <app-input [required]="true" [fieldId]="'vehicleNumber'"
                                                           [control]="customCotrol('vehicleNumber', shipper)">
                                                </app-input>
                                            </div>
                                            <div class="col-6">
                                                <app-input [fieldId]="'trailerNumber'"
                                                           [control]="customCotrol('trailerNumber', shipper)">
                                                </app-input>
                                                <!-- <div class="form-group">
                                                    <label class="" for="">Mode Of Trans <span
                                                            class="text-danger">*</span></label>
                                                    <select class="form-control custom-select-sm menu-disabled"
                                                        [formControl]="customCotrol('modeOfTransport', shipper)">
                                                        <option [ngValue]="name" *ngFor="let name of transModes">
                                                            {{ name }}
                                                        </option>
                                                    </select>
                                                </div> -->

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 pr-0">
                                <div class="card h-100vh">
                                    <div class="card-header">
                                        Consignee Info
                                    </div>
                                    <div class="card-body">
                                        <div class="flex-row">
                                            <app-input-select-new [selectSearch]="true" [mb]="'mb-0'"
                                                                  [fieldId]="'consignee'"
                                                                  [control]="customCotrol('customerId', consignee)"
                                                                  [predicate]="customersPredicate"
                                                                  [options]="customersList"
                                                                  [optionsList]="customersSub">
                                            </app-input-select-new>
                                            <small style="cursor: pointer;" class="text-primary"
                                                   (click)="openModal(newCustomer); title='New Consignee'">Add
                                                Consignee</small>
                                        </div>
                                        <div class="">
                                            <app-input [required]="true" [fieldId]="'destination'"
                                                       [control]="customCotrol('destination', consignee)">
                                            </app-input>
                                        </div>
                                        <div class="form-group">
                                            <label class="" for="">Type Of Sale <span
                                                    class="text-danger">*</span></label>
                                            <select class="form-control custom-select-sm menu-disabled"
                                                    [formControl]="customCotrol('typeOfSale', consignee)">
                                                <option [ngValue]="name" *ngFor="let name of saleTypes">
                                                    {{ name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row px-0 mt-3 ml-0" [ngClass]="{'menu-disabled':isEditing}">
                            <div class="card w-100">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-4 menu-disabled">
                                            <app-input [required]="true" [fieldId]="'portOfEntry'"
                                                       [control]="customCotrol('portOfEntry', moreInfoform)">
                                            </app-input>
                                        </div>
                                        <div class="col-4">
                                            <!-- [maxNo]="6" -->
                                            <app-input-figure [required]="true" [fieldId]="'totalVol'"
                                                              [control]="customCotrol('overallQuantity', moreInfoform)">
                                            </app-input-figure>
                                        </div>
                                        <div class="col-4">
                                            <label>No.Of Compartments <span class="text-danger">*</span></label>
                                            <div class="row">
                                                <div class="col-10"
                                                     [ngClass]="{'col-12':customCotrol('numberOfCompartments', moreInfoform).invalid}">
                                                    <div class="form-group mb-3">
                                                        <input type="number" class="form-control form-control-sm"
                                                               oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); if(this.value != 1 && this.value != 2&&this.value != 3&&this.value != 4&&this.value != 5&&this.value != 6&&this.value != 7)this.value = '';"
                                                               type="number" maxlength="1"
                                                               [formControl]="customCotrol('numberOfCompartments', moreInfoform)">
                                                    </div>
                                                </div>
                                                <div class="col-2"
                                                     [ngClass]="{'d-none':customCotrol('numberOfCompartments', moreInfoform).invalid}">
                                                    <button class="btn btn-sm btn-outline-dark"
                                                            (click)="openModal(compts)"><em
                                                            class="bi bi-pencil"></em></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div data-tab="2" [ngClass]="{'display-step': currentStep !== 'step2'}"
                         class="col-lg-12 col-md-12 px-0">
                        <span class="text-primary font-weight-bold">Job / Cargo</span>
                        <!-- table data -->
                        <div class="row mx-0 mb-2">
                            <div class="col-4 pl-0">
                                <app-input-date [fieldId]="'dippingDate'"
                                                [control]="customCotrol('dippingDate', remarksForm)">
                                </app-input-date>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label class="" for="">Variation Comment</label>
                                    <select class="form-control custom-select-sm"
                                            [formControl]="customCotrol('variationComment', remarksForm)">
                                        <option [ngValue]="name" *ngFor="let name of comments">
                                            {{ name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-4 pr-0">
                                <app-input [fieldId]="'dipperRemarks'"
                                           [control]="customCotrol('dipperRemarks', remarksForm)">
                                </app-input>
                            </div>
                            <div class="col-12 my-1 px-0" *ngFor="let cargo of cargoEntries.controls;let i = index">
                                <div class="card">
                                    <div class="card-header">
                                        <div colspan="1" class="border-right-0">
                                            <span class="text-muted font-weight-bold">Compartment No.
                                                {{ customEntryCell('compartmentNumber', i).value }}</span>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row mx-0">
                                            <div class="col-lg-6 user-form-group">
                                                <label for="" class="group-label text-primary">Cargo Info</label>
                                                <div class="row">
                                                    <div class="col-4 menu-disabled">
                                                        <app-input-select-new [selectSearch]="true" [mb]="'mb-0'"
                                                                              [fieldId]="'fuelTypeId'"
                                                                              [control]="customEntryCell('fuelTypeId', i)"
                                                                              [predicate]="fuelTypesPredicate"
                                                                              [options]="fuelTypesList"
                                                                              [optionsList]="fuelTypesSub">
                                                        </app-input-select-new>
                                                    </div>
                                                    <div class="col-4 menu-disabled" hideSubModule [module]="Comp.name"
                                                         [query]="Comp.children.DeclaredVol">
                                                        <!-- [maxNo]="5" -->
                                                        <app-input [required]="true" [fieldId]="'actualQuantityL'"
                                                                   [fieldType]="'number'" AllowDecimalNumbers
                                                                   [control]="customEntryCell('actualQuantityL', i)">
                                                        </app-input>
                                                    </div>
                                                    <div class="col-4" hideSubModule [module]="Comp.name"
                                                         [query]="Comp.children.ActualQtyL20">
                                                        <!-- [maxNo]="5" -->
                                                        <label class="label text-muted font-weight-bolder">
                                                            Actual Qty(L20)
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <input class="form-control form-control-sm" type=number
                                                               AllowDecimalNumbers
                                                               [formControl]="customEntryCell('actualQuantityL20', i)"/>
                                                        <!-- <app-input [required]="true" [fieldId]="'actualQuantityL20'"
                                                            [fieldType]="'number'"  type=number
                                                            [control]="customEntryCell('actualQuantityL20', i)">
                                                        </app-input> -->
                                                    </div>
                                                    <div class="col-6" hideSubModule [module]="Comp.name"
                                                         [query]="Comp.children.Density20KGV">
                                                        <label class="label text-muted font-weight-bolder">
                                                            Density @20°C KGV
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <input class="form-control form-control-sm" type=number
                                                               AllowDecimalNumbers
                                                               [formControl]="customEntryCell('densityAt20', i)"
                                                               maxlength="6"/>
                                                        <!-- oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" -->

                                                        <!-- <app-input [required]="true" [fieldId]="'densityAt20'"
                                                            [fieldType]="'number'" [step]="true" [maxNo]="5"
                                                            [control]="customEntryCell('densityAt20', i)">
                                                        </app-input> -->
                                                    </div>
                                                    <div class="col-6" hideSubModule [module]="Comp.name"
                                                         [query]="Comp.children.TemperatureC">
                                                        <label class="label text-muted font-weight-bolder">
                                                            Temperature (°C)
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <input class="form-control form-control-sm" type=number
                                                               AllowDecimalNumbers
                                                               [formControl]="customEntryCell('temperature', i)"/>
                                                        <!-- maxlength="6"
                                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"  -->
                                                        <!-- <app-input [required]="true" [fieldId]="'temperature'"
                                                            [fieldType]="'number'" [maxNo]="2"
                                                            [control]="customEntryCell('temperature', i)">
                                                        </app-input> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 user-form-group">
                                                <label for="" class="group-label text-primary">Dipping Info</label>
                                                <div class="row">
                                                    <div class="col-3" hideSubModule [module]="Comp.name"
                                                         [query]="Comp.children.OldSeal">
                                                        <app-input [required]="true" [fieldType]="'number'"
                                                                   [fieldId]="'oldSeal'"
                                                                   [control]="customEntryCell('oldSeal', i)">
                                                        </app-input>
                                                    </div>
                                                    <div class="col-3" hideSubModule [module]="Comp.name"
                                                         [query]="Comp.children.NewSeal">
                                                        <app-input [required]="true" [fieldType]="'number'"
                                                                   [fieldId]="'newSeal'"
                                                                   [control]="customEntryCell('newSeal', i)">
                                                        </app-input>
                                                    </div>

                                                    <div class="col-lg-6" hideSubModule [module]="Comp.name"
                                                         [query]="Comp.children.ObsDensity20C">
                                                        <label class="label text-muted font-weight-bolder">
                                                            Observed Density @20°C
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <input class="form-control form-control-sm" type=number
                                                               AllowDecimalNumbers
                                                               [formControl]="customEntryCell('observedDensityAt20C', i)"/>
                                                        <!-- maxlength="6"
                                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"  -->
                                                    </div>
                                                    <div class="col-lg-3" hideSubModule [module]="Comp.name"
                                                         [query]="Comp.children.FlashPoint">
                                                        <label class="label text-muted font-weight-bolder">
                                                            Flash Point (°C)
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <input class="form-control form-control-sm" type="number"
                                                               AllowDecimalNumbers
                                                               [formControl]="customEntryCell('flashPoint', i)"/>
                                                        <!-- maxlength="6"
                                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" -->
                                                    </div>
                                                    <div class="col-lg-3" hideSubModule [module]="Comp.name"
                                                         [query]="Comp.children.FinalBP">
                                                        <label class="label text-muted font-weight-bolder">
                                                            Final Boiling Point FBP (°C)
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <input class="form-control form-control-sm" type=number
                                                               AllowDecimalNumbers
                                                               [formControl]="customEntryCell('finalBoilingPoint', i)"/>
                                                        <!-- maxlength="6"
                                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"  -->
                                                    </div>
                                                    <div class="col-lg-6" hideSubModule [module]="Comp.name"
                                                         [query]="Comp.children.ObsVol20C">
                                                        <label class="label text-muted font-weight-bolder">
                                                            Observed Vol @20°C
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <input class="form-control form-control-sm" type=number
                                                               AllowDecimalNumbers
                                                               [formControl]="customEntryCell('observedVolumeAt20C', i)"/>
                                                        <!-- maxlength="6"
                                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"  -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </app-stepper>
            </div>
        </div>
    </div>

    <div *ngIf="jobSessions.length === 0">
        <div *ngIf="!loading" class=" mt-4">
            <div class="text-center">
                You have no active Session to Save a Job please contact Management to open a Session for you to proceed
            </div>
        </div>
    </div>

    <div *ngIf="loading" class="col-12 text-center">
        <app-loading-placeholder></app-loading-placeholder>
    </div>

</div>

<!-- new customer form -->
<ng-template #newCustomer>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="newBtn" [modalTitle]="title"
               [condition]="customerForm.invalid">
        <div class="row mx-0" [formGroup]="customerForm">
            <div class="col-12">
                <app-input [required]="true" [fieldId]="'companyName'"
                           [control]="customCotrol('companyName', customerForm)">
                </app-input>
                <app-input-phone [fieldId]="'contact'" [code]="customCotrol('code',customerForm)"
                                 [control]="customCotrol('contact',customerForm)"></app-input-phone>
                <!-- <app-input [fieldId]="'contact'" [control]="customCotrol('contact', customerForm)">
                </app-input> -->
                <app-input [fieldId]="'tin'" [control]="customCotrol('companyTIN', customerForm)">
                </app-input>
            </div>
        </div>
    </app-modal>
</ng-template>


<!-- new Driver form -->
<ng-template #newDriver>
    <app-modal [size]="'long'" [loading]="isSaving" [modalActionButton]="driverBtn" [modalTitle]="'New Driver'"
               [condition]="driverForm.invalid">
        <div class="row mx-0" [formGroup]="driverForm">
            <div class="col-6">
                <app-input [required]="true" [fieldId]="'firstName'" [control]="customCotrol('firstname', driverForm)">
                </app-input>
            </div>
            <div class="col-6">
                <app-input [required]="true" [fieldId]="'lastName'" [control]="customCotrol('lastname', driverForm)">
                </app-input>
            </div>
            <div class="col-6">
                <app-input [fieldId]="'otherName'" [control]="customCotrol('othername', driverForm)">
                </app-input>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label class="" for="">Nationality <span class="text-danger">*</span></label>
                    <select class="form-control custom-select-sm"
                            [formControl]="customCotrol('nationality', driverForm)">
                        <option [ngValue]="name.nationality" *ngFor="let name of nationality">
                            {{ name.nationality }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label class="" for="">ID Type <span class="text-danger">*</span></label>
                    <select class="form-control custom-select-sm"
                            [formControl]="customCotrol('typeOfIdentification', driverForm)">
                        <option [ngValue]="name" *ngFor="let name of identity">
                            {{ name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-6">
                <label>{{ customCotrol('typeOfIdentification', driverForm)?.value }} No. <span
                        class="text-danger">*</span></label>
                <div class="form-group mb-3">
                    <app-input [capitalType]="true" [control]="customCotrol('identificationNumber', driverForm)">
                    </app-input>
                    <!-- <input type="string" class="form-control form-control-sm" maxlength="9"
                        [formControl]="customCotrol('identificationNumber',driverForm)"> -->
                </div>
            </div>
            <div class="col-6">
                <app-input-phone [fieldId]="'phoneNo'" [code]="customCotrol('code',driverForm)"
                                 [control]="customCotrol('phoneNumber',driverForm)"></app-input-phone>
            </div>
        </div>
    </app-modal>
</ng-template>

<!-- new compts form -->
<ng-template #compts>
    <app-inject-compts [fuelTypesList]="fuelTypesList" [fuelTypesSub]="fuelTypesSub"
                       (compartsList)="_insertComps($event)" [fuelTypesPredicate]="fuelTypesPredicate"
                       [control]="customCotrol('numberOfCompartments', moreInfoform).value">
    </app-inject-compts>
</ng-template>