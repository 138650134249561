import {Routes} from '@angular/router';
import {ForgotComponent} from '../pages/auth/forgot/forgot.component';
import {LoginComponent} from '../pages/auth/login/login.component';
import {RightsComponent} from '../pages/auth/rights/rights.component';
import {WorkspaceComponent} from '../pages/auth/workspace/workspace.component';
import {DashboardComponent} from '../pages/dashboard/dashboard.component';
import {Access403Component} from '../pages/error/access403/access403.component';
import {Error404Component} from '../pages/error/error404/error404.component';
import {JobDetailsComponent} from '../pages/job/job-details/job-details.component';
import {JobsComponent} from '../pages/job/jobs/jobs.component';
import {NewJobComponent} from '../pages/job/new-job/new-job.component';
import {EmailConfrimComponent} from '../pages/open-links/confrim/email-confrim.component';
import {CompletedComponent} from '../pages/reports/job-reports/completed/completed.component';
import {IncompleteComponent} from '../pages/reports/job-reports/incomplete/incomplete.component';
import {JobReportsComponent} from '../pages/reports/job-reports/job-reports.component';
import {PaymentsComponent} from '../pages/reports/job-reports/payments/payments.component';
import {ReportUtilityComponent} from '../pages/reports/report-utility/report-utility.component';
import {StockActivityComponent} from '../pages/reports/stock-activity/stock-activity.component';
import {StockItemReportComponent} from '../pages/reports/stock-item-report/stock-item-report.component';
import {RolesComponent} from '../pages/settings/roles/roles.component';
import {UsersComponent} from '../pages/settings/users/users.component';
import {AuthorizationGuard} from '../services/authorization/guards/authorization.guard';
import {Category} from '../services/authorization/guards/category';
import {AuthGuard} from '../services/guards/auth.guard';
import {AdminLayoutComponent} from '../layout/admin-layout/admin-layout.component';
import {UserAccountComponent} from '../pages/user-account/user-account.component';
import {LockComponent} from '../pages/auth/lock/lock.component';
import {SessionsComponent} from '../pages/sessions/sessions.component';


export const routes: Routes = [
    {
        path: 'app',
        component: AdminLayoutComponent,

        canActivate: [AuthGuard],
        children: [
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
            {
                path: 'dashboard',
                component: DashboardComponent,
                canActivate: [AuthorizationGuard],
                data: {category: Category.dashboard}
            },
            {
                path: 'sessions',
                component: SessionsComponent,
                canActivate: [AuthorizationGuard],
            },
            {
                path: 'job',
                canActivate: [AuthorizationGuard],
                children: [
                    {
                        path: 'new-job',
                        component: NewJobComponent,
                        // canActivate: [AuthorizationGuard],
                        // data: { category: Category.job, item: CategoryItem.jobDetails, privilege: Privileges.create }
                    },
                    {
                        path: 'job-details/:id',
                        component: JobDetailsComponent,
                        // canActivate: [AuthorizationGuard],
                        // data: { category: Category.job, item: CategoryItem.jobDetails, privilege: Privileges.view }
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        component: JobsComponent,
                        // canActivate: [AuthorizationGuard],
                        // data: { category: Category.job, item: CategoryItem.jobDetails, privilege: Privileges.view },
                        children: []
                    },
                ]
            },
            {
                path: 'accounting',
                canActivate: [AuthorizationGuard],
                loadChildren: () =>
                    import('../pages/accounting/accounting.module').then(
                        (m) => m.AccountingModule
                    ),
            },
            {
                path: 'inventory',
                canActivate: [AuthorizationGuard],
                loadChildren: () =>
                    import('../pages/inventory/inventory.module').then(
                        (m) => m.InventoryModule
                    ),
            },
            {
                path: 'settings',
                canActivate: [AuthorizationGuard],
                loadChildren: () =>
                    import('../pages/settings/settings.module').then(
                        (m) => m.SettingsModule
                    ),
            },
            {
                path: 'reports',
                canActivate: [AuthorizationGuard],
                children: [
                    {
                        path: 'stock-item-report',
                        canActivate: [AuthorizationGuard],
                        component: StockItemReportComponent,
                    },
                    {
                        path: 'stock-activity',
                        canActivate: [AuthorizationGuard],
                        component: StockActivityComponent,
                    },
                    {
                        path: 'job-reports',
                        component: JobReportsComponent,
                        children: [
                            {path: '', redirectTo: 'completed', pathMatch: 'full'},
                            {
                                path: 'completed',
                                canActivate: [AuthorizationGuard],
                                component: CompletedComponent
                            },
                            {
                                path: 'incomplete',
                                canActivate: [AuthorizationGuard],
                                component: IncompleteComponent
                            },
                            {
                                path: 'payments',
                                canActivate: [AuthorizationGuard],
                                component: PaymentsComponent
                            }
                        ]
                    },
                    {
                        path: 'utility',
                        canActivate: [AuthorizationGuard],
                        component: ReportUtilityComponent,
                    },
                ]
            },
            {
                path: 'user-account',
                component: UserAccountComponent
            },
            {path: 'access403', component: Access403Component},
        ]
    },
    {
        path: 'auth',
        children: [
            {path: '', redirectTo: 'login', pathMatch: 'full'},
            {path: 'login', component: LoginComponent},
            {path: 'recover', component: ForgotComponent},
            {path: 'lock', component: LockComponent},
            {path: 'workspace', component: WorkspaceComponent},
            {
                path: 'auth-rights',
                canActivate: [AuthorizationGuard],
                data: {category: Category.settings},
                component: RightsComponent,
                children: [
                    {path: '', redirectTo: 'users', pathMatch: 'full'},
                    {
                        path: 'users',
                        canActivate: [AuthorizationGuard],
                        component: UsersComponent,
                    },
                    {
                        path: 'roles',
                        canActivate: [AuthorizationGuard],
                        component: RolesComponent,
                    },
                ]
            }
        ]
    },
    {
        path: 'open-links',
        children: [
            {path: 'password-reset', component: EmailConfrimComponent},
        ]
    },
    {path: '', redirectTo: 'app', pathMatch: 'full'},
    {path: '**', redirectTo: 'error404'},
    {path: 'error404', component: Error404Component},
];
