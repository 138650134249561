import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {


  actionTitle!: string;
  pdfViewable: any;
  // assetsUrl = environment.imageUrl;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
   }

  close(): void {
    this.bsModalRef.hide();
  }
}
