import { Component, OnInit, DoCheck, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { applyMixins } from '../../mixin/mixin';
import { BaseFormComponent } from '../base-form/base-form.component';

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends BaseFormComponent { }
applyMixins(BaseClass, [BaseFormComponent]);

@Component({
  selector: 'app-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss']
})
export class InputTimeComponent extends BaseClass implements OnInit, DoCheck {

  timeForm!: FormGroup;

  @Input() fieldId: string | null = null;
  @Input() control!: FormControl;

  ngOnInit(): void {
    super.ngOnInit();
    this.control.valueChanges.subscribe(val => {
      console.log(val);
    });
  }

  ngDoCheck(): void {
    super.ngDoCheck();
  }
}
