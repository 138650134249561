import { AccessItem } from './access-item';

export const DashBoards = [
  {
    name: AccessItem.DASHBOARD,
    link: '/app',
    display: 'Human Resource',
    icon: ''
  }
];
