import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { applyMixins } from '../../mixin/mixin';
import { BaseFormComponent } from '../base-form/base-form.component';

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends BaseFormComponent { }
applyMixins(BaseClass, [BaseFormComponent]);

@Component({
  selector: 'app-input-select-search',
  templateUrl: './input-select-search.component.html',
  styleUrls: ['./input-select-search.component.scss']
})
export class InputSelectSearchComponent extends BaseClass implements OnInit {

  iteratableOptions: Array<{ ngValue: any, display: string }> = [];
  selectionList: FormControl = new FormControl();
  currentDisplayValue!: string;
  searchFilter!: string;
  filterList = ['display'];

  @Input() isFilterVar = false;
  @Input() fieldId: string | null = null;
  @Input() control!: FormControl;
  @Input() required = false;
  @Input() uniqueClass!: string;
  @Input() loadingData = false;
  @Input() mb0: any;

  @Input() options: Array<any> = [];
  @Input() optionsList: BehaviorSubject<any> | undefined;
  @Input() predicate: any;

  @Output() selectObj: EventEmitter<any> = new EventEmitter();
  @Output() deleteObj: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
    this.optionsList = new BehaviorSubject([]);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.normalizeOptions();

    this.optionsList?.subscribe((val: any[]) => {
      this.options = val;
      this.normalizeOptions();
      this.init();
    });

    setTimeout(() => {
      // select drop down panel
      const select: any = document.querySelector('.elroy-select.' + this.uniqueClass);
      const options: any = document.querySelector('.elroy-options.' + this.uniqueClass);

      // toggle open dropdown
      const selectUi = document.querySelector('.elroy-select.' + this.uniqueClass);
      if (selectUi) {
        selectUi.addEventListener('click', () => {
          select.classList.toggle('open');
          options.classList.toggle('open');
        });
      }

      // close dropdown onclick option
      document.querySelectorAll('.elroy-options.' + this.uniqueClass + ' span').forEach(ele => {
        ele.addEventListener('click', () => {
          select.classList.remove('open');
          options.classList.remove('open');
        });
      });

      // close dropdown when click outside
      window.addEventListener('click', (e: any) => {
        if (select && options) {
          if (!select.contains(e.target) && !options.contains(e.target)) {
            select.classList.remove('open');
            options.classList.remove('open');
          }
        }
      });
    });

    this.init();
  }

  init(): void {
    if (this.control.value) {
      const temp = this.iteratableOptions.find((ele: any) => ele.ngValue === this.control.value);
      if (temp) {
        this.currentDisplayValue = temp.display;
      }
    }
  }

  onSelectOption(option: any, value: string): void {
    this.control.setValue(option);
    this.currentDisplayValue = value;
    this.selectObj.emit(value);
  }

  _findDisplayName(item: any): string {
    const value = this.iteratableOptions.find((val: any) => this.isEquivalent(val.ngValue, item));
    if (value) {
      return value.display;
    } else {
      return '';
    }
  }

  isEquivalent(a: { [x: string]: any; }, b: { [x: string]: any; }): boolean {
    if (typeof a === 'object' && typeof b === 'object') {
      const aProps = Object.getOwnPropertyNames(a);
      const bProps = Object.getOwnPropertyNames(b);
      if (aProps.length !== bProps.length) {
        return false;
      }
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];
        if (a[propName] !== b[propName]) {
          return false;
        }
      }
      return true;
    } else {
      return a === b;
    }
  }

  normalizeOptions(): void {
    this.iteratableOptions = [];
    this.options.forEach((val: any) => {
      this.iteratableOptions.push({
        ngValue: this.predicate.method1(val),
        display: this.predicate.method2(val)
      });
    });
  }
}
