<nav class="top-nav navbar navbar-expand-lg mb-0" style="background-color: white !important; ">
  <a class="navbar-brand" href="#">
    <!-- <img class="profile-image" src="assets/images/logo.png" style="width: 150px; width: 80px;" alt=""> -->
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active" hide-module [moduleQuery]="'Dashboard'">
        <!-- *ngIf="authCategory.authCategory('DASHBOARD')" -->
        <a class="nav-link" routerLink="/app/dashboard" routerLinkActive="active">Dashboard <span
            class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item active" hide-module [moduleQuery]="'Session'" >
        <!-- *ngIf="authCategory.authCategory('SETTINGS')" -->
        <a class="nav-link" routerLink="/app/sessions" routerLinkActive="active">Sessions <span
            class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item active" hide-module [moduleQuery]="'Data-Entry'">
        <!-- *ngIf="authCategory.authCatItem('DATA_ENTRY','JOB')" -->
        <a class="nav-link" routerLink="/app/job" [queryParams]="{type:'DataEntryJobs'}"
          routerLinkActive="active">DataEntry <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item active" hide-module [moduleQuery]="'Cashier'">
        <!-- *ngIf="authCategory.authCatItem('CASHIER','JOB')" -->
        <a class="nav-link" routerLink="/app/job" [queryParams]="{type:'CashierJobs'}" routerLinkActive="active"
          >Cashier <span class="sr-only">(current)</span></a>
          <!-- *ngIf="authCategory.authCategory('JOB')" -->
      </li>
      <li class="nav-item active" hideSubModule [module]="'Credit'" [query]="'View Credit Jobs'">
        <!-- *ngIf="authCategory.authCatItem('CASHIER','JOB')" -->
        <a class="nav-link" routerLink="/app/job" [queryParams]="{type:'Credit'}" routerLinkActive="active">Credit <span
            class="sr-only">(current)</span></a>
        <!-- *ngIf="authCategory.authCategory('JOB')" -->
      </li>
      <li class="nav-item active" hide-module [moduleQuery]="'Lab'">
        <!-- *ngIf="authCategory.authCatItem('LAB_PROCESSING','JOB')" -->
        <a class="nav-link" routerLink="/app/job" [queryParams]="{type:'LabJobs'}" routerLinkActive="active"
         >Lab <span class="sr-only">(current)</span></a>
          <!-- *ngIf="authCategory.authCategory('JOB')" -->
      </li>
      <li class="nav-item active" hide-module [moduleQuery]="'Archive'">
        <!-- *ngIf="authCategory.authCatItem('ARCHIVE','JOB')" -->
        <a class="nav-link" routerLink="/app/job" [queryParams]="{type:'ArchiveJobs'}" routerLinkActive="active"
         >Archive <span class="sr-only">(current)</span></a>
          <!-- *ngIf="authCategory.authCategory('JOB')" -->
      </li>
      <!-- <li class="nav-item dropdown">
        <a class="nav-link" style="cursor: pointer" id="jobbarDropdown" role="button" data-toggle="dropdown"
          aria-expanded="false" *ngIf="authCategory.authCategory('JOB')">Jobs</a>
        <div class="dropdown-menu" aria-labelledby="jobbarDropdown">
          <a *ngIf="authCategory.authCategoryItem('DATA_ENTRY','JOB')" class="dropdown-item"
            routerLink="/app/job" [queryParams]="{type:'DataEntryJobs'}" routerLinkActive="active">Data Entry</a>
          <a *ngIf="authCategory.authCategoryItem('CASHIER','JOB')" class="dropdown-item" routerLink="/app/job"
            [queryParams]="{type:'CashierJobs'}" routerLinkActive="active">Cashier</a>
          <a *ngIf="authCategory.authCategoryItem('LAB_PROCESSING','JOB')" class="dropdown-item"
            routerLink="/app/job" [queryParams]="{type:'LabJobs'}" routerLinkActive="active">Lab Processing</a>
          <a *ngIf="authCategory.authCategoryItem('ARCHIVE','JOB')" class="dropdown-item" routerLink="/app/job"
            [queryParams]="{type:'ArchiveJobs'}" routerLinkActive="active">Archive</a>
        </div>
      </li> -->
      <li class="nav-item dropdown" hide-module [moduleQuery]="'Inventory'">
        <!-- *ngIf="authCategory.authCategory('INVENTORY')" -->
        <a class="nav-link" style="cursor: pointer" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-expanded="false">
          Inventory
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" hideSubModule [module]="'Inventory'" [query]="'Stock Items'"
            routerLink="inventory/stock-items" routerLinkActive="active">Stock Items</a>
          <a class="dropdown-item" hideSubModule [module]="'Inventory'" [query]="'Direct Purchase'"
            routerLink="inventory/direct-puchase/all-direct-purchases" routerLinkActive="active">Direct Purchases</a>
          <a class="dropdown-item" hideSubModule [module]="'Inventory'" [query]="'Stock Adjustment'"
            routerLink="inventory/adjustment/adjustments" routerLinkActive="active">Stock
            Adjustments</a>
      <li class="dropdown-item" hideSubModule [module]="'Inventory'" [query]="'Settings'"><a
          routerLink="/app/settings/general" routerLinkActive="active">Settings</a>
      </li>
  </div>
  </li>

  <li class="nav-item active"  hide-module [moduleQuery]="'Client'">
    <!-- *ngIf="authCategory.authCategory('SETTINGS') || authCategory.authCatItem('CASHIER','JOB')" -->
    <a class="nav-link" routerLink="/app/settings/others/customers" routerLinkActive="active">Clients
      <span class="sr-only">(current)</span></a>
  </li>

  <!-- <li class="nav-item dropdown" *ngIf="authCategory.authCategory('ACCOUNTING')">
    <a class="nav-link" style="cursor: pointer" id="navbarDropdown3" role="button" data-toggle="dropdown"
      aria-expanded="false">
      Accounting
    </a>
    <div class="dropdown-menu" aria-labelledby="navbarDropdown3">
      <a class="dropdown-item" routerLink="accounting/chart-of-accounts" routerLinkActive="active">Chart Of
        Accounts</a>
      <a class="dropdown-item" routerLink="accounting/journals" routerLinkActive="active">Journals</a>
    </div>
  </li> -->
  <li class="nav-item dropdown" hide-module [moduleQuery]="'Reports'" >
    <!-- *ngIf="authCategory.authCategory('REPORTS')" -->
    <a class="nav-link" href="#" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-expanded="false">
      Reports
    </a>
    <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="navbarDropdown1">
      <!-- <li class="dropdown-submenu">
        <a class="dropdown-item" tabindex="-1" style="cursor: pointer">Financial Reports</a>
        <ul class="dropdown-menu">
          <li class="dropdown-item"><a tabindex="-1" routerLink="accounting/report/trial-balance"
              routerLinkActive="active">Trial Balance</a></li>
          <li class="dropdown-item"><a tabindex="-1" routerLink="accounting/report/balance-sheet"
              routerLinkActive="active">Balance Sheet</a></li>
          <li class="dropdown-item"><a tabindex="-1" routerLink="accounting/report/income-statement"
              routerLinkActive="active">Income Statement</a></li>
          <li class="dropdown-item"><a tabindex="-1" routerLink="accounting/report/cash-flow-statement"
              routerLinkActive="active">Cash Flow Statement</a></li>
          <li class="dropdown-item"><a tabindex="-1" routerLink="accounting/report/changes-in-equity"
              routerLinkActive="active">Changes in Equity</a></li>
        </ul>
      </li> -->
      <li class="dropdown-submenu">
        <a class="dropdown-item"  routerLinkActive="active" tabindex="-1" style="cursor: pointer">Job Reports</a>
        <ul class="dropdown-menu">
          <!-- <li class="dropdown-item"><a tabindex="-1" routerLink="reports/job-reports/payments"
              routerLinkActive="active">Payments Report</a></li> -->
          <li class="dropdown-item" hideSubModule [module]="'Reports'" [query]="'Completed Jobs'"><a tabindex="-1" routerLink="reports/job-reports/completed"
              routerLinkActive="active">Completed Jobs Report</a></li>
          <!-- <li class="dropdown-item"><a tabindex="-1" routerLink="reports/job-reports/incomplete"
              routerLinkActive="active">Incomplete Jobs Report</a></li> -->
        </ul>
      </li>
      <li class="dropdown-submenu">
        <a class="dropdown-item" tabindex="-1" style="cursor: pointer">Inventory Reports</a>
        <ul class="dropdown-menu">
          <li class="dropdown-item" hideSubModule [module]="'Reports'" [query]="'Stock Items'"><a tabindex="-1" routerLink="reports/stock-item-report"
              routerLinkActive="active">Stock Item Report</a></li>
          <li class="dropdown-item" hideSubModule [module]="'Reports'" [query]="'Stock Activity'"><a tabindex="-1" routerLink="reports/stock-activity" routerLinkActive="active">Stock
              Activity Report</a></li>
        </ul>
      </li>
    </ul>
  </li>
  <li class="nav-item dropdown" hide-module [moduleQuery]="'Settings'" >
    <!-- *ngIf="authCategory.authCategory('SETTINGS')" -->
    <a class="nav-link" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-expanded="false">
      Settings
    </a>
    <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="navbarDropdown1">
      <!-- <li class="dropdown-item"><a routerLink="reports/utility" routerLinkActive="active">Report Access</a></li> -->
      <li class="dropdown-submenu">
        <a class="dropdown-item" tabindex="-1" style="cursor: pointer">Business Settings</a>
        <ul class="dropdown-menu">
          <li class="dropdown-item" hideSubModule [module]="'Settings'" [query]="'Business'"><a tabindex="-1" routerLink="/app/settings/business/business-account"
              routerLinkActive="active">Business</a></li>
          <!-- <li class="dropdown-item"><a tabindex="-1" style="cursor: pointer">Sites</a></li> -->
          <li class="dropdown-item" hideSubModule [module]="'Settings'" [query]="'Users'"><a tabindex="-1" routerLink="/app/settings/business/users"
              routerLinkActive="active">Users</a></li>
          <li class="dropdown-item" hideSubModule [module]="'Settings'" [query]="'Roles'"><a tabindex="-1" routerLink="/app/settings/business/roles"
              routerLinkActive="active">Roles</a></li>
        </ul>
      </li>
      <li class="dropdown-submenu">
        <a class="dropdown-item" tabindex="-1" style="cursor: pointer">Others</a>
        <ul class="dropdown-menu">
          <!-- <li class="dropdown-item"><a tabindex="-1" routerLink="/app/settings/others/drivers"
              routerLinkActive="active">Drivers</a></li> -->
          <li class="dropdown-item" hideSubModule [module]="'Settings'" [query]="'Clients'"><a tabindex="-1" routerLink="/app/settings/others/customers"
              routerLinkActive="active">Clients</a></li>
          <li class="dropdown-item" hideSubModule [module]="'Settings'" [query]="'Vendors'"><a tabindex="-1" routerLink="/app/settings/others/vendors"
              routerLinkActive="active">Vendors</a></li>
          <li class="dropdown-item" hideSubModule [module]="'Settings'" [query]="'Lab Test'"><a tabindex="-1" routerLink="/app/settings/others/lab-test-settings"
              routerLinkActive="active">Lab Test Settings</a></li>
        </ul>
      </li>
    </ul>
  </li>

  </ul>
  <div class="d-flex align-items-center">
    <!-- single site-->
    <div *ngIf="siteList.length === 1" class="btn btn-light btn-sm mr-3" aria-expanded="false">
      <em class="fas fa-circle text-success"></em>
      {{ activeSite.name }}
    </div>
    <!-- multiple site-->
    <div *ngIf="siteList.length > 1" class="dropdown btn btn-light btn-sm mr-3">
      <a style="cursor: pointer" role="button" id="modules" data-toggle="dropdown" aria-expanded="false">
        <em class="fas fa-circle text-success"></em>
        {{ activeSite.name }}
      </a>

      <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="modules">
        <li><span class="dropdown-header">Select Current Site</span></li>
        <li>
          <a class="dropdown-item d-flex justify-content-between" *ngFor="let site of siteList"
            (click)="switchSite(site)">
            {{ site.name }}
            <em class="bi bi-check2-circle text-primary" *ngIf="site.name === activeSite.name"></em>
          </a>
        </li>
      </ul>
    </div>

    <!-- active user -->
    <div class="app-utility-item app-user-dropdown dropdown">
      <a href="#" class="dropdown-toggle" id="user-drop-toggle" data-toggle="dropdown" role="button"
        aria-expanded="false">
        <img [src]="Userphoto === undefined ? 'assets/images/person.png': assetsRoot + Userphoto" alt="">
      </a>
      <div class="dropdown-menu dropdown-menu-right p-2">
        <div class="dropdown-header d-sm-none">
          <a href="" class="header-arrow">
            <em class="icon ion-md-arrow-back"></em></a>
        </div>
        <div class="text-center">
          <div class="img-user ml-1">
            <img
              [src]="activeUser?.photo === undefined ? 'assets/images/person.png': assetsRoot + activeUser?.photo?.url"
              alt="">
          </div>
          <h6 class="hide-text mt-1 mb-0 font-weight-bolder" style="width: 180px;">{{activeUser?.name}}</h6>
          <span>{{activeUser?.email}}</span>
        </div>
        <div class="mt-3">
          <hr class="style5">
          <a href="javascript:;" [routerLink]="['/app/user-account']" class="dropdown-item">
            <em class="fa fa-user-cog mr-3 text-info"></em>
            Account Settings
          </a>
          <button (click)="_backToSignUp()" class="dropdown-item">
            <em class="fa fa-sign-out-alt mr-3 text-warning"></em>
            Sign Out
          </button>
          <button *ngIf="forgetMe" (click)="_forgetMe()" class="dropdown-item">
            <em class="fa fa-hands-wash mr-3 text-warning"></em>
            Forget Me
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>
</nav>


<!--<nav class="top-nav navbar navbar-expand-lg mb-0" style="background-color: white !important;">-->
<!--  <div class="container-fluid px-0">-->
<!--    <div class="app-header-content">-->
<!--      <div class="row justify-content-between align-items-center">-->
<!--        <div class="col-auto pr-0">-->
<!--          <a href="javascript:void(0);" id="sidepanel-toggler" class="sidepanel-toggler d-inline-block text-primary">-->
<!--            <img class="profile-image" src="assets/images/logo.png" style="width: 150px; width: 80px;" alt="">-->
<!--          </a>-->
<!--        </div>-->

<!--        <div class="col-auto mr-auto">-->
<!--          <ul class="d-flex flex-row task-menu justify-content-between align-items-center">-->
<!--            <li *ngFor="let mn of menu; index as i" routerLinkActive="active" class="nav-item">-->
<!--              <a *ngIf="mn.items.length <= 0" -->
<!--                [routerLink]="mn.link" class="px-2">-->
<!--                <span>{{mn.text}}</span>-->
<!--              </a>-->

<!--              <div *ngIf="mn.items.length > 0" class="app-utility-item app-client-dropdown dropdown">-->
<!--                <a href="javascript:void(0);"  class="dropdown-toggle" id="client-drop-toggle" data-toggle="dropdown"-->
<!--                  role="button" aria-expanded="false">-->
<!--                  <span>{{mn.text}}</span>-->
<!--                </a>-->
<!--                <ul class="dropdown-menu" aria-labelledby="client-dropdown-toggle">-->
<!--                  <li *ngFor="let smn of mn.items" routerLinkActive="active" class="px-0 m-0">-->
<!--                    <a href="nav-link" class="dropdown-item" [routerLink]="smn.link">{{smn.text}}</a>-->
<!--                  </li>-->
<!--                  <div *ngIf="mn.reports.length > 0" class="dropdown-divider"></div>-->
<!--                  <li *ngFor="let smn of mn.reports" routerLinkActive="active" class="px-0 m-0">-->
<!--                    <a href="nav-link" class="dropdown-item" [routerLink]="smn.link">{{smn.text}}</a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </li>-->

<!--            <li routerLinkActive="active" class="nav-item">-->
<!--              <div class="app-utility-item app-bar-dropdown dropdown">-->
<!--                <a href="javascript:void(0);" class="dropdown-toggle" id="bar-drop-toggle" data-toggle="dropdown"-->
<!--                  role="button" aria-expanded="false">-->
<!--                  <span class="nav-text">Reports</span>-->
<!--                </a>-->
<!--                <ul class="dropdown-menu" aria-labelledby="bar-dropdown-toggle">-->
<!--                  <li routerLinkActive="active" class="px-0 m-0">-->
<!--                    <a [routerLink]="['reports/job-reports']" class="dropdown-item">-->
<!--                      Job Reports-->
<!--                    </a>-->
<!--                  </li>-->
<!--                  <div class="dropdown-divider"></div>-->
<!--                  <li routerLinkActive="active" class="px-0 m-0">-->
<!--                    <a [routerLink]="['reports/stock-item-report']" class="dropdown-item">-->
<!--                      Stock Item Report-->
<!--                    </a>-->
<!--                  </li>-->
<!--                  <li routerLinkActive="active" class="px-0 m-0">-->
<!--                    <a [routerLink]="['reports/stock-activity']" class="dropdown-item">-->
<!--                      Stock Activity Report-->
<!--                    </a>-->
<!--                  </li>-->
<!--                  <div class="dropdown-divider"></div>-->
<!--                  <li routerLinkActive="active" class="px-0 m-0">-->
<!--                    <a [routerLink]="['reports/utility']" class="dropdown-item">-->
<!--                      Report Access-->
<!--                    </a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </li>-->

<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


<!--    <div class="app-utilities col-auto d-flex align-items-center">-->
<!--      &lt;!&ndash; Active site and all sites &ndash;&gt;-->
<!--      <div class="mr-2 app-utility-item app-settings-dropdown dropdown">-->
<!--        <button class="btn btn-light btn-sm dropdown-toggle no-caret" data-toggle="dropdown" role="button"-->
<!--          aria-expanded="false">-->
<!--          <em class="fas fa-circle text-success"></em>-->
<!--          {{ activeSite.name }}-->
<!--        </button>-->
<!--        <ul class="dropdown-menu" aria-labelledby="bar-dropdown-toggle">-->
<!--          <li *ngFor="let project of projectList" class="pl-0 pr-0" (click)="switchProject(project.project.id)">-->
<!--            <a href="javascript:void(0)" class="dropdown-item">-->
<!--              <em class="fas fa-project-diagram mr-2"></em>-->
<!--              <span>{{ project.project.name }}</span>-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--      &lt;!&ndash; settings &ndash;&gt;-->
<!--      <div class="pr-2 app-utility-item app-settings-dropdown dropdown">-->
<!--        <a class="dropdown-toggle d-flex align-items-center" data-toggle="dropdown" title="Settings" role="button"-->
<!--          aria-expanded="false" style="text-decoration: none;">-->
<!--          <em class="fas fa-cog" style="font-size: 25px;"></em>-->
<!--        </a>-->
<!--        <ul class="dropdown-menu text-muted" aria-labelledby="user-dropdown-toggle">-->
<!--          <li class="text-muted">-->
<!--            <a href="javascript:void(0);" [routerLink]="['/app/settings/general/markers']" class="dropdown-item">-->
<!--              <span class="mr-2">-->
<!--                <em class="fas fa-project-diagram"></em>-->
<!--              </span>-->
<!--              General-->
<!--            </a>-->
<!--          </li>-->
<!--          <div class="dropdown-divider"></div>-->
<!--          <li class="text-muted">-->
<!--            <a href="javascript:void(0);" [routerLink]="['/app/settings/business-account']" class="dropdown-item">-->
<!--              <span class="mr-2">-->
<!--                <em class="fas fa-project-diagram"></em>-->
<!--              </span>-->
<!--              Business-->
<!--            </a>-->
<!--          </li>-->
<!--          <div class="dropdown-divider"></div>-->
<!--          <li class="text-muted">-->
<!--            <a href="javascript:void(0);" [routerLink]="['/app/settings/users']" class="dropdown-item">-->
<!--              <em class="fa fa-users mr-2"></em>-->
<!--              User-->
<!--            </a>-->
<!--          </li>-->

<!--          <li class="text-muted">-->
<!--            <a href="javascript:void(0);" [routerLink]="['/app/settings/roles']" class="dropdown-item">-->
<!--              <span class="mr-2">-->
<!--                <em class="fas fa-user-tag"></em>-->
<!--              </span>-->
<!--              Roles-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--      &lt;!&ndash; active user &ndash;&gt;-->
<!--      <div class="app-utility-item app-user-dropdown dropdown">-->
<!--        <a href="#" class="dropdown-toggle" id="user-drop-toggle" data-toggle="dropdown" role="button"-->
<!--          aria-expanded="false">-->
<!--          <img [src]="Userphoto === undefined ? 'assets/images/person.png': assetsRoot + Userphoto" alt="">-->
<!--        </a>-->
<!--        <div class="dropdown-menu dropdown-menu-right p-2">-->
<!--          <div class="dropdown-header d-sm-none">-->
<!--            <a href="" class="header-arrow">-->
<!--              <em class="icon ion-md-arrow-back"></em></a>-->
<!--          </div>-->
<!--          <div class="text-center">-->
<!--            <div class="img-user ml-1">-->
<!--              <img-->
<!--                [src]="activeUser?.photo === undefined ? 'assets/images/person.png': assetsRoot + activeUser?.photo?.url"-->
<!--                alt="">-->
<!--            </div>-->
<!--            <h6 class="hide-text mt-1 mb-0 font-weight-bolder" style="width: 180px;">{{activeUser?.name}}</h6>-->
<!--            <span>{{activeUser?.email}}</span>-->
<!--          </div>-->
<!--          <div class="mt-3">-->
<!--            <hr class="style5">-->
<!--            <a href="javascript:;" [routerLink]="['/app/settings/user-account']" class="dropdown-item">-->
<!--              <em class="fa fa-user-cog mr-3 text-info"></em>-->
<!--              Account Settings-->
<!--            </a>-->
<!--            <button (click)="_backToSignUp()" class="dropdown-item">-->
<!--              <em class="fa fa-sign-out-alt mr-3 text-warning"></em>-->
<!--              Sign Out-->
<!--            </button>-->
<!--            <button *ngIf="forgetMe" (click)="_forgetMe()" class="dropdown-item">-->
<!--              <em class="fa fa-hands-wash mr-3 text-warning"></em>-->
<!--              Forget Me-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</nav>-->