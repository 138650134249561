<div class="container">
  <div class="row mx-0">
    <h1 class="app-page-title text-muted">Settings / Users</h1>
    <div class="ml-auto">
      <button (click)="showFilter =! showFilter" class="btn btn-secondary btn-sm mr-2">
        <em [ngClass]="{'d-none': !showFilter}" class="fa fa-bars"></em>
        <em [ngClass]="{'d-none': showFilter}" class="fa fa-times-circle"></em>
      </button>
      <button class="btn btn-primary btn-sm" (click)="siteInfo.clear(); newEntry(); openModal(newusermodal)">
        <em class="fa fa-plus"></em>
        New user
      </button>
    </div>
  </div>
  <div class="row">

    <!-- filter search -->
    <div class="col-12 mb-2" [ngClass]="{'d-none': showFilter}">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 ml-auto">
              <div class="filter-actions-btn d-flex align-items-center justify-content-end">
                <div class="filter-actions-btn d-flex align-items-center justify-content-end">
                  <div class="mr-1">
                    <input class="form-control form-control-sm" style="max-width: 200px;" type="text"
                      [(ngModel)]="searchFilter" placeholder="search">
                  </div>
                  <div class="entries">
                    <select [formControl]="showEntries"
                      class="custom-select custom-select-sm form-control form-control-sm">
                      <option [ngValue]="10">10</option>
                      <option [ngValue]="50">50</option>
                      <option [ngValue]="100">100</option>
                      <option [ngValue]="25">150</option>
                      <option [ngValue]="25">200</option>
                      <option [ngValue]="25">300</option>
                      <option [ngValue]="usersList.length">Show All</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- filter search -->

    <div class="col-12">
      <div *ngIf="!loading">
        <div class="table-card shadow-sm" *ngIf="usersList.length != 0">
          <div class="table-card-body">
            <div class="table-responsive">
              <table class="table themed-table themed-table-plain">
                <thead>
                  <th class="cell">
                    <div class="cell-border">
                      <span>Name</span>
                    </div>
                  </th>
                  <th class="cell">
                    <div class="cell-border">
                      <span>Email</span>
                    </div>
                  </th>
                  <th class="cell">
                    <div class="cell-border">
                      <span>Business</span>
                    </div>
                  </th>
                  <th class="cell">
                    <div class="cell-border">
                      <span>Role</span>
                    </div>
                  </th>
                  <th class="cell" style="min-width: 10px;">
                    <div class="cell-border">
                      <span class="text-center">Status</span>
                    </div>
                  </th>
                  <th class="cell text-center" style="min-width: 10px;">
                    <div class="cell-border">Action</div>
                  </th>
                </thead>
                <tbody>
                  <tr *ngFor="let item of chunkedRecords[currentPage]">
                    <td>
                      <img class="avatar"
                        [src]="item?.photo === undefined ? 'assets/images/person.png': assetsRoot + item?.photo?.url"
                        alt="">
                      {{ [item?.firstname, item?.lastname].join(' ') }}
                    </td>
                    <td>
                      {{ item?.email }}
                    </td>
                    <td>
                      {{ item?.business?.name }}
                    </td>
                    <td>
                      <span class="text-uppercase">{{ item?.role }}</span>
                    </td>
                    <td>
                      <app-status [statusType]="item.status"></app-status>
                    </td>
                    <td class=" text-center">
                      <div class="action-dropdown">
                        <button *ngIf="item.role.name === 'Super Administrator'" class="btn btn-light btn-sm">
                          <em class="bi bi-lock-fill"></em>
                        </button>
                        <button *ngIf="item.role.name !== 'Super Administrator'"
                          class="btn btn-light btn-sm dropdown-toggle" data-toggle="dropdown">
                          <em class="bi bi-three-dots-vertical"></em>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                          <button class="dropdown-item" (click)="_editUser(editusermodal, item);selectedTemplate = editusermodal">
                            <span class="text-primary mr-2">
                              <em class="bi bi-pencil-square"></em>
                            </span>
                            Edit
                          </button>
                          <button class="dropdown-item" (click)="reSendEmail(item.id)">
                            <span class="text-success mr-2">
                              <em class="bi bi-arrow-clockwise"></em>
                            </span>
                            Resend
                          </button>
                          <button *ngIf="item.status === 'DEACTIVATED'" class="dropdown-item"
                            (click)="activateUser(item.id)">
                            <span class="text-success mr-2">
                              <em class="fa fa-thumbs-up"></em>
                            </span>
                            Activate
                          </button>
                          <button *ngIf="item.status === 'ACTIVE'" class="dropdown-item"
                            (click)="deactivateUser(item.id)">
                            <span class="text-danger mr-2">
                              <em class="fa fa-thumbs-down"></em>
                            </span>
                            De-Activate
                          </button>
                          <!-- <button class="dropdown-item" (click)="deleteUser(item.id)">
                            <span class="text-danger mr-2">
                              <em class="bi bi-trash"></em>
                            </span>
                            Delete
                          </button> -->
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="usersList.length === 0" [hidden]="loading">
          <app-empty-placeholder></app-empty-placeholder>
        </div>
        <!-- loader -->
        <div *ngIf="loading" class=" d-flex justify-content-center ">
          <div>
            <span class="spinner-border ml-3" role="status">
            </span>
            <p class="text-center">Loading...</p>
          </div>
        </div>
      </div>
      <div *ngIf="loading" class="text-center">
        <app-loading-placeholder></app-loading-placeholder>
      </div>
      <!-- pagination -->
      <div class="d-block w-100 text-center">
        <app-paginator
          [chuckRecords]="usersList  | filter :filterList :searchFilter :false | paginator :elementsPerPage"
          (onPageChange)="displayActivePage($event)">
        </app-paginator>
      </div>
    </div>
  </div>
</div>

<!-- new user form -->
<ng-template #newusermodal>
  <app-modal [size]="'long'" [loading]="saving" [modalTitle]="'New User'"
    [condition]="newUserForm.invalid || siteInfo.invalid" [modalActionButton]="saveUserBtn">
    <form action="" [formGroup]="newUserForm">
      <div class="form-group row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <app-input [required]="true" [fieldId]="'firstName'" [control]="customControl('firstname',newUserForm)">
          </app-input>
          <app-input [required]="true" [fieldId]="'lastName'" [control]="customControl('lastname',newUserForm)">
          </app-input>
          <app-input [capitalType]="false" [required]="true" [fieldId]="'email'"
            [control]="customControl('email',newUserForm)">
          </app-input>
          <div class="form-group">
            <label class="" for="">Role <span class="text-danger">*</span></label>
            <select class="form-control custom-select-sm" [formControl]="customControl('role',newUserForm)">
              <option [ngValue]="role" *ngFor="let role of rolesList">
                {{ role }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <app-image-cropper [cropperWidth]="170" [cropperHeight]="150" [canvasHeight]="150" [fieldId]="'image'"
            [control]="customControl('photo',newUserForm)">
          </app-image-cropper>
          <div class="form-group">
            <label class="" for="">Business <span class="text-danger">*</span></label>
            <select class="form-control custom-select-sm" [formControl]="customControl('businessId', newUserForm)">
              <option [ngValue]="buz.id" *ngFor="let buz of businesses">
                {{ buz.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="table-card">
            <div class="table-card-body">
              <div class="">
                <table class="table display table-bordered table-striped bg-white m-0">
                  <thead>
                    <th scope="col">Site</th>
                    <th scope="col">Start Time</th>
                    <th scope="col">End Time</th>
                    <th scope="col" style="width: 2%"></th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let entry of siteInfo.controls; let i = index">
                      <td>
                        <app-input-select-search [mb0]="true" [predicate]="sitesPredicate" [options]="sitesList"
                          [optionsList]="sitesListSubject" [uniqueClass]="'item'+i"
                          [control]="customEntryCell('siteId', i)" [mb0]="true">
                        </app-input-select-search>
                      </td>
                      <td>
                        <div class="form-group mb-0">
                          <app-input [fieldType]="'time'" [control]="customEntryCell('allowedStartTime', i)">
                          </app-input>
                        </div>
                      </td>
                      <td>
                        <div class="form-group mb-0">
                          <app-input [fieldType]="'time'" [control]="customEntryCell('allowedEndTime', i) ">
                          </app-input>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="action">
                          <span (click)="deleteRow(i)" class="arrow-control action-btn">
                            <em class="bi bi-x text-danger"></em>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="border-0" [ngClass]="{'div-disabled':siteInfo.invalid}">
                        <span class="text-primary ml-2" (click)="newEntry()" style="cursor: pointer;">
                          <em class="bi bi-plus"></em>Add</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </app-modal>
</ng-template>
<!-- new user form -->

<!-- edit user form -->
<ng-template #editusermodal>
  <app-modal [size]="'extraLong'" [loading]="saving" [modalTitle]="'Edit User'" [modalActionButton]="editUserBtn">
    <form action="" [formGroup]="editUserForm">
      <div class="form-group row">
        <div class="col-6">
          <div class="row">
            <div class="col-6">
              <app-input [required]="true" [fieldId]="'firstName'" [control]="customControl('firstname',editUserForm)">
              </app-input>
            </div>
            <div class="col-6">
              <app-input [required]="true" [fieldId]="'lastName'" [control]="customControl('lastname',editUserForm)">
              </app-input>
            </div>
            <div class="col-6">
              <app-input [capitalType]="false" [required]="true" [fieldId]="'email'"
                [control]="customControl('email',editUserForm)">
              </app-input>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="" for="">Role <span class="text-danger">*</span></label>
                <select class="form-control custom-select-sm" [formControl]="customControl('role',editUserForm)">
                  <option [ngValue]="role" *ngFor="let role of rolesList">
                    {{ role }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="" for="">Business <span class="text-danger">*</span></label>
                <select class="form-control custom-select-sm" [formControl]="customControl('businessId', editUserForm)">
                  <option [ngValue]="buz.id" *ngFor="let buz of businesses">
                    {{ buz.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 mt-3">
              <div class="table-card">
                <div class="table-card-body">
                  <div class="">
                    <table class="table display table-bordered table-striped bg-white m-0">
                      <thead>
                        <th scope="col">Site</th>
                        <th scope="col">Start Time</th>
                        <th scope="col">End Time</th>
                        <th scope="col" style="width: 2%"></th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let entry of siteInfo.controls; let i = index">
                          <td>
                            <app-input-select-search [mb0]="true" [predicate]="sitesPredicate" [options]="sitesList"
                              [optionsList]="sitesListSubject" [uniqueClass]="'item'+i"
                              [control]="customEntryCell('siteId', i)" [mb0]="true">
                            </app-input-select-search>
                          </td>
                          <td>
                            <div class="form-group mb-0">
                              <app-input [fieldType]="'time'" [control]="customEntryCell('allowedStartTime', i)">
                              </app-input>
                            </div>
                          </td>
                          <td>
                            <div class="form-group mb-0">
                              <app-input [fieldType]="'time'" [control]="customEntryCell('allowedEndTime', i) ">
                              </app-input>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="action">
                              <span (click)="deleteRow(i)" class="arrow-control action-btn">
                                <em class="bi bi-x text-danger"></em>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="border-0" [ngClass]="{'div-disabled':siteInfo.invalid}">
                            <span class="text-primary ml-2" (click)="newEntry()" style="cursor: pointer;">
                              <em class="bi bi-plus"></em>Add</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 mt-2">
          <div [hidden]="rolling" class="permissions mb-2 permission-group" *ngFor="let section of selectedUser.access; let i = index">
            <div class="row mx-0 py-1">
              <div class="col-10 form-check pt-1">
                <input type="checkbox" [attr.for]="section.section + i" class="mt-0" [checked]="section.canAccess"
                  (change)="onCheckboxChange(section.id)" />
                <strong class="drop-link form-check-label text-primary ms-2" [attr.for]="section.section + i">
                  {{ section.section | titlecase }}
                </strong>
                <!-- <p class="m-0 ms-4"><small>{{section.description}}</small></p> -->
              </div>

              <div class="col-2" (click)="toggleExpansion(section)" *ngIf="section.children.length > 0 ">
                <span class="drop-btn font-weight-bold float-right">
                  <i class="bi" [ngClass]="{
                                                                          'bi-chevron-down': !section.expanded,
                                                                          'bi-chevron-up': section.expanded
                                                                          }">
                  </i>
                </span>
              </div>
            </div>

            <div *ngIf="section.children.length > 0" class="detailed-permissions"
              [ngClass]="{ 'd-none': !section.expanded }">
              <div class="border-top" *ngIf="section.children && section.children.length > 0">
                <div class="row mx-1">
                  <div class="col-6 my-2" *ngFor="let permission of section.children">
                    <label>
                      <input type="checkbox" [checked]="permission.canAccess"
                        (change)="onCheckboxChange(permission.id)">
                      {{ permission.section | titlecase }}
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div *ngIf="rolling">
            <app-loading-placeholder></app-loading-placeholder>
          </div>
        </div>
      </div>

    </form>
  </app-modal>
</ng-template>
<!-- edit user form -->