import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    public session: SessionStorageService,
    private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLoginStatus(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  checkLoginStatus(url?: string): boolean {
    if (this.session.getActiveUser() && this.session.getActiveUserRole() && (this.session.getjwtToken() !== null)) {
      console.log('User is logged in');
      return true;
    } else {
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: url } });
      return false;
    }
  }
}
