import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Endpoints} from './api-endpoints';
import {map} from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root'
})
export class ApiLinkService {
    endpoints: Endpoints;

    constructor(private http: HttpClient) {
        this.endpoints = new Endpoints();
        this.endpoints.fillURLLinks();
    }

    dataUplink(httpMethod: string, link: string, data?: any, params?: any, id?: number, dataTransform?: any): any {
        console.log(link, data, params, id);
        switch (httpMethod) {
            case 'GET':
                const getOptions = Object.assign({params} || {}, httpOptions);
                return this.http.get(id ? `${this.endpoints.getUrl(link)}/${id}` : this.endpoints.getUrl(link), getOptions)
                    .pipe(
                        map((value: any) => {
                            if (dataTransform) {
                                return dataTransform(value);
                            } else {
                                return value;
                            }
                        }), catchError(this.handleError));
            case 'POST':
                const postOptions = Object.assign({params} || {}, httpOptions);
                return this.http.post(id ? `${this.endpoints.getUrl(link)}/${id}` : this.endpoints.getUrl(link), data, postOptions)
                    .pipe(
                        map((value: any) => {
                            if (dataTransform) {
                                console.log(value, 'in');
                                return dataTransform(value);
                            } else {
                                console.log(value, 'out');
                                return value;
                            }
                        }), catchError(this.handleError));

            case 'PUT':
                const putOptions = Object.assign({params} || {}, httpOptions);
                return this.http.put(id ? `${this.endpoints.getUrl(link)}/${id}` : this.endpoints.getUrl(link), data, putOptions)
                    .pipe(catchError(this.handleError));

            case 'PATCH':
                const patchOptions = Object.assign({params} || {}, httpOptions);
                return this.http.patch(`${this.endpoints.getUrl(link)}/${id}`, data, patchOptions)
                    .pipe(catchError(this.handleError));

            case 'DELETE':
                const deleteOptions = Object.assign({params} || {}, httpOptions);
                return this.http.delete(`${this.endpoints.getUrl(link)}/${id}`, deleteOptions)
                    .pipe(catchError(this.handleError));

            default:
                break;
        }
    }

    handleError(error: HttpErrorResponse): Observable<never> {

        if (error.error instanceof ErrorEvent) {
            return throwError('There was an error!! ' + error);
        } else {
            console.log(error);
            return throwError(new Exception(error));
        }
    }
}

export class Exception {

    statusText: string;
    message: string;
    status: number;
    ok: boolean;

    constructor(error: any) {
        this.statusText = error.statusText;
        this.message = error;
        this.status = error.status;
        this.ok = error.ok;
    }
}
