<div *ngIf="!showFilterOnly" class="row mb-3">
    <div class="col-3">
        <app-input [placeHolder]="'Search ...'" [control]="customFormControl('search', filterForm)" [mb0]="'mb-0'">
        </app-input>
    </div>

    <div class="col-3 ml-auto d-flex align-items-center">
        <div class="form-group mb-0 ml-auto">
            <select class="form-control custom-select-sm"
                [formControl]="customFormControl('elementsPerPage', filterForm)">
                <option [ngValue]="5">5</option>
                <option [ngValue]="10">10</option>
                <option [ngValue]="20">20</option>
                <option [ngValue]="50">50</option>
                <option [ngValue]="100">100</option>
                <option [ngValue]="200">200</option>
                <option [ngValue]="0">All</option>
            </select>
        </div>

        <button *ngIf="exportConfig?.exportXlsx" (click)="onExportingXlsx()" class="btn btn-light btn-sm ml-2">
            <em class="bi bi-file-earmark-excel text-success"></em>
        </button>
        <button *ngIf="exportConfig?.exportPdf" (click)="onExportingPdf()" class="btn btn-light btn-sm ml-2">
            <em class="bi bi-file-earmark-pdf text-danger"></em>
        </button>
        <div class="ml-2 d-flex align-items-center" *ngIf="showExporting">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span>Exporting ...</span>
        </div>
        <button *ngIf="!hideFilterBtn" (click)="toggleFilterShow()" class="btn btn-primary btn-sm ml-2">
            <em class="bi" [ngClass]="{'bi-filter': !filterShow, 'bi-x-lg': filterShow}"></em>
        </button>
    </div>
</div>

<div class="row mb-3" [ngClass]="{'d-none': !filterShow}">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body" style="padding-bottom: 0.25rem;">
                <div class="row">
                    <div *ngFor="let filter of filterList" class="col-3">
                        <!-- text -->
                        <app-input *ngIf="filter.filterType.type === 'text'" [fieldId]="filter.filterLabel"
                            [isFilterVar]="true" [control]="customFormControl(filter.filterControl, filterForm)"
                            [mb0]="'mb-3'">
                        </app-input>

                        <!-- select -->
                        <app-input-select-search *ngIf="filter.filterType.type === 'select'"
                            [fieldId]="filter.filterLabel" [predicate]="filter.filterType.optionsPredicate"
                            [optionsList]="filter.filterType.optionList" [options]="[]" [uniqueClass]="filter.filterLabel + 'search-class'"
                            [isFilterVar]="true" [control]="customFormControl(filter.filterControl, filterForm)"
                            [mb0]="'mb-3'">
                        </app-input-select-search>

                        <!-- date -->
                        <app-input-date [mb0]="'mb-3'" *ngIf="filter.filterType.type === 'date'"
                            [singleDate]="filter.filterType.singleDate"
                            [control]="customFormControl(filter.filterControl, filterForm)"
                            [fieldId]="filter.filterLabel" [isFilterVar]="true">
                        </app-input-date>
                    </div>

                    <div class="col-3 d-flex justify-content-between ml-auto mb-3">
                        <button [disabled]="filterDisableBtn" (click)="loadData()" class="btn btn-primary btn-sm w-100">
                            <em class="bi bi-arrow-clockwise mr-2"></em>
                            Load
                        </button>
                        <button [disabled]="filterDisableBtn" (click)="clearFilters()"
                            class="btn btn-secondary btn-sm w-100 ml-2">
                            <em class="bi bi-x-circle text-danger mr-2"></em>
                            Clear
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>