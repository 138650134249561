<div class="d-flex align-items-center justify-content-center h-100vh" style="background: #3e88e5; height: 100%;">
  <div class="form-wrapper m-auto">
    <div class="form-container my-auto">
      <div class="row d-flex bg-white m-auto" style="border-radius: 10px;">
        <div class="col-lg-5 col-md-5 p-0 flex-fill">
          <div class="panel d-flex align-items-baseline flex-column h-100 bg"
            style="border: none; box-shadow: none; border-top-right-radius: 0px; border-bottom-right-radius: 0px;">
            <div class="cust">
              <div class="dix row d-flex ">
                <div class="col">
                  <a href="index.html">
                    <!-- <img class="" src="assets/images/pos-logo-meals.png" alt="logo"> -->
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-7 p-0 flex-fill">
          <div class="panel h-100" style="border: none; box-shadow: none;">
            <div class="text-center mb-3">
              <a class="app-logo" href="index.html">
                <img class="logo-icon mr-2" src="assets/images/arkapos.png" alt="logo" style="width: 200px;">
              </a>
            </div>
            <h4 class="font-weight-bold auth-heading text-left text-uppercase text-primary mb-2" style="font-size: 18px;">
              Forgot password
            </h4>
            <p class="" style="font-size: 14px;">
              <em class="fas fa-info-circle text-primary mr-1"></em>
              Enter your <b class="text-primary">username</b> and you will receive a new password to reset your old
              password
            </p>
            <div class="auth-form-container text-left mt-3">
              <form class="auth-form login-form" [formGroup]="recoverForm">
                <div class="email mb-3">
                  <app-input [capitalType]="false" [control]="Email" [placeHolder]="'Enter User Name ...'"></app-input>
                </div>
                <div class="text-center">
                  <button [disabled]="recoverForm.invalid" type="submit" (click)="recover()"
                    class="btn app-btn-primary btn-block theme-btn mx-auto mb-3">
                    <span *ngIf="loadingStatus" class="spinner-border text-light" role="status"></span>
                    <span *ngIf="!loadingStatus">Submit</span></button>
                  <a class="text-black" href="javascript:void(0);" (click)="back()">
                    <span class="mt-4"> <i class="fa fa-arrow-left"></i> Back</span>
                  </a>
                </div>
                <div *ngIf="recovered" class="alert alert-success mt-2 p-1 text-center" role="alert">
                  <em class="fa fa-check-circle text-success mr-2"></em>
                  <span>{{loginsucc}}</span>
                  <img src="alert.close" style="display:none;"
                    onerror="(function(el){ setTimeout(function(){ el.parentNode.parentNode.removeChild(el.parentNode); },4000 ); })(this);"
                    alt="Error image" />
                </div>
                <div *ngIf="showError" class="alert alert-danger mt-2 p-1 text-center" role="alert">
                  <em class="fa fa-exclamation-circle text-danger mr-2"></em>
                  <span>{{loginError}}</span>
                  <img src="alert.close" style="display:none;"
                    onerror="(function(el){ setTimeout(function(){ el.parentNode.parentNode.removeChild(el.parentNode); },4000 ); })(this);"
                    alt="Error image" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>