import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-access403',
  templateUrl: './access403.component.html',
  styleUrls: ['./access403.component.scss']
})
export class Access403Component implements OnInit {
  constructor(public location: Location) { }

  ngOnInit(): void { }

  back(): void {
    this.location.back();
  }
}
