<div class="mt-5 pt-2 cd-panel cd-panel--from-right js-cd-panel-main">
  <header class="cd-panel__header">
    <!-- <h5>Notifications & Audit Trails</h5> -->
    <h5>{{heading}} Audit Trails</h5>
    <a class="cd-panel__close js-cd-close">Close</a>
  </header>

  <div class="cd-panel__container mt-5">

    <div *ngIf="!isLoading" class="cd-panel__content">
      <ul class="timeline">
        <li *ngFor="let item of auditTrailsList">
          <!-- begin timeline-time -->
          <div class="timeline-time">
            <span class="date mr-1">{{ item.entryDate | date }}</span>
            <span class="time">{{ item.entryDate | date: 'shortTime' }}</span>
          </div>
          <div class="timeline-icon">
            <a href="javascript:;">&nbsp;</a>
          </div>
          <!-- begin timeline-body -->
          <div class="timeline-body">
            <div class="timeline-header">
              <span class="avatar">
                <img *ngIf="!item.user.photo" class="avatar" src="assets/images/person.png" alt="">
                <img *ngIf="item.user.photo" class="avatar" [src]="assetsRoot + item.user.photo.url" alt="">
              </span>
              <span class="font-weight-bold"> {{ item.user.firstname }} {{ item.user.lastname }}</span>
            </div>
            <div class="timeline-content">
              <p class="font-weight-bold">Action:- <span class="text-warning text-uppercase">{{ item.action }}</span>
              </p>
            </div>
            <div>
              <p>{{ item.description }}</p>
            </div>

          </div>
          <!-- end timeline-body -->
        </li>
      </ul>

      <div *ngIf="auditTrailsList.length === 0" class="text-center mt-10">
        <img class="text-center" src="assets/images/empty-state.svg" alt="" height="200px" width="200px">
      </div>

    </div> 
    <!-- cd-panel__content -->

    <div *ngIf="isLoading" class="text-center" style="margin-top: 100px;">
      <span class="spinner-border ml-3" role="status">
      </span>
      <p class="text-center">Loading...</p>
    </div>

  </div> <!-- cd-panel__container -->
</div> <!-- cd-panel -->