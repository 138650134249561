<app-modal [size]="'long'" [modalActionButton]="driverBtn" [modalTitle]="'Compartment Products'"
    [condition]="cargoEntries.invalid">
    <div class="row mx-0 mb-2">
        <div class="col-12 px-0">
            <table class="table display table-bordered table-striped table-hover bg-white m-0">
                <thead>
                    <tr>
                        <th>Compartment</th>
                        <th>Product</th>
                        <th>Declared Volume</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let cargo of cargoEntries.controls;let i = index">
                        <tr>
                            <td>
                                <span>Compartment No. {{customEntryCell('compartmentNumber', i).value}}</span>
                            </td>
                            <td>
                                <app-input-select-new [selectSearch]="true" [mb]="'mb-0'"
                                    [control]="customEntryCell('fuelTypeId', i)" [predicate]="fuelTypesPredicate"
                                    [options]="fuelTypesList" [optionsList]="fuelTypesSub">
                                </app-input-select-new>
                            </td>
                            <td>
                                <app-input-figure [maxNo]="6" [control]="customEntryCell('actualQuantityL', i)"></app-input-figure>
                                <!-- <app-input [fieldType]="'number'" [maxNo]="5"
                                    [control]="customEntryCell('actualQuantityL', i)"></app-input> -->
                            </td>
                        </tr>
                    </ng-container>

                </tbody>
            </table>
        </div>
    </div>
</app-modal>