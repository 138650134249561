import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { BaseFormComponent } from '../base-form/base-form.component';
import { applyMixins } from '../../mixin/mixin';
import { FormControl } from '@angular/forms';
import { SettingsService } from '../../../services/settings/settings.service';

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends BaseFormComponent { }
applyMixins(BaseClass, [BaseFormComponent]);

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss']
})
export class InputDateComponent extends BaseClass implements OnInit, DoCheck {

  @Input() fieldId!: string | null;
  @Input() control!: FormControl;
  @Input() readOnly = false;
  @Input() singleDate: boolean | undefined = true;
  @Input() minDate: Date | undefined = undefined;
  @Input() placeHolder: string | null = null;
  @Input() required = false;
  @Input() isFilterVar = false;
  @Input() mb0: any;
  @Input() placement: 'top' | 'bottom' | 'left' | 'right' = 'bottom';
  fakeControl: FormControl = new FormControl();
  currentDate = new Date();
  currentDateRange = [new Date(), new Date()]

  constructor(public settings: SettingsService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.singleDate && this.control.value) {
      // var slashDate: any = this.settings.formatStringToDate(this.control.value);
      // const [day, month, year] = slashDate.split('/');
      // const date = new Date(+year, month - 1, +day);
      // this.fakeControl.setValue(date.toString());
      // console.log(date)
    } else if (!this.singleDate && this.control.value) {
      this.fakeControl.setValue([this.settings.formatStringToDate(this.control.value[0]), this.settings.formatStringToDate(this.control.value[1])]);
    }
  }

  onPickDate($event: Date | Date[]): void {
    console.log($event)
    if ($event !== null) {
      if (!Array.isArray($event)) {
        this.control.setValue(this.settings.formatDateToSlashes($event));
      } else {
        this.control.setValue([this.settings.formatDateToSlashes($event[0]), this.settings.formatDateToSlashes($event[1])]);
      }
    }
  }
}
