import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobService } from '../job.service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {
  jobId!: number;
  jobDetails: any = {};
  loading = false;

 
  updating = true;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private jobService: JobService
  ) {
   
   }

  ngOnInit(): void {
    this.route.params.subscribe(val => {
      if (val.id) {
        this.jobId = val.id;
        this.getJobDetails(val.id);
      }
    });
  }

  getJobDetails(id: any): void {
    this.loading = true;
    this.jobService.saveJob('GET', undefined, (res: any, status: any) => {
      this.loading = false;
      if (status) {
        this.jobDetails = res.data;
        console.log(res.data);
      }
    }, undefined, id);
  }

  _updates(event: any): void {
    if (event) {
      this.getJobDetails(this.jobId);
    }
  }

  _back(): void {
    this.location.back();
  }

}
