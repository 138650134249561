
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class SidePanelService {

    sidePanelTrigger: BehaviorSubject<any | null>;

    constructor(
    ) {
        this.sidePanelTrigger = new BehaviorSubject(null);
    }

    open(data: any): void {
        this.sidePanelTrigger.next(data);
    }

}