import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertPopupComponent } from './alert-popup.component';

@Injectable({
  providedIn: 'root'
})
export class AlertPopupService {
  public bsModalRef?: BsModalRef;
  alertLoading: BehaviorSubject<any>;

  constructor(private modalService: BsModalService) {
    this.alertLoading = new BehaviorSubject(undefined);
  }

  setLoading(status: any): void {
    this.alertLoading.next(status);
  }

  getLoading(): Observable<boolean> {
    return this.alertLoading.asObservable();
  }

  openAlertModal(
    alert: Alert,
    actionTitle: string,
    action: any
  ): void {
    this.bsModalRef = this.modalService.show(AlertPopupComponent, {
      class: 'modal-dialog-centered',
      initialState: {
        type: alert.type,
        message: alert.message,
        actionBtn: action,
        actionTitle: actionTitle,
      },
      animated: true,
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}

export interface Alert {
  type?: string;
  title?: string;
  message?: string;
  icon?: string;
  code?: number;
}


