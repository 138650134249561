import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiConnectionService } from "src/app/services/api/apiconnection.service";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { AuthGuard } from "src/app/services/guards/auth.guard";
import { SessionStorageService } from "src/app/services/session/session-storage.service";
import { WorkSpaceService } from "../workspace/workspace.service";
import { webSocket } from 'rxjs/webSocket';
import { WebSocketService } from "src/app/services/settings/web-socket.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loadingStatus = false;
  showError = false;
  loginError: string = "";
  subject = webSocket('wss://socketsbay.com/wss/v2/2/demo/');

  loginForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, Validators.required),
    rememberMe: new FormControl(),
  });

  constructor(
    public authGuard: AuthGuard,
    public authService: AuthenticationService,
    public router: Router,
    public route: ActivatedRoute,
    public api: ApiConnectionService,
    public session: SessionStorageService,
    private webSocketService: WebSocketService
  ) { }

  ngOnInit(): void {
    this.authService.logout();
    // this.subject.subscribe((res) => {
    //   console.log(res);
    // });
    // this.subject.next('web test')
    // this.subject.complete()

    // this.subject.subscribe({
    //   next: msg => console.log('message received: ' + msg), // Called whenever there is a message from the server.
    //   error: err => console.log(err), // Called if at any point WebSocket API signals some kind of error.
    //   complete: () => console.log('complete') // Called when connection is closed (for whatever reason).
    // });

    // this.webSocketService.listenToEvent('/').subscribe(res => {
    //   console.log(res);
    // });
  }

  checkForCredentials(): void {
    const rem = this.session.getUserCredentials();
    if (rem) {
      if (rem.isValid) {
        this.submit({ email: rem.email, password: rem.password });
      }
    }
  }

  submit(form: any): void {
    this.loadingStatus = true;
    this.showError = false;
    const cred = new Credentials(form.email, form.password);
    if (form.rememberMe) {
      this.authService.rememberMe(Object.assign({ isValue: false }, cred));
    }
    this.authService.login(cred, this.onSubmit.bind(this));
  }

  onSubmit(res: any, status: string, callback: any): void {
    sessionStorage.clear();
    this.loadingStatus = false;
    if (status === "success") {
      console.log(res)
      const rem = this.session.getUserCredentials();
      if (rem) {
        rem.isValid = true;
        this.session.setUserCredentials(rem);
      }
      console.log(res)
      this.session.setjwtToken(res.jwt);
      this.session.setUserRoles(res.user.role);
      this.session.setActiveUser(res.user);
      this.session.setActiveUserRole(res.user.role);
      this.session.setUserAccess(res.user.access);
      this.session.setUserBusinesses(res.businesses);
      
      this.session.setActiveBusiness(res.user.business);
      this.router.navigate(['auth/workspace']);

    } else {
      this.showError = true;
      this.loginError = res.statusText;
    }
  }

  get username(): FormControl {
    return this.loginForm.get("email") as FormControl;
  }

  get password(): FormControl {
    return this.loginForm.get("password") as FormControl;
  }

  get rememberMe(): FormControl {
    return this.loginForm.get("rememberMe") as FormControl;
  }

}

export class Credentials {
  email: string;
  password: string;

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }
}
