
export const formLabels: any = {
  username: 'User Name:',
  password: 'Password:',
  oldPassword: 'Old Password',
  confirmPassword: 'Confirm Password',
  newPassword: 'New Password',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  otherName: 'Middle Name',
  nationality: 'Nationality',
  typeOfIdentification: 'ID Type',
  identificationNumber: 'ID No.',
  vehicleOwner: 'Vehicle Owner',
  notes:'Notes',
  role: 'Roles',
  business: 'Businesses',
  sites:'Sites',
  image: 'Photo',
  cost: 'Cost',
  startTime: 'Start Time',
  endTime: 'EndTime',
  vendor: 'Vendor',
  startDate: 'Start Date',
  date: 'Date',
  endDate: 'End Date',
  dateRage: 'Date Range',
  expectedDeliveryDate: 'Expected Delivery Date',
  name: 'Name',
  nin: 'National ID No.',
  displayName: 'Display Name',
  timezone: 'Time Zone',
  company: 'Company',
  companyName: 'Company Name',
  location: 'Location',
  phoneNo: 'Tel:',
  contact: 'Contact',
  description: 'Description',
  code: 'Code',
  sku: 'Code (SKU)',
  employeeNo: 'Employee No.',
  department: 'Department',
  type: 'Type',
  activeFrom: 'Active From',
  activeTo: 'Active To',
  badgeNo: 'Badge No',
  pos: 'POS',
  reorderLevel: 'Reorder Level',
  sellingPrice: 'Selling Price',
  dosagePerLitre: 'Dosage Per Litre',
  meals: 'Meals',
  meal: 'Meal',
  qty: 'Quantity',
  adminFname: 'Admin First Name',
  adminLname: 'Admin Last Name',
  adminEmail: 'Admin Email',
  reason: 'Reason',
  siteAddress: 'Site Address',
  selectSite:'Select Site',
  address: 'Address',
  hod: 'HOD',
  hodEmail: 'HOD Email',
  maximumMonthlyLoad: 'Maximum Monthly Load',
  cardType: 'CardType',
  cardNo: 'Card Number',
  issueDate: 'Issue Date',
  totalAmount: 'Total Amount',
  amount: 'Amount',
  effectiveLoadDate: 'Effective LoadDate',
  requestDate: 'Request Date',
  scanCard: 'Please Press the card on the scanner',
  toClient: 'To Client',
  fromClient: 'From Client',
  client: 'Client',
  subDepartment: 'Sub-Department',
  deptMembers: 'Choose Employee',
  poses: 'Pos',
  selectTill: 'Select Till',
  tin: 'Tin',
  debitLedgerId: 'Debit Ledger',

  allowedStartTime: 'Start',
  allowedEndTime: 'End',

  rangeStart: 'Range Start',
  rangeEnd: 'Range End',
  testCategory: 'Test Category',

  // job
  customer: 'Client',
  shipmentNumber: 'Shipment No.',
  modeOfTransport: 'Mode Of Trans',
  typeOfSale: 'Type Of Sale',
  vehicleNumber: 'Vehicle Number',
  destination: 'Destination',
  soldToParty: 'Sold To Party',
  deliveryTime: 'Delivery Time',
  deliveryDate: 'Shipment Date',
  driverId: 'Driver',
  dippingDate: 'Dipping Date',
  dipperRemarks: 'Dipper Remarks',
  variationComment: 'Variation Comment',
  labRemarks: 'Lab Remarks',
  ref: 'Payment Reference',
  consigner: 'Consigner',
  consignee: 'Consignee',
  portOfEntry: 'Port Of Entry',
  overallQuantity: 'Overall Quantity',
  numberOfCompartments: 'No. of Compartments',
  totalVol: 'Declared Truck Volume',

  //cargo
  compartmentNumber: 'Compartment No.',
  fuelTypeId: 'Fuel Type',
  orderQuantity: 'Order Qty.(L)',
  actualQuantityL: 'Declared Volume',
  actualQuantityL20: 'Actual Qty.(L20)',
  densityAt20: 'Density @20°C KGV',
  temperature: 'Temperature (°C)',
  lonNumber: 'LON Number',
  attachment: 'Attachment',
  flashPoint: 'Flash Point (°C)',
  finalBoilingPoint:'Final Boiling Point FBP (°C)',


  exchangeRage: 'Exchange Rage',
  openingDate: 'Opening Date',
  trailerNumber:'Trailer No.',
  // dipping
  oldSeal: 'Old Seal',
  newSeal: 'New Seal',
  observedVolumeAt20C: 'Observed Vol @20°C',
  observedDensityAt20C: 'Observed Density @20°C',
  T2: 'T2',
  T1: 'T1',
  markerId: 'Marker',

  // inventory
  unit: 'Unit Of Measure',
  costPrice: 'Cost Price',
  unitPrice: 'Unit Price',
  currency: 'Currency',
  item: 'Item',
  markerItems: 'Marker Items',
  barcode: 'Barcode',
  vat: 'VAT(%)',
  dateRange: 'Date',
  category: 'Category',
  journalStatus: 'Status',
  journalType: 'Type',
  journalReference: 'Reference',
  reference: 'Reference'
};
