import { NgModule } from '@angular/core';
import { DisableIfNotGrantedDirective } from './disable-if-not-granted.directive';
import { HideIfNotGrantedDirective } from './hide-sub-module.directive';
import { HideModuleDirective } from './hide-module.directive';
import { HideByUserTypeDirective } from './hide-by-user-type.directive';
import { StrictDecimalNumberDirective } from './strict-decimal.directive';

@NgModule({
  declarations: [
    HideIfNotGrantedDirective,
    HideModuleDirective,
    DisableIfNotGrantedDirective,
    HideByUserTypeDirective,
    StrictDecimalNumberDirective,
  ],
  imports: [],
  exports: [
    HideIfNotGrantedDirective,
    HideModuleDirective,
    DisableIfNotGrantedDirective,
    HideByUserTypeDirective,
    StrictDecimalNumberDirective,
  ],
  providers: [],
})
export class AuthDirectiveModule {}
