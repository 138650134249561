import { Component, Injectable, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ASSETSROOT } from 'src/app/services/api/api-endpoints';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ProfileService } from 'src/app/services/proflie/profile.service';
import { SidePanelService } from './sidepanel.service';

@Injectable({
    providedIn: 'root'
})

@Component({
    selector: 'app-slide-panel',
    templateUrl: './slide-panel.component.html',
    styleUrls: ['./slide-panel.component.scss']
})
export class SlidePanelComponent implements OnInit {

    auditTrailsList: Array<any> = [];
    heading: string = 'Empty';

    assetsRoot = ASSETSROOT;
    panelElement!: Element | null;
    isLoading = false;
    sidepanelTriger?: Subscription;

    constructor(
        public apiConnect: ApiConnectionService,
        public profile: ProfileService,
        public authService: AuthenticationService,
        public sidepanelService: SidePanelService
    ) { }

    ngOnInit(): void {
        this.sidepanelTriger = this.sidepanelService?.sidePanelTrigger?.subscribe((val: any) => {
            if (val !== null) {
                this.showTrail();
                this.getAuditTrails(val);
            }
        });
    }

    getAuditTrails(id: number): void {
        this.isLoading = true;
        this.apiConnect.secureConnect('GET', 'AuditTrail', undefined, undefined, id, this.onGetAuditTrails.bind(this));
    }
    onGetAuditTrails(res: any, status: string, callback: any): void {
        this.isLoading = false;
        console.log(res)
        if (status === 'success') {
            if (res !== null && res.length !== 0) {
                this.heading = res[0].section;
                this.auditTrailsList = res;
            }
        } else {
            callback('danger', 'Error', res.message.error);
        }
    }


    showTrail(): void {
        this.panelElement = document.querySelector('.js-cd-panel-main');
        if (this.panelElement !== null) {
            this.addClass(this.panelElement, 'cd-panel--is-visible');

            this.panelElement.addEventListener('click', (event: any) => {
                if (this.hasClass(event.target, 'js-cd-close') || this.hasClass(event.target, 'js-cd-panel-main')) {
                    if (this.panelElement)
                        this.removeClass(this.panelElement, 'cd-panel--is-visible');
                }
                // this.sidepanelTriger.unsubscribe();
                if (this.panelElement)
                    this.panelElement.removeEventListener('click', () => {
                        console.log('Listener removed');
                    }, true)
            });
        }
    }

    hasClass(el: Element, className: any): boolean {
        if (el.classList) return el.classList.contains(className);
        else return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
    }

    addClass(el: Element, className: any): void {
        if (el.classList) el.classList.add(className);
        else if (!this.hasClass(el, className)) el.className += " " + className;
    }

    removeClass(el: Element, className: any): void {
        if (el.classList) {
            el.classList.remove(className);
        }
        else if (this.hasClass(el, className)) {
            var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
            el.className = el.className.replace(reg, ' ');
        }
    }

}


//  export function triggerSidepanel(): any {

// }

