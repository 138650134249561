import { Injectable } from '@angular/core';
import { AlertService } from '../../../../app/services/alert/alert.service'
import { ApiConnectionService } from '../../../../app/services/api/apiconnection.service'

@Injectable({
    providedIn: 'root'
})
export class ItemService {

    constructor(
        private apiConnect: ApiConnectionService,
        private alertService: AlertService,
    ) { }

    getItem(onGet: (res?: any, status?: boolean) => void, queryParams?: any, id?: number): void {
        this.apiConnect.callApi('GET', 'InventoryItem', undefined, queryParams, id ? id : undefined)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message:   err.message.error.error,
                    });
                }
            )
    }

    getGenItem(onGet: (res?: any, status?: boolean) => void, queryParams?: any, id?: number): void {
        this.apiConnect.callApi('GET', 'GenInventoryItem', undefined, queryParams, id ? id : undefined)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message:   err.message.error.error,
                    });
                }
            )
    }

    saveItem(form: any, onGet: (res?: any, status?: boolean) => void): void {
        this.apiConnect.callApi('POST', 'InventoryItem', form, undefined, undefined)
            .subscribe(
                (res: any) => {
                    onGet(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message,
                    });
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message:   err.message.error.error,
                    });
                }
            )
    }

    updateItem(form: any, id: number, onUpdate: (res?: any, status?: boolean) => void): void {
        this.apiConnect.callApi('PUT', 'InventoryItem', form, undefined, id)
            .subscribe(
                (res: any) => {
                    onUpdate(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message,
                    });
                },
                (err: any) => {
                    onUpdate(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message:   err.message.error.error,
                    });
                }
            )
    }

    updateItemPhoto(form: any, id: number, onUpdate: (res?: any, status?: boolean) => void): void {
        this.apiConnect.callApi('PUT', 'InventoryItemPhoto', form, undefined, id)
            .subscribe(
                (res: any) => {
                    onUpdate(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message,
                    });
                },
                (err: any) => {
                    onUpdate(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message:   err.message.error.error,
                    });
                }
            )
    }

    deleteItem(id: number, onUpdate: (res?: any, status?: boolean) => void): void {
        this.apiConnect.callApi('DELETE', 'InventoryItem', undefined, undefined, id)
            .subscribe(
                (res: any) => {
                    onUpdate(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message,
                    });
                },
                (err: any) => {
                    onUpdate(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        message:   err.message.error.error,
                    });
                }
            )
    }
}