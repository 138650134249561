<div class="pagination pagination-custom my-2 justify-content-center align-items-center">
  <a href="javascript:void(0);" class="btn btn-primary btn-sm"
    style="border-radius: 50%; padding: 0.375rem 0.75rem !important;" (click)="onClickPage(activePage - 1)">
    <em class="fas fa-chevron-left"></em>
  </a>
  <span class="mx-2">Page: </span>
  <a href="javascript:void(0);" class="selected">
    {{ activePage }}
  </a>
  <span class="mx-2">of &nbsp;&nbsp;{{ pages.length }} </span>
  <a href="javascript:void(0);" class="btn btn-primary btn-sm"
    style="border-radius: 50%; padding: 0.375rem 0.75rem !important;" (click)="onClickPage(activePage + 1)">
    <em class="fas fa-chevron-right"></em>
  </a>
</div>