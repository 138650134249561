<div *ngIf="singleDate" class="form-group" [ngClass]="{'position-relative': isFilterVar, 'mb-0': mb0 !== undefined}">
  <label *ngIf="fieldId" [for]="fieldId" class="label text-muted font-weight-bolder"
    [ngClass]="{'position-absolute': isFilterVar, 'filter-label': isFilterVar}">
    {{label}}
    <span *ngIf="required" class="text-danger">*</span>
  </label>
  <input [formControl]="fakeControl" [readOnly]="readOnly"
    #datepickerYMD="bsDatepicker" bsDatepicker (bsValueChange)="onPickDate($event)"
    [bsConfig]="{ minDate: minDate, adaptivePosition: true, isAnimated: true, containerClass: 'theme-green' }" autocomplete="off"
    [attr.placeholder]="placeHolder" class="form-control form-control-sm" [placement]="placement"
    [bsConfig]="{ adaptivePosition: true }" [bsConfig]="{ dateInputFormat: 'Do MMM YYYY' }" />
  <input type="text" [formControl]="control" hidden>
  <app-form-validation [validationErrors]="validationErrors"></app-form-validation>
</div>

<div *ngIf="!singleDate" class="form-group" [ngClass]="{'position-relative': isFilterVar, 'mb-0': mb0 !== undefined}">
  <label *ngIf="fieldId" [for]="fieldId" class="label text-muted font-weight-bolder"
    [ngClass]="{'position-absolute': isFilterVar, 'filter-label': isFilterVar}">
    {{label}}
    <span *ngIf="required" class="text-danger">*</span>
  </label>
  <input [formControl]="fakeControl" [readOnly]="readOnly"
    #datepickerYMD="bsDaterangepicker" bsDaterangepicker (bsValueChange)="onPickDate($event)"
    [bsConfig]="{isAnimated: true, adaptivePosition: true, containerClass: 'theme-green' }" autocomplete="off"
    [attr.placeholder]="placeHolder" class="form-control form-control-sm" [placement]="placement"
    [bsConfig]="{ adaptivePosition: true }"
     [bsConfig]="{ rangeInputFormat : 'Do MMM YYYY', dateInputFormat: 'Do MMM YYYY', showWeekNumbers: false }" />
  <input type="text" [formControl]="control" hidden>
  <app-form-validation [validationErrors]="validationErrors"></app-form-validation>
</div>