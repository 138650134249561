import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgxPrintModule } from "ngx-print";
import { BsDatepickerModule, BsLocaleService } from "ngx-bootstrap/datepicker";

import { PaginatorComponent } from "./paginator/paginator.component";
import { BaseFormComponent } from "./form/base-form/base-form.component";
import { FormValidationComponent } from "./form/form-validation/form-validation.component";
import { InputComponent } from "./form/input/input.component";
import { InputDateComponent } from "./form/input-date/input-date.component";
import { InputFileComponent } from "./form/input-file/input-file.component";
import { InputImportComponent } from "./form/input-import/input-import.component";
import { InputSelectComponent } from "./form/input-select/input-select.component";
import { InputSelectSearchComponent } from "./form/input-select-search/input-select-search.component";
import { InputTextAreaComponent } from "./form/input-textarea/input-textarea.component";
import { InputFigureComponent } from "./form/input-figure/input-figure.component";
import { DateFormatPipe } from "./pipes/date.pipe";
import { FilterPipe } from "./pipes/filter.pipe";
import { PaginatorPipe } from "./pipes/pagination.pipe";
import { ModalComponent } from "./modal/modal.component";
import { FilterComponent } from "./filter/filter.component";
import { ImageCropperComponent } from "./image-cropper/image-cropper.component";
import { ImportXlsxComponent } from "./import-xlsx/import-xlsx.component";
import { StatusComponent } from "./status/status.component";
import { InputTimeComponent } from "./form/input-time/input-time.component";
import { PrintbtnComponent } from "./print-btn/print-btn.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { EmptyPlaceholderComponent } from "./empty-placeholder/empty-placeholder.component";
import { RibbonComponent } from "./ribbon/ribbon.component";
import { LoadingPlaceholderComponent } from "./loading-placeholder/loading-placeholder.component";
import { StepperComponent } from "./stepper/stepper.component";
import { PopperDirective } from "./popup/popup.directive";
import { TableFilterComponent } from "./table-filter/table-filter.component";
import { PageStateComponent } from "./page-state/page-state.component";
import { PdfViewerComponent } from "./pdf-viewer/pdf-viewer.component";
import { InputPhoneComponent } from "./form/input-phone/input-phone.component";
import { InputSelectComponentNew } from "./form/input-select-new/input-select-new.component";
import { AlertPopupComponent } from "./alert-popup/alert-popup.component";
import { PieChartComponent } from "./charts/pie/pie.component";
import { LineChartComponent } from "./charts/line/line-chart.component";
import { ColumnChartComponent } from "./charts/column/column-chart.component";
import { ColumnLineComponent } from "./charts/column-line/column-line.component";

@NgModule({
  declarations: [
    TableFilterComponent,
    PageStateComponent,
    PaginatorComponent,
    BaseFormComponent,
    FormValidationComponent,
    InputComponent,
    InputDateComponent,
    InputFileComponent,
    InputImportComponent,
    InputSelectComponent,
    InputTimeComponent,
    InputTextAreaComponent,
    ModalComponent,
    FilterComponent,
    ImportXlsxComponent,
    ImageCropperComponent,
    StatusComponent,
    PrintbtnComponent,
    InputSelectSearchComponent,
    EmptyPlaceholderComponent,
    RibbonComponent,
    LoadingPlaceholderComponent,
    StepperComponent,
    PopperDirective,
    InputFigureComponent,
    InputPhoneComponent,
    InputSelectComponentNew,
    AlertPopupComponent,
    PieChartComponent,
    LineChartComponent,
    ColumnChartComponent,
    ColumnLineComponent,

    // pipes
    FilterPipe,
    PaginatorPipe,
    DateFormatPipe,
    PdfViewerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxPrintModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    ModalModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [
    // components
    TableFilterComponent,
    PageStateComponent,
    PaginatorComponent,
    BaseFormComponent,
    FormValidationComponent,
    InputComponent,
    InputDateComponent,
    InputFileComponent,
    InputImportComponent,
    InputSelectComponent,
    InputTextAreaComponent,
    InputTimeComponent,
    ModalComponent,
    FilterComponent,
    ImportXlsxComponent,
    ImageCropperComponent,
    StatusComponent,
    PrintbtnComponent,
    InputSelectSearchComponent,
    EmptyPlaceholderComponent,
    RibbonComponent,
    LoadingPlaceholderComponent,
    StepperComponent,
    PopperDirective,
    InputFigureComponent,
    InputPhoneComponent,
    InputSelectComponentNew,
    AlertPopupComponent,
    PieChartComponent,
    LineChartComponent,
    ColumnChartComponent,
    ColumnLineComponent,

    // pipes
    FilterPipe,
    PaginatorPipe,
    DateFormatPipe,

    // modules
    FormsModule,
    CommonModule,
    ModalModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    NgxExtendedPdfViewerModule,
  ],
  providers: [BsLocaleService],
})
export class ComponentsModule {}
