<div id="toaster-parent" style="position: fixed; bottom: 10px; right: 10px; z-index: 1000">
  <!-- Then put toasts within -->
  <div *ngFor="let alert of alertArray" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <span class="rounded custom-alert text-center mr-2" [ngClass]="alertBackground[alert.type]">
        <i class="text-white" [ngClass]="alertIcons[alert.type]"></i>
      </span>
      <strong class="mr-auto">{{ alert.title }}</strong>
      <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="toast-body text-white" [ngClass]="alertBackground[alert.type]">
      {{ alert.message }}
    </div>
  </div>
</div>
