import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { applyMixins } from '../../mixin/mixin';
import { BaseFormComponent } from '../base-form/base-form.component';

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends BaseFormComponent { }
applyMixins(BaseClass, [BaseFormComponent]);

@Component({
  selector: 'app-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss']
})
export class InputPhoneComponent extends BaseClass implements OnInit {
  @Input() fieldId!: string | null;
  @Input() control!: FormControl;

  @Input() isFilterVar = false;
  @Input() fieldType = '';
  @Input() disabled = false;
  @Input() required = false;
  @Input() readOnly = false;
  @Input() formControlSm = true;
  @Input() minNo: number | null = null;
  @Input() maxNo: number | null = null;
  @Input() placeHolder: string | null = null;
  @Input() mb0: any = undefined;
  @Input() code!: FormControl;
  number: FormControl = new FormControl();

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.number.valueChanges.subscribe(val => {
      if (val && val.toString().length > 9) {
        this.number.setValue(val.toString().slice(0, -1))
      }
      this.control.setValue(this.code.value + '' + val);
    });
    this.code.valueChanges.subscribe((res: string) => {
      if (res.length === 4) {
        this.code.setValue(res);
      }
    })
  }

  ngDoCheck(): void {
    super.ngDoCheck();
  }
}
