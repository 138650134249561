import { Injectable } from '@angular/core';
import { Role } from '../authorization/models/Role.model';
import {
  ACTIVE_BUSINESS, ACTIVE_ROLE, ACTIVE_SITE, ACTIVE_USER, JWT_TOKEN, PROJECT_VAT, SESSION_TOKEN,
  TOKEN, USER_CREDENTIALS, LOCK_URL, USER_ROLES, LOCK_EMAIL, LOCK_STATUS, USER_ACCESS, USER_BUSINESSES
} from './constants';

@Injectable({
  providedIn: "root",
})
export class SessionStorageService {
  constructor() {}

  /**
   * Sets session token
   * @param token [set session token]
   */
  setSessionToken(token: string): void {
    sessionStorage.removeItem(SESSION_TOKEN);
    sessionStorage.setItem(SESSION_TOKEN, token);
  }

  /**
   * Sets token
   * @param token [session token for user]
   */
  setToken(token: string): void {
    sessionStorage.setItem(TOKEN, token);
  }

  /**
   * Sets user roles
   * @param roles [all user roles]
   */
  setActiveBusiness(activeBusiness: any): void {
    sessionStorage.removeItem(ACTIVE_BUSINESS);
    sessionStorage.setItem(ACTIVE_BUSINESS, JSON.stringify(activeBusiness));
  }

  setActiveSite(activeSite: any): void {
    sessionStorage.removeItem(ACTIVE_SITE);
    sessionStorage.setItem(ACTIVE_SITE, JSON.stringify(activeSite));
  }

  setActiveProjectVAT(vat: any): void {
    sessionStorage.removeItem(PROJECT_VAT);
    sessionStorage.setItem(PROJECT_VAT, vat);
  }

  setUserRoles(roles: any): void {
    sessionStorage.removeItem(USER_ROLES);
    sessionStorage.setItem(USER_ROLES, JSON.stringify(roles));
  }

  setUserAccess(roles: any): void {
    sessionStorage.removeItem(USER_ACCESS);
    sessionStorage.setItem(USER_ACCESS, JSON.stringify(roles));
  }

  /**
   * Sets active user role
   * @param role [user active selected role]
   */
  setActiveUserRole(role: Role): void {
    sessionStorage.removeItem(ACTIVE_ROLE);
    sessionStorage.setItem(ACTIVE_ROLE, JSON.stringify(role));
  }

  /**
   * Setjwts token
   * @param token [jwt token]
   */
  setjwtToken(token: string): void {
    sessionStorage.removeItem(JWT_TOKEN);
    sessionStorage.setItem(JWT_TOKEN, token);
  }

  /**
   * Sets active user
   * @param user [active logged in user]
   */
  setActiveUser(user: any): void {
    sessionStorage.removeItem(ACTIVE_USER);
    sessionStorage.setItem(ACTIVE_USER, JSON.stringify(user));
  }

  setUserBusinesses(data: any): void {
    sessionStorage.removeItem(USER_BUSINESSES);
    sessionStorage.setItem(USER_BUSINESSES, JSON.stringify(data));
  }

  setUserCredentials(userCredentails: any): void {
    localStorage.removeItem(USER_CREDENTIALS);
    localStorage.setItem(USER_CREDENTIALS, JSON.stringify(userCredentails));
  }

  /**
   * Setjwts token
   * @param lock_url [jwt token]
   */
  setLockURL(url: any): void {
    sessionStorage.removeItem(LOCK_URL);
    sessionStorage.setItem(LOCK_URL, url);
  }

  setLockEmail(email: string): void {
    sessionStorage.removeItem(LOCK_EMAIL);
    sessionStorage.setItem(LOCK_EMAIL, email);
  }

  setLockStatus(status: any): void {
    sessionStorage.removeItem(LOCK_STATUS);
    sessionStorage.setItem(LOCK_STATUS, status);
  }

  /**
   * Gets user credentials
   * @returns user credentials
   */
  getUserCredentials(): any {
    const value = localStorage.getItem(USER_CREDENTIALS);
    if (value) {
      return JSON.parse(value);
    } else {
      return null;
    }
  }

  /**
   * Gets active user
   * @returns active user
   */
  getActiveUser(): any {
    const value = sessionStorage.getItem(ACTIVE_USER);
    if (value) {
      return JSON.parse(value);
    } else {
      return null;
    }
  }

  getUserBusinesses(): any {
    const value = sessionStorage.getItem(USER_BUSINESSES);
    if (value) {
      return JSON.parse(value);
    } else {
      return null;
    }
  }

  getUserExcess(): any {
    const value = sessionStorage.getItem(USER_ACCESS);
    if (value) {
      return JSON.parse(value);
    } else {
      return null;
    }
  }

  /**
   * Gets session token
   * @returns session token
   */
  getSessionToken(): any {
    return sessionStorage.getItem(SESSION_TOKEN);
  }

  /**
   * Gets token
   * @returns token
   */
  getToken(): string | null {
    return sessionStorage.getItem(TOKEN);
  }

  /**
   * Gets token
   * @returns token
   */
  getActiveBusiness(): any {
    const value = sessionStorage.getItem(ACTIVE_BUSINESS);
    if (value) {
      return JSON.parse(value);
    } else {
      return null;
    }
  }

  /**
   * Gets token
   * @returns token
   */
  getActiveSite(): any {
    const value = sessionStorage.getItem(ACTIVE_SITE);
    if (value && value !== "undefined") {
      return JSON.parse(value);
    } else {
      return null;
    }
  }

  /**
   * Gets user roles
   * @returns user roles
   */
  getUserRoles(): any {
    const value = sessionStorage.getItem(USER_ROLES);
    if (value) {
      return JSON.parse(value);
    } else {
      return [];
    }
  }

  /**
   * Gets active user roles
   * @returns active role
   */
  getActiveUserRole(): any {
    let role: Role = JSON.parse(sessionStorage.getItem(ACTIVE_ROLE)!);

    return role;
  }

  /**
   * Getjwts token
   * @returns token
   */
  getjwtToken(): string | null {
    return sessionStorage.getItem(JWT_TOKEN);
  }

  getLockURL(): string | null {
    return sessionStorage.getItem(LOCK_URL);
  }

  getLockEmail(): string | null {
    return sessionStorage.getItem(LOCK_EMAIL);
  }

  getLockStatus(): string | null {
    return sessionStorage.getItem(LOCK_STATUS);
  }
}
