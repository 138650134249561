import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { applyMixins } from "src/app/components/mixin/mixin";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { JobService } from "../../job.service";
import { PdfViewerService } from "src/app/components/pdf-viewer/pdf-viewer.service";
import { JournalService } from "src/app/pages/accounting/journal/journal.service";
import { BehaviorSubject } from "rxjs";
import { SettingsService } from "src/app/services/settings/settings.service";
import { PdfPrintService } from "src/app/services/settings/pdf-print.service";
import { SessionStorageService } from "src/app/services/session/session-storage.service";
import { WebSocketService } from "src/app/services/settings/web-socket.service";
import { TestsService } from "src/app/pages/settings/lab-tests/test.service";
import { Currency } from "src/app/constants/form-labels/others";
import { AlertPopupService } from "src/app/components/alert-popup/alert-popup.service";
import { ROLES } from "src/app/constants/roles";
import { GeneralService } from "src/app/pages/settings/general-settings/general.service";

class BaseClass {}
interface BaseClass extends ModalComponent {}
applyMixins(BaseClass, [ModalComponent]);

@Component({
  selector: "app-job-actions",
  templateUrl: "./job-actions.component.html",
  styleUrls: ["./job-actions.component.scss"],
})
export class JobActionsComponent extends BaseClass implements OnInit {
  @Output() updates = new EventEmitter<boolean>();
  JobRoles = ROLES.Job;
  comments: any[] = [];
  pendingTests: any[] = [];
  testEntries!: FormArray;
  testBtn: any;
  currency: string[] = Currency;
  sessionId: any;

  jobId!: number;
  jobDetails: any = {};
  voucher!: TemplateRef<any>;

  postableLedgers: any[] = [];
  postableLedgersSub: BehaviorSubject<[]> = new BehaviorSubject([]);
  postableLedgersPredicate!: object;

  jobUpdateForm!: FormGroup;
  jobBtn!: object;

  completeForm!: FormGroup;
  compBtn!: object;

  changeConsignee: FormControl = new FormControl(null, Validators.required);
  changeBtn!: object;
  customersSub: BehaviorSubject<[]> = new BehaviorSubject([]);
  customersList: any[] = []; //
  customersPredicate!: object;

  receivePayForm!: FormGroup;
  recBtn!: object;
  role: any;

  isSaving = false;
  updating = false;
  gettingPDF = false;
  getting = false;

  constructor(
    private route: ActivatedRoute,
    private jobService: JobService,
    public modalService: BsModalService,
    public pdfService: PdfViewerService,
    private journalService: JournalService,
    private settingService: SettingsService,
    private pdfPrintService: PdfPrintService,
    private session: SessionStorageService,
    private websocket: WebSocketService,
    public testsService: TestsService,
    public alertPopupService: AlertPopupService,
    public generalService: GeneralService
  ) {
    super();
    this.testEntries = new FormArray([]);
    this.jobUpdateForm = new FormGroup({
      dippingDate: new FormControl(null, Validators.required),
      dipperRemarks: new FormControl(null, Validators.required),
      variationComment: new FormControl(null, Validators.required),
    });
    this.completeForm = new FormGroup({
      labRemarks: new FormControl(null, Validators.required),
    });

    this.receivePayForm = new FormGroup({
      bankReference: new FormControl(null, Validators.required),
      scan: new FormControl(null),
      notes: new FormControl(null),
      currency: new FormControl(null, Validators.required),
      amount: new FormControl(null, Validators.required),
      date: new FormControl(
        this.settingService.formatDateToSlashes(new Date()),
        Validators.required
      ),
    });

    this.jobBtn = {
      method: () => {
        return this.updateJob(this.jobUpdateForm.value);
      },
      text: "Update",
    };
    this.recBtn = {
      method: () => {
        return this.receivePayment(this.receivePayForm.value);
      },
      text: "Save",
    };
    this.changeBtn = {
      method: () => {
        return this.changeClient();
      },
      text: "Save",
    };
    this.testBtn = {
      method: () => {
        return this.saveTests();
      },
      text: "Update",
    };
    this.compBtn = {
      method: () => {
        return this.completeJob(this.completeForm.value);
      },
      text: "Complete",
    };
    this.postableLedgersPredicate = {
      method1: (obj: any) => obj.id,
      method2: (obj: any) => obj.name,
    };
    this.customersPredicate = {
      method1: (obj: any) => obj.id,
      method2: (obj: any) => obj.companyName,
    };
  }

  ngOnInit(): void {
    this.route.params.subscribe((val) => {
      if (val.id) {
        this.jobId = val.id;
        this.getJobDetails(val.id);
      }
    });
    // this.getPostableLedgers();
    this.getActiveSession();
    this.getVariationComments();
    this.getPendingTests(this.jobId);
    this.role = this.session.getActiveUserRole();
    this.getCustomers();
  }

  getActiveSession(): void {
    this.isSaving = true;
    this.jobService.sessions(
      "GET",
      "ActiveSession",
      undefined,
      (res, status) => {
        this.isSaving = false;
        if (status) {
          if (res.data.length > 0) {
            this.sessionId = res.data[0].id;
          }
        }
      }
    );
  }

  getJobDetails(id: any): void {
    this.loading = true;
    this.jobService.saveJob(
      "GET",
      undefined,
      (res: any, status: any) => {
        this.loading = false;
        if (status) {
          this.jobDetails = res.data;
          this._prefill(res.data);
        }
      },
      undefined,
      id
    );
  }

  _prefill(data: any): void {
    this.customCotrol("dippingDate", this.jobUpdateForm).setValue(
      data.dippingDate
    );
    this.customCotrol("dipperRemarks", this.jobUpdateForm).setValue(
      data.dipperRemarks
    );
    this.customCotrol("variationComment", this.jobUpdateForm).setValue(
      data.variationComment
    );
    this.customCotrol("dippingDate", this.jobUpdateForm).setValue(
      this.settingService.formatDateToSlashes(new Date())
    );
  }

  updateJob(form: any): void {
    this.updating = false;
    this.closeModal();
    this.jobService.saveJob(
      "PUT",
      this.jobUpdateForm.value,
      (res, status) => {
        this.updating = true;
        if (status) {
          this.updates.emit(true);
        }
      },
      undefined,
      this.jobId
    );
  }

  acceptInfo(): void {
    this.gettingPDF = true;
    this.jobService.acceptInfo(
      (res, status) => {
        this.gettingPDF = false;
        if (status) {
          this.updates.emit(true);
          this.getJobDetails(this.jobId);
        }
      },
      undefined,
      this.jobId
    );
  }

  completeJob(form: any): void {
    this.gettingPDF = true;
    this.jobService.completeJob(
      form,
      (res, status) => {
        this.gettingPDF = false;
        this.closeModal();
        if (status) {
          this.updates.emit(true);
          this.getJobDetails(this.jobId);
          this.websocket.emitWebNoti(
            `Job No. ${this.jobDetails?.jobNumber} Has Been Completed...`
          );
        }
      },
      undefined,
      this.jobId
    );
  }

  exemptJob(): void {
    this.alertPopupService.openAlertModal(
      {
        type: "warning",
        message: "Are you sure you want to Exempt this Job !",
      },
      "Confirm",
      () => {
        this.alertPopupService.setLoading(true);
        this.gettingPDF = true;
        this.jobService.exemptJob(
          (res, status) => {
            this.gettingPDF = false;
            if (status) {
              this.updates.emit(true);
              this.getJobDetails(this.jobId);
            }
          },
          undefined,
          this.jobId
        );
        this.alertPopupService.setLoading(false);
      }
    );
  }

  requestCredit(): void {
    this.gettingPDF = true;
    this.alertPopupService.openAlertModal(
      {
        type: "warning",
        message: "Are you sure you want to Request Credit on this Job!",
      },
      "Confirm",
      () => {
        this.alertPopupService.setLoading(true);
        this.jobService.requestCredit(
          (res, status) => {
            this.gettingPDF = false;
            this.alertPopupService.setLoading(false);
            this.closeModal();
            if (status) {
              this.updates.emit(true);
              this.getJobDetails(this.jobId);
            }
          },
          undefined,
          this.jobId
        );
      }
    );
  }

  permitCredit(): void {
    this.gettingPDF = true;
    this.alertPopupService.openAlertModal(
      {
        type: "warning",
        message: "Are you sure you want to permit this Job on Credit !",
      },
      "Confirm",
      () => {
        this.alertPopupService.setLoading(true);
        this.jobService.permitCredit(
          (res, status) => {
            this.gettingPDF = false;
            this.alertPopupService.setLoading(false);
            this.closeModal();
            if (status) {
              this.updates.emit(true);
              this.getJobDetails(this.jobId);
            }
          },
          undefined,
          this.jobId
        );
      }
    );
  }

  payWithBal(): void {
    this.alertPopupService.openAlertModal(
      {
        type: "warning",
        message:
          "Are you sure you want to Pay this Job with Client's Balance !",
      },
      "Confirm",
      () => {
        this.alertPopupService.setLoading(true);
        this.gettingPDF = true;
        this.jobService.payWithBal(
          (res, status) => {
            this.gettingPDF = false;
            if (status) {
              this.updates.emit(true);
              this.getJobDetails(this.jobId);
            }
          },
          undefined,
          this.jobId
        );
        this.alertPopupService.setLoading(false);
      }
    );
  }

  proceedLab(): void {
    this.gettingPDF = true;
    this.alertPopupService.openAlertModal(
      {
        type: "warning",
        message: "Are you sure you want to Push this Job for Lab Processing!",
      },
      "Confirm",
      () => {
        this.alertPopupService.setLoading(true);
        this.jobService.proceedLab(
          (res, status) => {
            this.gettingPDF = false;
            this.alertPopupService.setLoading(false);
            this.closeModal();
            if (status) {
              this.updates.emit(true);
              this.getJobDetails(this.jobId);
            }
          },
          undefined,
          this.jobId
        );
      }
    );
  }

  processJob(): void {
    this.gettingPDF = true;
    this.jobService.processJob(
      undefined,
      (res, status) => {
        this.gettingPDF = false;
        this.closeModal();
        if (status) {
          this.updates.emit(true);
          this.getJobDetails(this.jobId);
          this.websocket.emitWebNoti(
            `Job No. ${this.jobDetails?.jobNumber} Is Under Processing...`
          );
        }
      },
      undefined,
      this.jobId
    );
  }

  createVoucherJob(): void {
    this.gettingPDF = true;
    this.jobService.jobVoucher(
      undefined,
      (res, status) => {
        this.gettingPDF = false;
        if (status) {
          this.updates.emit(true);
          this.getJobDetails(this.jobId);
          this.getPDF("PaymentVoucher");
        }
      },
      undefined,
      this.jobId
    );
  }

  receivePayment(form: any): void {
    this.isSaving = true;
    this.jobService.receivePayment(
      { ...form, jobId: this.jobId, sessionId: this.sessionId },
      (res, status) => {
        this.isSaving = false;
        this.closeModal();
        if (status) {
          this.updates.emit(true);
          this.getJobDetails(this.jobId);
          this.jobService.printReceipt(res.data.id);
        }
      }
    );
  }

  verifyPayment(): void {
    this.gettingPDF = true;
    this.jobService.verifyPayment(
      (res, status) => {
        this.gettingPDF = false;
        if (status) {
          this.updates.emit(true);
          this.getJobDetails(this.jobId);
          this.websocket.emitWebNoti(
            `Payment For Job No. ${this.jobDetails?.jobNumber} Has Been Verified...`
          );
        }
      },
      undefined,
      this.jobId
    );
  }

  rejectPayment(): void {
    this.gettingPDF = true;
    this.jobService.rejectPayment(
      (res, status) => {
        this.gettingPDF = false;
        if (status) {
          this.updates.emit(true);
          this.getJobDetails(this.jobId);
          this.websocket.emitWebNoti(
            `Payment For Job No. ${this.jobDetails?.jobNumber} Has Been Rejected...`
          );
        }
      },
      undefined,
      this.jobId
    );
  }

  getPDF(link: any): void {
    this.gettingPDF = true;
    this.jobService.printForms(
      link,
      (res, status) => {
        this.gettingPDF = false;
        if (status) {
          if (link !== "PaymentVoucher") {
            this.pdfService.openPDFModal(link, res.data.data);
          }
          if (link === "AcknowledgementForm") {
            this.getJobDetails(this.jobId);
          }
          if (link === "PaymentVoucher") {
            this.pdfPrintService.openNewWindowPdfpreview(res.data.data);
          }
        }
      },
      undefined,
      this.jobId
    );
  }

  getPostableLedgers(): void {
    this.journalService.getCashLedgers((res: any, status) => {
      if (status) {
        this.postableLedgers = res;
        this.postableLedgersSub.next(res);
      }
    });
  }

  getVariationComments(): void {
    this.jobService.variationComments((res, status) => {
      if (status) {
        this.comments = res.data;
      }
    });
  }

  getPendingTests(id: number): void {
    this.getting = true;
    this.testsService.testAPi(
      "GET",
      "JobPendingTests",
      undefined,
      (res, status) => {
        this.getting = false;
        if (status) {
          this.pendingTests = res;
          res.forEach((res: any) => {
            this.newEntry(res);
          });
        }
      },
      undefined,
      id
    );
  }

  saveTests(): void {
    this.isSaving = true;
    this.testsService.testAPi(
      "PUT",
      "JobAddTests",
      { tests: this.testEntries.value },
      (res, status) => {
        this.isSaving = false;
        if (status) {
          // this.closeModal();
          this.getJobDetails(this.jobId);
          this.getPendingTests(this.jobId);
          this.updates.emit(true);
        }
      },
      undefined,
      this.jobId
    );
  }

  newEntry(item?: any): void {
    this.testEntries.push(
      new FormGroup({
        testId: new FormControl(item?.id, Validators.required),
        testName: new FormControl(item.name, Validators.required),
        testCat: new FormControl(
          item.testCategoryData?.name,
          Validators.required
        ),
        testResult: new FormControl(null, Validators.required),
        range: new FormControl(
          item.start + "-" + item.end,
          Validators.required
        ),
      })
    );
  }

  customEntryCell(name: string, index: number): FormControl {
    return (this.testEntries.controls[index] as FormGroup)?.get(
      name
    ) as FormControl;
  }
  customCotrol(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl;
  }

  changeClient(): void {
    this.isSaving = true;
    this.jobService.changeJobClient(
      this.jobId,
      this.changeConsignee.value,
      (res, status) => {
        this.isSaving = false;
        if (status) {
          this.getJobDetails(this.jobId);
          this.closeModal();
        }
      }
    );
  }

  getCustomers(): void {
    this.generalService.saveCustomer("GET", undefined, (res, status) => {
      if (status) {
        this.customersList = res.data;
        this.customersSub.next(res.data);
      }
    });
  }
}
