<div class="container">
    <div class="row mx-0 mb-2 mt-2">
        <div class="col-12 d-flex px-0">
            <div>
                <h5 class="font-weight-bold text-muted">All Sessions</h5>
            </div>
            <div class="ml-auto">
                <button hideSubModule [module]="'Session'" [query]="'Create'" class="btn-sm btn btn-primary"
                        (click)="openModal(newSession)">
                    New Session
                </button>
            </div>
        </div>
    </div>
    <div class="row mx-0 mb-2 mt-2">
        <div class="col-12 d-flex px-0">
            <div class="entries">

            </div>
            <div class="ml-auto mr-1">
                <input type="text" placeholder="search" [(ngModel)]="searchFilter" class="form-control form-control-sm">
            </div>
            <div class="entries">
                <select [formControl]="showEntries" class="custom-select custom-select-sm form-control form-control-sm">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                    <option [ngValue]="sessionList.length">Show All</option>
                </select>
            </div>
        </div>
    </div>
    <!-- list -->
    <div class="col-lg-12 p-0" style="overflow-x: auto;">
        <div *ngIf="!loading">
            <div class="table-card shadow-sm" *ngIf="sessionList.length != 0">
                <div class="table-card-body">
                    <div class="table-responsive">
                        <table class="table themed-table themed-table-plain">
                            <thead>
                            <th class="cell">
                                <div class="cell-border">
                                    <span>Session No.</span>
                                </div>
                            </th>
                            <th class="cell">
                                <div class="cell-border">
                                    <span>Open</span>
                                </div>
                            </th>
                            <th class="cell">
                                <div class="cell-border">
                                    <span>Exchange Rate</span>
                                </div>
                            </th>
                            <th class="cell">
                                <div class="cell-border">
                                    <span>Closing Date</span>
                                </div>
                            </th>
                            <th class="cell">
                                <div class="cell-border">
                                    <span class="text-center">Status</span>
                                </div>
                            </th>
                            <th class="cell text-center" style="min-width: 10px;">
                                <div class="cell-border">Action</div>
                            </th>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of chunkedRecords[currentPage]">
                                <td style="cursor:pointer;">
                                    {{ item.sessionNo }}
                                </td>
                                <td> {{ item.openingDate | date }}
                                    by: {{ [item.firstname, item.lastname].join(' ') }}
                                </td>
                                <td class="text-right">
                                    <strong class="text-primary">{{ item.exchangeRate | number }}</strong>
                                </td>
                                <td>{{ item.closingDate | date }}</td>
                                <td>
                                    <app-status [statusType]="item.status"></app-status>
                                </td>
                                <td class="text-center">
                                    <div class="action-dropdown" *ngIf="item.status !== 'CLOSED'">
                                        <button class="btn btn-light btn-sm dropdown-toggle" data-toggle="dropdown">
                                            <em class="fa fa-ellipsis-v m-1"></em>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <button hideSubModule [module]="'Session'" [query]="'Approve'"
                                                    *ngIf="item.status === 'PENDING'" class="dropdown-item text-primary"
                                                    (click)="approveSession(item.id)">
                                                <em class="bi bi-check-circle"></em> Approve
                                            </button>
                                            <button *ngIf="item.status === 'PENDING'" class="dropdown-item text-bg-info"
                                                    (click)="_editSession(editSession,item)">
                                                <em class="bi bi-pencil"></em> Edit
                                            </button>
                                            <button hideSubModule [module]="'Session'" [query]="'Close'"
                                                    *ngIf="item.status !== 'PENDING'  && item.status !== 'CLOSED'"
                                                    class="dropdown-item text-danger" (click)="closeSession(item.id)">
                                                <em class="bi bi-x-circle"></em> Close session
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card h-100" *ngIf="sessionList.length === 0" [hidden]="loading">
                <app-empty-placeholder></app-empty-placeholder>
                <div class="text-center mb-4">
                    <button class="btn btn-primary btn-sm" (click)="getSessions()"><em
                            class="bi bi-arrow-clockwise"></em>
                        Reload
                    </button>
                </div>
            </div>

        </div>

        <div *ngIf="loading" class="text-center">
            <app-loading-placeholder></app-loading-placeholder>
        </div>

        <!-- pagination -->
        <div class="d-flex justify-content-center align-items-center mt-3" *ngIf="sessionList.length != 0">
            <app-paginator
                    [chuckRecords]="sessionList | filter :filterList :searchFilter :false | paginator :elementsPerPage"
                    (onPageChange)="displayActivePage($event)">
            </app-paginator>
        </div>
    </div>

</div>

<!-- new session form -->
<ng-template #newSession>
    <app-modal [loading]="isSaving" [modalActionButton]="saveBtn" [modalTitle]="'New Session'"
               [condition]="newSessionForm.invalid">
        <div class="row mx-0" [formGroup]="newSessionForm">
            <div class="col-6">
                <app-input-date [required]="true" [fieldId]="'openingDate'"
                                [control]="customCotrol('openingDate', newSessionForm)">
                </app-input-date>
            </div>
            <div class="col-6">
                <app-input-figure [required]="true" [fieldId]="'exchangeRage'"
                                  [control]="customCotrol('exchangeRage', newSessionForm)">
                </app-input-figure>
            </div>
        </div>
    </app-modal>
</ng-template>

<!-- edit session form -->
<ng-template #editSession>
    <app-modal [loading]="isSaving" [modalActionButton]="editBtn" [modalTitle]="'Edit Session'"
               [condition]="editSessionForm.invalid">
        <div class="row mx-0" [formGroup]="editSessionForm">
            <div class="col-6">
                <app-input-date [required]="true" [fieldId]="'openingDate'"
                                [control]="customCotrol('openingDate', editSessionForm)">
                </app-input-date>
            </div>
            <div class="col-6">
                <app-input-figure [required]="true" [fieldId]="'exchangeRage'"
                                  [control]="customCotrol('exchangeRage', editSessionForm)">
                </app-input-figure>
            </div>
        </div>
    </app-modal>
</ng-template>