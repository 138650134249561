import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiException } from 'src/app/services/api/exception.interface';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { Ledger } from '../utilities/ledger.interface';

@Injectable({
    providedIn: 'root'
})
export class JournalService {

    constructor(
        private apiConnect: ApiConnectionService,
        private alertService: AlertService
    ) { }

    getPostableLedgers(onGet: (res: Ledger[], status?: boolean) => void): void {
        this.apiConnect.callApi('GET', 'JournalPostableLedger', undefined, undefined, undefined)
            .pipe(map(mapLedgers))
            .subscribe(
                (res: any) => {
                    onGet(res, true);
                },
                (err: any) => {
                    onGet([], true);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message
                    });
                }
            )
    }

    getJournalTypeEnum(onGet: (res: any, status?: boolean) => void): void {
        this.apiConnect.callApi('GET', 'JournalTypeEnums', undefined, undefined, undefined)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                },
                (err: any) => {
                    onGet(undefined, true);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message
                    });
                }
            )
    }

    getJournalStatusEnum(onGet: (res: any, status?: boolean) => void): void {
        this.apiConnect.callApi('GET', 'JournalStatusEnums', undefined, undefined, undefined)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                },
                (err: any) => {
                    onGet(undefined, true);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message
                    });
                }
            )
    }

    getJournals(onGet: (res?: any, status?: boolean) => void, queryParams?: any, id?: number): void {
        this.apiConnect.callApi('GET', 'Journal', undefined, queryParams, id ? id : undefined)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                },
                (err: ApiException) => {
                    onGet(undefined, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message
                    });
                }
            )
    }

    getJournalDetails(onGet: (res?: any, status?: boolean) => void, id: number): void {
        this.apiConnect.callApi('GET', 'Journal', undefined, undefined, id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                },
                (err: ApiException) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message
                    });
                }
            )
    }

    saveJournal(form: any, onSave: (res?: any, status?: boolean) => void): void {
        this.apiConnect.callApi('POST', 'Journal', form, undefined, undefined)
            .subscribe(
                (res: any) => {
                    onSave(res.data, true)
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: ApiException) => {
                    onSave(undefined, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message
                    });
                }
            )
    }

    updateJournal(form: any, id: number, onUpdate: (res?: any, status?: boolean) => void): void {
        this.apiConnect.callApi('PUT', 'Journal', form, undefined, id)
            .subscribe(
                (res: any) => {
                    onUpdate(res.data, true)
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: ApiException) => {
                    onUpdate(undefined, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message
                    });
                }
            )
    }

    journalAction(link: string, id: number, onAction: (res?: any, status?: boolean) => void): void {
        this.apiConnect.callApi('PUT', link, undefined, undefined, id)
            .subscribe(
                (res: any) => {
                    onAction(res.data, true)
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: ApiException) => {
                    onAction(undefined, false);

                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message
                    });
                }
            )
    }

    deleteJournal(id: number, onDelete: (status?: boolean) => void): void {
        this.apiConnect.callApi('DELETE', 'Journal', undefined, undefined, id)
            .subscribe(
                (res: any) => {
                    onDelete(true)
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        message: res.message
                    });
                },
                (err: ApiException) => {
                    onDelete(false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message
                    });
                }
            )
    }

    getCashLedgers(onGet: (res: Ledger[], status?: boolean) => void): void {
        this.apiConnect.callApi('GET', 'JournalPostableCashLedger', undefined, undefined, undefined)
            .pipe(map(mapLedgers))
            .subscribe(
                (res: any) => {
                    onGet(res, true);
                },
                (err: any) => {
                    onGet([], true);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message
                    });
                }
            )
    }
}

export const mapLedgers = (res: any): Ledger[] => {
    console.log(res);
    return res?.data.map((data: any): Ledger => {
        return {
            id: +data.id,
            name: data.name,
            type: data.type,
            code: data.code,
            showOnChart: data.showOnChart,
            description: data.description,
            hasSubLedger: data.hasSubAccounts,
            isSuperLedger: data.isSupaAccount,
            parentLedgerId: data.parentLedger?.id,
            isContra: data.isContra,
            isPostable: data.isPostable,
            isCategory: data.isCategory,
            parentCategory: data?.category?.id
        }
    })
}