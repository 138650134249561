import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';

class BaseClass { }
interface BaseClass extends ModalComponent { }
applyMixins(BaseClass, [ModalComponent]);

@Component({
  selector: 'app-inject-compts',
  templateUrl: './inject-compts.component.html',
  styleUrls: ['./inject-compts.component.scss']
})
export class InjectComptsComponent extends BaseClass implements OnInit {

  @Input() control!: any;
  @Output() compartsList: EventEmitter<any[]> = new EventEmitter();
  comparts: any[] = [];
  cargoEntries: FormArray;

  @Input() fuelTypesList: any;
  @Input() fuelTypesSub!: BehaviorSubject<[]>;
  @Input() fuelTypesPredicate!: object;

  driverBtn!: object;

  constructor() {
    super();
    this.cargoEntries = new FormArray([
      new FormGroup({
        compartmentNumber: new FormControl(null, Validators.required),
        fuelTypeId: new FormControl(null, Validators.required),
        actualQuantityL: new FormControl(null, Validators.required)
      })
    ]);
    this.driverBtn = {
      method: () => { return this.save() },
      text: 'Save'
    };
  }

  ngOnInit(): void {
    if (this.control) {
      for (var i = 0; i < this.control; i++) {
        this.comparts.push({ no: i + 1 });
      }
    }
    this.cargoEntries.clear();
    this.comparts.forEach(vax => {
      this.newEntry(vax)
    })
  }

  save(): void {
    this.compartsList.next(this.cargoEntries.value);
  }

  customCotrol(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl;
  }

  customEntryCell(name: string, index: number): FormControl {
    return (this.cargoEntries.controls[index] as FormGroup)?.get(name) as FormControl;
  }

  newEntry(item?: any): void {
    this.cargoEntries.push(new FormGroup({
      compartmentNumber: new FormControl(item.no, Validators.required),
      fuelTypeId: new FormControl(null, Validators.required),
      actualQuantityL: new FormControl(null, Validators.required)
    }))
  }

}
