import { Injectable } from '@angular/core';
import { PdfViewerComponent } from './pdf-viewer.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Injectable({
    providedIn: 'root',
})
export class PdfViewerService {

    public bsModalRef?: BsModalRef;

    constructor(private modalService: BsModalService) { }

    openPDFModal(
        actionTitle: string,
        pdfSrc: string
    ): void {
        this.bsModalRef = this.modalService.show(PdfViewerComponent, {
            class: 'modal-lg',
            initialState: {
                actionTitle: actionTitle,
                pdfViewable: pdfSrc
            },
            animated: true,
        });
        this.bsModalRef.content.closeBtnName = 'Close';
    }
}
