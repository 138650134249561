import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';

@Component({
  selector: 'app-lock',
  templateUrl: './lock.component.html',
  styleUrls: ['./lock.component.scss']
})
export class LockComponent implements OnInit {

  fieldTextType = false;
  loading = false;
  prevURL: any;
  unlockError: any;
  unlockForm: FormGroup = new FormGroup({
    email: new FormControl(this.session.getLockEmail()),
    password: new FormControl(null, Validators.required)
  });

  constructor(
    private session: SessionStorageService,
    private authService: AuthenticationService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.prevURL = this.session.getLockURL();
  }

  unlock(): void {
    this.loading = true;
    this.authService.apiConnect.callApi('POST', 'Login', this.unlockForm.value)
      .subscribe(
        (res: any) => {
          this.loading = false;
          if (res) {
            this.session.setjwtToken(res.jwt);
            this.session.setUserRoles(res.user.role);
            this.session.setActiveUser(res.user);
            this.session.setActiveUserRole(res.user.role);
            this.session.setActiveBusiness(res.user.business);
            this.session.setLockStatus(null);
            if (this.prevURL !== 'null') {
              console.log(this.prevURL)
              this.router.navigateByUrl(this.prevURL);
            }
            if (this.prevURL === '/auth/lock') {
              this.router.navigate(['app']);
            } else {
              this.authService.logout();
            }
            this.session.setLockURL(null);
          } else {
            this.unlockError = 'Invalid Password Credentials'
          }
        },
        (err: any) => {
          this.unlockError = 'something went wrong'
          this.authService.logout();
        }
      )
  }

  toggleFieldTextType(): void {
    this.fieldTextType = !this.fieldTextType;
  }

  customControl(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl;
  }


}
