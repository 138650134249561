<div class="container">
    <div class="row mx-0 mb-2 mt-2">
        <div class="col-12 d-flex px-0">
            <div>
                <h5 class="font-weight-bold text-muted">Vendors</h5>
            </div>
            <div class="entries mr-2 ml-auto">
                <select [formControl]="showEntries" class="custom-select custom-select-sm form-control form-control-sm">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                    <option [ngValue]="vendorsList.length">Show All</option>
                </select>
            </div>
            <div class="">
                <button class="btn-sm btn btn-primary" (click)="openModal(newVendor);">
                    New Vendor
                </button>
            </div>
        </div>
    </div>

    <!-- table data -->
    <div class="row mx-0">
        <div class="col-12 px-0">
            <div *ngIf="!loading">
                <!-- <div class="d-block"> -->
                <div class="table-card shadow-sm" *ngIf="vendorsList.length != 0">
                    <div class="table-card-body">
                        <div class="table-responsive">
                            <table class="table themed-table themed-table-plain">
                                <thead>
                                    <th class="cell">
                                        <div class="cell-border">
                                            <span>Name</span>
                                        </div>
                                    </th>
                                    <th class="cell">
                                        <div class="cell-border">
                                            <span>DisplayName</span>
                                        </div>
                                    </th>
                                    <th class="cell" style="min-width: 10px;">
                                        <div class="cell-border">
                                            <span>email</span>
                                        </div>
                                    </th>
                                    <th class="cell text-center" style="min-width: 10px;">
                                        <div class="cell-border">
                                            <span>phone</span>
                                        </div>
                                    </th>
                                    <th class="cell" style="min-width: 10px;">
                                        <div class="cell-border">
                                            <span>Address</span>
                                        </div>
                                    </th>
                                    <th class="cell text-center" style="min-width: 10px;">
                                        <div class="cell-border">Action</div>
                                    </th>
                                </thead>
                                <tbody>
                                    <!-- data loader -->
                                    <tr *ngFor="let item of chunkedRecords[currentPage]">
                                        <td>
                                            <span class="font-weight-bolder text-black">{{ item.name }}</span>
                                        </td>
                                        <td>
                                            <span class="font-weight-bolder text-info">{{ item.displayName }}</span>
                                        </td>
                                        <td>
                                            <span class="font-weight-bolder text-info">{{ item.email }}</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="font-weight-bolder text-info">{{ item.phone }}</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="font-weight-bolder text-info">{{ item.address }}</span>
                                        </td>
                                        <!-- <td>
                                        <app-status [statusType]="item.status"></app-status>
                                    </td> -->
                                        <td class="text-center">
                                            <div class="action-dropdown">
                                                <button class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    <em class="fa fa-ellipsis-h m-1"></em>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <button class="dropdown-item"
                                                        (click)="_editVendor(editVendor,item)">
                                                        <em class="bi bi-pencil-square text-primary mr-2"></em> Edit
                                                    </button>
                                                    <button class="dropdown-item" (click)="deleteVendor(item.id)">
                                                        <em class="bi bi-trash text-danger mr-2"></em> Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="text-center" *ngIf="loading">
                                        <td colspan="6">
                                            <app-loading-placeholder></app-loading-placeholder>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div *ngIf="vendorsList.length === 0" [hidden]="loading">
                    <app-empty-placeholder></app-empty-placeholder>
                </div>
            </div>

            <div *ngIf="loading" class="text-center">
                <app-loading-placeholder></app-loading-placeholder>
            </div>

            <!-- pagination -->
            <div class="d-flex justify-content-center align-items-center mt-3" *ngIf="vendorsList.length != 0">
                <app-paginator
                    [chuckRecords]="vendorsList | filter :filterList :searchFilter :false | paginator :elementsPerPage"
                    (onPageChange)="displayActivePage($event)">
                </app-paginator>
            </div>
        </div>
    </div>

</div>

<!-- new marker form -->
<ng-template #newVendor>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="newBtn" [modalTitle]="'New Vendor'"
        [condition]="vendorForm.invalid">
        <div class="row mx-0">
            <div class="col-12">
                <app-input [required]="true" [fieldId]="'name'" [control]="customCotrol('name', vendorForm)">
                </app-input>
                <app-input [required]="true" [fieldId]="'displayName'" [control]="customCotrol('displayName', vendorForm)">
                </app-input>
            </div>
            <div class="col-6">
                <app-input [capitalType]="false" [fieldId]="'email'" [control]="customCotrol('email', vendorForm)">
                </app-input>
            </div>
            <div class="col-6">
                <app-input [fieldId]="'phoneNo'" [control]="customCotrol('phone', vendorForm)">
                </app-input>
            </div>
            <div class="col-12">
                <app-input [fieldId]="'address'" [control]="customCotrol('address', vendorForm)">
                </app-input>
            </div>
        </div>
    </app-modal>
</ng-template>

<!-- new marker form -->
<ng-template #editVendor>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="editBtn" [modalTitle]="'Edit Vendor'"
        [condition]="editVendorForm.invalid">
        <div class="row mx-0">
            <div class="col-12">
                <app-input [required]="true" [fieldId]="'name'" [control]="customCotrol('name', editVendorForm)">
                </app-input>
                <app-input [required]="true" [fieldId]="'displayName'" [control]="customCotrol('displayName', editVendorForm)">
                </app-input>
            </div>
            <div class="col-6">
                <app-input [fieldId]="'email'" [control]="customCotrol('email', editVendorForm)">
                </app-input>
            </div>
            <div class="col-6">
                <app-input [fieldId]="'phone'" [control]="customCotrol('phone', editVendorForm)">
                </app-input>
            </div>
            <div class="col-12">
                <app-input [fieldId]="'address'" [control]="customCotrol('address', editVendorForm)">
                </app-input>
            </div>
        </div>
    </app-modal>
</ng-template>