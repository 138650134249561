import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { ExportXlsxComponent } from 'src/app/components/export-xlsx/export-xlsx.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ASSETSROOT } from 'src/app/services/api/api-endpoints';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

class BaseClass { }
interface BaseClass extends ModalComponent, ExportXlsxComponent { }
applyMixins(BaseClass, [ModalComponent, ExportXlsxComponent]);

@Component({
    selector: 'app-stock-item-report',
    templateUrl: './stock-item-report.component.html',
    styleUrls: ['./stock-item-report.component.scss']
})
export class StockItemReportComponent extends BaseClass implements OnInit {

    reportsList: Array<any> = [];
    posList: Array<any> = [];
    posListSubject: BehaviorSubject<[]> = new BehaviorSubject([]);
    posPredicate: any;

    currentFilter: any = {};

    reportForm = new FormGroup({
        salesPosId: new FormControl(null),
    });

    exporting = false;
    showFilter = false;
    assetsRoot = ASSETSROOT;
    loading = false;
    userType: any;
    pdfReport: any;

    totalSales = 0;

    constructor(
        public modalService: BsModalService,
        public apiConnect: ApiConnectionService,
        public authService: AuthenticationService,
    ) {
        super();
        this.posPredicate = { method1: (obj: any) => (obj.id), method2: (obj: any) => (obj.name) };
    }

    ngOnInit(): void {
        this.getReports();
    }

    getReports(): void {
        this.loading = true;
        this.apiConnect.secureConnect('GET', 'StockItemReport', undefined, this.currentFilter, undefined, this.onGetReports.bind(this));
    }

    onGetReports(res: any, status: string, callback: any): void {
        this.loading = false;
        if (status === 'success') {
            this.pdfReport = res;
        } else {
            callback('danger', 'Error', res.message.error);
        }
    }

    exportExcelReport(): void {
        this.exporting = true;
        if (this.pdfReport) {
            const blobFile = this.convertBase64xlsxToBlob(this.pdfReport.xls.data);
            this.exportXlsx(blobFile, 'Stock-Item-Report.xlsx');
        }
        this.exporting = false;
    }


}
