import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor() { }

  formatDate(date: Date): Date {
    return date
  }

  formatStringToDate(date: string): Date {
    var dateParts = date.split("/");
    return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
  }

  formatDateToSlashes(date: Date): string {
    const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join('/');
  }

  formatString4DatePicker(dateStr: string): string {
    if (dateStr) {
      const yyy = dateStr.slice(0, 4);
      const mm = dateStr.slice(5, 7);
      const dd = dateStr.slice(8, 10);
      return [dd, mm, yyy].join('/')
    } else {
      return dateStr
    }
  }

  formatToLocalTime(hours: number, minutes: any): string {
    let h = hours;
    let m = minutes;
    if (hours > 12) {
      h = hours - 12;
    }

    const h1 = (h < 10) ? ('0' + h) : h;

    if (typeof minutes === 'number') {
      m = (minutes < 10) ? ('0' + minutes) : minutes;
    }
    const ampm = hours < 12 ? ' AM' : ' PM';
    const time = h1 + ':' + m + ampm;
    return time;
  }

  formatToLocalTime24(hours: number, minutes: any): string {
    const h = hours;
    let m = minutes;
    const h1 = (h < 10) ? ('0' + h) : h;

    if (typeof minutes === 'number') {
      m = (minutes < 10) ? ('0' + minutes) : minutes;
    }

    const time = h1 + ':' + m;
    return time;
  }

  getCurrentDayDate(): Date {
    return this.formatDate(new Date());
  }

  getCurrentWeekDates(): { first: any; last: any } {
    const currentDate = Object.assign(new Date());
    const firstDay = currentDate.getDate() - currentDate.getDay();
    const lastDay = firstDay + 6;

    return Object.assign({
      first: this.formatDate(new Date(currentDate.setDate(firstDay))),
      last: this.formatDate(new Date(currentDate.setDate(lastDay))),
    });
  }

  getCurrentMonthDates(): { first: string; last: string } {
    const currentDate = new Date();
    const firstDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    return { first: this.formatDateToSlashes(this.formatDate(firstDay)), last: this.formatDateToSlashes(this.formatDate(lastDay)) };
  }

  getCurrentYearDates(): { first: any; last: any } {
    const currentDate = new Date();
    const firstDay = new Date(currentDate.getFullYear(), 0, 1);
    const lastDay = new Date(currentDate.getFullYear(), 12, 0);
    return { first: this.formatDate(firstDay), last: this.formatDate(lastDay) };
  }

  getDayOfWeek(date: Date): any {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[date.getDay()];
  }

  dateIsValid(dateString: { toString: () => string; }): boolean {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (dateString.toString().match(regEx)) {
      return true;
    }
    return false;
  }

  getHour(value: number): number {
    const hours = Math.floor(value / 60);
    return hours;
  }

  getMinute(value: number): number {
    const minutes = Math.floor(value % 60);
    return minutes;
  }

  getCurrentTime(): string {
    const hours = new Date().getHours()-12;
    const minutes = new Date().getMinutes().toString().length > 1 ? new Date().getMinutes() : ([0, new Date().getMinutes()].join(''));
    const sec = new Date().getSeconds();
    return this.convertTime24To12([hours, minutes, sec].join(':'));
  }

  convertTime24To12(time: any): any {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      // time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }

    return (time.join('').split(":")[0].length > 1 ? time.join('') : ([0, time.join('')].join(''))).slice(0, 5);
  }

  transformMinutesToHours(value: number): string {
    const hours = Math.floor(value / 60);
    const minutes = Math.floor(value % 60);
    return `${hours} hrs ${minutes} min`;
  }
}
