<div class="d-flex align-items-center justify-content-center h-100vh" style="background: #237d4b; height: 100%;">
  <div class="form-wrapper m-auto">
    <div class="form-container my-auto">
      <div class="row d-flex bg-white m-auto" style="border-radius: 10px;">

        <div class="col-lg-7 col-md-7 p-0 flex-fill">
          <div class="panel d-flex align-items-baseline flex-column h-100 bg"
            style="border: none; box-shadow: none; border-top-right-radius: 0px; border-bottom-right-radius: 0px;">
            <div class="cust">
              <div class="dix row d-flex ">
                <div class="col">
                  <a href="index.html">
                    <!-- <img class="" src="assets/images/pos-logo-meals.png" alt="logo"> -->
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-5 col-md-5 p-0 flex-fill">
          <div class="panel h-100" style="border: none; box-shadow: none;">
            <div class="text-center mb-3">
              <a class="app-logo" href="index.html">
                <!-- <img class="logo-icon mr-2" src="../../../../assets/images/logo.png" alt="logo" style="width: 100px;"> -->
              </a>
            </div>
            <h4 class="auth-heading text-primary text-center text-uppercase mb-2 font-weight-bold"
              style="font-size: 18px;">
              Login
            </h4>
            <div class="auth-form-container text-left">
              <form class="auth-form login-form" [formGroup]="loginForm">

                <div class="email mb-3">
                  <app-input [fieldType]="'email'" [capitalType]="false" [fieldId]="'username'" [control]="username" [placeHolder]="'Enter user name'"></app-input>
                </div>

                <div class="password mb-3">
                  <app-input [capitalType]="false" [fieldId]="'password'" [fieldType]="'Password'" [control]="password"
                    [placeHolder]="'Enter password'"></app-input>
                  <div class="extra mt-3 row justify-content-between">
                    <div class="col-6">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" [formControl]="rememberMe"
                          id="RememberPassword">
                        <label class="form-check-label" for="RememberPassword">
                          Remember me
                        </label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="forgot-password text-right">
                        <a href="" [routerLink]="['/auth/recover']">Forgot password?</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-center">
                  <button [disabled]="loginForm.invalid" (click)="submit(loginForm.value)" type="submit"
                    class="btn app-btn-primary btn-block theme-btn mx-auto">
                    <span *ngIf="loadingStatus" class="spinner-border text-light" role="status">
                    </span>
                    <span *ngIf="!loadingStatus">Log In</span>
                  </button>
                </div>

                <div *ngIf="showError" class="alert alert-danger mt-2 p-1 text-center" role="alert">
                  <em class="fa fa-exclamation-circle text-danger mr-2"></em>
                  <span>{{loginError}}</span>
                  <img src="alert.close" style="display:none;"
                    onerror="(function(el){ setTimeout(function(){ el.parentNode.parentNode.removeChild(el.parentNode); },4000 ); })(this);"
                    alt="Error image" />
                </div>
                
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>