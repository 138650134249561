import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ExportXlsxComponent } from 'src/app/components/export-xlsx/export-xlsx.component';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { GeneralService } from '../../settings/general-settings/general.service';

class BaseClass { }
interface BaseClass extends ModalComponent, PaginatorComponent, FilterComponent, ExportXlsxComponent { }
applyMixins(BaseClass, [ModalComponent, PaginatorComponent, FilterComponent, ExportXlsxComponent]);


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent extends BaseClass implements OnInit {

  currentPage = 0;
  elementsPerPage = 10;
  showEntries: FormControl = new FormControl(10);
  chunkedRecords: Array<any> = [];
  searchFilter: string = '';
  customersList: Array<any> = [];

  customerForm!: FormGroup;
  newBtn!: object;

  editCustomerForm!: FormGroup;
  editBtn!: object;

  isSaving = false;
  loading = false;
  exporting = false;

  constructor(
    public modalService: BsModalService,
    public apiConnect: ApiConnectionService,
    public router: Router,
    public generalService: GeneralService
  ) {
    super();
    this.customerForm = new FormGroup({
      companyName: new FormControl(null, Validators.required),
      companyTIN: new FormControl(null),
      contact: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      canClearWithNoPayment: new FormControl(false, Validators.required)
    });
    this.newBtn = {
      method: () => { return this.saveCustomer(this.customerForm.value); },
      text: 'Save'
    };
    this.editBtn = {
      method: () => { return this.updateCustomer(this.editCustomerForm.value); },
      text: 'Update'
    };
  }

  ngOnInit(): void {
    this.getCustomers();
  }

  getCustomers(): void {
    this.loading = true;
    this.generalService.getDetailedCustomer('GET', undefined, (res, status) => {
      this.loading = false
      if (status) {
        this.customersList = res.data;
      }
    });
  }

  saveCustomer(form: any): void {
    this.isSaving = true;
    this.generalService.saveCustomer('POST', form, (res, status) => {
      this.isSaving = false;
      this.closeModal();
      if (status) {
        this.getCustomers();
        this.customerForm.reset();
        this.customerForm.get("canClearWithNoPayment")?.setValue(false);
      }
    });
  }

  _editCustomer(template: TemplateRef<any>, item: any): void {
    this.editCustomerForm = new FormGroup({
      id: new FormControl(item.id),
      name: new FormControl(item.companyName, Validators.required),
      companyName: new FormControl(item?.companyName),
      companyTIN: new FormControl(item?.companyTIN),
      contact: new FormControl(item?.contact, Validators.required),
      email: new FormControl(item?.email),
      canClearWithNoPayment: new FormControl(item?.canClearWithNoPayment, Validators.required)
    });
    this.modalService.show(template);
  }

  updateCustomer(form: any): void {
    this.isSaving = true;
    this.generalService.saveCustomer('PUT', form, (res, status) => {
      this.isSaving = false;
      this.closeModal();
      if (status) {
        this.getCustomers();
        this.editCustomerForm.reset();
      }
    }, undefined, form.id);
  }

  deleteCustomer(id: number): void {
    this.generalService.saveCustomer('DELETE', undefined, (res, status) => {
      if (status) {
        this.getCustomers();
      }
    }, undefined, id);
  }


  exportExcelReport(): void {
    this.exporting = true;
    this.generalService.getCustomerXlsx((res, status) => {
      this.exporting = false;
      if (status) {
        const blobFile = this.convertBase64xlsxToBlob(res.data.data);
        this.exportXlsx(blobFile, 'Clients.xlsx');
      }
    });
  }

  displayActivePage(chunckedData: any): void {
    setTimeout(() => {
      this.chunkedRecords = chunckedData.chunks;
      if (chunckedData.controlClicked) {
        this.currentPage = chunckedData.currentPage;
      }
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ['name'];
    }
  }

  customCotrol(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl
  }

}
