
import { Injectable } from '@angular/core';
import { AlertService } from '../../../services/alert/alert.service';
import { ApiConnectionService } from '../../../services/api/apiconnection.service';

@Injectable({
    providedIn: 'root'
})
export class TestsService {

    constructor(
        private apiConnect: ApiConnectionService,
        private alertService: AlertService,
    ) {
    }

    testAPi(link: string, endPoint: string, form: any, onSave: (res?: any, status?: boolean) => void, queryParam?: any, id?: any): void {
        this.apiConnect.callApi(link, endPoint, form, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res.data, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }
}
