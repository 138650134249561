<div class="modal-body">
    <div class="d-flex flex-column justify-content-center align-items-center">
        <span class="alert-icon" [ngClass]="alertBorder[type]">
            <em [ngClass]="[alertIcons[type], alertText[type]]"></em>
        </span>
        <p class="mt-3">{{ message }}</p>
    </div>

    <div class="d-flex justify-content-end w-100 mt-4">
        <button (click)="close()" class="btn btn-secondary btn-sm ms-auto">
            Cancel
        </button>
        <button [disabled]="alertPopupService.getLoading() | async" (click)="actionBtn()"
            class="btn btn-primary btn-sm ms-2">
            <span *ngIf="alertPopupService.getLoading() | async" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            {{
            (alertPopupService.getLoading() | async) ? "loading..." : actionTitle
            }}
        </button>
    </div>
</div>