import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { SessionStorageService } from '../session/session-storage.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[disable]",
})
export class DisableIfNotGrantedDirective implements OnInit {
  constructor(
    public session: SessionStorageService, private el: ElementRef,
    private renderer: Renderer2
  ) { }

  @Input() query!: string;
  @Input() module!: string;

  roles: any[] = [];

  ngOnInit(): void {
    this.roles = this.session.getUserExcess();
    this.check();
  }

  check(): void {
    if (this.query && this.module) {
      this.roles.forEach((role) => {
        if (role.section === this.module) {
          role.children.forEach((child: any) => {
            if (child.section === this.query) {
              if (!child.canAccess) {
                 this.renderer.addClass(this.el.nativeElement, "menu-disabled");
                // this.el.nativeElement.style.display = "menu-disabled";
              }
            }
          });
        }
      });
    }
  }
}

