import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
declare var $: any;

interface Alert {
  type: string;
  title: string;
  message: string;
  icon: string;
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  constructor(public alertService: AlertService) { }

  alertArray: Array<any> = [];

  alertIcons: any = {
    success: 'fas fa-check',
    danger: 'fas fa-ban',
    info: 'fas fa-info',
    warning: 'fas fa-exclamation-triangle'
  };

  alertBackground: any = {
    success: 'alertbg-success',
    danger: 'alertbg-danger',
    info: 'alertbg-primary',
    warning: 'alertbg-secondary'
  };

  ngOnInit(): void {
    this.alertService.alertObservable.subscribe((alert: Alert) => {
      if (!$.isEmptyObject(alert)) {
        this.alertArray.push(alert);
        setTimeout(() => {
          $('.toast').toast({ animation: true, autohide: true, delay: 5000 });
          $('.toast').toast('show').on('hidden.bs.toast', () => {
            this.alertArray = [];
          });
        });
      }
    });
  }
}
