import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { GeneralService } from '../general.service';

class BaseClass { }
interface BaseClass extends ModalComponent, PaginatorComponent, FilterComponent { }
applyMixins(BaseClass, [ModalComponent, PaginatorComponent, FilterComponent]);


@Component({
  selector: 'app-marker-items',
  templateUrl: './marker-items.component.html',
  styleUrls: ['./marker-items.component.scss']
})
export class MarkerItemsComponent extends BaseClass implements OnInit {

  currentPage = 0;
  elementsPerPage = 10;
  showEntries: FormControl = new FormControl(10);
  chunkedRecords: Array<any> = [];
  searchFilter: string = '';
  markersList: Array<any> = [];

  units: any[] = ['Litre (L)','Metric Litre (ML)']

  markerForm!: FormGroup;
  newBtn!: object;

  editMarkerForm!: FormGroup;
  editBtn!: object;

  isSaving = false;
  loading = false;

  constructor(
    public modalService: BsModalService,
    public apiConnect: ApiConnectionService,
    public router: Router,
    public generalService: GeneralService,
  ) {
    super();
    this.markerForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      reorderLevel: new FormControl(null, Validators.required),
      code: new FormControl(null, Validators.required),
      unit: new FormControl(null, Validators.required),
    });
    this.newBtn = {
      method: () => { return this.saveMarker(this.markerForm.value); },
      text: 'Save'
    };
    this.editBtn = {
      method: () => { return this.updateMarker(this.editMarkerForm.value); },
      text: 'Update'
    };
  }

  ngOnInit(): void {
    this.getMarkers();
  }

  getMarkers(): void {
    this.loading = true;
    this.generalService.saveMarkerItem('GET', undefined, (res, status) => {
      this.loading = false
      if (status) {
        this.markersList = res.data;
        console.log(res.data);
      }
    });
  }

  saveMarker(form: any): void {
    this.isSaving = true;
    this.generalService.saveMarkerItem('POST', form, (res, status) => {
      this.isSaving = false;
      this.closeModal();
      if (status) {
        this.markerForm.reset();
        this.getMarkers();
      }
    });
  }

  _editMarker(template: TemplateRef<any>, item: any): void {
    this.editMarkerForm = new FormGroup({
      id: new FormControl(item.id),
      name: new FormControl(item.name, Validators.required),
      reorderLevel: new FormControl(item.reOrderLevel, Validators.required),
      code: new FormControl(item.code, Validators.required),
      unit: new FormControl(item.unit, Validators.required),
    });
    this.modalService.show(template);
  }

  updateMarker(form: any): void {
    this.isSaving = true;
    this.generalService.saveMarkerItem('PUT', form, (res, status) => {
      this.isSaving = false;
      this.closeModal();
      if (status) {
        this.getMarkers();
        this.editMarkerForm.reset();
      }
    }, undefined, form.id);
  }

  deleteMarker(id: number): void {
    this.generalService.saveMarkerItem('DELETE', undefined, (res, status) => {
      if (status) {
        this.getMarkers();
      }
    }, undefined, id);
  }


  displayActivePage(chunckedData: any): void {
    setTimeout(() => {
      this.chunkedRecords = chunckedData.chunks;
      if (chunckedData.controlClicked) {
        this.currentPage = chunckedData.currentPage;
      }
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ['name'];
    }
  }

  customCotrol(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl
  }

}
