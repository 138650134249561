import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-print-btn',
    templateUrl: './print-btn.component.html',
    styleUrls: ['./print-btn.component.scss']
})
export class PrintbtnComponent implements OnInit {

    @Input() printSectionId!: string;
    @Input() printTitle!: string;
    @Input() printStyle: any;
    @Input() useExistingCss = false;
    @Input() styleSheetFile = '';

    constructor() { }

    ngOnInit(): void { }
}
