<div class="container-xl">
    <div class="row mx-0">
        <h1 class="app-page-title text-muted">Completed Jobs Report</h1>
        <div class="ml-auto">
            <button (click)="showFilter =! showFilter" class="btn btn-secondary btn-sm mr-2">
                <em [ngClass]="{'d-none': !showFilter}" class="fa fa-filter"></em>
                <em [ngClass]="{'d-none': showFilter}" class="fa fa-times-circle"></em>
            </button>
        </div>
    </div>
    <div class="row">
        <!-- filter search -->
        <div class="col-12 mb-2" [ngClass]="{'d-none': showFilter}">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-8 col-sm-8">
                            <form action="" [formGroup]="reportForm">
                                <div class="form-row align-items-center">
                                    <div class="col-6">
                                        <app-input-date [mb0]="'mb-0'" [isFilterVar]="true" [singleDate]="false"
                                                        [fieldId]="'dateRage'" [control]="filterdateRange">
                                        </app-input-date>
                                    </div>
                                </div>
                            </form>
                            <div class="d-flex flex-row justify-content-between mt-2" *ngIf="pdfReport">
                                <div class="d-flex flex-column">
                                    <span>Total Amount</span>
                                    <strong class="text-primary">{{ pdfReport?.totalAmount |number }}</strong>
                                </div>
                                <div class="d-flex flex-column">
                                    <span>Total Paid</span>
                                    <strong class="text-primary">{{ pdfReport?.totalPaid |number }}</strong>
                                </div>
                                <div class="d-flex flex-column">
                                    <span>Total Balance</span>
                                    <strong class="text-danger">{{ pdfReport?.totalBalance |number }}</strong>
                                </div>
                                <div class="d-flex flex-column">
                                    <span>Total Jobs</span>
                                    <strong>{{ pdfReport?.totalJobs }}</strong>
                                </div>
                                <div class="d-flex flex-column">
                                    <span>Exempted</span>
                                    <strong>{{ pdfReport?.exempted }}</strong>
                                </div>
                                <div class="d-flex flex-column">
                                    <span>Non-Exempted</span>
                                    <strong>{{ pdfReport?.non_exempted }}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 ml-auto text-right">
                            <button [disabled]="reportForm.invalid" class="btn btn-info btn-sm mb-2"
                                    (click)="getReports()">Load
                            </button>
                            <p *ngIf="pdfReport?.jobs.length>0" style="cursor: pointer;">
                                <span (click)="getReportsXls()">
                                    <em class="fa fa-file-excel mr-2"></em>Export Excel
                                </span>
                                <span *ngIf="exporting"><em
                                        class="fa fa-spinner fa-spin text-success fs-29"></em></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- filter search -->

        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div *ngIf="loading" class=" d-flex justify-content-center ">
                        <div>
                            <span class="spinner-border ml-3" role="status">
                            </span>
                            <p class="text-center">Loading...</p>
                        </div>
                    </div>
                    <div *ngIf="!loading">
                        <table class="table">
                            <thead class="thead-dark">
                            <tr>
                                <th scope="col">Job No.</th>
                                <th scope="col">Vehicle No.</th>
                                <th scope="col">Client</th>
                                <th scope="col">Amount($)</th>
                                <th scope="col">Paid($)</th>
                                <th scope="col">Bal($)</th>
                                <th scope="col">Volume</th>
                                <th scope="col">Fuel Type</th>
                                <th scope="col">Exempted</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of pdfReport?.jobs">
                                <th>{{ item.jobNumber }}</th>
                                <th> {{ item.vehicleNo }}</th>
                                <th>{{ item.client }}</th>
                                <th class="text-dark font-weight-bold text-right">{{ item.amount | number }}</th>
                                <th class="text-primary font-weight-bold text-right">{{ item.paid | number }}</th>
                                <th class="text-danger font-weight-bold text-right">{{ item.balance | number }}</th>
                                <th> {{ item.volume | number }}</th>
                                <th> {{ item.fuelType }}</th>
                                <td class="text-center">
                                        <span>
                                            <em *ngIf="item.exempted =='true'"
                                                class="bi bi-check-circle-fill text-primary"></em>
                                            <em *ngIf="item.exempted=='false'"
                                                class="bi bi-x-circle-fill text-danger"></em>
                                        </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>