import { Injectable } from '@angular/core';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';
import { RedirectionManagerService } from 'src/app/services/permissions/redirection-manager/redirection-manager.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert/alert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    public apiConnect: ApiConnectionService,
    public sessionStorageService: SessionStorageService,
    public redirect: RedirectionManagerService,
    private router: Router,
    public alertService: AlertService
  ) { }

  login(loginData: any, callback: any): void {
    this.apiConnect.secureConnect('POST', 'Login', loginData, undefined, undefined, callback.bind(this));
  }

  logout(): void {
    this.alertService.alertObservable.next(null);
    sessionStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  forgetMe(): void {
    this.alertService.alertObservable.next(null);
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  rememberMe(credentails: any): void {
    this.sessionStorageService.setUserCredentials(credentails);
  }

  selectRole(roleId: number, callback: any): void {
    this.apiConnect.secureConnect('POST', 'ActiveRole', { activeRoleId: roleId }, undefined, undefined, callback.bind(this));
  }

  onSelectRole(res: any, status: string, callback: any): void {
    if (status === 'SUCCESS') {
      this.sessionStorageService.setActiveUserRole(res.data);
    }
  }

  recoverPassword(recoverData: any, callback: any): void {
    this.apiConnect.secureConnect(recoverData, 'RecoverPassword', callback.bind(this));
  }
}
