import { Component, OnInit, DoCheck } from '@angular/core';
import { formLabels } from '../../labels/form-labels';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { ValidationMessages } from 'src/app/constants/form-labels/validation-messages';

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.scss']
})
export class BaseFormComponent implements OnInit, DoCheck {

  label!: string;
  fieldId!: string | null;
  control!: FormControl;
  validationErrors!: any;

  constructor() { }

  ngOnInit(): void {
    if (this.fieldId) {
      this.label = formLabels[this.fieldId] ? formLabels[this.fieldId] : '';
    } else {
      this.label = '';
    }
  }

  ngDoCheck(): void {
    if (this.control) {
      this.validationErrors = this.control.touched && this.control.invalid ? this.control.errors : null;
    }
  }

  setFormLabel(): string {
    return this.fieldId && formLabels[this.fieldId] ? formLabels[this.fieldId] : '';
  }

  requiredValidator(): boolean {
    if (this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      return (validator && validator['required']);
    } else {
      return false;
    }
  }

  validationError(errors: ValidationErrors | null): any {
    for (let error in errors) {
      return ValidationMessages[error];
    }
    return null;
  }
}
