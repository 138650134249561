
import { Injectable } from '@angular/core';
import { AlertService } from '../../../../app/services/alert/alert.service';
import { ApiConnectionService } from '../../../../app/services/api/apiconnection.service';

@Injectable({
    providedIn: 'root'
})
export class WorkSpaceService {

    constructor(
        private apiConnect: ApiConnectionService,
        private alertService: AlertService,
    ) {
    }

    saveBusiness(link: string, form: any, onSave: (res?: any, status?: boolean) => void, id?: any): void {
        this.apiConnect.callApi(link, 'Business', form, undefined, id)
            .subscribe(
                (res: any) => {
                    onSave(res.data, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    console.log(err)
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message:   err.message.error.error,
                    });
                }
            );
    }

    saveSite(link: string, form: any, onSave: (res?: any, status?: boolean) => void): void {
        this.apiConnect.callApi(link, 'Site', form, undefined, undefined)
            .subscribe(
                (res: any) => {
                    onSave(res.data, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message:   err.message.error.error,
                    });
                }
            );
    }

    getTimeZones(onGet: (res?: any, status?: boolean) => void, queryParams?: any, id?: number): void {
        this.apiConnect.callApi('GET', 'TimeZone', undefined, queryParams, id ? id : undefined)
            .subscribe(
                (res: any) => {
                    onGet(res, true);
                },
                (err: any) => {
                    onGet(err, false);
                    console.log('errrrooorr  ',err)
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message:   err.message.error.error,
                    });
                }
            )
    }

    getCurrencies(onGet: (res?: any, status?: boolean) => void, queryParams?: any, id?: number): void {
        this.apiConnect.callApi('GET', 'Currency', undefined, queryParams, id ? id : undefined)
            .subscribe(
                (res: any) => {
                    onGet(res, true);
                },
                (err: any) => {
                    onGet(err, false);
                    console.log(err)
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message:   err.message.error.error,
                    });
                }
            )
    }

    getBusiness(link: string, form: any, onSave: (res?: any, status?: boolean) => void, id?: any): void {
        this.apiConnect.callApi(link, 'BusinessList', form, undefined, id)
            .subscribe(
                (res: any) => {
                    onSave(res.data, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message:   err.message.error.error,
                    });
                }
            );
    }

    getUserSites(onSave: (res?: any, status?: boolean) => void, id?: any): void {
        this.apiConnect.callApi('GET', 'UserSites', undefined, undefined, id)
            .subscribe(
                (res: any) => {
                    onSave(res.data, true);
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message:   err.message.error.error,
                    });
                }
            );
    }

}