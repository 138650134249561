<div class="container-xl">
  <div class="row mx-0">
    <h1 class="app-page-title text-muted">My Account</h1>
    <div class="ml-auto">

    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <div class="card mb-3 text-center">
        <div class="text-center mt-2">
          <h6 class="mr-1">
            <span class="badge badge-secondary">
              <span class="text-white">{{ activeUser.role }}</span>
            </span>
          </h6>
          <a href="JavaScript:void(0);" class="avatarBig ml-4">
            <img [src]="userPhoto === undefined ? 'assets/images/person.png' : assetsroot + userPhoto" alt="">
          </a>
          <div class="pencil-status-icon" (click)="openModal(updatePhoto)"><i class="fa fa-pencil-alt text-warning"></i>
          </div>
        </div>
        <h6 class="mt-3 mb-0 text-bold">{{ activeUser.name }}</h6>
        <small class="text-primary mb-2">{{ activeUser.email }}</small>
      </div>

      <div class="card mb-4 ">
        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <!-- <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab"
            aria-controls="v-pills-home" aria-selected="true">
            <div class="d-flex justify-content-between align-items-center ">
              <i class="fa fa-comments"></i>
              Notifications
              <span><i class="fas fa-angle-right mx-2 fs-18"></i></span></div>
          </a> -->
          <a class="nav-link active" id="v-pills-details-tab" data-toggle="pill" href="#v-pills-details" role="tab"
            aria-controls="v-pills-details" aria-selected="false">
            <div class="d-flex justify-content-between align-items-center ">
              <i class="fa fa-user-circle"></i>
              Account Details
              <span><i class="fas fa-angle-right mx-2 fs-18"></i></span></div>
          </a>
          <!-- <a class="nav-link" id="v-pills-password-tab" data-toggle="pill" href="#v-pills-password" role="tab"
            aria-controls="v-pills-password" aria-selected="false">
            <div class="d-flex justify-content-between align-items-center ">
              <i class="fa fa-unlock-alt"></i>
              Change Password
              <span><i class="fas fa-angle-right mx-2 fs-18"></i></span></div>
          </a> -->
        </div>
      </div>
    </div>

    <div class="col-8 mb-2">
      <div class="card">
        <div class="card-body">
          <div class="row-12">
            <div class="tab-content" id="v-pills-tabContent">
              <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                aria-labelledby="v-pills-home-tab">
                <div class=" mb-4">
                  <form action="" [formGroup]="updatePasswordForm">
                    <app-input [capitalType]="false" [fieldType]="'Password'" [fieldId]="'oldPassword'" [control]="oldpass">
                    </app-input>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <app-input [capitalType]="false" [fieldType]="'Password'" [fieldId]="'newPassword'" [control]="newpass">
                        </app-input>
                        <small class="text-warning" *ngIf="newpass.errors?.minlength">
                          Password must be at least 4 characters long.
                        </small>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <app-input [capitalType]="false" [fieldType]="'Password'" [fieldId]="'confirmPassword'" [control]="confirmpass">
                        </app-input>
                        <small class="text-danger" *ngIf="match">
                          Password does not match
                        </small>
                      </div>
                    </div>
                  </form>
                  <div class="d-flex justify-content-end">
                    <button [disabled]="updatePasswordForm.invalid" class="btn btn-sm btn-primary"
                      (click)="changePassword()">
                      <span *ngIf="saving" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                      {{saving ? 'Loading...' : 'Reset'}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #updatePhoto>
  <app-modal [size]="'medium'" [loading]="saving" [modalTitle]="'Update Photo'" [modalActionButton]="updatePhotoBtn">
    <form [formGroup]="updateUserPhotoForm">
      <app-image-cropper [cropperWidth]="200" [cropperHeight]="200" [canvasHeight]="220" [fieldId]="'image'"
        [control]="photo">
      </app-image-cropper>
    </form>
  </app-modal>
</ng-template>
