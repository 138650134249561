
import { Injectable } from '@angular/core';
import { AlertService } from '../../../../app/services/alert/alert.service'
import { ApiConnectionService } from '../../../../app/services/api/apiconnection.service'

@Injectable({
    providedIn: 'root'
})
export class ReportUtilityService {

    constructor(
        private apiConnect: ApiConnectionService,
        private alertService: AlertService,
    ) {
    }

    saveReportUtility(link: string, form: any, onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi(link, 'ReportUtility', form, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message,
                    });
                }
            );
    }

    updateReportUtility(form: any, onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi('PUT', 'UpdateReportUtility', form, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message,
                    });
                }
            );
    }

    getReports(onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi('GET', 'ReportUtilityReports', undefined, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message,
                    });
                }
            );
    }

    getFreqs(onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi('GET', 'ReportUtilityFreq', undefined, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message,
                    });
                }
            );
    }

    addFreq(form: any, onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi('PUT', 'ReportCreateFreq', form, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message,
                    });
                }
            );
    }

}