export function clearFixForm(entries: Array<any>): any {
  console.log(entries);
  let lastIndex = -1;
  entries.forEach((entry, index) => {
    if (entries[index].inventoryItemId === null) {
      entries.splice(index, 1);
    }
  });

  if (lastIndex > -1) {
    entries.splice(lastIndex, 1);
  }
  return entries;
}

export function clearFixForm2(entries: Array<any>): any {
  console.log(entries);
  let lastIndex = -1;
  entries.forEach((entry, index) => {
    if (entries[index].itemId === null) {
      entries.splice(index, 1);
    }
  });

  if (lastIndex > -1) {
    entries.splice(lastIndex, 1);
  }
  return entries;
}