<div class="modal-header">
  <h4 class="modal-title pull-left">{{ actionTitle }}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container">
    <!-- body here ... -->
    <div class="pdf-file" *ngIf="pdfViewable !== undefined">
      <ngx-extended-pdf-viewer [base64Src]="pdfViewable" height="80vh" [useBrowserLocale]="true">
      </ngx-extended-pdf-viewer>
    </div>
  </div>
</div>