export const colors = [
  '#d97f3d', '#0da74d', '#f25f4c', '#e2c636', '#d22d3d', '#898989', '#fe8a7d',
  '#2c323f', '#FF69B4', '#FFA500', '#FAFAD2', '#E6E6FA', '#663399', '#00FFFF'
];

export const DATAETRY = ['NEW', 'DIPPED'];
export const CASHIER = ['PENDING_PAYMENT', 'PAID', 'PAYMENT_VERIFIED'];
export const LAB = ['PAYMENT_VERIFIED', 'LABARATORY_PROCESSING'];
export const ARCHIVE = ['COMPLETED'];
export const ALLSTATUS = ['NEW', 'DIPPED', 'ACKNOWLEDGED', 'PAID', 'PENDING_PAYMENT',
  'PAYMENT_VERIFIED', 'LABARATORY_PROCESSING', 'COMPLETED']

export const Currency = ['USD', 'SSP'];