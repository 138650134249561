import {Injectable} from '@angular/core';
import {AlertService} from '../../../services/alert/alert.service';
import {ApiConnectionService} from '../../../services/api/apiconnection.service';

@Injectable({
    providedIn: 'root'
})
export class GeneralService {

    constructor(
        private apiConnect: ApiConnectionService,
        private alertService: AlertService,
    ) {
    }

    saveMarkerItem(link: string, form: any, onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi(link, 'MarkerItem', form, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    saveMarker(link: string, form: any, onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi(link, 'Marker', form, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    saveLab(link: string, form: any, onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi(link, 'Lab', form, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    saveFuelType(link: string, form: any, onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi(link, 'FuelType', form, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }


    saveCustomer(link: string, form: any, onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi(link, 'Customers', form, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    getDetailedCustomer(link: string, form: any, onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi(link, 'CustomersList', form, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    getCustomerXlsx(onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi('GET', 'CustomersListXls', undefined, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }


    getClientDetails(onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi('GET', 'CustomerDetails', undefined, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }


    saveDriver(link: string, form: any, onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi(link, 'Drivers', form, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    saveVendor(link: string, form: any, onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi(link, 'Vendor', form, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    getNationality(onGet: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi('GET', 'Nationality', undefined, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onGet(res, true);
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }


}
