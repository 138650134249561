import { HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorageService } from '../session/session-storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private session: SessionStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    let authReq = req;

    const token = this.session.getjwtToken();
    var site: any = this.session.getActiveSite();
    var business: any = this.session.getActiveBusiness();

    if (token !== null) {
      authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token),
      });
    } else {
      console.log('no token');
    }

    if (site !== null && site !== undefined) {
      authReq = authReq.clone({
        headers: authReq.headers.set('siteId', site.id.toString()),
      });
    } 

     if (business !== null && business !== undefined) {
       authReq = authReq.clone({
         headers: authReq.headers.set("businessId", business.id.toString()),
       });
     } 
    return next.handle(authReq);
  }
}

export const jwtInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
];
