import { AfterViewInit, Directive, Input } from '@angular/core';
import { Instance, createPopper } from '@popperjs/core';

@Directive({
  selector: '[app-popper]',
})
export class PopperDirective implements AfterViewInit {
  @Input()
  targetElement!: string;
  @Input()
  popperElement!: string;
  @Input() popperType: string = 'toolTip';
  @Input() popperOffset: any[] = [];
  @Input() showEvents: any[] = ['mouseenter', 'focus'];
  @Input() hideEvents: any[] = ['mouseleave', 'blur'];
  @Input() placement:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end'
    | 'auto'
    | 'auto-start'
    | 'auto-end' = 'auto';

  target!: any;
  popper!: any;
  popperInstance!: Instance;

  constructor() { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.target = document.querySelector(`#${this.targetElement}`);
      this.popper = document.querySelector(`#${this.popperElement}`);

      if (this.target && this.popper)
        this.popperInstance = createPopper(this.target, this.popper, {
          placement: this.placement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: this.popperOffset,
              },
            },
            {
              name: 'preventOverflow',
              options: {
                mainAxis: false,
              },
            },
          ],
        });

      this.showEvents?.forEach((event) => {
        this.target?.addEventListener(event, this.show.bind(this));
      });

      if (this.popperType === 'toolTip') {
        this.hideEvents?.forEach((event) => {
          this.target?.addEventListener(event, this.hide.bind(this));
        });
      } else {
        window.addEventListener('click', (event: any) => {
          if (
            !this.target?.contains(event.target) &&
            !this.popper?.contains(event.target)
          ) {
            this.hide();
          }
        });
      }
    });
  }

  show(): void {
    this.popper.setAttribute('data-show', '');
    this.popperInstance.setOptions({
      ...this.popperInstance.state.options,
      modifiers: [
        ...this.popperInstance.state.options.modifiers,
        { name: 'eventListeners', enabled: true },
      ],
    });
    this.popperInstance.update();
  }

  hide(): void {
    this.popper.removeAttribute('data-show');
    this.popperInstance.setOptions({
      ...this.popperInstance.state.options,
      modifiers: [
        ...this.popperInstance.state.options.modifiers,
        { name: 'eventListeners', enabled: false },
      ],
    });
  }

  @Input()
  close(): void {
    this.hide()
  }
}
