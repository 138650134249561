import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EMAIL_PATTERN } from 'src/app/components/validations-pattern/list';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  recoverForm!: FormGroup;

  recovered = false;

  loginError!: string;
  loginsucc!: string;
  loadingStatus = false;
  showError = false;

  constructor(
    public location: Location,
    public router: Router,
    public route: ActivatedRoute,
    public apiConnect: ApiConnectionService) {

    this.recoverForm = new FormGroup({
      email: new FormControl(null, Validators.required)
    });

  }

  ngOnInit(): void { }

  recover(): void {
    this.loadingStatus = true;
    this.apiConnect.secureConnect('PUT', 'RecoverPassword', undefined, undefined, this.Email.value, this.onRecover.bind(this));
  }

  onRecover(res: any, status: string, callback: any): void {
    this.loadingStatus = false;
    if (status === 'success') {
      this.recovered = true;
      this.loginsucc = res.message
      setTimeout(() => { this.router.navigate(['auth/login']) }, 4000)
    } else {
      this.showError = true;
      this.loginError = res.message.error;
      callback('danger', 'Error', res.message.error);
    }
  }

  get Email(): FormControl {
    return this.recoverForm.get('email') as FormControl
  }

  back(): void{
    this.location.back();
  }
}
