import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { GeneralService } from '../../settings/general-settings/general.service';

class BaseClass { }
interface BaseClass extends ModalComponent, PaginatorComponent, FilterComponent { }
applyMixins(BaseClass, [ModalComponent, PaginatorComponent, FilterComponent]);

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})
export class DriversComponent extends BaseClass implements OnInit {
  identity: any[] = ['Passport', 'National ID', "Driver's Licence"];
  nationality: any[] = []
  currentPage = 0;
  elementsPerPage = 10;
  showEntries: FormControl = new FormControl(10);
  chunkedRecords: Array<any> = [];
  searchFilter: string = '';
  driversList: Array<any> = [];

  driverForm!: FormGroup;
  newBtn!: object;

  editDriverForm!: FormGroup;
  editBtn!: object;

  isSaving = false;
  loading = false;

  constructor(
    public modalService: BsModalService,
    public apiConnect: ApiConnectionService,
    public router: Router,
    public generalService: GeneralService
  ) {
    super();
    this.driverForm = new FormGroup({
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      othername: new FormControl(null),
      nationality: new FormControl(null, Validators.required),
      typeOfIdentification: new FormControl(null, Validators.required),
      identificationNumber: new FormControl(null, Validators.required),
      code: new FormControl(null, Validators.required),
      phoneNumber: new FormControl(null, Validators.required),
    });
    this.newBtn = {
      method: () => { return this.saveDriver(this.driverForm.value); },
      text: 'Save'
    };
    this.editBtn = {
      method: () => { return this.updateDriver(this.editDriverForm.value); },
      text: 'Update'
    };
  }

  ngOnInit(): void {
    this.getDrivers();
    this.getNationality();
    this.customCotrol('nationality', this.driverForm).valueChanges.subscribe(val => {
      this._findCode(val, this.driverForm);
    });
  }

  _findCode(val: any, form: FormGroup) {
    this.nationality.forEach(vax => {
      if (vax.nationality === val) {
        this.customCotrol('code', form).patchValue(vax.code);
      }
    })
  }

  getDrivers(): void {
    this.loading = true;
    this.generalService.saveDriver('GET', undefined, (res, status) => {
      this.loading = false
      if (status) {
        this.driversList = res.data;
        console.log(res.data)
      }
    });
  }

  saveDriver(form: any): void {
    this.isSaving = true;
    this.generalService.saveDriver('POST', form, (res, status) => {
      this.isSaving = false;
      this.closeModal();
      if (status) {
        this.getDrivers();
        this.driverForm.reset();
      }
    });
  }

  _editDriver(template: TemplateRef<any>, item: any): void {
    this.editDriverForm = new FormGroup({
      id: new FormControl(item.id),
      firstname: new FormControl(item.firstname, Validators.required),
      lastname: new FormControl(item.lastname, Validators.required),
      othername: new FormControl(item.othername),
      nationality: new FormControl(item.nationality, Validators.required),
      typeOfIdentification: new FormControl(item.typeOfIdentification, Validators.required),
      identificationNumber: new FormControl(item.identificationNumber, Validators.required),
      code: new FormControl(item.phoneNumber.substring(0, 3), Validators.required),
      phoneNumber: new FormControl(item.phoneNumber.substring(3), Validators.required),
    });
    this.customCotrol('nationality', this.editDriverForm).valueChanges.subscribe(val => {
      this._findCode(val, this.editDriverForm);
    });
    this.modalService.show(template);
  }

  updateDriver(form: any): void {
    this.isSaving = true;
    this.generalService.saveDriver('PUT',form, (res, status) => {
      this.isSaving = false;
      this.closeModal();
      if (status) {
        this.getDrivers();
        this.editDriverForm.reset();
      }
    }, undefined, form.id);
  }

  deleteDriver(id: number): void {
    this.generalService.saveDriver('DELETE', undefined, (res, status) => {
      if (status) {
        this.getDrivers();
      }
    }, undefined, id);
  }

  getNationality(): void {
    this.generalService.getNationality((res, status) => {
      if (status) {
        this.nationality = res.data;
      }
    });
  }

  displayActivePage(chunckedData: any): void {
    setTimeout(() => {
      this.chunkedRecords = chunckedData.chunks;
      if (chunckedData.controlClicked) {
        this.currentPage = chunckedData.currentPage;
      }
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ['name'];
    }
  }

  customCotrol(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl
  }

}
