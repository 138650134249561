import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ribbon',
  templateUrl: './ribbon.component.html',
  styleUrls: ['./ribbon.component.scss']
})
export class RibbonComponent implements OnInit {

  /*
   * Ribbon style types include
   * success
   * danger
   * warning
   * primary
   * light
   */

  label = '';
  type!: string;
  tooltip = '';

  /*
   * Pass value to appear on the ribbon
   */
  @Input()
  set ribbonLabel(value: string) {
    this.label = value;
  }

  get ribbonLabel(): string {
    return this.label;
  }

  /*
   * Pass style name only
   */
  @Input()
  set ribbonType(value: string) {
      this.type = value;
  }

  get ribbonType(): string {
    return this.type;
  }

  /*
   * Use to pass style after evaluating conditional statements
   */
  @Input()
  set conditionClass(value: any) {
    for (let i in value) {
      if (value[i] === true) {
        this.type = i;
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
