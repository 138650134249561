import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { GeneralService } from 'src/app/pages/settings/general-settings/general.service';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';
import { JobService } from '../../job.service';
import { ROLES } from 'src/app/constants/roles';

class BaseClass { }
interface BaseClass extends ModalComponent { }
applyMixins(BaseClass, [ModalComponent]);

@Component({
  selector: "app-cargo",
  templateUrl: "./cargo.component.html",
  styleUrls: ["./cargo.component.scss"],
})
export class CargoComponent extends BaseClass implements OnInit {
  @Output() updates = new EventEmitter<boolean>();
  Comp = ROLES.Compartment;
  Job = ROLES.Job;
  role: any;
  jobId!: number;
  jobDetails: any;
  cargoList: any[] = [];
  fuelTypesList: any[] = [];
  fuelTypesSub: BehaviorSubject<[]> = new BehaviorSubject([]);
  fuelTypesPredicate!: object;

  markersList: any[] = [];
  markerSub: BehaviorSubject<[]> = new BehaviorSubject([]);
  markerPredicate: object;

  dippingForm!: FormGroup;
  newBtn!: object;

  editDippingForm!: FormGroup;
  editBtn!: object;

  cargoForm!: FormGroup;
  cargobtn!: object;

  cargo: any;
  loading = false;
  expand = false;
  isSaving = false;
  lab = false;

  constructor(
    private route: ActivatedRoute,
    private jobService: JobService,
    private generalService: GeneralService,
    public modalService: BsModalService,
    private session: SessionStorageService
  ) {
    super();

    this.dippingForm = new FormGroup({
      oldSeal: new FormControl(null, Validators.required),
      newSeal: new FormControl(null, Validators.required),
      observedVolumeAt20C: new FormControl(null, Validators.required),
      observedDensityAt20C: new FormControl(null, Validators.required),
      compartmentId: new FormControl(null, Validators.required),
    });

    this.newBtn = {
      method: () => {
        return this.saveDipping(this.dippingForm.value);
      },
      text: "Save",
    };
    this.editBtn = {
      method: () => {
        return this.updateDipping(this.editDippingForm.value);
      },
      text: "Update",
    };
    this.cargobtn = {
      method: () => {
        return this.updateCompt(this.cargoForm.value);
      },
      text: "Update",
    };
    this.markerPredicate = {
      method1: (obj: any) => obj.id,
      method2: (obj: any) => obj.name,
    };
    this.fuelTypesPredicate = {
      method1: (obj: any) => obj.id,
      method2: (obj: any) => obj.name,
    };
  }

  ngOnInit(): void {
    this.route.params.subscribe((val) => {
      if (val.id) {
        this.jobId = val.id;
        this.getJobDetails(val.id);
        this.getFuelTypes();
      }
    });
    this.role = this.session.getActiveUserRole().name;
    if (this.role === "Lab Officer") {
      this.lab = true;
    } else {
      this.lab = false;
    }
  }

  getJobDetails(id: any): void {
    this.loading = true;
    this.jobService.saveJob(
      "GET",
      undefined,
      (res: any, status: any) => {
        this.loading = false;
        if (status) {
          this.jobDetails = res.data;
          const newArr1 = res.data.compartment.map((v: any) => ({
            ...v,
            expand: true,
          }));
          this.cargoList = newArr1;
        }
      },
      undefined,
      id
    );
  }

  _dippComp(template: TemplateRef<any>, item: any): void {
    this.cargo = item;
    this.customCotrol("compartmentId", this.dippingForm).setValue(item.id);
    this.modalService.show(template);
  }

  saveDipping(form: any): void {
    this.isSaving = true;
    this.jobService.saveDipping("POST", form, (res, status) => {
      this.isSaving = false;
      this.closeModal();
      if (status) {
        this.getJobDetails(this.jobId);
        this.updates.emit(true);
        this.dippingForm.reset();
      }
    });
  }

  updateCompt(form: any): any {
    this.isSaving = true;
    this.jobService.saveCargo(
      "PUT",
      form,
      (res, status) => {
        this.isSaving = false;
        this.closeModal();
        if (status) {
          this.getJobDetails(this.jobId);
          this.updates.emit(true);
          this.cargoForm.reset();
        }
      },
      undefined,
      form.id
    );
  }

  _editCargo(template: TemplateRef<any>, item: any): void {
    this.cargoForm = new FormGroup({
      id: new FormControl(item.id, Validators.required),
      compartmentNumber: new FormControl(item?.compartmentNumber),
      actualQuantityL: new FormControl(item?.actualQuantityL),
      actualQuantityL20: new FormControl(item?.actualQuantityL20),
      densityAt20: new FormControl(item?.densityAt20),
      temperature: new FormControl(item?.temperature),
      fuelTypeId: new FormControl(item?.fuelType.id),
      oldSeal: new FormControl(item?.dipping?.oldSeal),
      newSeal: new FormControl(item?.dipping?.newSeal),
      finalBoilingPoint: new FormControl(item?.dipping?.finalBoilingPoint),
      flashPoint: new FormControl(item?.dipping?.flashPoint),
      observedVolumeAt20C: new FormControl(item?.dipping?.observedVolumeAt20C),
      observedDensityAt20C: new FormControl(
        item?.dipping?.observedDensityAt20C
      ),
    });
    this.modalService.show(template);
  }

  _editDipping(template: TemplateRef<any>, item: any, cargoId: number): void {
    this.editDippingForm = new FormGroup({
      id: new FormControl(item.id),
      oldSeal: new FormControl(item.oldSeal.toUpperCase(), Validators.required),
      newSeal: new FormControl(item.newSeal.toUpperCase(), Validators.required),
      observedVolumeAt20C: new FormControl(
        item.observedVolumeAt20C,
        Validators.required
      ),
      observedDensityAt20C: new FormControl(
        item.observedDensityAt20C,
        Validators.required
      ),
      compartmentId: new FormControl(cargoId, Validators.required),
    });
    this.modalService.show(template);
  }

  updateDipping(form: any): void {
    this.isSaving = true;
    this.jobService.saveDipping(
      "PUT",
      form,
      (res, status) => {
        this.isSaving = false;
        this.closeModal();
        if (status) {
          this.getJobDetails(this.jobId);
          this.updates.emit(true);
          this.editDippingForm.reset();
        }
      },
      undefined,
      form.id
    );
  }

  deleteDipping(id: number): void {
    this.jobService.saveDipping(
      "DELETE",
      undefined,
      (res, status) => {
        if (status) {
          this.getJobDetails(this.jobId);
          this.updates.emit(true);
        }
      },
      undefined,
      id
    );
  }

  getMarkers(): void {
    this.generalService.saveMarker("GET", undefined, (res, status) => {
      if (status) {
        this.markersList = res.data;
        this.markerSub.next(res.data);
      }
    });
  }

  expandCargo(cargo: any): void {
    cargo = this.cargoList.find((a: any) => a.id === cargo.id);
    cargo.expand = !cargo.expand;
  }

  getFuelTypes(): void {
    this.generalService.saveFuelType("GET", undefined, (res, status) => {
      if (status) {
        this.fuelTypesList = res.data;
        this.fuelTypesSub.next(res.data);
      }
    });
  }

  _findTotals(typeId: any): any {
    let aL = 0;
    let temp = 0;
    let aL20 = 0;
    let aL20Obs = 0;
    let aL20Var = 0;
    let d20 = 0;
    let d20Obs = 0;
    let d20Var = 0;
    let total = 0;
    this.cargoList.forEach((tt) => {
      aL = aL + tt.actualQuantityL;
      temp = temp + tt.temperature;
      aL20 = aL20 + tt.actualQuantityL20;
      aL20Obs = aL20Obs + tt.dipping?.observedVolumeAt20C;
      aL20Var = aL20Var + tt.dipping?.variationInVolumeAt20C;
      d20 = d20 + tt.densityAt20;
      d20Obs = d20Obs + tt.dipping?.observedDensityAt20C;
      d20Var = d20Var + tt.dipping?.variationInDensityAt20C;
    });
    switch (typeId) {
      case "aL":
        total = aL;
        break;
      case "temp":
        total = temp;
        break;
      case "aL20":
        total = aL20;
        break;
      case "aL20Obs":
        total = aL20Obs;
        break;
      case "aL20Var":
        total = aL20Var;
        break;
      case "d20":
        total = d20;
        break;
      case "d20Obs":
        total = d20Obs;
        break;
      case "d20Var":
        total = d20Var;
        break;
      default:
        break;
    }
    return total;
  }

  customCotrol(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl;
  }
}
