import { Injectable } from '@angular/core';
import { SessionStorageService } from '../../session/session-storage.service';
import { Categories, CategoriesItems } from './category';

@Injectable({
    providedIn: 'root'
})
export class CategoryAuthService {
    role: any;

    constructor(private session: SessionStorageService) { }

    authCategory(category: string): boolean {
        let allowed = false;
        this.role = this.session.getActiveUserRole();
        if (this.role === 'SUPER_ADMIN' || this.role === 'ARXCESS') {
            return true;
        }

        if (category) {
            allowed = this.hasAccess(this.role, category);
        }

        return allowed;
    }

    authCatItem(item: string, category: string): boolean {
        let allowed = false;
        this.role = this.session.getActiveUserRole();
        if (this.role === 'SUPER_ADMIN' || this.role === 'ARXCESS') {
            return true;
        }
        if (item && category) {
            allowed = this.hasAccessToItem(category, item, this.role);
        }

        return allowed;
    }

    hasAccess(role: string, cat: string): boolean {
        const foundCategory = Categories.find((c) => c.cat === cat);
        if (foundCategory) {
            return foundCategory.role.includes(role);
        }
        return false;
    }

    hasAccessToItem(cat: string, item: string, role: string): boolean {
        const foundCategory = CategoriesItems.find((c) => c.cat === cat);
        if (foundCategory) {
            const foundItem = foundCategory.items.find((i) => i.item === item);
            if (foundItem) {
                return foundItem.role.includes(role);
            }
        }
        return false;
    }
}
