import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AlertComponent } from './../components/alerts/alert.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AdminHeaderComponent } from './admin-layout/header/admin-header.component';
import { SlidePanelComponent } from './../components/slidepanel/slide-panel.component';
import { AuthDirectiveModule } from '../services/directives/auth.directive.module';

@NgModule({
  declarations: [
    AlertComponent,
    AdminLayoutComponent,
    AdminHeaderComponent,
    SlidePanelComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AuthDirectiveModule
  ],
  exports: [],
  providers: [],
})
export class LayoutModule { }
