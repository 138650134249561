<div class="container">
    <div class="row mx-0 mb-2 mt-2">
        <div class="col-12 d-flex px-0">
            <div>
                <h5 class="font-weight-bold text-muted">Drivers</h5>
            </div>
            <div class="entries mr-2 ml-auto">
                <select [formControl]="showEntries" class="custom-select custom-select-sm form-control form-control-sm">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                    <option [ngValue]="driversList.length">Show All</option>
                </select>
            </div>
            <div class="">
                <button class="btn-sm btn btn-primary" (click)="openModal(newDriver)">
                    New Driver
                </button>
            </div>
        </div>
    </div>

    <!-- table data -->
    <div class="row mx-0">
        <div class="col-12 px-0">
            <div class="table-card shadow-sm" *ngIf="driversList.length != 0">
                <div class="table-card-body">
                    <div class="table-responsive">
                        <table class="table themed-table themed-table-plain">
                            <thead>
                                <tr>
                                    <th class="cell">
                                        <div class="cell-border">
                                            <span>Name</span>
                                        </div>
                                    </th>
                                    <th class="cell">
                                        <div class="cell-border">
                                            <span>Contact</span>
                                        </div>
                                    </th>
                                    <th class="cell">
                                        <div class="cell-border">
                                            <span>Nationality</span>
                                        </div>
                                    </th>
                                    <th class="cell">
                                        <div class="cell-border">
                                            <span>ID Type</span>
                                        </div>
                                    </th>
                                    <th class="cell">
                                        <div class="cell-border">
                                            <span>ID No.</span>
                                        </div>
                                    </th>
                                    <th class="cell">
                                        <div class="cell-border justify-content-center">
                                            <span>Status</span>
                                        </div>
                                    </th>
                                    <th class="cell text-center">
                                        <div class="cell-border">
                                            <span>Action</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- data loader -->
                                <tr *ngFor="let item of chunkedRecords[currentPage]">
                                    <td>
                                        <span class="font-weight-bolder text-black">
                                            {{[item.firstname,item.lastname,item.othername].join(' ')}}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="font-weight-bolder text-info">{{item.phoneNumber}} </span>
                                    </td>
                                    <td>
                                        <span class="font-weight-bolder text-info">{{item.nationality}} </span>
                                    </td>
                                    <td>
                                        <span class="font-weight-bolder text-info">{{item.typeOfIdentification}} </span>
                                    </td>
                                    <td>
                                        <span class="font-weight-bolder text-info">{{item.identificationNumber}} </span>
                                    </td>
                                    <td class="text-center">
                                        <app-status [statusType]="item.status"></app-status>
                                    </td>
                                    <td class="text-center">
                                        <div class="action-dropdown" *ngIf="item.status !== 'DELETED'">
                                            <button class="btn btn-light btn-sm dropdown-toggle" data-toggle="dropdown">
                                                <em class="fa fa-ellipsis-v m-1"></em>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <button class="dropdown-item" (click)="_editDriver(editDriver,item)">
                                                    <em class="bi bi-pencil-square text-primary mr-2"></em> Edit
                                                </button>
                                                <button class="dropdown-item" (click)="deleteDriver(item.id)">
                                                    <em class="bi bi-trash text-danger mr-2"></em> Delete
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="driversList.length === 0" [hidden]="loading">
                        <app-empty-placeholder></app-empty-placeholder>
                    </div>
                </div>
            </div>
            <div *ngIf="loading" class="text-center">
                <app-loading-placeholder></app-loading-placeholder>
            </div>
            <!-- pagination -->
            <div class="d-flex justify-content-center align-items-center mt-3" *ngIf="driversList.length != 0">
                <app-paginator
                    [chuckRecords]="driversList | filter :filterList :searchFilter :false | paginator :elementsPerPage"
                    (onPageChange)="displayActivePage($event)">
                </app-paginator>
            </div>
        </div>
    </div>

    <!-- new driver form -->
    <ng-template #newDriver>
        <app-modal [size]="'long'" [loading]="isSaving" [modalActionButton]="newBtn" [modalTitle]="'New Driver'"
            [condition]="driverForm.invalid">
            <div class="row mx-0" [formGroup]="driverForm">
                <div class="col-6">
                    <app-input [required]="true" [fieldId]="'firstName'"
                        [control]="customCotrol('firstname', driverForm)">
                    </app-input>
                </div>
                <div class="col-6">
                    <app-input [required]="true" [fieldId]="'lastName'"
                        [control]="customCotrol('lastname', driverForm)">
                    </app-input>
                </div>
                <div class="col-6">
                    <app-input [fieldId]="'otherName'" [control]="customCotrol('othername', driverForm)">
                    </app-input>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="" for="">Nationality <span class="text-danger">*</span></label>
                        <select class="form-control custom-select-sm"
                            [formControl]="customCotrol('nationality', driverForm)">
                            <option [ngValue]="name.nationality" *ngFor="let name of nationality">
                                {{ name.nationality }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="" for="">ID Type <span class="text-danger">*</span></label>
                        <select class="form-control custom-select-sm"
                            [formControl]="customCotrol('typeOfIdentification', driverForm)">
                            <option [ngValue]="name" *ngFor="let name of identity">
                                {{ name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <label>{{customCotrol('typeOfIdentification', driverForm)?.value}} No. <span
                            class="text-danger">*</span></label>
                    <div class="form-group mb-3">
                        <input type="string" class="form-control form-control-sm" maxlength="9"
                            [formControl]="customCotrol('identificationNumber',driverForm)">
                    </div>
                </div>
                <div class="col-6">
                    <app-input-phone [fieldId]="'phoneNo'" [code]="customCotrol('code',driverForm)"
                        [control]="customCotrol('phoneNumber',driverForm)"></app-input-phone>
                </div>
            </div>
        </app-modal>
    </ng-template>

    <!-- edit lab form -->
    <ng-template #editDriver>
        <app-modal [size]="'long'" [loading]="isSaving" [modalActionButton]="editBtn"
            [modalTitle]="'Edit Driver Details'" [condition]="editDriverForm.invalid">
            <div class="row mx-0" [formGroup]="editDriverForm">
                <div class="col-6">
                    <app-input [required]="true" [fieldId]="'firstName'"
                        [control]="customCotrol('firstname', editDriverForm)">
                    </app-input>
                </div>
                <div class="col-6">
                    <app-input [required]="true" [fieldId]="'lastName'"
                        [control]="customCotrol('lastname', editDriverForm)">
                    </app-input>
                </div>
                <div class="col-6">
                    <app-input [fieldId]="'otherName'" [control]="customCotrol('othername', editDriverForm)">
                    </app-input>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="" for="">Nationality <span class="text-danger">*</span></label>
                        <select class="form-control custom-select-sm"
                            [formControl]="customCotrol('nationality', editDriverForm)">
                            <option [ngValue]="name.nationality" *ngFor="let name of nationality">
                                {{ name.nationality }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="" for="">ID Type <span class="text-danger">*</span></label>
                        <select class="form-control custom-select-sm"
                            [formControl]="customCotrol('typeOfIdentification', editDriverForm)">
                            <option [ngValue]="name" *ngFor="let name of identity">
                                {{ name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <label>{{customCotrol('typeOfIdentification', editDriverForm)?.value}} No. <span
                            class="text-danger">*</span></label>
                    <div class="form-group mb-3">
                        <input type="string" class="form-control form-control-sm" maxlength="9"
                            [formControl]="customCotrol('identificationNumber',editDriverForm)">
                    </div>
                </div>
                <div class="col-6">
                    <app-input-phone [fieldId]="'phoneNo'" [code]="customCotrol('code',editDriverForm)"
                        [control]="customCotrol('phoneNumber',editDriverForm)"></app-input-phone>
                </div>
            </div>
        </app-modal>
    </ng-template>