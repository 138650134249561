<div class="container">
  <div class="d-flex justify-content-between align-items-center m-3">
    <span><strong>Settings / Roles & Permissions</strong></span>
    <div>
      <button type="button" class="btn btn-sm btn-primary" (click)="openModal(newrolemodal)">
        <i class="bi bi-plus"></i> New Role
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <div class="card">
        <div *ngIf="!loadingRoles" class="card-body">
          <div class="roles-menu">
            <span><strong>Roles</strong></span>
            <ul class="mt-10">
              <li *ngFor="let role of rolesList" class="active" [ngClass]="{ active: activeRoleId === role.id }">
                <a href="JavaScript:void(0);">
                  <span class="role-status" [ngClass]="{
                      'bg-success': role.status === 'ACTIVE',
                      'bg-danger': role.status === 'DE-ACTIVE'
                    }"></span>
                  <span class="text-uppercase" (click)="getRoleDetails(role.id)">{{ role.name }}</span>
                  <span class="role-action" [hidden]="!role.id">
                    <span [hidden]="role.name === 'Super Administrator'" class="action-circle large action-dropdown"
                      data-toggle="dropdown" aria-expanded="false">
                      <em class="fas fa-ellipsis-v text-primary"></em>
                    </span>

                    <span [hidden]="role.name !== 'Super Administrator'" class="action-circle large">
                      <em class="fas fa-lock" style="color: #95661b"></em>
                    </span>

                    <!-- dropdown menu -->
                    <div [hidden]="role.name === 'Super Administrator'" (click)="$event.stopPropagation()"
                      class="dropdown-menu" style="min-width: 250px">
                      <div class="dropdown-body">
                        <label class="dropdown-header d-flex justify-content-between" for="">
                          <span>Actions</span>
                        </label>
                        <div (click)="_editRole(editrolemodal, role)" class="dropdown-item">
                          <em class="fas fa-pencil-alt text-primary mr-2"></em>
                          Edit
                        </div>
                        <div (click)="deleteRole(role.id)" class="dropdown-item">
                          <em class="fas fa-ban text-danger text-ban mr-2"></em>
                          Delete
                        </div>
                      </div>
                    </div>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="loadingRoles">
          <app-loading-placeholder></app-loading-placeholder>
        </div>
      </div>
    </div>

    <div class="col-md-8" *ngIf="!loadingRoles" [ngClass]="{ 'de-active': activeRole.status === 'DE-ACTIVE' }">
      <div class="card" *ngIf="!loadingPerminsions">
        <div class="card-header">
          <span><strong>Permissions</strong></span>
        </div>
        <div class="card-body">
          <div class="accordion" id="rolesAccordion">
            <div class="card" *ngFor="let p of activeRole.categories; let i = index">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                    [attr.data-target]="'#collapseOne' + i" aria-expanded="false"
                    [attr.aria-controls]="'collapseOne' + i">
                    <div [attr.id]="'checkbox' + i">
                      <input class="form-check-input" type="checkbox" value="" (click)="updateAccess(p.id!)" />
                      <input class="form-check-input" type="checkbox" value="" (click)="updateAccess(p.id!)" checked
                        *ngIf="p.access" />
                    </div>
                    {{ p.name }}
                  </button>
                </h2>
              </div>

              <div *ngIf="p.access" [attr.id]="'collapseOne' + i" class="collapse" aria-labelledby="headingOne"
                data-parent="#rolesAccordion">
                <div class="card-body mt-0 mb-0 pb-1 pt-1">
                  <div class="accordion" id="rolesAccordion1">
                    <div class="card" *ngFor="let q of p.items; let k = index">
                      <div class="card-header" id="headingTwo">
                        <h2 class="mb-0">
                          <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                            [attr.data-target]="'#collapseTwo' + k" aria-expanded="false"
                            [attr.aria-controls]="'collapseTwo' + k">
                            <div [attr.id]="'checkbox' + i">
                              <input class="form-check-input" type="checkbox" value=""
                                (click)="updateAccessCategory(q.id!)" />
                              <input class="form-check-input" type="checkbox" value=""
                                (click)="updateAccessCategory(q.id!)" checked *ngIf="q.access" />
                            </div>
                            <em><strong>{{ q.name }}</strong></em>
                          </button>
                        </h2>
                      </div>
                      <div *ngIf="q.access" [attr.id]="'collapseTwo' + k" class="collapse" aria-labelledby="headingTwo"
                        data-parent="#rolesAccordion1">
                        <div class="row mx-4 my-2 p-2">
                          <div class="mb-1 col-md-3" *ngFor="let r of q.privileges; let o = index">
                            <div [attr.id]="'checkbox' + i">
                              <input class="form-check-input" type="checkbox" value=""
                                (click)="updateAccessCategoryItem(r.id!)" />
                              <input class="form-check-input" type="checkbox" value=""
                                (click)="updateAccessCategoryItem(r.id!)" checked *ngIf="r.access" />
                            </div>
                            {{ r.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="loadingPerminsions" class="card h-100 justify-content-center">
        <app-loading-placeholder></app-loading-placeholder>
      </div>
    </div>
  </div>
</div>


<!-- new role form -->
<ng-template #newrolemodal>
  <app-modal [loading]="saving" [modalActionButton]="saveRoleBtn" [modalTitle]="'New Role'"
    [condition]="newRoleForm.invalid">
    <form action="" [formGroup]="newRoleForm">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <app-input [required]="true" [fieldId]="'name'" [control]="rolename">
          </app-input>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <app-input-textarea [rows]="2" [fieldId]="'description'" [control]="roledescription">
          </app-input-textarea>
        </div>
      </div>
    </form>
  </app-modal>
</ng-template>
<!-- new role form -->

<!-- edit role form -->
<ng-template #editrolemodal>
  <app-modal [loading]="saving" [modalActionButton]="editRoleBtn" [modalTitle]="'Edit Role'"
    [condition]="editRoleForm.invalid">
    <form action="" [formGroup]="editRoleForm">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <app-input [required]="true" [fieldId]="'name'" [control]="editrolename">
          </app-input>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <app-input-textarea [rows]="2" [fieldId]="'description'" [control]="editroledescription">
          </app-input-textarea>
        </div>
      </div>
    </form>
  </app-modal>
</ng-template>
<!-- edit role form -->