<div class="container">
    <div class="row mx-0 mb-2 mt-2">
        <div class="col-12 d-flex px-0">
            <div>
                <h5 class="font-weight-bold text-muted">Clients</h5>
            </div>
            <div class="mr-2 ml-auto">
                <input type="text" placeholder="search" [(ngModel)]="searchFilter" class="form-control form-control-sm">
            </div>
            <div class="entries mr-2">
                <select [formControl]="showEntries" class="custom-select custom-select-sm form-control form-control-sm">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                    <option [ngValue]="customersList.length">Show All</option>
                </select>
            </div>
            <div class="">
                <button class="btn-sm btn btn-primary" (click)="openModal(newCustomer)">
                    New Client
                </button>
                <button class="btn-sm btn btn-success ml-2" (click)="exportExcelReport()">
                    <em *ngIf="!exporting" class="bi bi-filetype-xls"></em>
                    <em *ngIf="exporting" class="fa fa-spinner fa-spin"></em>
                </button>
            </div>
        </div>
    </div>

    <!-- table data -->
    <div class="row mx-0">
        <div class="col-12 px-0">
            <div class="table-responsive">
                <table class="table table-bordered mb-0">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Client No.</th>
                        <th>Email</th>
                        <th>Contact</th>
                        <th>Credit</th>
                        <th>Status</th>
                        <th class="text-center">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of customersList | filter :['companyName'] :searchFilter">
                        <td>
                            <strong class="text-primary">{{ item.companyName }}</strong>
                        </td>
                        <td><strong>{{ item.clientNo }}</strong></td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.contact }}</td>
                        <td class="text-center">
                                <span>
                                    <em *ngIf="item.canClearWithNoPayment"
                                        class="bi bi-check-circle-fill text-primary"></em>
                                    <em *ngIf="!item.canClearWithNoPayment"
                                        class="bi bi-x-circle-fill text-danger"></em>
                                </span>
                        </td>

                        <td>
                            <app-status [statusType]="item.status"></app-status>
                        </td>
                        <td class="text-center">
                            <div class="action-dropdown">
                                <button class="btn btn-light btn-sm dropdown-toggle" data-toggle="dropdown">
                                    <em class="fa fa-ellipsis-v m-1"></em>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <button class="dropdown-item" (click)="_editCustomer(editCustomer,item)">
                                        <em class="bi bi-pencil-square text-primary mr-2"></em> Edit
                                    </button>
                                    <button class="dropdown-item"
                                            [routerLink]="['/app/settings/others/customers/details',item.id]">
                                        <em class="bi bi-eye text-primary mr-2"></em> View Details
                                    </button>
                                    <button class="dropdown-item" (click)="deleteCustomer(item.id)">
                                        <em class="bi bi-trash text-danger mr-2"></em> Delete
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="text-center" *ngIf="loading">
                        <td colspan="8">
                            <app-loading-placeholder></app-loading-placeholder>
                        </td>
                    </tr>
                    <tr class="text-center" *ngIf="customersList.length == 0">
                        <td colspan="8" [hidden]="loading">
                            <app-empty-placeholder></app-empty-placeholder>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>

<!-- new FuelType form -->
<ng-template #newCustomer>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="newBtn" [modalTitle]="'New Client'"
               [condition]="customerForm.invalid">
        <div class="row mx-0" [formGroup]="customerForm">
            <div class="col-12">
                <app-input [required]="true" [fieldId]="'companyName'"
                           [control]="customCotrol('companyName', customerForm)">
                </app-input>
                <app-input [capitalType]="false" [fieldId]="'email'" [control]="customCotrol('email', customerForm)">
                </app-input>
                <app-input [fieldId]="'contact'" [control]="customCotrol('contact', customerForm)">
                </app-input>
                <app-input [fieldId]="'tin'" [control]="customCotrol('companyTIN', customerForm)">
                </app-input>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                           [formControl]="customCotrol('canClearWithNoPayment', customerForm)" value=""
                           id="flexCheckChecked">
                    <label class="form-check-label" for="flexCheckChecked">
                        Can Clear With No Payment
                    </label>
                </div>
            </div>
        </div>
    </app-modal>
</ng-template>

<!-- edit FuelType form -->
<ng-template #editCustomer>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="editBtn" [modalTitle]="'Edit Client Details'"
               [condition]="editCustomerForm.invalid">
        <div class="row mx-0" [formGroup]="editCustomerForm">
            <div class="col-12">
                <app-input [required]="true" [fieldId]="'companyName'"
                           [control]="customCotrol('companyName', editCustomerForm)">
                </app-input>
                <app-input [capitalType]="false" [fieldId]="'email'"
                           [control]="customCotrol('email', editCustomerForm)">
                </app-input>
                <app-input [fieldId]="'contact'" [control]="customCotrol('contact', editCustomerForm)">
                </app-input>
                <app-input [fieldId]="'tin'" [control]="customCotrol('companyTIN', editCustomerForm)">
                </app-input>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                           [formControl]="customCotrol('canClearWithNoPayment', editCustomerForm)" value=""
                           id="flexCheckChecked">
                    <label class="form-check-label" for="flexCheckChecked">
                        Can Clear With No Payment
                    </label>
                </div>
            </div>
        </div>
    </app-modal>
</ng-template>

<ng-template #details>
    <app-modal [size]="'extraLong'" [loading]="isSaving" [modalTitle]="'Edit Client Details'">
        <div class="row mx-0">
        </div>
    </app-modal>
</ng-template>