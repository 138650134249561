import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SessionStorageService } from 'src/app/services/session/session-storage.service';
import { WebSocketService } from 'src/app/services/settings/web-socket.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  lockScreenBackURL: any;
  activeUserEmail: any;
  userActivityInterval: NodeJS.Timeout | undefined;
  private readonly publicKey: string = 'BNSC3XYGEC3kz-3xsKHw7w8LBlPFFV1eWKTH8O9E6bg6nRgzNQEXeUZY1aKM6gYJVHsI7TaDvDF6vfGcY57VWMQ';

  constructor(
    public router: Router,
    public sessionStorageService: SessionStorageService,
    public websocket: WebSocketService,
  ) {

    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.lockScreenBackURL = event.url;
      });

  }

  ngOnInit(): void {
    this.userActivityWatcher();
    const rem = this.sessionStorageService.getActiveUser();
    if (rem) {
      this.activeUserEmail = rem.email
    }
    this.websocket.pushSubscription();
    
  }

  userActivityWatcher(): void {
    var secondsSinceLastActivity = 0;
    var maxDomarntSeccond = 5 * 100;

    this.userActivityInterval = setInterval(() => {
      secondsSinceLastActivity++;
      if (secondsSinceLastActivity > maxDomarntSeccond) {
        this.lockUserSession();
      }
    }, 1000);

    const activity = () => {
      secondsSinceLastActivity = 0;
    }

    const activityEvents: any[] = [
      'mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'
    ];

    activityEvents.forEach((event: string) => {
      document.addEventListener(event, activity, true)
    });
  }

  lockUserSession(): void {
    if (this.activeUserEmail) {
      this.sessionStorageService.setLockStatus('locked');
      this.sessionStorageService.setLockURL(this.lockScreenBackURL);
      this.sessionStorageService.setLockEmail(this.activeUserEmail);
      this.router.navigate(['/auth/lock']);
    } else {
      this.router.navigate(['/auth']);
    }

  }

  
}
