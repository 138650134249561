import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { BehaviorSubject } from "rxjs";
import { applyMixins } from "src/app/components/mixin/mixin";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { ApiConnectionService } from "src/app/services/api/apiconnection.service";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { Role } from "src/app/services/authorization/models/Role.model";
import { AuthGuard } from "src/app/services/guards/auth.guard";
import { ACTIVE_ROLE } from "src/app/services/session/constants";
import { SessionStorageService } from "src/app/services/session/session-storage.service";
import { WorkSpaceService } from "./workspace.service";

class BaseClass {}
interface BaseClass extends ModalComponent {}
applyMixins(BaseClass, [ModalComponent]);

@Component({
  selector: "app-workspace",
  templateUrl: "./workspace.component.html",
  styleUrls: ["./workspace.component.scss"],
})
export class WorkspaceComponent extends BaseClass implements OnInit {
  activeRole!: any;
  userType: boolean = false;
  businessList: any[] = [];
  sitesList: any[] = [];
  selectedBuz: any;

  sitesListSub: BehaviorSubject<[]> = new BehaviorSubject([]);
  sitesListPredicate!: object;

  timeZones: any[] = [];
  timeZonesSub: BehaviorSubject<[]> = new BehaviorSubject([]);
  timePredicate!: object;

  currencies: any[] = [];
  currenciesSub: BehaviorSubject<[]> = new BehaviorSubject([]);
  currenciesPredicate!: object;

  selectSite: FormControl = new FormControl(null, Validators.required);
  selectBuz: FormControl = new FormControl(null, Validators.required);

  businessForm!: FormGroup;
  newBtn!: object;

  siteForm!: FormGroup;
  siteBtn!: object;

  isSaving = false;
  showBusiness = true;
  loading = false;

  constructor(
    public modalService: BsModalService,
    public router: Router,
    public route: ActivatedRoute,
    public session: SessionStorageService,
    public authService: AuthenticationService,
    private workSpaceService: WorkSpaceService
  ) {
    super();
    this.businessForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      contact: new FormControl(null, Validators.required),
      code: new FormControl(null, Validators.required),
      timezone: new FormControl(null, Validators.required),
      currencyId: new FormControl(null, Validators.required),
    });
    this.siteForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      contact: new FormControl(null, Validators.required),
      timezone: new FormControl(null, Validators.required),
      pcode: new FormControl(null, Validators.required),
      code: new FormControl(null, Validators.required),
    });

    this.siteBtn = {
      method: () => {
        return this.saveNewSite(this.siteForm.value);
      },
      text: "save",
    };
    this.sitesListPredicate = {
      method1: (obj: any) => obj,
      method2: (obj: any) => obj.name,
    };
    this.timePredicate = {
      method1: (obj: any) => obj.id,
      method2: (obj: any) => obj.displayName,
    };
    this.currenciesPredicate = {
      method1: (obj: any) => obj.id,
      method2: (obj: any) => obj.name + "(" + obj.code + ")",
    };
  }

  ngOnInit(): void {
    this.businessList = this.session.getUserBusinesses();
    this.getTimeZone();
    this.getCurrencies();
    this.getSites();
    this.activeRole = this.session.getActiveUserRole();
    if (
      this.activeRole === "Super Administrator" ||
      this.activeRole === "ARXCESS"
    ) {
      this.userType = true;
    }
  }

  selectActiveSite() {
    this.setSelectSite(this.selectSite.value);
  }

  setSelectSite(site: any): void {
    this.session.setActiveSite(site);
    this.session.setActiveBusiness(this.selectBuz.value);
    this.router.navigate(["app"]);
  }

  saveNewSite(form: any): void {
    this.isSaving = true;
    this.workSpaceService.saveSite("POST", form, (res, status) => {
      this.isSaving = false;
      this.closeModal();
      if (status) {
        this.getSites();
        this.siteForm.reset();
      }
    });
  }

  getSites(): void {
    this.loading = true;
    this.workSpaceService.getUserSites((res, status) => {
      this.loading = false;
      if (status) {
        this.sitesList = res;
        this.sitesListSub.next(res);
        console.log(res);
        if (!this.userType) {
          if (this.sitesList.length === 1) {
            this.setSelectSite(this.sitesList[0]);
            this.selectBuz.setValue(this.businessList[0]);
          }
        }
        this.selectSite.setValue(this.sitesList[0]);
      }
    });
  }

  getTimeZone(): void {
    this.workSpaceService.getTimeZones((res, status) => {
      this.timeZones = res;
      this.timeZonesSub.next(res);
    });
  }

  getCurrencies(): void {
    this.workSpaceService.getCurrencies((res, status) => {
      this.currencies = res.data;
      this.currenciesSub.next(res.data);
    });
  }

  _rightsPage(): void {
    this.router.navigate(["/auth/auth-rights"]);
  }

  customCotrol(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl;
  }
}
