import {Injectable} from '@angular/core';
import {PdfFindPreviousComponent} from 'ngx-extended-pdf-viewer';
import {PdfPrintService} from 'src/app/services/settings/pdf-print.service';
import {AlertService} from '../../../app/services/alert/alert.service';
import {ApiConnectionService} from '../../../app/services/api/apiconnection.service';

@Injectable({
    providedIn: 'root',
})
export class JobService {
    constructor(
        private apiConnect: ApiConnectionService,
        private alertService: AlertService,
        public pdfPrintService: PdfPrintService
    ) {
    }

    saveJob(
        link: string,
        form: any,
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect.callApi(link, 'Job', form, queryParam, id).subscribe(
            (res: any) => {
                onSave(res, true);
                if (link !== 'GET') {
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                }
            },
            (err: any) => {
                onSave(err, false);
                this.alertService.setAlert({
                    type: 'danger',
                    title: 'Error',
                    code: err.status,
                    message: err.message.error.error,
                });
            }
        );
    }

    saveExemptedJob(
        link: string,
        form: any,
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi(link, 'JobWithoutDipping', form, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    getJobs(
        link: any,
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect.callApi('GET', link, undefined, queryParam, id).subscribe(
            (res: any) => {
                onSave(res, true);
            },
            (err: any) => {
                onSave(err, false);
                this.alertService.setAlert({
                    type: 'danger',
                    title: 'Error',
                    code: err.status,
                    message: err.message.error.error,
                });
            }
        );
    }

    updateJob(
        link: string,
        form: any,
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect.callApi(link, 'UpdateJob', form, queryParam, id).subscribe(
            (res: any) => {
                onSave(res, true);
                if (link !== 'GET') {
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                }
            },
            (err: any) => {
                onSave(err, false);
                this.alertService.setAlert({
                    type: 'danger',
                    title: 'Error',
                    code: err.status,
                    message: err.message.error.error,
                });
            }
        );
    }

    saveCargo(
        link: string,
        form: any,
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi(link, 'Compartment', form, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    console.log(err);
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    saveDipping(
        link: string,
        form: any,
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect.callApi(link, 'Dipping', form, queryParam, id).subscribe(
            (res: any) => {
                onSave(res, true);
                if (link !== 'GET') {
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                }
            },
            (err: any) => {
                onSave(err, false);
                this.alertService.setAlert({
                    type: 'danger',
                    title: 'Error',
                    code: err.status,
                    message: err.message.error.error,
                });
            }
        );
    }

    completeJob(
        form: any,
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('PUT', 'JobComplete', form, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    exemptJob(
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('PUT', 'ExemptJob', undefined, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    payWithBal(
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('POST', 'PaymentWithBalance', undefined, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    processJob(
        form: any,
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('PUT', 'JobProcess', form, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    console.log(err);
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    proceedLab(
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('PUT', 'ProceedLab', undefined, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    console.log(err);
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    requestCredit(
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('PUT', 'RequestCredit', undefined, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    console.log(err);
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    permitCredit(
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('PUT', 'PermitCredit', undefined, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    console.log(err);
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    jobVoucher(
        form: any,
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('PUT', 'JobVoucher', form, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    acceptInfo(
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('PUT', 'AcceptInfo', undefined, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    onSave(err, false);
                    console.log(err);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    receivePayment(
        form: any,
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('POST', 'ReceivePayment', form, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    receiveClientPayment(
        form: any,
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('POST', 'ReceiveClientPayment', form, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    printReceipt(id: any): void {
        this.apiConnect
            .callApi('GET', 'PaymentReceipt', undefined, undefined, id)
            .subscribe(
                (res: any) => {
                    this.pdfPrintService.openNewWindowPdfpreview(res.data.data);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    printInvoice(id: any, link?: string): void {
        this.apiConnect
            .callApi('GET', link ? link : 'PaymentVoucher', undefined, undefined, id)
            .subscribe(
                (res: any) => {
                    this.pdfPrintService.openNewWindowPdfpreview(res.data.data);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    verifyPayment(
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('PUT', 'VerifyPayment', undefined, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    rejectPayment(
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('PUT', 'RejectPayment', undefined, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    printForms(
        link: string,
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect.callApi('GET', link, undefined, queryParam, id).subscribe(
            (res: any) => {
                onSave(res, true);
                this.alertService.setAlert({
                    type: 'success',
                    title: 'Success',
                    code: res.status,
                    message: res.message,
                });
            },
            (err: any) => {
                onSave(err, false);
                this.alertService.setAlert({
                    type: 'danger',
                    title: 'Error',
                    code: err.status,
                    message: err.message.error.error,
                });
            }
        );
    }

    variationComments(
        onGet: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('GET', 'VariationComments', undefined, queryParam, id)
            .subscribe(
                (res: any) => {
                    onGet(res.data, true);
                },
                (err: any) => {
                    onGet(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    sessions(
        type: string,
        link: string,
        form: any,
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect.callApi(type, link, form, queryParam, id).subscribe(
            (res: any) => {
                onSave(res, true);
                if (type !== 'GET') {
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                }
            },
            (err: any) => {
                onSave(err, false);
                this.alertService.setAlert({
                    type: 'danger',
                    title: 'Error',
                    code: err.status,
                    message: err.message.error.error,
                });
            }
        );
    }

    changeJobClient(
        jobId: any,
        client: any,
        onSave: (res?: any, status?: boolean) => void,
    ): void {
        this.apiConnect
            .callApi(
                'PUT',
                'ChangeJobCLient',
                undefined,
                undefined,
                `${jobId}/${client}`
            )
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    this.alertService.setAlert({
                        type: 'success',
                        title: 'Success',
                        code: res.status,
                        message: res.message,
                    });
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }
}
