<div class="container">
    <div class="row mx-0 mb-2 mt-2">
        <div class="col-12 d-flex px-0">
            <div>
                <h5 class="font-weight-bold text-muted">Job Details</h5>
            </div>
            <div class="ml-auto">
                <button class="btn-sm btn btn-primary" (click)="_back()">
                    <em class="bi bi-arrow-left mr-1"></em> back
                </button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header bg-white" *ngIf="!loading">
                    <div class="d-flex">
                        <h6 class="text-muted">Job Info (  {{jobDetails.jobNumber}})
                        </h6>
                        <app-job-actions class="ml-auto" (updates)="_updates($event)"></app-job-actions>
                    </div>
                </div>
                <div class="card-body p-0 group-action" *ngIf="!loading" style="position: relative!important;">
                    <div class="row mx-0">
                        <div class="col card-divider">
                            <div class="profile-view py-3">
                                <div class="profile-basic position-relative">
                                    <ul class="personal-info">
                                        <li>
                                            <div class="title">Job Status</div>
                                            <a class="text"> :
                                                <a href="javascript:void(0);" class="ml-1">
                                                    <app-status [statusType]="jobDetails.jobStatus"></app-status>
                                                </a>
                                            </a>
                                        </li>
                                        <li>
                                            <div class="title">Dipping Date</div>
                                            <a class="text"> :
                                                <a href="javascript:void(0);" class="ml-1">
                                                    {{jobDetails?.entryDate | date}}
                                                </a>
                                            </a>
                                        </li>
                                        <li>
                                            <div class="title">Consignee</div>
                                            <a class="text"> :
                                                <a href="javascript:void(0);" class="ml-1">
                                                    {{jobDetails.consignee.client.companyName}}
                                                </a>
                                            </a>
                                        </li>
                                        <li>
                                            <div class="title">Destination</div>
                                            <div class="text"> :
                                                <a href="javascript:void(0);"
                                                    class="ml-1">{{jobDetails?.consignee?.destination}}</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="title">Type of Sale</div>
                                            <div class="text"> :
                                                <a href="javascript:void(0);"
                                                    class="ml-1">{{jobDetails?.consignee?.typeOfSale}}</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col card-divider">
                            <div class="profile-view py-3">
                                <div class="profile-basic position-relative">
                                    <ul class="personal-info">
                                        <li>
                                            <div class="title">Shipment No.</div>
                                            <div class="text"> :
                                                <a href="javascript:void(0);"
                                                    class="ml-1">{{jobDetails?.shipper?.shipmentNumber}}</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="title">Mode of Trans</div>
                                            <div class="text"> :
                                                <a href="javascript:void(0);"
                                                    class="ml-1">{{jobDetails?.shipper?.modeOfTransport}}</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="title">Vehicle Owner.</div>
                                            <div class="text"> :
                                                <a href="javascript:void(0);"
                                                    class="ml-1">{{jobDetails?.shipper?.vehicleOwner}}</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="title">Vehicle No.</div>
                                            <div class="text"> :
                                                <a href="javascript:void(0);"
                                                    class="ml-1">{{jobDetails?.shipper?.vehicleNumber}}</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="title">Driver</div>
                                            <div class="text">:
                                                <a href="javascript:void(0);" class="ml-1">
                                                    {{
                                                    [jobDetails?.shipper?.driver?.firstname,jobDetails?.shipper?.driver?.lastname].join('
                                                    ') }}
                                                </a>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="title">Source</div>
                                            <div class="text"> :
                                                <a href="javascript:void(0);" class="ml-1">{{jobDetails?.consigner?.source}}</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col card-divider">
                            <div class="profile-view py-3">
                                <div class="profile-basic position-relative">
                                    <ul class="personal-info">
                                        <li>
                                            <div class="title">Consigner</div>
                                            <div class="text"> :
                                                <a href="javascript:void(0);" class="ml-1">
                                                    {{jobDetails.consigner?.client?.companyName}}
                                                </a>
                                            </div>
                                        </li>
                                        <li *ngIf="jobDetails.jobStatus === 'INVOICED'">
                                            <div class="title">Clear With No Payment</div>
                                            <div class="text"> :
                                                <a href="javascript:void(0);" class="ml-1">
                                                    <em *ngIf="jobDetails?.consigner?.client?.canClearWithNoPayment"
                                                        class="bi bi-check-circle-fill text-primary"></em>
                                                    <em *ngIf="!jobDetails?.consigner?.client?.canClearWithNoPayment"
                                                        class="bi bi-x-circle-fill text-danger"></em>
                                                        <!-- <strong class="ml-2">Bal: {{jobDetails.clientBalance | number}}</strong> -->
                                                </a>
                                            </div>
                                        </li>
                                        <li *ngIf="jobDetails.jobStatus === 'INVOICED'">
                                            <div class="title">Payment Status</div>
                                            <a class="text"> :
                                                <a href="javascript:void(0);" class="ml-1">
                                                    <app-status [statusType]="jobDetails?.paymentStatus"></app-status>
                                                </a>
                                            </a>
                                        </li>
                                        <li *ngIf="jobDetails.jobStatus === 'INVOICED'">
                                            <div class="title">Job Amount</div>
                                            <a class="text"> :
                                                <a href="javascript:void(0);" class="ml-1">
                                                  <strong>{{jobDetails?.jobAmount}}</strong> 
                                                  <!-- <strong class="mx-2">Paid: </strong> <strong>{{jobDetails?.amountPaid}}</strong> -->
                                                </a>
                                            </a>
                                        </li>
                                        <li>
                                            <div class="title">Delivery Date</div>
                                            <div class="text"> :
                                                <a href="javascript:void(0);"
                                                    class="ml-1">{{jobDetails?.consigner?.deliveryDate|customdateformat:'toEpoch'|date}}</a>
                                            </div>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 card-section mb-2 comment-divider" *ngIf="jobDetails.dipperRemarks">
                        <div class="col" *ngIf="jobDetails.variationComment">
                            <div class="comment">Variation Comment</div>
                            <div class="status-container">
                                <p class="m-0"><small>{{jobDetails.variationComment}}</small></p>
                            </div>
                        </div>
                        <div class="col">
                            <div class="comment">Dipper Remarks</div>
                            <div class="status-container">
                                <p class="m-0"><small>{{jobDetails.dipperRemarks}}</small></p>
                            </div>
                        </div>
                        <div class="col" *ngIf="jobDetails.labRemarks">
                            <div class="comment">Lab Remarks</div>
                            <div class="status-container">
                                <p class="m-0"><small>{{jobDetails.labRemarks}}</small></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center" *ngIf="loading">
                    <app-loading-placeholder></app-loading-placeholder>
                </div>
            </div>
        </div>
    </div>

    <app-cargo (updates)="_updates($event)"></app-cargo>
</div>