<div class="container">
    <div class="row mx-0 mb-2 mt-2">
        <div class="col-12 d-flex px-0">
            <div>
                <h5 class="font-weight-bold text-muted">Client Details</h5>
            </div>

            <div class="mr-2 ml-auto">
                <button class="btn-sm btn btn-primary" (click)="location.back()">
                    <em class="bi bi-arrow-left mr-1"></em>
                    back
                </button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 mb-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="col-6" style="border-right: 1px solid grey;">
                            <div class="d-flex justify-content-between">
                                <span>Client Name</span>
                                <h6 class="m-0">{{ customerDetails?.companyName }}</h6>
                            </div>
                            <div class="d-flex justify-content-between">
                                <span>Client Email</span>
                                <h6 class="m-0">{{ customerDetails?.email }}</h6>
                            </div>
                            <div class="d-flex justify-content-between">
                                <span>Client No.</span>
                                <h6 class="m-0">{{ customerDetails?.clientNo }}</h6>
                            </div>
                            <div class="d-flex justify-content-between">
                                <span>Client Contact</span>
                                <span class="m-0">{{ customerDetails?.contact }}</span>
                            </div>
                            <div class="d-flex justify-content-between">
                                <span>Can Clear With No Payment</span>
                                <span class="m-0">
                                    <em *ngIf="customerDetails?.canClearWithNoPayment"
                                        class="bi bi-check-circle-fill text-primary"></em>
                                    <em *ngIf="!customerDetails?.canClearWithNoPayment"
                                        class="bi bi-x-circle-fill text-danger"></em>
                                </span>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex justify-content-between">
                                <h6>Balance :</h6>
                                <div class="border-primary bg-primary p-2"
                                    [ngClass]="{'border-danger bg-danger':(customerDetails?.balance+'')[0] === '-'}"
                                    style="border-radius: 5px;">
                                    <h5 class="text-white mb-0">{{ customerDetails?.balance | number }}</h5>
                                </div>
                            </div>
                            <div class="text-right mt-3" hideSubModule [module]="JobRoles.name" [query]="JobRoles.children.RecvPayment">
                                <button class="ml-auto btn btn-sm btn-outline-primary"
                                    (click)="openModal(receivePay)">Receive Payment</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="home-tab" data-toggle="tab" data-target="#home" type="button"
                        role="tab" aria-controls="home" aria-selected="true">Invoices</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="profile-tab" data-toggle="tab" data-target="#profile" type="button"
                        role="tab" aria-controls="profile" aria-selected="false">Payments</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="card">
                        <div class="card-header">Invoices</div>
                        <div class="card-body">
                            <table class="table table-bordered">
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" class="text-right">Amount</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Voucher No.</th>
                                        <th scope="col">Reference</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of chunkedRecords2[currentPage]; let i=index">
                                        <th>{{i+1}}</th>
                                        <td class="text-primary text-right">{{item.amount | number}}</td>
                                        <td><small>{{item.entryDate | date }}</small></td>
                                        <td><app-status [statusType]="item.status"></app-status></td>
                                        <td><small>{{item.voucherNumber }}</small></td>
                                        <td><small>{{item.reference}}</small></td>
                                        <td class="text-center"><span class="btn btn-primary btn-sm" (click)="print(item.id);"><em class="bi bi-printer"></em></span></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center align-items-center mt-3"
                                *ngIf="invoices.length != 0">
                                <app-paginator [chuckRecords]="invoices | paginator :elementsPerPage"
                                    (onPageChange)="displayActivePage2($event)">
                                </app-paginator>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div class="card">
                        <div class="card-header">Payments</div>
                        <div class="card-body">
                            <table class="table table-bordered">
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" class="text-right">Amount</th>
                                        <th class="text-right">SSP</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Reference</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of chunkedRecords[currentPage]; let i=index">
                                        <th>{{i+1}}</th>
                                        <td class="text-primary text-right">{{item.amount | number}}</td>
                                        <td class="text-warning text-right">{{ item.amountSSD | number }}</td>
                                        <td>{{ item.date }}</td>
                                        <td>{{ item.reference }}</td>
                                        <td class="text-center"><span class="btn btn-primary btn-sm" (click)="this.jobService.printReceipt(item.id);"><em class="bi bi-printer"></em></span></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center align-items-center mt-3"
                                *ngIf="payments.length != 0">
                                <app-paginator [chuckRecords]="payments | paginator :elementsPerPage"
                                    (onPageChange)="displayActivePage($event)">
                                </app-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- receive payment -->
<ng-template #receivePay>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="btn" [modalTitle]="'Receive Payment'"
        [condition]="receivePayForm.invalid">
        <form action="" [formGroup]="receivePayForm">
            <div class="row mx-0">
                <div *ngIf="jobSessions[0]?.id" class="col-12">
                    <app-input-date [required]="true" [fieldId]="'date'"
                        [control]="customCotrol('date',receivePayForm)">
                    </app-input-date>
                    <app-input-figure [required]="true" [fieldId]="'amount'"
                        [control]="customCotrol('amount', receivePayForm)">
                    </app-input-figure>
                    <div class="form-group">
                        <label for="">Currency <small class="text-danger">*</small></label>
                        <select class="form-control" [formControl]="customCotrol('currency', receivePayForm)">
                            <option *ngFor="let item of Currency" [value]="item">{{item}}</option>
                        </select>
                    </div>
                    <app-input [required]="true" [fieldId]="'ref'"
                        [control]="customCotrol('bankReference', receivePayForm)">
                    </app-input>
                    <app-input [fieldId]="'notes'" [control]="customCotrol('notes', receivePayForm)">
                    </app-input>
                </div>
                <div *ngIf="jobSessions.length == 0" class="text-center">
                    <h6>
                        You don`t have an active Session
                        to detamine the Exchange Rate to use for this Payment, please create a new one from Sessions
                    </h6>
                </div>
            </div>
        </form>
    </app-modal>
</ng-template>