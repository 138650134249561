<div class="container">
    <div class="row mx-0 mb-2 mt-2">
        <div class="col-12 d-flex px-0">
            <div>
                <h5 class="font-weight-bold text-muted">Report Access Utility</h5>
            </div>
            <div class="entries mr-2 ml-auto">
                <select [formControl]="showEntries" class="custom-select custom-select-sm form-control form-control-sm">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                    <option [ngValue]="utilitiesList.length">Show All</option>
                </select>
            </div>
            <div class="">
                <button class="btn-sm btn btn-primary" (click)="openModal(reportUtitlity); reportFrequencyList.clear()">
                    New Utility
                </button>
            </div>
        </div>
    </div>

    <!-- table data -->
    <div class="row mx-0">
        <div class="col-12 px-0">
            <div *ngIf="!loading">
                <!-- <div class="d-block"> -->
                <div class="table-card shadow-sm" *ngIf="utilitiesList.length != 0">
                    <div class="table-card-body">
                        <div class="table-responsive">
                            <table class="table themed-table themed-table-plain">
                                <thead>
                                    <th class="cell">
                                        <div class="cell-border">
                                            <span>Name</span>
                                        </div>
                                    </th>
                                    <th class="cell">
                                        <div class="cell-border">
                                            <span>Email</span>
                                        </div>
                                    </th>
                                    <th class="cell text-center" style="min-width: 10px;">
                                        <div class="cell-border">
                                            <span>Report Freq</span>
                                        </div>
                                    </th>
                                    <th class="cell text-center" style="min-width: 10px;">
                                        <div class="cell-border">Action</div>
                                    </th>
                                </thead>
                                <tbody>
                                    <!-- data loader -->
                                    <tr *ngFor="let item of chunkedRecords[currentPage]">
                                        <td>
                                            <span class="font-weight-bolder text-black">{{ item.name }}</span>
                                        </td>
                                        <td>
                                            <span class="font-weight-bolder text-info">{{ item.email}}</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="font-weight-bolder text-info">{{
                                                item.reportFrequencyList.length }}</span>
                                        </td>
                                        <td class="text-center">
                                            <div class="action-dropdown">
                                                <button class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    <em class="fa fa-ellipsis-h m-1"></em>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <button class="dropdown-item"
                                                        (click)="openModal(addFreq);editId=item.id;isEditting =true;">
                                                        <em class="bi bi-plus-circle text-primary mr-2"></em> Add
                                                        Frequency
                                                    </button>
                                                    <div class="dropdown-divider"></div>
                                                    <button class="dropdown-item"
                                                        (click)="openModal(editUtility);editId = item.id;
                                                        customCotrol('name',utilityForm).setValue(item.name);
                                                         customCotrol('email',utilityForm).setValue(item.email)">
                                                        <em class="bi bi-pencil-square text-info mr-2"></em> Edit
                                                    </button>
                                                    <button class="dropdown-item" (click)="deleteUtility(item.id)">
                                                        <em class="bi bi-trash text-danger mr-2"></em> Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="text-center" *ngIf="loading">
                                        <td colspan="6">
                                            <app-loading-placeholder></app-loading-placeholder>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div *ngIf="utilitiesList.length === 0" [hidden]="loading">
                    <app-empty-placeholder></app-empty-placeholder>
                </div>
            </div>

            <div *ngIf="loading" class="text-center">
                <app-loading-placeholder></app-loading-placeholder>
            </div>

            <!-- pagination -->
            <div class="d-flex justify-content-center align-items-center mt-3" *ngIf="utilitiesList.length != 0">
                <app-paginator
                    [chuckRecords]="utilitiesList | filter :filterList :searchFilter :false | paginator :elementsPerPage"
                    (onPageChange)="displayActivePage($event)">
                </app-paginator>
            </div>
        </div>
    </div>
</div>

<!-- new report form -->
<ng-template #reportUtitlity>
    <app-modal [size]="'long'" [loading]="isSaving" [modalActionButton]="utilityBtn"
        [modalTitle]="'New Report Access Utility'" [condition]="utilityForm.invalid">
        <div class="row mx-0">
            <div class="col-6">
                <app-input [required]="true" [fieldId]="'name'" [control]="customCotrol('name', utilityForm)">
                </app-input>
            </div>
            <div class="col-6">
                <app-input [capitalType]="false" [fieldId]="'email'" [required]="true" [control]="customCotrol('email', utilityForm)">
                </app-input>
            </div>
            <div class="col-12 mt-3">
                <div class="table-card">
                    <div class="table-card-body">
                        <div class="">
                            <table class="table themed-table themed-table-plain">
                                <thead>
                                    <th class="cell" style="min-width: 50px">
                                        <div class="cell-border">
                                            <span>Report & Frequecy</span>
                                        </div>
                                    </th>
                                    <th class="cell">
                                        <div class="cell-border">
                                            <span>Sites</span>
                                        </div>
                                    </th>
                                    <th class="cell text-center" style="min-width: 5px">
                                        <!-- <div class="cell-border">Action</div> -->
                                    </th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let entry of reportFrequencyList.controls; let i = index">
                                        <td>
                                            <div class="form-group">
                                                <select class="form-control custom-select-sm"
                                                    [formControl]="customEntryCell('report', i)">
                                                    <option [ngValue]="name.name" *ngFor="let name of reports">
                                                        {{ name.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <select class="form-control custom-select-sm"
                                                    [formControl]="customEntryCell('category', i)">
                                                    <option [ngValue]="name.name" *ngFor="let name of category">
                                                        {{ name.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <app-input-select [optionsList]="sitesListSubject" [multiSelected]="[]"
                                                [single]="false" [options]="sitesList" [noLabel]="true" [mb0]="true"
                                                [predicate]="sitesPredicate" [control]="customEntryCell('siteIds',i)">
                                            </app-input-select>
                                        </td>
                                        <td class="text-center">
                                            <div *ngIf="reportFrequencyList.controls.length !== (i+1)" class="action">
                                                <span (click)="deleteRow(i)" class="arrow-control action-btn">
                                                    <em class="bi bi-x text-danger"></em>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-modal>
</ng-template>

<!-- add freq form -->
<ng-template #addFreq>
    <app-modal [size]="'long'" [loading]="isSaving" [modalActionButton]="addBtn" [modalTitle]="'Add Frequecy'">
        <div class="col-12 mt-3">
            <div class="table-card">
                <div class="table-card-body">
                    <div class="">
                        <table class="table themed-table themed-table-plain">
                            <thead>
                                <th class="cell" style="min-width: 50px">
                                    <div class="cell-border">
                                        <span>Report & Frequecy</span>
                                    </div>
                                </th>
                                <th class="cell">
                                    <div class="cell-border">
                                        <span>Sites</span>
                                    </div>
                                </th>
                                <th class="cell text-center" style="min-width: 5px">
                                    <!-- <div class="cell-border">Action</div> -->
                                </th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let entry of reportFrequencyList.controls; let i = index">
                                    <td>
                                        <div class="form-group">
                                            <select class="form-control custom-select-sm"
                                                [formControl]="customEntryCell('report', i)">
                                                <option [ngValue]="name.name" *ngFor="let name of reports">
                                                    {{ name.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <select class="form-control custom-select-sm"
                                                [formControl]="customEntryCell('category', i)">
                                                <option [ngValue]="name.name" *ngFor="let name of category">
                                                    {{ name.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <app-input-select [optionsList]="sitesListSubject" [multiSelected]="[]"
                                            [single]="false" [options]="sitesList" [noLabel]="true" [mb0]="true"
                                            [predicate]="sitesPredicate" [control]="customEntryCell('siteIds',i)">
                                        </app-input-select>
                                    </td>
                                    <td class="text-center">
                                        <div *ngIf="reportFrequencyList.controls.length !== (i+1)" class="action">
                                            <span (click)="deleteRow(i)" class="arrow-control action-btn">
                                                <em class="bi bi-x text-danger"></em>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </app-modal>
</ng-template>

<!-- new marker form -->
<ng-template #editUtility>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="editBtn" [modalTitle]="'Edit Report Utility'"
        [condition]="utilityForm.invalid">
        <div class="row mx-0">
            <div class="col-12">
                <app-input [required]="true" [fieldId]="'name'" [control]="customCotrol('name', utilityForm)">
                </app-input>
                <app-input [fieldId]="'email'" [required]="true" [control]="customCotrol('email', utilityForm)">
                </app-input>
            </div>
        </div>
    </app-modal>
</ng-template>