<div class="d-flex flex-column align-items-center justify-content-center h-100vh"
    style="background: #237d4b; height: 100%;">
    <div class="container">
        <div class="text-center mb-4">
            <a class="app-logo" href="index.html">
                <!-- <img class="logo-icon" src="../../../../assets/images/logo.png" alt="logo" style="width: 250px;"> -->
            </a>
        </div>
    </div>
    <div class="container px-5">
        <div class="row justify-content-center">
            <div class="col-6 card">
                <div class="card-body">

                    <div *ngIf="userType" class="row justify-content-center"
                        style="border-bottom: 1px solid #237d4b; margin-bottom: 1em;">
                        <div class="col-4">
                            <span class="text-primary font-weight-bold">
                                Select Site</span>
                        </div>
                        <div class="col-8    text-right mb-1">
                            <button class="btn btn-sm btn-outline-primary" (click)="openModal(newSite)">
                                <em class="bi bi-plus-circle"></em>
                                Add Site </button>
                            <button class="btn btn-sm btn-outline-info ml-2" (click)="_rightsPage()">
                                <em class="bi bi-arrow-right mr-1"></em>
                                Users</button>

                        </div>
                    </div>
                    <div [hidden]="loading" *ngIf="sitesList.length != 0">
                        <app-input-select-search [fieldId]="'selectSite'" [uniqueClass]="'timezone'"
                            [control]="selectSite" [predicate]="sitesListPredicate" [options]="sitesList"
                            [optionsList]="sitesListSub">
                        </app-input-select-search>

                        <div class="form-group" *ngIf="selectSite.valid">
                            <label class="" for="">Business <span class="text-danger">*</span></label>
                            <select class="form-control custom-select-sm" [formControl]="selectBuz">
                                <option [ngValue]="buz" *ngFor="let buz of businessList">
                                    {{ buz.name }}
                                </option>
                            </select>
                        </div>

                        <div class="d-flex justify-content-between">
                            <button class="btn btn-sm btn-info ml-2" (click)="authService.logout()">
                                <em class="bi bi-arrow-left mr-1"></em>
                                Logout</button>
                            <button class="btn btn-sm btn-primary-o" (click)="selectActiveSite()"
                                [disabled]="!selectSite.value">
                                <em class="bi bi-arrow-right mr-1"></em>
                                Proceed
                            </button>
                        </div>

                    </div>
                    <div class="text-center" [hidden]="loading" *ngIf="sitesList.length == 0">
                        <img src="../../../../assets/images/empty-illustration.svg" width="200px" alt="">
                        <p>No Sites Available..</p>
                    </div>
                    <div *ngIf="loading" class="mt-4">
                        <app-loading-placeholder></app-loading-placeholder>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- new item form -->
<ng-template #newSite>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="siteBtn" [modalTitle]="'New Site'"
        [condition]="siteForm.invalid">
        <div class="row mx-0" [formGroup]="siteForm">
            <div class="col-12">
                <app-input [required]="true" [fieldId]="'name'" [control]="customCotrol('name', siteForm)">
                </app-input>
                <app-input-phone [maxNo]="9" [required]="true" [fieldId]="'contact'" [code]="customCotrol('pcode',siteForm)"
                    [control]="customCotrol('contact',siteForm)"></app-input-phone>
                <!-- <app-input [required]="true" [fieldId]="'contact'" [control]="customCotrol('contact', siteForm)">
                </app-input> -->
                <app-input [required]="true" [fieldId]="'siteAddress'" [control]="customCotrol('address', siteForm)">
                </app-input>
                <app-input [required]="true" [fieldId]="'code'" [control]="customCotrol('code', siteForm)">
                </app-input>
                <app-input-select-search [required]="true" [fieldId]="'timezone'" [uniqueClass]="'timezonemmmm'"
                    [control]="customCotrol('timezone', siteForm)" [predicate]="timePredicate" [options]="timeZones"
                    [optionsList]="timeZonesSub">
                </app-input-select-search>
            </div>
        </div>
    </app-modal>
</ng-template>

<!-- new item form -->
<!-- <ng-template #newBuz>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="newBtn" [modalTitle]="'New Business'"
        [condition]="businessForm.invalid">
        <div class="row mx-0" [formGroup]="businessForm">
            <div class="col-12">
                <app-input [required]="true" [fieldId]="'name'" [control]="customCotrol('name', businessForm)">
                </app-input>
                <app-input [required]="true" [fieldId]="'contact'" [control]="customCotrol('contact', businessForm)">
                </app-input>
                <app-input [required]="true" [fieldId]="'address'" [control]="customCotrol('address', businessForm)">
                </app-input>
                <app-input-select-search [required]="true" [fieldId]="'timezone'" [uniqueClass]="'timezone'"
                    [control]="customCotrol('timezone', businessForm)" [predicate]="timePredicate" [options]="timeZones"
                    [optionsList]="timeZonesSub">
                </app-input-select-search>
                <app-input-select-search [required]="true" [fieldId]="'currency'" [uniqueClass]="'currencyId'"
                    [control]="customCotrol('currencyId', businessForm)" [predicate]="currenciesPredicate"
                    [options]="currencies" [optionsList]="currenciesSub">
                </app-input-select-search>
            </div>
        </div>
    </app-modal>
</ng-template> -->