import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { WorkSpaceService } from '../../auth/workspace/workspace.service';
import { clearFixForm } from '../../settings/general-settings/utilities';
import { ReportUtilityService } from './report-utility.service';

class BaseClass { }
interface BaseClass extends ModalComponent, PaginatorComponent, FilterComponent { }
applyMixins(BaseClass, [ModalComponent, PaginatorComponent, FilterComponent]);


@Component({
  selector: 'app-report-utility',
  templateUrl: './report-utility.component.html',
  styleUrls: ['./report-utility.component.scss']
})
export class ReportUtilityComponent extends BaseClass implements OnInit {

  currentPage = 0;
  elementsPerPage = 10;
  showEntries: FormControl = new FormControl(10);
  chunkedRecords: Array<any> = [];
  searchFilter: string = '';
  editId: any;
  addBtn: any

  reports: any[] = [];
  category: any[] = [];

  sitesList: Array<any> = [];
  sitesListSubject: BehaviorSubject<[]> = new BehaviorSubject([]);
  sitesPredicate: any;

  utilitiesList: any[] = [];

  utilityForm!: FormGroup;
  utilityBtn!: object;
  editBtn: any;
  reportFrequencyList: FormArray;

  loading = false;
  isSaving = false;
  isEditting = false;

  constructor(
    public modalService: BsModalService,
    private reportUtilityService: ReportUtilityService,
    private workSpaceService: WorkSpaceService
  ) {
    super();
    this.utilityForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required)
    })
    this.reportFrequencyList = new FormArray([
      new FormGroup({
        report: new FormControl(null, Validators.required),
        category: new FormControl(null, Validators.required),
        siteIds: new FormControl(null, Validators.required)
      })
    ]);
    this.utilityBtn = {
      method: () => { return this.saveReportUtility(this.utilityForm.value); },
      text: 'Save'
    };
    this.addBtn = {
      method: () => { return this.addFreq(); },
      text: 'Add'
    };
    this.editBtn = {
      method: () => { return this.updateUtility(); },
      text: 'Update'
    };
    this.sitesPredicate = { method1: (obj: any) => (obj.id), method2: (obj: any) => (obj.name) };
  }

  ngOnInit(): void {
    this.getReportUtility();
    this.getSite();
    this.getFreqs();
    this.getReports();
    this.reportFrequencyList.valueChanges.subscribe(val => {
      if (!this.isEditting) {
        if (val[val.length - 1]?.report !== null && val[val.length - 1]?.category !== null && val[val.length - 1]?.siteIds !== null) {
          this.newEntry();
        }
      }
    });
  }

  getReportUtility(): void {
    this.loading = true;
    this.reportUtilityService.saveReportUtility('GET', undefined, (res, status) => {
      this.loading = false;
      if (status) {
        this.utilitiesList = res.data;
      }
    });
  }

  saveReportUtility(form: any): void {
    this.isSaving = true;
    this.reportUtilityService.saveReportUtility('POST', { ...form, reportFrequencyList: clearFixForm(this.reportFrequencyList.value) }, (res, status) => {
      this.isSaving = false;
      this.closeModal();
      if (status) {
        this.getReportUtility();
        this.utilityForm.reset();
        this.reportFrequencyList.clear();
      }
    });
  }

  addFreq(): void {
    this.isSaving = true;
    this.reportUtilityService.addFreq(this.reportFrequencyList.value[0], (res, status) => {
      this.isSaving = false;
      this.closeModal();
      if (status) {
        this.getReportUtility();
        this.reportFrequencyList.clear();
        this.isEditting = false;
      }
    },undefined,this.editId);
  }

  customCotrol(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl
  }
  customEntryCell(name: string, index: number): FormControl {
    return (this.reportFrequencyList.controls[index] as FormGroup)?.get(name) as FormControl;
  }

  newEntry(): void {
    this.reportFrequencyList.push(new FormGroup({
      report: new FormControl(null, Validators.required),
      category: new FormControl(null, Validators.required),
      siteIds: new FormControl(null, Validators.required)
    }));
  }

  deleteRow(index: number): void {
    this.reportFrequencyList.removeAt(index);
  }

  getSite(): void {
    this.workSpaceService.saveSite('GET', undefined, (res, status) => {
      if (status) {
        this.sitesList = res;
        this.sitesListSubject.next(res);
      }
    });
  }

  getReports(): void {
    this.reportUtilityService.getReports((res, status) => {
      this.reports = res.data;
    })
  }

  getFreqs(): void {
    this.reportUtilityService.getFreqs((res, status) => {
      this.category = res.data;
    })
  }

  updateUtility(): void{
    this.isSaving = true;
    this.reportUtilityService.updateReportUtility(this.utilityForm.value, (res: any, status: any) => {
      this.isSaving = false;
      this.closeModal();
      if (status) {
        this.getReportUtility();
        this.utilityForm.reset();
      }
    },undefined, this.editId)
  }

  deleteUtility(id: number): void {
    this.reportUtilityService.saveReportUtility('DELETE', undefined, (res, status) => {
      if (status) {
        this.getReportUtility();
      }
    }, undefined, id)
  }

  displayActivePage(chunckedData: any): void {
    setTimeout(() => {
      this.chunkedRecords = chunckedData.chunks;
      if (chunckedData.controlClicked) {
        this.currentPage = chunckedData.currentPage;
      }
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ['name'];
    }
  }


}
