import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { io } from 'socket.io-client';
import { SessionStorageService } from '../session/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  socket: any;
  localBaseURL = 'https://notifications.arxcess.com';
  // localBaseURL = 'http://localhost:9080'
  private readonly publicKey: string =
    'BNSC3XYGEC3kz-3xsKHw7w8LBlPFFV1eWKTH8O9E6bg6nRgzNQEXeUZY1aKM6gYJVHsI7TaDvDF6vfGcY57VWMQ';
  notificationEvent: BehaviorSubject<any> | undefined;

  constructor(
    public http: HttpClient,
    public swPush: SwPush,
    private session: SessionStorageService
  ) {
    this.notificationEvent = new BehaviorSubject({});
    this.connect();
    this.pushSubscription();
    // this.socket = io.connect(this.urlss, { transports: ['websocket'] });
  }

  connect(): any {
    this.socket = io(this.localBaseURL);
    this.requestPermission();
    this.socket.on('message', (data: any) => {
      console.log(data);
    });
  }

  listenToEvent(eventName: string): Observable<any> {
    return new Observable((Subscriber) => {
      this.socket.on(eventName, (data: any) => {
        Subscriber.next(data);
      });
    });
  }

  emitEvent(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  /**
   * We check if the browser supports push notifications, if it does, we request a subscription from
   * the browser, then we send the subscription to our server
   */
  //   pushSubscription() {
  //     let param: any = new HttpParams().set(
  //       "appId",
  //       `Fuel-${this.session.getActiveSite().id}-${
  //         this.session.getActiveUser().id
  //       }`
  //     );
  //     if (!this.swPush.isEnabled) {
  //       console.log("Notification is not enebled");
  //       this.swPush
  //         .requestSubscription({
  //           serverPublicKey: this.publicKey,
  //         })
  //         .then((sub) => {})
  //         .catch((err) => console.log(err));
  //     }
  //     this.swPush
  //       .requestSubscription({
  //         serverPublicKey: this.publicKey,
  //       })
  //       .then((subKeys: any) => {
  //         if (subKeys !== undefined && param.updates.length !== 0) {
  //           this.http
  //             .post(
  //               `${this.localBaseURL}register-user`,
  //               JSON.stringify(subKeys),
  //               {
  //                 params: param,
  //                 headers: new HttpHeaders({
  //                   "Content-Type": "application/json",
  //                 }),
  //               }
  //             )
  //             .subscribe((data) => {
  //               console.log(data);
  //             });
  //         }
  //       })
  //       .catch((err) => console.log(err));
  //   }

  pushSubscription() {
    this.swPush
      .requestSubscription({
        serverPublicKey: this.publicKey,
      })
      .then((subKeys: any) => {
        if (subKeys !== undefined) {
          let user = {
            id: this.session.getActiveUser()?.user?.id,
            appId: 'Fuel-Marksol',
            swPush: subKeys,
          };
          this.socket.emit('user_connect', user);
        }
      })
      .catch((err) => console.log(err));
  }

  requestPermission(): void {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        console.log('Notification permission:', permission);
      });
    }
  }

  emitWebNoti(message: string): void {
    const notification = {
      title: 'Fuel Marking Program',
      message,
      link: 'https://system.ssnbsfuelmarking.org',
      actions: [
        {
          action: 'http://localhost:4200',
          title: 'https://system.ssnbsfuelmarking.org',
        },
      ],
    };
    const notificationId = this.session.getActiveUser().id;
    let params: any = new HttpParams();
    params = params.set(notification);

    const url = `https://notifications.arxcess.com/app/notify/${notificationId}`;

    this.http.get(url, { params }).subscribe((data) => {
      console.log(data);
    });
  }

  // localNoti() {
  //     Notification.requestPermission().then(perm => {
  //         if (perm === 'granted') {
  //             const notification = new Notification("SSNBS Fuel Marking Program", {
  //                 body: data.message,
  //                 data: data,
  //                 icon: "assets/images/logo.png",
  //                 vibrate: [200, 100, 200]
  //                 // tag: data.tag
  //             })

  //             notification.addEventListener('click', () => {
  //                 window.open(notification.data.link)
  //             });
  //         }
  //     })
  // }
}
