import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';

import {Observable} from 'rxjs';
import {AlertService} from '../../alert/alert.service';
import {SessionStorageService} from '../../session/session-storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate {
    role!: any;

    constructor(
        private alertService: AlertService,
        private session: SessionStorageService,
        private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.validateCategoryAccess(
            route.data.category,
            route.data.item,
            route.data.privilege
        );
    }

    validateCategoryAccess(
        category: string,
        item: string,
        privilege: string
    ): boolean {
        let allowed = false;
        this.role = this.session.getActiveUserRole();
        return true;
        // if (this.role === 'SUPER_ADMIN' || this.role === 'ARXCESS') {
        //     return true;
        // }
        // return allowed;
    }
}
