import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { SessionStorageService } from '../session/session-storage.service';

@Directive({
  selector: '[hideByUserType]',
})
export class HideByUserTypeDirective implements OnInit {

  constructor(
    public session: SessionStorageService,
    private el: ElementRef) { }

  @Input() userType!: string[];

  ngOnInit(): void {
    setTimeout(() => {
      this._check();
    })
  }

  _check(): void {
    if (!this.userType.includes(this.session.getActiveUser()?.type)) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}

