
export const sidebarMenu = [
    {
        text: 'Dashboard',
        link: '/app/dashboard',
        icon: 'bi-speedometer',
        access: 'dashboard|access',
        items: [],
        reports: []
    },
    {
        text: 'Jobs',
        icon: 'bi-people-fill',
        // link: '/app/job/jobs',
        access: 'job_settings|access',
        items: [
            {
                text: 'Data Entry',
                link: '/app/job/jobs/data-entry',
            },
            {
                text: 'Lab Processing',
                link: 'inventory/stock-items',
            },
            {
                text: 'Cashier',
                link: 'inventory/stock-items',
            },
            {
                text: 'Archive',
                link: 'inventory/stock-items',
            },
        ]
    },
    {
        text: 'Inventory',
        link: '/app/stock',
        icon: 'bi-boxes',
        access: 'inventory|access',
        items: [
            {
                text: 'Stock items',
                link: 'inventory/stock-items',
            },
            {
                text: 'Direct Purchases',
                access: 'purchase|access',
                link: 'inventory/direct-puchase/all-direct-purchases'
            },
        ],
        reports: []
    },
    {
        text: 'Accounting',
        icon: 'bi-journals',
        items: [
            {
                text: 'Chart of Accounts',
                link: 'accounting/chart-of-accounts',
            },
            {
                text: 'Journals',
                link: 'accounting/journals',
            },
        ],
        reports: [
            {
                text: 'Balance Sheet',
                link: 'accounting/report/balance-sheet',
            },
            {
                text: 'Income Statement',
                link: 'accounting/report/income-statement',
            },
            {
                text: 'Trial Balance',
                link: 'accounting/report/trial-balance',
            },
            {
                text: 'Cash Flow',
                link: 'accounting/report/cash-flow-statement',
            },
            {
                text: 'Changes in Equity',
                link: 'accounting/report/changes-in-equity',
            }
        ]
    },
    {
        text: 'Others',
        icon: 'bi-boxes',
        items: [
            {
                text: 'Clients',
                link: 'others/customers',
            },
            {
                text: 'Drivers',
                link: 'others/drivers',
            },
            {
                text: 'Vendors',
                link: 'others/vendors',
            },
        ],
        reports: []
    },
];
