<div class="container" style="padding-top: 5rem !important;">
    <div class="d-flex justify-content-end mb-1">
        <button class="btn btn-info btn-sm" (click)="_back()">
            <em class="bi bi-arrow-left"></em>
            back</button>
    </div>
    <div class=" mb-3">
        <ul class="nav nav-tabs border-0 p-1">
            <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['users']" href="javascript:void(0);">
                    Users</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['roles']"
                    href="javascript:void(0);">Roles</a>
            </li>
        </ul>
    </div>
    <router-outlet></router-outlet>
</div>