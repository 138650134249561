import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnChanges {

  @Input() chuckRecords: Array<any> = [];
  // version 1.0.0 paginator event for others
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onPageChange: EventEmitter<any> = new EventEmitter();

  // version 2.0.0 paginator event for schedules
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onPageChangeV2: EventEmitter<Observable<any>> = new EventEmitter();

  pages: number[] = [];
  activePage!: number;


  constructor() { }

  ngOnChanges(): void {
    const pageCount = this.chuckRecords?.length;
    this.pages = this.getArrayOfPages(pageCount || 0);
    this.activePage = 1;
    this.onPageChange?.emit({ chunks: this.chuckRecords, currentPage: this.activePage - 1, controlClicked: false });
    this.onPageChangeV2?.emit(new BehaviorSubject({ chunks: this.chuckRecords, currentPage: this.activePage - 1, controlClicked: false }));
  }

  private getArrayOfPages(pageCount: number): number[] {
    const pageArray: number[] = [];

    if (pageCount > 0) {
      for (let i = 1; i <= pageCount; i++) {
        pageArray.push(i);
      }
    }
    return pageArray;
  }

  onClickPage(pageNumber: number): void {
    if (pageNumber < 1) { return; }
    if (pageNumber > this.pages?.length) { return; }
    this.activePage = pageNumber;
    this.onPageChange?.emit({ chunks: this.chuckRecords, currentPage: this.activePage - 1, controlClicked: true });
    this.onPageChangeV2?.emit(new BehaviorSubject({ chunks: this.chuckRecords, currentPage: this.activePage - 1, controlClicked: true }));
  }
}
