export const GENDER = [
  { name: 'Male', id: 'Male' },
  { name: 'Female', id: 'Female' }
];

export const LEVELS = [
  { name: 'Senior', id: 'Senior' },
  { name: 'Junior', id: 'Junior' }
];

export const SALUTATION = [
  { name: 'Sir', id: 'Sir' },
  { name: 'Madam', id: 'Madam' },
  { name: 'Mr', id: 'Mr' },
  { name: 'Mrs', id: 'Mrs' },
  { name: 'Miss', id: 'Miss' }
];

export const SEVERITY = [
  { name: 'High', id: 'High' },
  { name: 'Low', id: 'Low' },
  { name: 'Moderate', id: 'Moderate' }
];

export const RELATIONSHIP = [
  { name: 'Son', id: 'son' },
  { name: 'Daughter', id: 'daughter' },
  { name: 'Father', id: 'father' },
  { name: 'Mother', id: 'mother' },
  { name: 'Brother', id: 'brother' },
  { name: 'Sister', id: 'sister' },
  { name: 'Others', id: 'others' }
];

export const MARITALSTATUS = [
  { name: 'Married', id: 'Married' },
  { name: 'Single', id: 'Single' },
  { name: 'Widowed', id: 'Widowed' },
  { name: 'Divorced', id: 'Divorced' },
  { name: 'Others', id: 'Others' }
];

export const UNITS = [
  { name: 'Kilogram', id: 'kilogram(kg)' },
  { name: 'Meter', id: 'meter(m)' },
  { name: 'Kelvin', id: 'Kelvin(K)' },
  { name: 'Ampere', id: 'ampere(A)' },
  { name: 'Density', id: 'density(kg/m^3)' },
  { name: 'Tonnes', id: 'tonnes(t)' },
  { name: 'Pieces', id: 'pieces(pcs)' },
  { name: 'Others', id: 'Others' }
];

export const KPIRESULTSTATUS = [
  { name: 'Passed', id: 'PASSED' },
  { name: 'Failed', id: 'FAILED' }
];

export const EMPLOYEEMENTSTATUS = [
  { name: 'Contract', id: 'Contract' },
  { name: 'Permanent', id: 'Permanent' }
];

export const REPORTTYPE = [
  { name: 'Summary', id: 'Summary' },
  { name: 'Detailed', id: 'Detailed' }
];

export const MONTH = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
];

export const DAYS = [
  {
    name: 'sunday',
    nameSM: 'Sun'
  },
  {
    name: 'monday',
    nameSM: 'Mon'
  },
  {
    name: 'tuesday',
    nameSM: 'Tue'
  },
  {
    name: 'wednesday',
    nameSM: 'Wed'
  },
  {
    name: 'thursday',
    nameSM: 'Thu'
  },
  {
    name: 'friday',
    nameSM: 'Fri'
  },
  {
    name: 'saturday',
    nameSM: 'Sat'
  }
];

export const TRANSMODE = [
  'Road',
  // 'Air', 'Railway', 'Water', 'Pipeline'
];

export const SALETYPE = [
  'Import',
  // 'Export',
];

export const PERIODENUM = [
  'FISCAL_YEAR', 'LAST_7_DAYS', 'PREVIOUS_12_MONTHS',
  'PREVIOUS_MONTH', 'PREVIOUS_QUARTER', 'QUARTER', 'THIS_MONTH', 'THIS_WEEK', 'PREVIOUS_WEEK'
];
// 'CUSTOM'

export const ColumnOptions = {
  series: [
    // {
    //     name: 'Petrolium',
    //     data: [44, 55, 41, 67, 22, 43]
    // },
  ],
  chart: {
    type: 'bar',
    height: 350,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      endingShape: 'rounded',
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  xaxis: {
    type: 'string',
    // categories: ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT',
    //     '01/05/2011 GMT', '01/06/2011 GMT'
    // ],
    categories: [],
  },
  legend: {
    position: 'bottom',
    offsetX: 40,
  },
  fill: {
    opacity: 1,
  },
  title: {
    text: '',
    align: 'left',
  },
  yaxis: {
    title: {
      text: 'Litres',
    },
  },
  tooltip: {
    y: {
      formatter(val: any): string {
        return 'Ltr ' + val + '';
      },
    },
  },
};

export const ColumnStackedOptions = {
  series: [
    // {
    //     name: 'Petrolium',
    //     data: [44, 55, 41, 67, 22, 43]
    // },
  ],
  chart: {
    type: 'bar',
    height: 350,
    stacked: true,
    toolbar: {
      show: true,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      endingShape: 'rounded',
    },
  },
  dataLabels: {
    enabled: true,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  xaxis: {
    type: 'string',
    // categories: ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT',
    //     '01/05/2011 GMT', '01/06/2011 GMT'
    // ],
    categories: [],
  },
  legend: {
    position: 'bottom',
    offsetX: 40,
  },
  fill: {
    opacity: 1,
  },
  title: {
    text: '',
    align: 'left',
  },
  yaxis: {
    title: {
      text: 'Litres',
    },
  },
  tooltip: {
    y: {
      formatter(val: any): string {
        return 'Ltr ' + val + '';
      },
    },
  },
};

export const ChartOptions: any = {
   series: [
     // {
     //     name: 'sales',
     //     data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 69, 91, 148]
     // }
   ],
   chart: {
     height: 350,
     type: 'line',
     zoom: {
       enabled: false,
     },
   },
   dataLabels: {
     enabled: false,
   },
   stroke: {
     curve: 'smooth',
   },
   title: {
     text: '',
     align: 'left',
   },
   grid: {
     row: {
       colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
       opacity: 0.5,
     },
   },
   xaxis: {
     title: {
       text: 'Months',
     },
     categories: [],
     // categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
   },
   yaxis: {
     title: {
       text: 'Amount (USD)',
     },
   },
   tooltip: {
     y: {
       formatter(val: any): string {
         return '$ ' + val + '';
       },
     },
   },
 };

export type PieOptions = {
   series: ApexNonAxisChartSeries;
   chart: ApexChart;
   responsive: ApexResponsive[];
   labels: any;
   fill: ApexFill;
   legend: ApexLegend;
   dataLabels: ApexDataLabels;
 };

export const PieChartOptions: any = {
   series: [44, 55, 13, 43, 22],
   chart: {
     type: 'donut',
   },
   labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
   responsive: [
     {
       breakpoint: 480,
       options: {
         chart: {
           width: 200,
         },
         legend: {
           position: 'bottom',
         },
       },
     }
   ]
 };


