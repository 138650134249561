import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';

@Component({
    selector: 'app-email-confrim',
    templateUrl: './email-confrim.component.html',
    styleUrls: ['./email-confrim.component.scss']
})
export class EmailConfrimComponent implements OnInit {

    isDisplay = true;
    isSuccess = false;
    isError = false;
    loadingStatus = false;
    title = '';
    data: any;
    newPassword = new FormControl(null, [Validators.required, Validators.minLength(4)]);

    constructor(
        public route: ActivatedRoute,
        public apiConnect: ApiConnectionService,
    ) {

        this.route.queryParams.subscribe((val: any) => {
            console.log(encodeURIComponent(val.data))
            const props = Object.getOwnPropertyNames(val);
            if (props.length > 0) {
                if (val.data) {
                    this.data = encodeURIComponent(encodeURIComponent(val.data));
                }
            }
        });
    }

    ngOnInit(): void {

    }

    _submit(): void {
        if (this.data !== undefined) {
            this.confirmEmail();
        } else {
            this.isError = true;
            this.title = 'Error Invalid link, Please try again';
         }
    }

    confirmEmail(): void {
        this.loadingStatus = true;
        this.apiConnect.secureConnect('POST', 'confirmEmail', { password: this.newPassword.value }, undefined, this.data, this.onConfirmEmail.bind(this)
        );
    }
    onConfirmEmail(res: any, status: string, callback: any): void {
        this.loadingStatus = false;
        this.isDisplay = false;
        if (status === 'success') {
            this.title = res.message;
            this.isSuccess = true;
            callback('success', 'Success', ' Successfully');
        } else {
            this.isError = true;
            this.title = res.message.error;
            callback('danger', 'Error', res.message.error);
        }
    }


}
