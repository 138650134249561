import { Component, OnInit, DoCheck, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { applyMixins } from '../../mixin/mixin';
import { BaseFormComponent } from '../base-form/base-form.component';

class BaseClass { }
// tslint:disable-next-line: no-empty-interface
interface BaseClass extends BaseFormComponent { }
applyMixins(BaseClass, [BaseFormComponent]);

@Component({
  selector: 'app-input-figure',
  templateUrl: './input-figure.component.html',
  styleUrls: ['./input-figure.component.scss']
})
export class InputFigureComponent extends BaseClass implements OnInit, DoCheck {

  @Input() fieldId: string | null = null;
  @Input() cursorInputId?: string;
  @Input() control!: FormControl;
  @Input() fieldType = '';
  @Input() placeHolder: string | null = null;
  @Input() disabled = false;
  @Input() required = false;
  @Input() pattern?: string;
  @Input() minNo: number | null = null;
  @Input() maxNo: number | null = null;
  @Input() readOnly = false;
  @Input() mb0: any = undefined;

  fakeControl: FormControl = new FormControl();
  inputCursor!: any;

  ngOnInit(): void {
    super.ngOnInit();

    this.initialInput();

    this.fakeControl.valueChanges.subscribe(val => {
      this.formatFigure(val);
    });
  }

  ngDoCheck(): void {
    super.ngDoCheck();
    
  }

  initialInput(): void {
    if (this.control.value) {
      this.formatFigure(this.control.value.toString());
    }
  }

  formatNumber(n: string): string {
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatFigure(input: string): void {
    var tempInput = input;
    var originalLen = input.length;
    this.inputCursor = document.querySelector('#' + this.cursorInputId);
    var cusrorPos = this.inputCursor?.selectionStart;

    // return for empty string.
    if (tempInput.length === 0) {
      this.control.setValue(input.split(',').join(''));
      return;
    }

    if (tempInput.indexOf(".") >= 0) {
      let decimalPos = tempInput.indexOf(".");
      var leftSide = tempInput.substring(0, decimalPos);
      var rightSide = tempInput.substring(decimalPos);
      leftSide = this.formatNumber(leftSide);

      tempInput = leftSide + rightSide;
      this.fakeControl.setValue(tempInput, { emitEvent: false });
    } else {
      tempInput = this.formatNumber(tempInput);
      this.fakeControl.setValue(tempInput, { emitEvent: false });
    }

    var updatedLen = tempInput.length;
    cusrorPos = updatedLen - originalLen + cusrorPos;
    this.inputCursor?.setSelectionRange(cusrorPos, cusrorPos);

    this.control.setValue(input.split(',').join(''));
  }

}
