<div class="row mx-0 mb-2 mt-2">
    <div class="col-12 d-flex px-0">
        <div>
            <h5 class="font-weight-bold text-muted">Chemicals</h5>
        </div>
        <div class="entries mr-2 ml-auto">
            <select [formControl]="showEntries" class="custom-select custom-select-sm form-control form-control-sm">
                <option [ngValue]="10">10</option>
                <option [ngValue]="50">50</option>
                <option [ngValue]="100">100</option>
                <option [ngValue]="markersList.length">Show All</option>
            </select>
        </div>
        <div class="">
            <button class="btn-sm btn btn-primary" (click)="openModal(newMarker)">
                New Chemical
            </button>
        </div>
    </div>
</div>

<!-- table data -->
<div class="row mx-0">
    <div class="col-12 px-0">
        <div *ngIf="!loading">
            <!-- <div class="d-block"> -->
            <div class="table-card shadow-sm" *ngIf="markersList.length != 0">
                <div class="table-card-body">
                    <div class="table-responsive">
                        <table class="table themed-table themed-table-plain">
                            <thead>
                                <th class="cell">
                                    <div class="cell-border">
                                        <span>Name</span>
                                    </div>
                                </th>
                                <th class="cell">
                                    <div class="cell-border">
                                        <span>Re-Order Level</span>
                                    </div>
                                </th>
                                <th class="cell">
                                    <div class="cell-border">
                                        <span>Unit</span>
                                    </div>
                                </th>
                                <th class="cell">
                                    <div class="cell-border justify-content-center">Status</div>
                                </th>
                                <th class="cell text-center" style="min-width: 10px;">
                                    <div class="cell-border">Action</div>
                                </th>
                            </thead>
                            <tbody>
                                <!-- data loader -->
                                <tr *ngFor="let item of chunkedRecords[currentPage]">
                                    <td>
                                        <span class="font-weight-bolder text-black">{{ item.name }}</span>
                                    </td>
                                    <td>
                                        <span class="font-weight-bolder text-info">{{ item.reOrderLevel }} </span>
                                    </td>
                                    <td>
                                        <span class="font-weight-bolder text-info">{{ item.unit }}</span>
                                    </td>
                                    <td class="text-center">
                                        <app-status [statusType]="item.status"></app-status>
                                    </td>
                                    <td class="text-center">
                                        <div class="action-dropdown">
                                            <button class="btn btn-light btn-sm dropdown-toggle" data-toggle="dropdown">
                                                <em class="fa fa-ellipsis-h m-1"></em>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <button class="dropdown-item" (click)="_editMarker(editMarker,item)">
                                                    <em class="bi bi-pencil-square text-primary mr-2"></em> Edit
                                                </button>
                                                <button class="dropdown-item" (click)="deleteMarker(item.id)">
                                                    <em class="bi bi-trash text-danger mr-2"></em> Delete
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="text-center" *ngIf="loading">
                                    <td colspan="6">
                                        <app-loading-placeholder></app-loading-placeholder>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="markersList.length === 0" [hidden]="loading">
                <app-empty-placeholder></app-empty-placeholder>
            </div>
        </div>

        <div *ngIf="loading" class="text-center">
            <app-loading-placeholder></app-loading-placeholder>
        </div>

        <!-- pagination -->
        <div class="d-flex justify-content-center align-items-center mt-3" *ngIf="markersList.length != 0">
            <app-paginator
                [chuckRecords]="markersList | filter :filterList :searchFilter :false | paginator :elementsPerPage"
                (onPageChange)="displayActivePage($event)">
            </app-paginator>
        </div>
    </div>
</div>

<!-- new marker form -->
<ng-template #newMarker>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="newBtn" [modalTitle]="'New Chemical'"
        [condition]="markerForm.invalid">
        <div class="row mx-0">
            <div class="col-12">
                <app-input [required]="true" [fieldId]="'name'" [control]="customCotrol('name', markerForm)">
                </app-input>
            </div>
            <div class="col-6">
                <app-input [required]="true" [fieldId]="'reorderLevel'" [fieldType]="'number'"
                    [control]="customCotrol('reorderLevel', markerForm)">
                </app-input>
            </div>
            <div class="col-6">
                <label for="">Unit of Measure <span class="text-danger">*</span></label>
                <div class="input-group input-group-sm mb-3">
                    <select [formControl]="customCotrol('unit',markerForm)"
                        class="form-control custom-select custom-select-sm">
                        <option *ngFor="let unit of units" [ngValue]="unit">
                            {{ unit }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-12">
                <app-input [required]="true" [fieldId]="'code'" [control]="customCotrol('code', markerForm)">
                </app-input>
            </div>
        </div>
    </app-modal>
</ng-template>

<!-- new marker form -->
<ng-template #editMarker>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="editBtn" [modalTitle]="'Edit Chemical'"
        [condition]="editMarkerForm.invalid">
        <div class="row mx-0">
            <div class="col-12">
                <app-input [required]="true" [fieldId]="'name'" [control]="customCotrol('name', editMarkerForm)">
                </app-input>
            </div>
            <div class="col-6">
                <app-input [required]="true" [fieldId]="'reorderLevel'" [fieldType]="'number'"
                    [control]="customCotrol('reorderLevel', editMarkerForm)">
                </app-input>
            </div>
            <div class="col-6">
                <label for="">Unit of Measure <span class="text-danger">*</span></label>
                <div class="input-group input-group-sm mb-3">
                    <select [formControl]="customCotrol('unit',editMarkerForm)"
                        class="form-control custom-select custom-select-sm">
                        <option *ngFor="let unit of units" [ngValue]="unit">
                            {{ unit }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-12">
                <app-input [required]="true" [fieldId]="'code'" [control]="customCotrol('code', editMarkerForm)">
                </app-input>
            </div>
        </div>
    </app-modal>
</ng-template>