<div class="auth-wrapper">
  <div class="auth-content">
    <div class="row my-auto">
      <div class="col-lg-12 col-md-12 col-sm-12 text-center align-items-center">
        <img width="850" src="assets/images/access.png" alt="" style="width: 20%;">
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 text-center align-items-center mt-4 text-primary">
        <h3 class="font-weight-bolder">403 Access Denied for this page</h3>
        <!-- <a href="javascript:void(0);" (click)="back()"><em class="fas fa-arrow-left"></em> return back</a> -->
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 text-center">
        <span>More links</span>
        <p>
          <a [routerLink]="['/app/job']">Jobs <em class="fas fa-arrow-right"></em></a>
        </p>
      </div>
    </div>
  </div>
</div>