import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService } from '../../services/settings/settings.service';
import { MONTH } from '../labels/others';

@Pipe({ name: 'customdateformat' })
export class DateFormatPipe implements PipeTransform {
  constructor(public settings: SettingsService) { }

  transform(value: any, type: string): any {
    if (type === 'time12') {
      return this.covert24TimeTo12(value);
    }

    if (type === 'dateString') {
      return this.convertDateToString(value);
    }

    if (type === 'dateDiffHrs') {
      return this.dateDifferrenceHrs(value.split('D')[0], value.split('D')[1]);
    }

    if (type === 'secToHM') {
      return this.covertSecondToHM(value);
    }

    if (type === 'slashDate') {
      return this.convertToSlashString(value);
    }

    if (type === 'toYear') {
      return this.getYear(value);
    }

    if (type === 'toMonth') {
      return this.getMonth(value);
    }

    if (type === 'toDateTime') {
      return this.convertToDateTime(value);
    }
    if (type === 'toEpoch') {
      if (value) {
        return this.convertStringToEpoch(value);
      } else {
        return undefined
      }
    }
  }

  getYear(value: Date): string {
    return value.getFullYear().toString();
  }

  getMonth(value: Date): string {
    return MONTH[value.getMonth()];
  }

  convertToDateTime(value: number): string {
    const date = new Date(value);
    const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    const h = date.getHours();
    const m = date.getMinutes();

    return [date.getFullYear(), mnth, day].join('/') + '   ' + this.settings.formatToLocalTime(h, m);
  }

  covert24TimeTo12(time: string): string {
    const H: any = +time.substr(0, 2);
    // tslint:disable-next-line: radix
    return this.settings.formatToLocalTime(H, time.substr(3, 2));
  }

  convertToSlashString(value: number): string {
    const date = new Date(value);
    const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('/');
  }

  convertDateToString(date: string): string {
    const date1 = new Date(date);
    return date1.toDateString();
  }

  dateDifferrenceHrs(date1: string, date2: string): string {
    const ddate1 = new Date(date1).valueOf();
    const ddate2 = new Date(date2).valueOf();
    const min = Math.floor((ddate2 - ddate1) / (1000 * 60));
    const hours = Math.floor(min / 60);
    return hours + 'h' + ' ' + (min - (hours * 60)) + 'm';
  }

  covertSecondToHM(time: number): string {
    const min = Math.floor(time / 60);
    const hours = Math.floor(min / 60);
    return hours + 'h' + ' ' + (min - (hours * 60)) + 'm';
  }

  convertStringToEpoch(datestring: any) {
    var dateParts = datestring.replace(/-/g, "/");
    var someDate = new Date(dateParts);
    return someDate.getTime();
  }
}
