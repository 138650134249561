<div class="row mr-1">
    <div class="btn-group" role="group" aria-label="Basic example">
        <button *ngIf="jobDetails.button === 'REMARKS'" class="btn btn-primary btn-sm" (click)="openModal(updateJob)">
            <em class="bi bi-check2-square mr-1"></em>Remarks
        </button>
        <!-- <button *ngIf="jobDetails.button === 'ACKNOWLEDGEMENT FORM'" class="btn btn-outline-info btn-sm"
            (click)="getPDF('AcknowledgementForm')">
            <em class="bi bi-file-pdf text-info mr-2"></em>
            Acknowledgement Form
        </button> -->
        <!-- <button [hidden]="jobDetails.button !== 'DRIVER ACKNOWLEDGE'" *ngIf="jobDetails.jobStatus === 'DIPPED'"
            class="btn btn-outline-info btn-sm" (click)="acceptInfo()">
            <em class="bi bi-file-earmark-check mr-1"></em>
            Driver Acknowledgement
        </button> -->
        <button hideSubModule [module]="JobRoles.name" [query]="JobRoles.children.GenInvoice" [disabled]="gettingPDF"
            *ngIf="jobDetails.jobStatus === 'DIPPED'" class="btn btn-primary btn-sm" (click)="createVoucherJob()">
            <em class="bi bi-receipt-cutoff mr-2"></em>
            Generate Invoice
        </button>

        <ng-container *ngIf="!jobDetails?.isCreditRequested  && jobDetails.jobStatus === 'INVOICED'">
            <button hideSubModule [module]="'Credit'" [query]="'Request Credit Jobs'" [disabled]="gettingPDF"
                *ngIf="jobDetails.paymentStatus === 'PENDING'" class="btn btn-warning btn-sm ml-2"
                (click)="requestCredit()">
                <em class="bi bi-wechat mr-2"></em>
                Request Credit
            </button>
        </ng-container>

        <ng-container *ngIf="!jobDetails?.isCreditAllowed">
            <button hideSubModule [module]="'Credit'" [query]="'Approve Credit Jobs'" [disabled]="gettingPDF"
                *ngIf="jobDetails.isCreditRequested" class="btn btn-warning btn-sm ml-2" (click)="permitCredit()">
                <em class="bi bi-hand-thumbs-up mr-2"></em>
                Permit Credit
            </button>
        </ng-container>

        <ng-container class="ml-2" *ngIf="jobDetails.paymentStatus !== 'PAID'">
            <button hideSubModule [module]="JobRoles.name" [query]="JobRoles.children.RecvPayment"
                *ngIf="jobDetails.jobStatus === 'INVOICED'" class="btn btn-primary btn-sm"
                (click)="openModal(receivePay)">
                <em class="bi bi-credit-card-2-front mr-1"></em>
                Receive Payment
            </button>
        </ng-container>
        <ng-container class="ml-2" *ngIf="jobDetails.paymentStatus !== 'PAID'">
            <button hideSubModule [module]="JobRoles.name" [query]="JobRoles.children.PayClientBal"
                *ngIf="jobDetails.jobStatus === 'INVOICED'" class="btn btn-warning btn-sm"
                (click)="openModal(consigneeChange)">
                <em class="bi bi-person mr-1"></em>
                Change Client
            </button>
        </ng-container>
        <!-- <button [disabled]="gettingPDF" *ngIf="jobDetails.jobStatus === 'PENDING_PAYMENT'"
            class="btn btn-primary btn-sm" (click)="getPDF('PaymentVoucher')">
            <em class="bi bi-file-pdf mr-1"></em>
            Print Voucher
        </button> -->
        <!-- <button *ngIf="jobDetails.jobStatus === 'PAID'" class="btn btn-outline-danger btn-sm" (click)="rejectPayment()">
            <em class="bi bi-x-circle mr-1"></em>
            Reject Payment
        </button> -->
        <!-- <button *ngIf="jobDetails.jobStatus === 'PAID'" class="btn btn-danger btn-sm" (click)="rejectPayment()">
            <em class="bi bi-x-circle mr-1"></em>
            Reject Payment
        </button>
        <button *ngIf="jobDetails.jobStatus === 'PAID'" class="btn btn-primary btn-sm" (click)="verifyPayment()">
            <em class="bi bi-check-circle mr-1"></em>
            Verify Payment
        </button> -->
        <ng-container *ngIf="jobDetails?.consigner?.client?.canClearWithNoPayment" class="ml-2">
            <button hideSubModule [module]="JobRoles.name" [query]="JobRoles.children.ToLab"
                [hidden]="role.name === 'Data Entrant' || role.name === 'Data Entrant'"
                *ngIf="jobDetails.jobStatus === 'INVOICED'" class="btn btn-primary btn-sm ml-2" (click)="proceedLab()">
                <em class="bi bi-activity mr-1"></em>
                Proceed To Lab
            </button>
        </ng-container>

        <ng-container class="ml-2" *ngIf="jobDetails.jobStatus === 'INVOICED'">
            <button hideSubModule [module]="JobRoles.name" [query]="JobRoles.children.ToLab"
                [hidden]="role.name === 'Data Entrant' || role.name === 'Data Entrant'"
                *ngIf="jobDetails.paymentStatus === 'PAID' ||  jobDetails.isCreditAllowed"
                class="btn btn-primary btn-sm ml-2" (click)="proceedLab()">
                <em class="bi bi-activity mr-1"></em>
                Proceed To Lab
            </button>
        </ng-container>

        <button hideSubModule [module]="JobRoles.name" [query]="JobRoles.children.ProJob"
            [hidden]="role.name === 'Cashier' || role.name === 'Data Entrant'"
            *ngIf="jobDetails.jobStatus === 'LAB_PENDING'" class="btn btn-primary btn-sm" (click)="processJob()">
            <em class="bi bi-activity mr-1"></em>
            Process Job
        </button>
        <!-- <button *ngIf="jobDetails.jobStatus === 'LAB_PROCESSING'" class="btn btn-primary btn-sm"
            (click)="openModal(tests)">
            <em class="bi bi-funnel mr-1"></em>
            Lab Tests
        </button> -->
        <button hideSubModule [module]="JobRoles.name" [query]="JobRoles.children.CompJob"
            *ngIf="jobDetails.jobStatus === 'LAB_PROCESSING'" class="btn btn-primary btn-sm"
            (click)="openModal(completeJob)">
            <em class="bi bi-clipboard-check-fill mr-1"></em>
            Complete Job
        </button>
    </div>
    <div hideSubModule [module]="JobRoles.name" [query]="JobRoles.children.EditDip" class="">
        <button *ngIf="jobDetails.jobStatus === 'DIPPED'" class="btn btn-primary btn-sm mr-2"
            [routerLink]="['/app/job/new-job']" [queryParams]="{id:jobDetails?.id}">
            <em class="bi bi-pencil text-info mr-2"></em>
            Edit
        </button>
    </div>
    <div class="action-dropdown mr-1" *ngIf="jobDetails.jobStatus === 'COMPLETED'">
        <button class="btn btn-primary btn-sm dropdown-toggle" data-toggle="dropdown">
            <em class="fa m-1" [ngClass]="{'fa-spinner fa-spin': gettingPDF}"></em>
            <em *ngIf="jobDetails.jobStatus === 'COMPLETED'" class="fa fa-ellipsis-v"></em>
        </button>
        <div *ngIf="jobDetails.jobStatus === 'COMPLETED'" class="dropdown-menu dropdown-menu-right">
            <ng-container>
                <label for="" class="dropdown-header">Exports</label>
                <div class="dropdown-divider"></div>
                <!-- <a class="dropdown-item" (click)="getPDF('AcknowledgementForm')">
                    <em class="bi bi-file-pdf text-info mr-2"></em>
                    Acknowledgement Form
                </a> -->
                <a hideSubModule [module]="JobRoles.name" [query]="JobRoles.children.Print"
                    class="dropdown-item cursor-pointer" (click)="getPDF('CertificateForm')">
                    <em class="bi bi-file-pdf text-info mr-2"></em>
                    Certificate Form
                </a>
                <!-- <a class="dropdown-item cursor-pointer" (click)="getPDF('PaymentVoucher')">
                    <em class="bi bi-file-pdf text-info mr-2"></em>
                    Payment Vocher
                </a> -->
            </ng-container>
        </div>
    </div>

    <button *ngIf="gettingPDF" class="btn btn-primary btn-sm dropdown-toggle mx-1" data-toggle="dropdown">
        <em class="fa fa-spinner fa-spin m-1"></em>
    </button>
</div>

<!-- updateJob form -->
<ng-template #updateJob>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="jobBtn" [modalTitle]="'Update Job Remarks'"
        [condition]="jobUpdateForm.invalid">
        <div class="row mx-0" [formGroup]="jobUpdateForm">
            <div class="col-12">
                <div class="form-group">
                    <label class="" for="">Variation Comment</label>
                    <select class="form-control custom-select-sm"
                        [formControl]="customCotrol('variationComment', jobUpdateForm)">
                        <option [ngValue]="name" *ngFor="let name of comments">
                            {{ name }}
                        </option>
                    </select>
                </div>
                <app-input-date [fieldId]="'dippingDate'" [control]="customCotrol('dippingDate', jobUpdateForm)">
                </app-input-date>
                <app-input-textarea [fieldId]="'dipperRemarks'"
                    [control]="customCotrol('dipperRemarks', jobUpdateForm)">
                </app-input-textarea>
            </div>
        </div>
    </app-modal>
</ng-template>

<!-- receive payment -->
<ng-template #receivePay>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="recBtn" [modalTitle]="'Receive Payment'"
        [condition]="receivePayForm.invalid">
        <form action="" [formGroup]="receivePayForm">
            <div class="row mx-0">
                <div class="col-12">
                    <app-input-date [required]="true" [fieldId]="'date'"
                        [control]="customCotrol('date',receivePayForm)">
                    </app-input-date>
                    <app-input-figure [required]="true" [fieldId]="'amount'"
                        [control]="customCotrol('amount', receivePayForm)">
                    </app-input-figure>
                    <div class="form-group">
                        <label for="">Currency</label>
                        <select class="form-control" [formControl]="customCotrol('currency', receivePayForm)">
                            <option *ngFor="let item of currency" [value]="item">{{item}}</option>
                        </select>
                    </div>
                    <!-- <app-input-select-search [required]="true" [fieldId]="'debitLedgerId'"
                        [uniqueClass]="'debitLedgerId'" [control]="customCotrol('debitLedgerId',receivePayForm)"
                        [predicate]="postableLedgersPredicate" [options]="postableLedgers"
                        [optionsList]="postableLedgersSub">
                    </app-input-select-search> -->
                    <app-input [required]="true" [fieldId]="'ref'"
                        [control]="customCotrol('bankReference', receivePayForm)">
                    </app-input>
                    <app-input [fieldId]="'notes'" [control]="customCotrol('notes', receivePayForm)">
                    </app-input>
                    <app-input-file [fieldId]="'attachment'" [control]="customCotrol('scan', receivePayForm)">
                    </app-input-file>
                </div>
            </div>
        </form>
    </app-modal>
</ng-template>

<!-- completeJob form -->
<ng-template #completeJob>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="compBtn" [modalTitle]="'Complete Job'"
        [condition]="completeForm.invalid">
        <div class="row mx-0" [formGroup]="completeForm">
            <div class="col-12">
                <app-input-textarea [fieldId]="'labRemarks'" [control]="customCotrol('labRemarks', completeForm)">
                </app-input-textarea>
            </div>
        </div>
    </app-modal>
</ng-template>

<!-- consignee change form -->
<ng-template #consigneeChange>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="changeBtn" [modalTitle]="'Change Client'"
        [condition]="changeConsignee.invalid">
        <div class="row mx-0">
            <div class="col-12">
                <app-input-select-new [selectSearch]="true" [predicate]="customersPredicate" [options]="customersList"
                    [optionsList]="customersSub" [mb]="'mb-0'" [fieldId]="'client'"
                    [control]="changeConsignee"></app-input-select-new>
            </div>
        </div>
    </app-modal>
</ng-template>

<!-- receipt Voucher -->
<ng-template #voucher>
    <app-modal [size]="'medium'" [loading]="isSaving" [modalActionButton]="compBtn" [modalTitle]="'Payment Voucher'">
        <div class="row mx-0">
            <div class="col-12">
                <p>Receipt Form</p>
            </div>
        </div>
    </app-modal>
</ng-template>

<!-- receipt Voucher -->
<ng-template #tests>
    <app-modal [size]="'extraLong'" [loading]="isSaving" [isModalBtn]="pendingTests.length !== 0"
        [modalActionButton]="testBtn" [modalTitle]="'Lab Tests'">
        <div class="row mx-0">
            <div class="col">
                <span>Lab Test Analysis</span>
                <div *ngIf="jobDetails?.jobTests.length === 0">
                    <app-empty-placeholder></app-empty-placeholder>
                </div>
                <div class="table-card" *ngIf="jobDetails?.jobTests.length !== 0">
                    <div class="table-card-body">
                        <div class="">
                            <table class="table display table-bordered table-striped bg-white m-0">
                                <thead>
                                    <th>Category</th>
                                    <th scope="col">Test</th>
                                    <th scope="col">Range</th>
                                    <th scope="col">Result</th>
                                    <th scope="col">Var</th>
                                    <th scope="col">Status</th>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let data of jobDetails.jobTests; let i = index">
                                        <tr>
                                            <td>
                                                <span>{{data.test?.testCategoryData?.name}}</span>
                                            </td>
                                            <td>
                                                <span>{{data.test?.name}}</span>
                                            </td>
                                            <td>
                                                <span>{{data.test?.start}} - {{data.test?.end}}</span>
                                            </td>
                                            <td>
                                                <span>{{data.testResult}}</span>
                                            </td>
                                            <td>
                                                <span>{{data.variation}}</span>
                                            </td>
                                            <td>
                                                <span>{{data.status}}</span>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col border-left">
                <span>Pending Lab Test </span>
                <div *ngIf="pendingTests.length == 0">
                    <app-empty-placeholder></app-empty-placeholder>
                </div>
                <div class="table-card" *ngIf="pendingTests.length !== 0">
                    <div class="table-card-body">
                        <div class="">
                            <table class="table display table-bordered table-striped bg-white m-0">
                                <thead>
                                    <th>Category</th>
                                    <th scope="col">Test</th>
                                    <th scope="col">Range</th>
                                    <th scope="col">Result</th>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let entry of testEntries.controls; let i = index">
                                        <tr>
                                            <td><span>{{customEntryCell('testCat', i).value}}</span></td>
                                            <td>
                                                <span>{{customEntryCell('testName', i).value}}</span>
                                            </td>
                                            <td>
                                                <span>{{customEntryCell('range', i).value}}</span>
                                            </td>
                                            <td>
                                                <div class="form-group mb-0">
                                                    <app-input-figure [mb0]="'mb-0'"
                                                        [control]="customEntryCell('testResult', i)">
                                                    </app-input-figure>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-modal>
</ng-template>