<div class="container-xl">
  <div class="row mx-0">
    <h1 class="app-page-title text-muted">Stock Item Report</h1>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div *ngIf="loading" class=" d-flex justify-content-center ">
            <div>
              <span class="spinner-border ml-3" role="status">
              </span>
              <p class="text-center">Loading...</p>
            </div>
          </div>
          <div *ngIf="!loading">
            <div class="pdf-file" *ngIf="pdfReport !== undefined">
              <ngx-extended-pdf-viewer [base64Src]="pdfReport.pdf.data" height="80vh">
              </ngx-extended-pdf-viewer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>