<div class="app">
  <!-- alert -->
  <app-alert></app-alert>
  <!-- navigation -->
  <header class="app-header fixed-top">

    <app-admin-header></app-admin-header>

  </header>
  <!-- navigation -->

  <!-- main content -->
  <div class="app-wrapper">
    <div class="app-content pt-3 p-md-3 px-lg-0">
      <router-outlet></router-outlet>
      <app-slide-panel></app-slide-panel>
    </div>
  </div>
  <!-- main content -->
</div>