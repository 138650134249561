import { Directive, DoCheck, ElementRef, Input, OnInit } from "@angular/core";
import { PermissionManagerService } from "../permissions/permission-manager/permission-manager.service";
import { SessionStorageService } from "../session/session-storage.service";

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[hide-module]",
})
export class HideModuleDirective implements OnInit, DoCheck {
  constructor(public session: SessionStorageService, private el: ElementRef) {}

  @Input() moduleQuery!: string;
  @Input() open = false;

  roles: any[] = [];

  ngOnInit(): void {
    this._check();
    this.roles = this.session.getUserExcess();
  }

  ngDoCheck(): void {
    this._check();
  }

  _check(): void {
    if (this.moduleQuery) {
      this.roles.forEach((role: any) => {
        if (role.section === this.moduleQuery) {
          if (!role.canAccess) {
            this.el.nativeElement.style.display = "none";
          }
        }
      });
    }
    // if (!this.open) {
    //   if (!this.permissions.isGranted(this.roleQuery)) {
    //     this.el.nativeElement.style.display = 'none';
    //   }
    // }
  }
}
