import { Component, DoCheck, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ASSETSROOT } from 'src/app/services/api/api-endpoints';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ProfileService } from 'src/app/services/proflie/profile.service';


class BaseClass { }
interface BaseClass extends ModalComponent { }
applyMixins(BaseClass, [ModalComponent]);
@Component({
    selector: 'app-user-account',
    templateUrl: './user-account.component.html',
    styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent extends BaseClass implements OnInit, DoCheck {

    activeUser: any;
    userPhoto: any;
    userDetailsForm: FormGroup = new FormGroup({});

    updateUserPhotoForm!: FormGroup;
    updatePhotoBtn: any;

    updatePasswordForm!: FormGroup;

    saving = false;
    match = false;
    assetsroot = ASSETSROOT;

    constructor(
        public apiConnect: ApiConnectionService,
        public modalService: BsModalService,
        public profile: ProfileService,
        public authService: AuthenticationService
    ) {
        super();

        this.updateUserPhotoForm = new FormGroup({
            photo: new FormControl(null)
        });

        this.updatePasswordForm = new FormGroup({
            oldPassword: new FormControl(null, [Validators.required,]),
            newPassword: new FormControl(null, [Validators.required, Validators.minLength(4)]),
            confirmPassword: new FormControl(null, [Validators.required, Validators.minLength(4)])
        });

        // modal inits
        this.updatePhotoBtn = {
            method: () => {
                this.updateUserPhoto(this.updateUserPhotoForm.value);
            },
            text: 'Update'
        };
    }

    ngOnInit(): void {
        this.confirmpass.valueChanges.subscribe(val => {
            if (val) {
                if (this.newpass.value !== null) {
                    if (this.newpass.value !== val) {
                        this.match = true;
                        this.confirmpass.setErrors({ password_deos_not_match: true });
                    } else {
                        this.match = false;
                    }
                }
            }

        })
    }

    ngDoCheck(): void {
        this.profile.watchStorage().subscribe(val => {
            this.checkActiveUser();
        });
    }

    checkActiveUser(): void {
        const rem = this.authService.sessionStorageService.getActiveUser();
        if (rem) {
            this.activeUser = {
                id: rem.id,
                name: rem.firstname + ' ' + rem.lastname,
                email: rem.email,
                role: rem.role.name
            };
            if (rem.photo) {
                this.userPhoto = rem.photo.url
            }
        }
    }

    changePassword(): void {
        this.saving = true;
        this.apiConnect.secureConnect('PUT', 'ChangePassword', this.updatePasswordForm.value, undefined, undefined, this.onChangePassword.bind(this))
    }
    onChangePassword(res: any, status: string, callback: any): void {
        this.saving = false;
        if (status === 'success') {
            callback('success', 'Success', res.message);
            setTimeout(() => { this.authService.logout() }, 2000)
        } else {
            callback('danger', 'Error', res.message.error);
        }
    }

    updateUserPhoto(form: any): void {
        this.saving = true;
        this.apiConnect.secureConnect('PUT', 'UserProfileImage', form.photo, undefined, undefined, this.onUpdateUserPhoto.bind(this));
    }
    onUpdateUserPhoto(res: any, status: string, callback: any): void {
        this.saving = false;
        if (status === 'success') {
            callback('success', 'Success', res.message);
            this.profile.updateUserData(this.activeUser.id);
            this.closeModal();
        } else {
            callback('danger', 'Error', res.message.error);
        }
    }

    get photo(): FormControl {
        return this.updateUserPhotoForm.get('photo') as FormControl;
    }

    get oldpass(): FormControl {
        return this.updatePasswordForm.get('oldPassword') as FormControl;
    }
    get newpass(): FormControl {
        return this.updatePasswordForm.get('newPassword') as FormControl;
    }
    get confirmpass(): FormControl {
        return this.updatePasswordForm.get('confirmPassword') as FormControl;
    }
}
