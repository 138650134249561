import {Injectable} from '@angular/core';
import {AlertService} from '../../services/alert/alert.service';
import {ApiConnectionService} from '../../services/api/apiconnection.service';

@Injectable({
    providedIn: 'root',
})
export class DashBoardService {
    constructor(
        private apiConnect: ApiConnectionService,
        private alertService: AlertService
    ) {
    }

    getCards(
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('GET', 'DashBoardCards', undefined, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    getSalesTrend(
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('GET', 'DashBoardSalesTrend', undefined, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    getStockCard(
        onSave: (res?: any, status?: boolean) => void,
        queryParam?: any,
        id?: any
    ): void {
        this.apiConnect
            .callApi('GET', 'DashBoardStockCard', undefined, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

    getMarkedVols(link: string,
                  onSave: (res?: any, status?: boolean) => void,
                  queryParam?: any,
                  id?: any
    ): void {
        this.apiConnect
            .callApi('GET', link, undefined, queryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message: err.message.error.error,
                    });
                }
            );
    }

}
