// export const APIROOT = "http://92.205.59.8:9090/bankpayments/";
// export const ASSETSROOT = "http://92.205.59.8:9090/bankpayments";
// export const APIROOT = "http://192.168.0.109:8080/fuel-marking-api/";
// export const ASSETSROOT = "http://192.168.0.109:8080/fuel-marking-api";

// export const APIROOT = 'http://192.168.0.113:8080/fuel-marking-api/';
// export const ASSETSROOT = 'http://192.168.0.113:8080/fuel-marking-api';

// export const APIROOT = '/api/fuel-marking-api/';
// export const ASSETSROOT = '/api/fuel-marking-api';

export const APIROOT = 'https://system.ssnbsfuelmarking.org/bankpayments/'; // 'http://localhost:9090/bankpayments/'; //
export const ASSETSROOT =  'https://system.ssnbsfuelmarking.org/bankpayments'; // 'http://localhost:9090/bankpayments/'; //

export class Endpoints {
  apiLinks: Array<{ name: string; url: string; type?: string }> = [];

  getUrl(link: string): string {
    let foundURL = '';
    let type: string | undefined;

    this.apiLinks.forEach((element: any) => {
      if (element.name === link) {
        foundURL = element.url;
      }
    });
    return APIROOT + '' + foundURL;
  }

  fillURLLinks(): void {
    // Login
    this.apiLinks.push({ name: 'Login', url: 'auth/login' });
    this.apiLinks.push({
      name: 'RecoverPassword',
      url: 'auth/forgot-password',
    });
    this.apiLinks.push({ name: 'confirmEmail', url: 'auth/password' });

    // Dashboard
    this.apiLinks.push({ name: 'DashBoardCards', url: 'dashboard/cards' });
    this.apiLinks.push({
      name: 'DashBoardMarkedFuelVol',
      url: 'dashboard/marked-fuel-volume',
    });
    this.apiLinks.push({
      name: 'DashBoardMarkedFuelAgoVol',
      url: 'dashboard/marked-fuel-volume/ago',
    });
    this.apiLinks.push({
      name: 'DashBoardMarkedFuelPmsVol',
      url: 'dashboard/marked-fuel-volume/pms',
    });
    this.apiLinks.push({
      name: 'DashBoardMarkedFuelJetVol',
      url: 'dashboard/marked-fuel-volume/jet',
    });
    this.apiLinks.push({
      name: 'DashBoardSalesTrend',
      url: 'dashboard/sales-trend',
    });
    this.apiLinks.push({
      name: 'DashBoardStockCard',
      url: 'dashboard/stock-cards',
    });

    // session
    this.apiLinks.push({ name: 'Session', url: 'session' });
    this.apiLinks.push({ name: 'ApproveSession', url: 'session/approve' });
    this.apiLinks.push({ name: 'UpdateSession', url: 'session/update' });
    this.apiLinks.push({ name: 'ActiveSession', url: 'session/active' });
    this.apiLinks.push({ name: 'CloseSession', url: 'session/close' });

    // job
    this.apiLinks.push({ name: 'Job', url: 'job' });
    this.apiLinks.push({ name: 'CreditJob', url: 'job/credit-jobs' });
    this.apiLinks.push({ name: 'RequestCredit', url: 'job/request-credit' });
    this.apiLinks.push({ name: 'PermitCredit', url: 'job/permit-credit' });
    this.apiLinks.push({
      name: 'JobWithoutDipping',
      url: 'job/create-without-dipping',
    });
    this.apiLinks.push({ name: 'LabJobs', url: 'job/lab-processing' });
    this.apiLinks.push({ name: 'DataEntryJobs', url: 'job/data-entry' });
    this.apiLinks.push({ name: 'CashierJobs', url: 'job/cashier' });
    this.apiLinks.push({ name: 'ArchiveJobs', url: 'job/archive' });
    this.apiLinks.push({ name: 'UpdateJob', url: 'job/update' });
    this.apiLinks.push({ name: 'ExemptJob', url: 'job/exempt' });
    this.apiLinks.push({ name: 'JobComplete', url: 'job/complete' });
    this.apiLinks.push({ name: 'ProceedLab', url: 'job/proceed-to-lab' });
    this.apiLinks.push({ name: 'JobProcess', url: 'job/process' });
    this.apiLinks.push({ name: 'JobVoucher', url: 'job/voucher' });
    this.apiLinks.push({ name: 'AcceptInfo', url: 'job/accept-info' });
    this.apiLinks.push({ name: 'ChangeJobCLient', url: 'job/update-to-client' });
    this.apiLinks.push({
      name: 'ReceivePayment',
      url: 'job-payments/with-job',
    });
    this.apiLinks.push({
      name: 'PaymentWithBalance',
      url: 'job-payments/pay-with-balance',
    });

    this.apiLinks.push({
      name: 'PaymentReceipt',
      url: 'job-payments/print-receipt',
    });
    this.apiLinks.push({
      name: 'ReceiveClientPayment',
      url: 'job-payments/without-job',
    });
    this.apiLinks.push({ name: 'VerifyPayment', url: 'job/verify-payment' });
    this.apiLinks.push({ name: 'RejectPayment', url: 'job/reject-payment' });
    this.apiLinks.push({
      name: 'VariationComments',
      url: 'job/variation-comments',
    });
    // cargo
    this.apiLinks.push({ name: 'Compartment', url: 'compartment' });
    // bill-of-lading
    this.apiLinks.push({ name: 'BillOfLanding', url: 'bill-of-landing' });

    // job pdfs
    this.apiLinks.push({
      name: 'AcknowledgementForm',
      url: 'job-reports/acceptance-report',
    });
    this.apiLinks.push({
      name: 'CertificateForm',
      url: 'job-reports/certificate',
    });
    this.apiLinks.push({ name: 'InvoicePrint', url: 'job-reports/invoice' });
    this.apiLinks.push({
      name: 'PaymentVoucher',
      url: 'job-reports/payment-voucher',
    });
    // job reports
    this.apiLinks.push({
      name: 'CompletedJobs',
      url: 'job-reports/completed-jobs',
    });
    this.apiLinks.push({
      name: 'CompletedJobsXls',
      url: 'job-reports/completed-jobs/xls',
    });
    this.apiLinks.push({
      name: 'IncompleJobs',
      url: 'reports/incomplete-jobs',
    });
    this.apiLinks.push({ name: 'JobPayments', url: 'reports/payments' });

    // dipping,
    this.apiLinks.push({ name: 'Dipping', url: 'dipping' });

    // reports utility
    this.apiLinks.push({ name: 'ReportUtility', url: 'report-utility' });
    this.apiLinks.push({
      name: 'ReportCreateFreq',
      url: 'report-utility/create-frequency',
    });
    this.apiLinks.push({
      name: 'ReportUtilityFreq',
      url: 'report-utility/frequencies',
    });
    this.apiLinks.push({
      name: 'ReportUtilityReports',
      url: 'report-utility/reports',
    });
    this.apiLinks.push({
      name: 'UpdateReportUtilityFreq',
      url: 'report-utility/update-frequency',
    });
    this.apiLinks.push({
      name: 'UpdateReportUtility',
      url: 'report-utility/update-schedule',
    });

    // Inventory
    this.apiLinks.push({ name: 'InventoryStore', url: 'store' });
    this.apiLinks.push({ name: 'InventoryItem', url: 'stock-item' });
    this.apiLinks.push({ name: 'GenInventoryItem', url: 'stock-item/general' });
    this.apiLinks.push({ name: 'InventoryItemPhoto', url: 'stock-item/photo' });
    this.apiLinks.push({ name: 'Vendor', url: 'vendor' });
    this.apiLinks.push({ name: 'PurchaseOrder', url: 'purchase-order' });
    this.apiLinks.push({ name: 'DirectPurchase', url: 'direct-purchase' });
    this.apiLinks.push({ name: 'DirectPurchase', url: 'direct-purchase' });
    this.apiLinks.push({
      name: 'DirectPurchaseApprove',
      url: 'direct-purchase/approve',
    });

    this.apiLinks.push({ name: 'adjustment', url: 'adjustment' });
    this.apiLinks.push({
      name: 'adjustmentApprove',
      url: 'adjustment/approve',
    });

    // receive
    this.apiLinks.push({ name: 'StockReceive', url: 'stock-receive' });
    this.apiLinks.push({
      name: 'StockReceiveApprove',
      url: 'stock-receive/approve',
    });
    this.apiLinks.push({
      name: 'StockReceiveReverse',
      url: 'stock-receive/reverse',
    });

    // adjust stock
    this.apiLinks.push({ name: 'StockAdjustment', url: 'adjustment' });
    this.apiLinks.push({
      name: 'StockAdjustmentApprove',
      url: 'adjustment/approve',
    });
    this.apiLinks.push({
      name: 'StockAdjustmentReverse',
      url: 'adjustment/reverse',
    });
    this.apiLinks.push({ name: 'StockItemReport', url: 'stock-item-report' });
    this.apiLinks.push({
      name: 'StockActivity',
      url: 'stock-item-report/activity',
    });

    // settings
    this.apiLinks.push({ name: 'MarkerItem', url: 'marker-item' });
    this.apiLinks.push({ name: 'Marker', url: 'marker' });
    this.apiLinks.push({ name: 'Lab', url: 'labaratory' });
    this.apiLinks.push({ name: 'FuelType', url: 'fuel-Types' });
    this.apiLinks.push({
      name: 'CustomersList',
      url: 'customer/detailed-list',
    });
    this.apiLinks.push({
      name: 'CustomersListXls',
      url: 'customer/detailed-list/xls',
    });
    this.apiLinks.push({ name: 'Customers', url: 'customer' });
    this.apiLinks.push({
      name: 'CustomerDetails',
      url: 'customer/client-details',
    });
    this.apiLinks.push({
      name: 'CustomersDetailed',
      url: 'customer/detailed-list',
    });
    this.apiLinks.push({ name: 'Drivers', url: 'drivers' });
    this.apiLinks.push({ name: 'Nationality', url: 'drivers/nationality' });

    // lab tests
    this.apiLinks.push({ name: 'TestCategory', url: 'test-category' });
    this.apiLinks.push({
      name: 'TestCategoryActivate',
      url: 'test-category/activate',
    });
    this.apiLinks.push({
      name: 'TestCategoryDeactivate',
      url: 'test-category/deactivate',
    });
    this.apiLinks.push({ name: 'Test', url: 'tests' });
    this.apiLinks.push({ name: 'TestActivate', url: 'tests/activate' });
    this.apiLinks.push({ name: 'TestDeactivate', url: 'tests/deactivate' });

    this.apiLinks.push({ name: 'JobPendingTests', url: 'job/pending-tests' });
    this.apiLinks.push({ name: 'JobAddTests', url: 'job/test' });

    // Users
    this.apiLinks.push({ name: 'User', url: 'user' });
    this.apiLinks.push({ name: 'UserDeactivate', url: 'user/deactivate' });
    this.apiLinks.push({ name: 'UserActivate', url: 'user/activate' });
    this.apiLinks.push({ name: 'UserResend', url: 'user/resend-credentials' });

    // User-account
    this.apiLinks.push({ name: 'GetDetails', url: 'account-user' });
    this.apiLinks.push({
      name: 'ChangePassword',
      url: 'account-user/password-update',
    });
    this.apiLinks.push({
      name: 'UserProfileImage',
      url: 'account-user/profile-picture',
    });

    // Roles
    this.apiLinks.push({ name: 'Role', url: 'role' });
    this.apiLinks.push({ name: 'ApproveRole', url: 'role/approve' });
    this.apiLinks.push({ name: 'ActivateRole', url: 'role/activate' });
    this.apiLinks.push({ name: 'DeactivateRole', url: 'role/deactivate' });
    this.apiLinks.push({
      name: 'UpdateRolePermission',
      url: 'role/definition',
    });

    // Permissions
    this.apiLinks.push({
      name: 'UpdateCategoryPermission',
      url: 'permissions/category',
    });
    this.apiLinks.push({
      name: 'UpdateCategoryItemPermission',
      url: 'permissions/category-item',
    });
    this.apiLinks.push({
      name: 'UpdateCategoryPrivilegePermission',
      url: 'permissions/privilege',
    });

    // setup
    this.apiLinks.push({ name: 'Business', url: 'business' });
    this.apiLinks.push({ name: 'BusinessHeader', url: 'business/header' });
    this.apiLinks.push({ name: 'BusinessLogo', url: 'business/logo' });
    this.apiLinks.push({ name: 'Site', url: 'site' });
    this.apiLinks.push({ name: 'UserSites', url: 'site/user-sites' });
    this.apiLinks.push({ name: 'SiteManager', url: 'site-manager' });
    this.apiLinks.push({
      name: 'BusinessList',
      url: 'business/user-businesses',
    });

    // setUp
    this.apiLinks.push({ name: 'TimeZone', url: 'time-zone' });
    this.apiLinks.push({ name: 'Currency', url: 'currency' });

    // accounting
    this.apiLinks.push({
      name: 'CoaLedger',
      url: 'business/accounting/ledgers/chartofaccounts',
    });
    this.apiLinks.push({
      name: 'LedgerBalance',
      url: 'business/accounting/ledgers/chartledgerbalance',
    });
    this.apiLinks.push({
      name: 'LedgerOpeningBalance',
      url: 'business/accounting/ledgers/get-opening-balance-ledgers',
    });
    this.apiLinks.push({
      name: 'LedgerSaveOpeningBalance',
      url: 'business/accounting/ledgers/post-opening-balances',
    });
    this.apiLinks.push({
      name: 'LedgerPostable',
      url: 'business/accounting/ledgers/postable',
    });
    this.apiLinks.push({ name: 'Ledger', url: 'business/accounting/ledgers' });
    this.apiLinks.push({
      name: 'LedgerGetLedgerHistory',
      url: 'business/accounting/ledgers/ledger-history',
    });

    this.apiLinks.push({
      name: 'JournalPostableCashLedger',
      url: 'business/accounting/ledgers/postable-cash-ledgers',
    });
    this.apiLinks.push({
      name: 'JournalPostableLedger',
      url: 'business/accounting/journals/postable',
    });
    this.apiLinks.push({
      name: 'JournalTypeEnums',
      url: 'business/accounting/journals/journal-type-enums',
    });
    this.apiLinks.push({
      name: 'JournalStatusEnums',
      url: 'business/accounting/journals/journal-status-enums',
    });
    this.apiLinks.push({
      name: 'Journal',
      url: 'business/accounting/journals',
    });
    this.apiLinks.push({
      name: 'JournalApprove',
      url: 'business/accounting/journals/approve',
    });
    this.apiLinks.push({
      name: 'JournalReject',
      url: 'business/accounting/journals/reject',
    });
    this.apiLinks.push({
      name: 'JournalPublish',
      url: 'business/accounting/journals/publish',
    });
    this.apiLinks.push({
      name: 'JournalDecline',
      url: 'business/accounting/journals/decline',
    });
    this.apiLinks.push({
      name: 'JournalUnDecline',
      url: 'business/accounting/journals/undecline',
    });
    this.apiLinks.push({
      name: 'JournalUnReject',
      url: 'business/accounting/journals/unreject',
    });

    this.apiLinks.push({
      name: 'BalanceSheet',
      url: 'business/accounting/balance-sheet',
    });
    this.apiLinks.push({
      name: 'CashFlow',
      url: 'business/accounting/indirect-cash-flow',
    });
    this.apiLinks.push({
      name: 'TrialBalance',
      url: 'business/accounting/trial-balance',
    });
    this.apiLinks.push({
      name: 'IncomeStatement',
      url: 'business/accounting/income-statement',
    });
    this.apiLinks.push({
      name: 'ChangesInEquity',
      url: 'business/accounting/changes-in-equity',
    });

    this.apiLinks.push({
      name: 'Businesses',
      url: 'business/businesses',
    });

    this.apiLinks.push({
      name: 'UserAccess',
      url: 'user/access',
    });
  }
}

// Ats@Y00@Ats
