import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { ExportXlsxComponent } from 'src/app/components/export-xlsx/export-xlsx.component';
import { applyMixins } from 'src/app/components/mixin/mixin';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ASSETSROOT } from 'src/app/services/api/api-endpoints';
import { ApiConnectionService } from 'src/app/services/api/apiconnection.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { ItemService } from '../../inventory/stock-items/item.service';

class BaseClass { }
interface BaseClass extends ModalComponent, ExportXlsxComponent { }
applyMixins(BaseClass, [ModalComponent, ExportXlsxComponent]);

@Component({
    selector: 'app-stock-activity',
    templateUrl: './stock-activity.component.html',
    styleUrls: ['./stock-activity.component.scss']
})
export class StockActivityComponent extends BaseClass implements OnInit {

    reportsList: Array<any> = [];
    posList: Array<any> = [];
    posListSubject: BehaviorSubject<[]> = new BehaviorSubject([]);
    posPredicate: any;

    itemsList: Array<any> = [];
    itemsListSub: BehaviorSubject<[]> = new BehaviorSubject([]);
    itemsPredicate!: object;

    currentFilter: any = {};
    reportForm = new FormGroup({
        itemId: new FormControl(null, Validators.required),
        posId: new FormControl(null),
        dateRange: new FormControl([this.settingsService.getCurrentMonthDates().first,
            this.settingsService.getCurrentMonthDates().last])
    });

    exporting = false;
    showFilter = false;
    assetsRoot = ASSETSROOT;
    loading = false;
    userType: any;
    pdfReport: any;

    totalSales = 0;

    constructor(
        private itemService: ItemService,
        public modalService: BsModalService,
        public apiConnect: ApiConnectionService,
        public authService: AuthenticationService,
        private settingsService: SettingsService
    ) {
        super();
        this.posPredicate = { method1: (obj: any) => (obj.id), method2: (obj: any) => (obj.name) };
        this.itemsPredicate = { method1: (obj: any) => (obj.id), method2: (obj: any) => (obj.name) };
    }

    ngOnInit(): void {
        this.getItems();
        this.checkActiveUser();

        this.reportForm.valueChanges.subscribe(val => {
            const currentFilter: any = {};

            if (this.posId.value !== null && this.posId.value !== 'select') {
                currentFilter.posId = this.posId.value;
            }

            currentFilter.startDate = this.filterdateRange.value[0];
            currentFilter.endDate = this.filterdateRange.value[1];

            this.reportsList = [];
            this.currentFilter = currentFilter;
        });

    }

    checkActiveUser(): void {
        const rem = this.authService.sessionStorageService.getActiveUser();
        if (rem) {
            this.userType = rem.type;
        }
    }

    getItems(): void {
        this.itemService.getGenItem((res: any, status: any) => {
            if (status) {
                this.itemsList = res?.items;
                this.itemsListSub.next(res?.items);
            }
        });
    }

    getReports(): void {
        if (this.reportForm.value?.itemId) {
            this.loading = true;
            this.apiConnect.secureConnect('GET', 'StockActivity', undefined, this.currentFilter, this.reportForm.value?.itemId, this.onGetReports.bind(this));
        }
    }

    onGetReports(res: any, status: string, callback: any): void {
        this.loading = false;
        if (status === 'success') {
            this.pdfReport = res;
        } else {
            callback('danger', 'Error', res.message.error);
        }
    }

    exportExcelReport(): void {
        this.exporting = true;
        if (this.pdfReport) {
            const blobFile = this.convertBase64xlsxToBlob(this.pdfReport.xls.data);
            this.exportXlsx(blobFile, 'Stock-Item-Report.xlsx');
        }
        this.exporting = false;
    }

    // Form Controls
    get filterdateRange(): FormControl {
        return this.reportForm.get('dateRange') as FormControl;
    }
    get filtercompany(): FormControl {
        return this.reportForm.get('companyId') as FormControl;
    }
    get filterdepartment(): FormControl {
        return this.reportForm.get('departmentId') as FormControl;
    }
    get posId(): FormControl {
        return this.reportForm.get('posId') as FormControl;
    }
    get itemId(): FormControl {
        return this.reportForm.get('itemId') as FormControl;
    }

}
