import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseFormComponent } from '../base-form/base-form.component';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { componentMixin } from '../../component.mixin';
import ResizeObserver from "resize-observer-polyfill";

class BaseClass { }
interface BaseClass extends BaseFormComponent { }
componentMixin(BaseClass, [BaseFormComponent]);

@Component({
  selector: 'app-input-select-new',
  templateUrl: './input-select-new.component.html',
  styleUrls: ['./input-select-new.component.scss']
})
export class InputSelectComponentNew extends BaseClass implements OnInit, AfterViewInit, OnDestroy {

  @Input() fieldId!: string;
  @Input() control!: FormControl;

  @Input() selectSearch!: boolean;
  @Input() groupedOptions!: boolean;
  @Input() multipleSelect!: boolean;

  @Input() mb: string = '';
  @Input() inTable = true;
  @Input() readOnly = false;
  @Input() isFilterVar = false;
  @Input() isHidden: boolean | undefined = false;

  @Input() predicate: any;
  @Input() options: Array<any> = [];
  @Input() selected: Array<any> = [];
  @Input() optionsList: BehaviorSubject<[]> | undefined;

  searchText: string = '';
  selectKeyClass!: string;
  filterList = ['display'];
  selectionList: FormControl = new FormControl();
  iteratableOptions: Array<{ ngValue: any, display: string }> = [];

  constructor() {
    super();
    this.optionsList = new BehaviorSubject([]);
    this.selectKeyClass = 'select_' + (Math.random().toString()).split('.').join('') + (new Date()).getTime().toString();
  }

   ngOnInit(): void {
    super.ngOnInit();
    this.formatOptions();

    this.optionsList?.subscribe((val: any[]) => {
      this.options = val;
      this.formatOptions();
    });

    this.selectionList.valueChanges.subscribe((val: any) => {
      if ((this._checkIfItemExists(val) < 0) && val !== 'select') {
        this.selected.push(val);
        this.control.setValue(this.selected);
      }
    });
  }

  ngAfterViewInit(): void {
    window.addEventListener('click', ($event: any) => {
      document.querySelectorAll('.select2-container--open').forEach((selection: Element) => {
        if (!selection?.contains($event.target) && !document.querySelector('.' + this.selectKeyClass)?.contains($event.target)) {
          document.querySelector('.' + this.selectKeyClass)?.classList.remove('select2-container--open');
        }
      });
    });

    if (this.inTable) {
      this.selectSizeObserver();
    }
  }

  selectSizeObserver(): void {
    const input: Element | null = document.querySelector('.'+this.selectKeyClass);
    const optionsContainer: HTMLElement | null = document.querySelector('.'+this.selectKeyClass+ ' .select2-dropdown.select2-dropdown--below')

    const resizeObserver = new ResizeObserver((entries: any) => {
      entries.forEach((entry: any) => {
        if (optionsContainer) {
          optionsContainer.style.width = (entry.contentBoxSize[0].inlineSize).toString() + 'px';
        }
      })
    })

    if (input) {
      resizeObserver.observe(input);
    }
  }

  toggleSelectDropdown($event: any): void {
    let selectSearch: HTMLElement;
    let selectContainer: HTMLElement;
    let removeSelection: HTMLElement | null;
    let path = $event.path || ($event.composedPath && $event.composedPath())

    selectContainer = path.find((element: HTMLElement) => {
      return element.classList?.contains(this.selectKeyClass)
    });

    selectSearch = path.find((element: HTMLElement) => {
      return element.classList?.contains('select2-search__field')
    });

    if (this.multipleSelect) {
      removeSelection = path.find((element: HTMLElement) => {
        return element.classList?.contains('select2-selection__choice__remove')
      });
    } else {
      removeSelection = null;
    }

    if (!selectSearch && !removeSelection) {
      selectContainer.classList.toggle('select2-container--open');
    }

    if (selectContainer.classList.contains('select2-container--open')) {
      this.control.markAsTouched({ onlySelf: true });
    }
  }

  selectItem(selectedValue: any): void {
    if (this.multipleSelect) {
      const index = this._checkIfItemExists(selectedValue);
      if (index < 0) {
        this.selected.push(selectedValue);
        this.control.setValue(this.selected);
      }
    }
    else {
      this.control.setValue(selectedValue);
    }
  }

  getSelection(item?: any): string {
    if (this.multipleSelect) {
      return this._findDisplayName(item);
    } else {
      return this._findDisplayName();
    }
  }

  removeSelection(item: any): void {
    const index = this._checkIfItemExists(item);
    if (index >= 0) {
      this.selected.splice(index, 1);
      this.control.setValue(this.selected);
    }
  }

  _checkIfItemExists(item: any): any {
    return this.selected.findIndex((val: any) => this.isEqual(val, item));
  }

  _findDisplayName(item?: any): string {
    const value = this.iteratableOptions.find((val: any) => this.isEqual(val.ngValue, item || this.control.value));
    return value ? value.display : ''
  }

  isEqual(a: any, b: any): boolean {
    if (typeof a === 'object' && typeof b === 'object') {
      const aProps = Object.getOwnPropertyNames(a);
      const bProps = Object.getOwnPropertyNames(b);
      if (aProps.length !== bProps.length) {
        return false;
      }

      for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];
        if (a[propName] !== b[propName]) {
          return false;
        }
      }

      return true;
    } else {
      return a === b;
    }
  }

  formatOptions(): void {
    this.iteratableOptions = [];
    this.options.forEach((val: any) => {
      this.iteratableOptions.push({
        ngValue: this.predicate.method1(val),
        display: this.predicate.method2(val)
      });
    });
  }

  ngOnDestroy(): void {

  }
}
