import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { BsModalService } from "ngx-bootstrap/modal";
import { FilterComponent } from "src/app/components/filter/filter.component";
import { applyMixins } from "src/app/components/mixin/mixin";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { PaginatorComponent } from "src/app/components/paginator/paginator.component";
import { EMAIL_PATTERN } from "src/app/components/validations-pattern/list";
import { ASSETSROOT } from "src/app/services/api/api-endpoints";
import { ApiConnectionService } from "src/app/services/api/apiconnection.service";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { UserService } from "src/app/pages/settings/users/users.service";
import { RoleService } from "../roles/roles.service";
import { BehaviorSubject } from "rxjs";
import { WorkSpaceService } from "../../auth/workspace/workspace.service";
import { ActivatedRoute } from "@angular/router";

class BaseClass {}
interface BaseClass
  extends ModalComponent,
    PaginatorComponent,
    FilterComponent {}
applyMixins(BaseClass, [ModalComponent, PaginatorComponent, FilterComponent]);

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent extends BaseClass implements OnInit {
  currentPage = 0;
  elementsPerPage = 10;
  showEntries: FormControl = new FormControl(10);
  chunkedRecords: Array<any> = [];
  searchFilter: string = "";

  sitesList: Array<any> = [];
  sitesListSubject: BehaviorSubject<[]> = new BehaviorSubject([]);
  sitesPredicate: any;

  rolesList: Array<any> = ['ARXCESS', 'USER', 'ADMIN'];
  roleListSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  userRolePredicate: any;

  usersList: Array<any> = [];
  siteInfo!: FormArray;
  newUserForm!: FormGroup;
  saveUserBtn: any;

  businesses: any[] = [];

  editUserForm!: FormGroup;
  editUserBtn: any;
  selectedUser: any;
  @ViewChild("editusermodal") selectedTemplate!: TemplateRef<any>;

  userType: any;
  saving = false;
  showFilter = false;
  assetsRoot = ASSETSROOT;
  loading = false;
  rolling = false;

  constructor(
    public modalService: BsModalService,
    private route: ActivatedRoute,
    public apiConnect: ApiConnectionService,
    public authService: AuthenticationService,
    private userService: UserService,
    private roleService: RoleService,
    private workSpaceService: WorkSpaceService
  ) {
    super();

    this.newUserForm = new FormGroup({
      email: new FormControl(null, Validators.required),
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      othername: new FormControl(),
      phone: new FormControl(),
      isSuperAdmin: new FormControl(),
      role: new FormControl(null, Validators.required),
      photo: new FormControl(),
      businessId: new FormControl(),
    });
    this.siteInfo = new FormArray([
      new FormGroup({
        siteId: new FormControl(null, Validators.required),
        allowedStartTime: new FormControl(null, Validators.required),
        allowedEndTime: new FormControl(null, Validators.required),
      }),
    ]);

    this.saveUserBtn = {
      method: () => {
        this.saveUser(this.newUserForm.value);
      },
      text: "save",
    };
    this.editUserBtn = {
      method: () => {
        this.updateUser(this.editUserForm.value);
      },
      text: "update",
    };
    this.sitesPredicate = {
      method1: (obj: any) => obj.id,
      method2: (obj: any) => obj.name,
    };
    this.userRolePredicate = {
      method1: (obj: any) => obj,
      method2: (obj: any) => obj,
    };
    this.filterList = ["firstname", "lastname", "username", "role|name"];
    this.showEntries.valueChanges.subscribe((wes) => {
      this.elementsPerPage = wes;
    });
  }

  ngOnInit(): void {
    this.checkActiveUser();
    this.getUsers();
    this.getSites();
    this.roleListSubject.next(["MANAGER", "CASHIER", "DATA_ENTRY", "LAB"]);
    this.getBusinesses();
  }

  checkActiveUser(): void {
    const rem = this.authService.sessionStorageService.getActiveUser();
    if (rem) {
      this.userType = rem.type;
    }
  }

  getSites(): void {
    this.loading = true;
    this.workSpaceService.getUserSites((res, status) => {
      this.loading = false;
      if (status) {
        this.sitesList = res;
        this.sitesListSubject.next(res);
      }
    }, undefined);
  }

  getUsers(): void {
    this.loading = true;
    this.userService.saveUser("GET", undefined, (res, status) => {
      this.loading = false;
      if (status) {
        this.usersList = res;
      }
    });
  }

  saveUser(form: any): void {
    this.saving = true;
    this.userService.saveUser(
      "POST",
      { ...form, siteInfo: this.siteInfo.value },
      (res, status) => {
        this.saving = false;
        this.closeModal();
        if (status) {
          this.getUsers();
          this.newUserForm.reset();
          this.newUserForm.get("siteIds")?.patchValue([]);
        }
      }
    );
  }

  _editUser(template: TemplateRef<any>, item: any): void {
    console.log(item);
    this.editUserForm = new FormGroup({
      id: new FormControl(item.id),
      email: new FormControl(item.email, [
        Validators.required,
        Validators.pattern(EMAIL_PATTERN),
      ]),
      firstname: new FormControl(item.firstname, Validators.required),
      lastname: new FormControl(item.lastname, Validators.required),
      role: new FormControl(item.role, Validators.required),
      businessId: new FormControl(item.business?.id),
    });
    this.siteInfo.clear();
    if (item.sites.length !== 0) {
      item.sites.forEach((item: any) => {
        this.siteInfo.push(
          new FormGroup({
            siteId: new FormControl(item.site?.id, Validators.required),
            allowedStartTime: new FormControl(
              item.allowedStartTime,
              Validators.required
            ),
            allowedEndTime: new FormControl(
              item.allowedEndTime,
              Validators.required
            ),
          })
        );
      });
    }
    this.selectedUser = item;
    this.modalRef = this.modalService.show(template);
  }

  updateUser(form: any): void {
    this.saving = true;
    this.userService.saveUser(
      "PUT",
      { ...form, siteInfo: this.siteInfo.value },
      (res, status) => {
        this.saving = false;
        this.closeModal();
        if (status) {
          this.getUsers();
          this.editUserForm.reset();
        }
      },
      undefined,
      form.id
    );
  }

  reSendEmail(id: number): void {
    this.apiConnect.secureConnect(
      "PUT",
      "UserResend",
      undefined,
      undefined,
      id,
      this.onReSendEmail.bind(this)
    );
  }
  onReSendEmail(res: any, status: string, callback: any): void {
    if (status === "success") {
      callback("success", "Success", res.message);
      this.getUsers();
    } else {
      callback("danger", "Error", res.message.error);
    }
  }

  activateUser(id: number): void {
    this.apiConnect.secureConnect(
      "PUT",
      "UserActivate",
      undefined,
      undefined,
      id,
      this.onActivateUser.bind(this)
    );
  }
  onActivateUser(res: any, status: string, callback: any): void {
    if (status === "success") {
      callback("success", "Success", res.message);
      this.getUsers();
    } else {
      callback("danger", "Error", res.message.error);
    }
  }

  deactivateUser(id: number): void {
    this.apiConnect.secureConnect(
      "PUT",
      "UserDeactivate",
      undefined,
      undefined,
      id,
      this.onDeactivateUser.bind(this)
    );
  }
  onDeactivateUser(res: any, status: string, callback: any): void {
    if (status === "success") {
      callback("success", "Success", res.message);
      this.getUsers();
    } else {
      callback("danger", "Error", res.message.error);
    }
  }

  deleteUser(id: number): void {
    this.userService.saveUser(
      "DELETE",
      undefined,
      (res, status) => {
        if (status) {
          this.getUsers();
        }
      },
      undefined,
      id
    );
  }

  getBusinesses(): void {
    this.userService.getBusinesses((res, status) => {
      if (status) {
        this.businesses = res;
      }
    });
  }

  onFilterUpdate(event: any): void {
    this.filterList = event;
    if (this.filterList.length === 0) {
      this.filterList = ["name"];
    }
  }
  displayActivePage(chunckedData: any): void {
    setTimeout(() => {
      this.chunkedRecords = chunckedData.chunks;
      if (chunckedData.controlClicked) {
        this.currentPage = chunckedData.currentPage;
      }
    });
  }

  newEntry(): void {
    this.siteInfo.push(
      new FormGroup({
        siteId: new FormControl(null, Validators.required),
        allowedStartTime: new FormControl(null, Validators.required),
        allowedEndTime: new FormControl(null, Validators.required),
      })
    );
  }

  deleteRow(index: number): void {
    this.siteInfo.removeAt(index);
  }

  customControl(name: string, group: FormGroup): FormControl {
    return group.get(name) as FormControl;
  }
  customEntryCell(name: string, index: number): FormControl {
    return (this.siteInfo.controls[index] as FormGroup)?.get(
      name
    ) as FormControl;
  }

  toggleExpansion(item: any) {
    item.expanded = !item.expanded;
  }

  onCheckboxChange(id: any): void {
    this.rolling = true;
    this.userService.toggleUserAccess(id, (res, status) => {
      this.rolling = false;
      if (status) {
        // this.closeModal();
        this.getUsers();
        this.usersList.forEach((user) => {
          if (user.id === id) {
            this._editUser(this.selectedTemplate, this.selectedUser);
          }
        });
      }
    });
  }
}
