
import { Injectable } from '@angular/core';
import { AlertService } from '../../../services/alert/alert.service'
import { ApiConnectionService } from '../../../services/api/apiconnection.service'

@Injectable({
    providedIn: 'root'
})
export class JobReportsService {

    constructor(
        private apiConnect: ApiConnectionService,
        private alertService: AlertService,
    ) {
    }


    getJobReports(link: string, form: any, onSave: (res?: any, status?: boolean) => void, quaryParam?: any, id?: any): void {
        this.apiConnect.callApi('GET', link, form, quaryParam, id)
            .subscribe(
                (res: any) => {
                    onSave(res, true);
                    if (link !== 'GET') {
                        this.alertService.setAlert({
                            type: 'success',
                            title: 'Success',
                            code: res.status,
                            message: res.message,
                        });
                    }
                },
                (err: any) => {
                    onSave(err, false);
                    this.alertService.setAlert({
                        type: 'danger',
                        title: 'Error',
                        code: err.status,
                        message:   err.message.error.error,
                    });
                }
            );
    }

}